import moment from "moment";

const baseUrl = "/sys/roles/auth";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    roleId:number,
    actionId:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    roleName: string;
    accessResource: string;
    effTime: string;
    expTime: string;
    statusDesc: string;
    notes: string;
}

export interface RoleAuthByIdObj {
    roleId: number;
    actionIds: number[];
    effTime: string;
    expTime: string;
    statusCd: string;
    notes: string;
}



export  {baseUrl};

