// src/layouts/tabs-layout.tsx
import React, { useCallback, useMemo } from "react";
import {Dropdown, Tabs} from 'antd';
import {KeepAliveTab, useTabs} from "./useTabs";
import router from "../../router";
import {MenuItemType} from "antd/es/menu/hooks/useItems";
import { KeepAliveTabContext } from './tabs-context';

enum OperationType {
    REFRESH = 'refresh',
    CLOSE = 'close',
    CLOSEOTHER = 'close-other',
}
const TabsLayout: React.FC = () => {

    const { activeTabRoutePath, tabs, closeTab, refreshTab, closeOtherTab } = useTabs();

    const menuItems: MenuItemType[] = useMemo(
        () => [
            {
                label: '刷新',
                key: OperationType.REFRESH,
            },
            tabs.length <= 1 ? null : {
                label: '关闭',
                key: OperationType.CLOSE,
            },
            tabs.length <= 1 ? null : {
                label: '关闭其他',
                key: OperationType.CLOSEOTHER,
            },
        ].filter(o => o !== null) as MenuItemType[],
        [tabs]
    );
    const menuClick = useCallback(({ key, domEvent }: any, tab: KeepAliveTab) => {
        domEvent.stopPropagation();

        if (key === OperationType.REFRESH) {
            refreshTab(tab.routePath);
        } else if (key === OperationType.CLOSE) {
            closeTab(tab.routePath);
        } else if (key === OperationType.CLOSEOTHER) {
            closeOtherTab(tab.routePath);
        }
    }, [closeOtherTab, closeTab, refreshTab]);

    const renderTabTitle = useCallback((tab: KeepAliveTab) => {
        return (
            <Dropdown
                menu={{ items: menuItems, onClick: (e) => menuClick(e, tab) }}
                trigger={['contextMenu']}
            >
                <div className='flex gap-[6px]' style={{ margin: '-12px 0', padding: '12px 0' }}>
                    {/*{getIcon(tab.icon)}*/}
                    {tab.title}
                </div>
            </Dropdown>
        )
    }, [menuItems]);

    const onTabEdit = (
        targetKey: React.MouseEvent | React.KeyboardEvent | string,
        action: 'add' | 'remove',
    ) => {
        if (action === 'remove') {
            closeTab(targetKey as string);
        }
    };

    const tabItems = useMemo(() => {
        return tabs.map(tab => {
            return {
                key: tab.routePath,
                label: renderTabTitle(tab),
                children: (
                    <div
                        key={tab.key}
                        className='px-[16px]'
                    >
                        {tab.children}
                    </div>
                ),
                closable: tabs.length > 1, // 剩最后一个就不能删除了
            }
        })
    }, [tabs]);

    const onTabsChange = useCallback((tabRoutePath: string) => {
        router.navigate(tabRoutePath);
    }, []);
    const keepAliveContextValue = useMemo(
        () => ({
            closeTab,
            closeOtherTab,
            refreshTab,
        }),
        [closeTab, closeOtherTab, refreshTab]
    );
    return (
        <KeepAliveTabContext.Provider value={keepAliveContextValue}>
            <Tabs
                activeKey={activeTabRoutePath}
                items={tabItems}
                type="editable-card"
                onChange={onTabsChange}
                hideAdd
                onEdit={onTabEdit}
                size="small"
            />
        </KeepAliveTabContext.Provider>
    )

}

export default TabsLayout;
