import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Space} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/res/idcConfig";
import idcConfigApi from "../../../../apis/res/idcConfig";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import idcApi from "../../../../apis/res/idc";
import propertiesApi from "../../../../apis/sys/properties";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    record: TableDataType;
    refresh: () => void;
    idcId: number;
    closeOpen: () => void;
    open: boolean;
    model: String;
}


const IdcConfigFrom: React.FC<StaffFromProps> = ({
                                                     record,
                                                     refresh,
                                                     idcId,
                                                     open,
                                                     model,
                                                     closeOpen,
                                                 }) => {
    // 下拉列表选项
    const [configItemCdOption, setConfigItemCdOption] = useState<OptionRes[]>([])
    const [statusCdOption, setStatusCdOption] = useState<OptionRes[]>([])

    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        queryParams()
        console.log("idcId:",idcId)
        form.setFieldValue("idcId",idcId)
        if (record){
            form.setFieldsValue({
                idcId:idcId,
                ...record
            })
        }
    }, [open]);

    // 查询参数
    function queryParams() {
        propertiesApi.optinos('DM_IdcConfig_Item').then((res) => {
            if (res) {
                setConfigItemCdOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Status').then((res) => {
            if (res) {
                setStatusCdOption(res.data)
            }
        })
    }

    //处理新增方法
    const handleCreate = () => {
        idcConfigApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                refresh();
                form.resetFields()
            }
        });
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        idcConfigApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                refresh();
                form.resetFields()
            }
        });

    }

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            form.setFieldsValue({
                idcId: idcId
            })
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={handleOK}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="idcId" label="idcId" hidden={true}>
                </Form.Item>
                <Form.Item name="configName" label="配置名称"
                           rules={[{required: true, message: "配置名称必填"}, {
                               max: 128,
                               message: "长度不可超过128位"
                           }]}>
                    <Input style={{width: 192}}/>
                </Form.Item>
                <Form.Item name="configItemCd" label="配置项目"
                           rules={[{required: true, message: "配置项目必填"}]}>
                    <Select
                        style={{width: 192}}
                        showSearch
                        optionFilterProp="children"
                        allowClear

                        filterOption={filterOption}
                        options={configItemCdOption}
                    />
                </Form.Item>
                <Form.Item name="configValue" label="配置参数值"
                           rules={[{required: true, message: "配置参数值必填"}, {
                               max: 255,
                               message: "长度不可超过255位"
                           }]}>
                    <Input style={{width: 192}}/>
                </Form.Item>
                {/*<Form.Item name="defaultMark" label="是否默认配置"*/}
                {/*           rules={[{required: true, message: "是否默认配置必填"}]}>*/}
                {/*    <Select*/}
                {/*        style={{width: 192}}*/}
                {/*        allowClear*/}
                {/*    >*/}
                {/*        <Option value="1">是</Option>*/}
                {/*        <Option value="0">否</Option>*/}
                {/*    </Select>*/}
                {/*</Form.Item>*/}
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        style={{width: 192}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusCdOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注" rules={[{max: 255, message: "长度不可超过255位"}]}>
                    <TextArea rows={1} style={{width: 192}}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default IdcConfigFrom;



