const baseUrl = "/pub/sensitiveWords";
/** 获取列表-参数 */
export interface PageParam {
    sensitiveTypeCd: string;
    sensitiveWords: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    sensitiveTypeCd: string;
    sensitiveWords: string;
    statusCd: string;
    notes: string;
}



export  {baseUrl};
