import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider, DatePicker,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography, Upload
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/mkt/redeemCode/redeemCodeVo";
import redeemCodeApi from "../../../../apis/mkt/redeemCode/redeemCodeApi";
import CreateRedeemCodeForm from "./CreateRedeemCodeForm";
import productApi from "../../../../apis/pdt/product";
import dayjs from "dayjs";
import Details from "../../mkt/redeemCode/details";
import instanceApi from "../../../../apis/res/instance";
import channelVersionApi from "../../../../apis/pub/channel/channelVersion";

const {RangePicker} = DatePicker;

const RedeemCodeContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos("DM_Status").then((res) => {
            if (res) {
                setStatusOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Redeem_Type").then((res) => {
            if (res) {
                setRedeemTypeOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Redeem_Status").then((res) => {
            if (res) {
                setRedeemStatusOption(res.data)
            }
        })

        /**
         * 查询产品编码选项
         */
        productApi.getProductCodeOptions().then((res) => {
            if (res) {
                setProductCodeOption(res.data)
            }
        })

    }, []);

    //定义用到的useState，用来绑定数据
    const [model, setModel] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [redeemTypeOption, setRedeemTypeOption] = useState<OptionRes[]>([])
    const [redeemStatusOption, setRedeemStatusOption] = useState<OptionRes[]>([])
    const [productCodeOption, setProductCodeOption] = useState<OptionRes[]>([])
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();

        var beginCodeCreateTime = form.getFieldValue('beginCodeCreateTime');
        var endCodeCreateTime = form.getFieldValue('endCodeCreateTime');

        body = {
            ...body,
            beginCodeCreateTime: beginCodeCreateTime ? dayjs(beginCodeCreateTime).format('YYYY-MM-DD 00:00:00') : null,
            endCodeCreateTime: endCodeCreateTime ? dayjs(endCodeCreateTime).format('YYYY-MM-DD 00:00:00') : null,
        };

        let res = redeemCodeApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        var beginCodeCreateTime = form.getFieldValue('beginCodeCreateTime');
        var endCodeCreateTime = form.getFieldValue('endCodeCreateTime');

        body = {
            ...body,
            beginCodeCreateTime: beginCodeCreateTime ? dayjs(beginCodeCreateTime).format('YYYY-MM-DD 00:00:00') : null,
            endCodeCreateTime: endCodeCreateTime ? dayjs(endCodeCreateTime).format('YYYY-MM-DD 00:00:00') : null,
        };
        redeemCodeApi.derive(body);
    }
    //处理启用
    const handleEnable = () => {
        const body = {
            redeemIds: idList
        }
        redeemCodeApi.batchEnable(body).then((result) => {
            if (result){
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const handleDisable = () => {
        const body = {
            redeemIds: idList
        }
        redeemCodeApi.batchDisable(body).then((result) => {
            if (result){
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }
    function enableconfirm() {
        if (idList.length === 0) {
            message.error('启用失败，请选择需要启用的数据');
        } else {
            message.success('确认启用');
            handleEnable();
        }
    }

    function enablecancel() {
        message.error('取消启用');
    }

    function disableconfirm() {
        if (idList.length === 0) {
            message.error('禁用失败，请选择需要禁用的数据');
        } else {
            message.success('确认禁用');
            handleDisable();
        }
    }

    function disablecancel() {
        message.error('取消禁用');
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {title: "批次号", dataIndex: "batchNo", key: "batchNo", ellipsis:true,width:100},
        {title: "兑换码", dataIndex: "redeemCode", key: "redeemCode", ellipsis:true,width:100},
        {title: "渠道备注", dataIndex: "channelNote", key: "channelNote", ellipsis:true,width:100},
        {title: "兑换类型", dataIndex: "redeemTypeDesc", key: "redeemTypeDesc", ellipsis:true,width:100,},
        {title: "产品等级", dataIndex: "productCodeDesc", key: "productCodeDesc", ellipsis:true,width:100,},
        {title: "兑换时长(小时)", dataIndex: "redeemDuration", key: "redeemDuration", ellipsis:true,width:100},
        {title: "兑换码开始生效时间", dataIndex: "effTime", key: "effTime", ellipsis:true,width:100},
        {title: "兑换码结束生效时间", dataIndex: "expTime", key: "expTime", ellipsis:true,width:100},
        {title: "兑换码生成的时间", dataIndex: "createTime", key: "createTime", ellipsis:true,width:100},
        {title: "使用状态", dataIndex: "redeemStatusDesc", key: "redeemStatusDesc", ellipsis:true,width:100,},
        {title: "兑换用户", dataIndex: "subscriberCode", key: "subscriberCode", ellipsis:true,width:100},
        {title: "云手机编码", dataIndex: "instanceCode", key: "instanceCode", ellipsis:true,width:100},
        {title: "兑换码消费时间", dataIndex: "redeemTime", key: "redeemTime", ellipsis:true,width:100},
        {
            title: "状态",
            dataIndex: "statusCdDesc",
            key: "statusCdDesc",
            ellipsis:true,
            width: 150
        },
        {title: "备注", dataIndex: "notes", key: "notes", ellipsis:true,width:100},
        {title: "创建人", dataIndex: "creatorDesc", key: "creatorDesc", ellipsis:true,width:100},
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    function cancel() {
        message.error('取消删除');
    }

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >

                    <Form.Item style={{margin: 5}} name="batchNo" label="批次号" >
                        <Input size = "small" />
                    </Form.Item>

                    <Form.Item style={{margin:5}} name="redeemCode" label="兑换码" >
                        <Input size = "small"/>
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="channelNote" label="渠道备注" >
                        <Input size = "small" />
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="redeemType" label="兑换类型" size = "small">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={redeemTypeOption}
                            size = "small"
                        />
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="productCode" label="产品等级">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={productCodeOption}
                            size = "small"
                        />
                    </Form.Item>

                    {/* 这里的codeCreateTime参数实际上服务端接口是不需要的，是为了重置的时候把页面上的日期选择器显示重置掉   */}
                    <Form.Item style={{margin: 5}} label="兑换码生成的时间" name={['codeCreateTime', 'dates']}>
                        <RangePicker
                            style={{width: 250}}
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginCodeCreateTime", null);
                                    form.setFieldValue("endCodeCreateTime", null);
                                } else if (Array.isArray(value)) {
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("beginCodeCreateTime", date[0]);
                                        form.setFieldValue("endCodeCreateTime", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }}
                            size = "small"
                        />
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="redeemStatus" label="兑换码使用状态">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={redeemStatusOption}
                            size = "small"
                        />
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="subscriberCode" label="兑换用户" >
                        <Input size = "small" />
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="instanceCode" label="云手机编码" >
                        <Input size = "small" />
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="statusCd" label="状态">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={statusOption}
                            size = "small"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" size = "small">查询</Button>
                            <Button htmlType="reset" onClick={()=>{
                                form.resetFields()
                                handleQuery()
                            }} size = "small" >重置</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 5}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setModel('create')
                }} style={{marginRight: '25px'}} size="small">
                    生成兑换码
                </Button>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}} size="small">
                        导出
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="是否确认启用所选项？"
                    description="已使用的兑换码不允许启用"
                    onConfirm={enableconfirm}
                    onCancel={enablecancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button
                        type="primary" // 或者保持"type='primary'"，根据你的设计偏好
                        size="small"
                        style={{
                            backgroundColor: '#5cb85c', // 绿色
                            marginRight: '25px', //右间距
                            color: 'white', // 确保文字颜色与背景色对比良好
                            border: 'none', // 移除默认的边框，使按钮看起来更整洁
                        }}
                    >
                        启用
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="是否确认禁用所选项？"
                    description="已使用的兑换码不允许禁用"
                    onConfirm={disableconfirm}
                    onCancel={disablecancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}size="small">
                        禁用
                    </Button>
                </Popconfirm>

                <Divider style={{height: 1, marginTop: 10, marginBottom: 5}}/>




                {/*<Popconfirm*/}
                {/*    title="是否确认删除所选项？"*/}
                {/*    onConfirm={confirm}*/}
                {/*    onCancel={cancel}*/}
                {/*    okText="是"*/}
                {/*    cancelText="否"*/}
                {/*>*/}
                {/*    <Button type="primary" danger style={{marginRight: '25px'}}>*/}
                {/*        删除*/}
                {/*    </Button>*/}
                {/*</Popconfirm>*/}

                {/*表格内容*/}
                   <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "60vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


            {/*新增和编辑的form表单，子组件*/}
            <CreateRedeemCodeForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                statusOption={statusOption}
                redeemTypeOption={redeemTypeOption}
                redeemStatusOption={redeemStatusOption}
                productCodeOption={productCodeOption}
            ></CreateRedeemCodeForm>

            {/*鼠标双击出现的详情展示表单*/}
            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default RedeemCodeContent;