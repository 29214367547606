import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form, Image,
    Input, InputNumber,
    message,
    Modal,
    Pagination,
    PaginationProps,
    Popconfirm,
    Row,
    Select,
    Table,
    Typography, Upload, UploadProps
} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/pdt/goods";
import goodsApi from "../../../../apis/pdt/goods";
import {ColumnsType} from "antd/es/table";
import {ChannelTableDataType, PageParam} from "../../../../apis/types/pdt/goodsChannel";
import goodsChannelApi from "../../../../apis/pdt/goodsChannel";
import dayjs from "dayjs";
import GoodsChannelForm from "./goodsChannel/goodsChannelForm";
import goodsPriceApi from "../../../../apis/pdt/goodsPrice";
import GoodsPriceForm from "./goodsPrice/goodsPriceForm";
import GoodsAttributeContent from "./goodsAttribute/goodsAttributeContent";
import Dragger from "antd/es/upload/Dragger";
import {InboxOutlined, UploadOutlined} from "@ant-design/icons";
import {getToken} from "../../../../utils";
import {UploadFile} from "antd/es/upload/interface";
import {RcFile} from "antd/es/upload";
import pdtPictureApi from "../../../../apis/pdt/pdtPicture";
import TextArea from "antd/es/input/TextArea";
import {domain, imgIp} from "../../../../components/constantConfig";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface GoodsFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    statusOption: OptionRes[];
    goodsTypeOption: OptionRes[];
    saleModeOption: OptionRes[];
    displayModeOption: OptionRes[];
    saleObjectOption: OptionRes[];
    productIdOption: OptionRes[];
    type: number;
}


const GoodsForm: React.FC<GoodsFormProps> = ({
                                                 refresh,
                                                 record,
                                                 open,
                                                 closeOpen,
                                                 productIdOption,
                                                 statusOption,
                                                 goodsTypeOption,
                                                 saleModeOption,
                                                 displayModeOption,
                                                 saleObjectOption,
                                                 type,
                                             }) => {


        // 商品图标
        const [oldGoodsIcon, setOldGoodsIcon] = useState(record.goodsIcon);
        const [oldGoodsIconUnselected, setOldGoodsIconUnselected] = useState(record.goodsIconUnselected);
        const [goodsIcon, setGoodsIcon] = useState(record.goodsIcon)
        const [goodsIconUnselected, setGoodsIconUnselected] = useState(record.goodsIconUnselected)
        const [goodsIconList, setGoodsIconList] = useState<UploadFile[]>([])
        const [goodsIconUnselectedList, setGoodsIconUnselectedList] = useState<UploadFile[]>([])

        // 商品图标上传
        const goodsIconprops: UploadProps = {
            multiple: false,
            name: 'file',
            maxCount: 1,
            action: domain + '/pub/picture/upload',
            headers: {
                authorization: getToken(),
            },

            fileList: goodsIconList,
            onChange(info) {
                let {fileList} = info;
                const {status} = info.file;
                let file = info.file;
                // setGoodsIconList([...goodsIconList])
                if (status !== 'uploading') {
                }

                if (status === 'done') {
                    if (file.response.code == 0) {
                        let urlRes = file.response.data;
                        console.log("done,", urlRes)
                        setGoodsIcon(urlRes)
                        // setGoodsIconList([...goodsIconList,file])
                        setGoodsIconList(prevList => [...prevList, file]);
                        message.success(`${info.file.name} 文件上传成功 .`);
                    } else {
                        message.error(`${info.file.name} 文件上传失败.`);
                    }

                } else if (status === 'error') {
                    message.error(`${info.file.name} 文件上传失败.`);
                }
                setGoodsIconList([...fileList])

            },
            beforeUpload(file) {
                const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
                const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
                if (!allowedExtensions.includes(fileExtension)) {
                    message.error('只能上传图片');
                    return false;
                }
                const isLt20M = file.size / 1024 / 1024 < 20;
                if (!isLt20M) {
                    message.error('图片大小不能超过20m');
                    return false;
                }
                return true;
            },
            onDrop: function (e) {


            },
            onRemove(file) {
                setGoodsIcon(oldGoodsIcon)
            },

        };

        // 商品未选中状态图标上传
        const goodsIconUnselectedprops: UploadProps = {
            multiple: false,
            name: 'file',
            maxCount: 1,
            action: domain + '/pub/picture/upload',

            headers: {
                authorization: getToken(),
            },
            fileList: goodsIconUnselectedList,
            onChange(info) {
                let {fileList} = info;
                const {status} = info.file;
                let file = info.file;
                // setGoodsIconList([...goodsIconList])
                if (status !== 'uploading') {
                }

                if (status === 'done') {
                    if (file.response.code == 0) {
                        let urlRes = file.response.data;
                        console.log("done,", urlRes)
                        setGoodsIconUnselected(urlRes)
                        // setGoodsIconList([...goodsIconList,file])
                        setGoodsIconUnselectedList(prevList => [...prevList, file]);
                        message.success(`${info.file.name} 文件上传成功 .`);
                    } else {
                        message.error(`${info.file.name} 文件上传失败.`);
                    }

                } else if (status === 'error') {
                    message.error(`${info.file.name} 文件上传失败.`);
                }
                setGoodsIconUnselectedList([...fileList])

            },
            beforeUpload(file) {
                const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
                const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
                if (!allowedExtensions.includes(fileExtension)) {
                    message.error('只能上传图片');
                    return false;
                }
                const isLt20M = file.size / 1024 / 1024 < 20;
                if (!isLt20M) {
                    message.error('图片大小不能超过20m');
                    return false;
                }
                return true;
            },
            onDrop: function (e) {


            },
            onRemove(file) {
                setGoodsIconUnselected(oldGoodsIconUnselected)
            },

        };

        const [pagination, setPagination] = useState<PaginationProps>({
            current: 1,
            pageSize: 20,
            total: 0
        })

        const handlePaginationChange = (page: number, pageSize: number) => {
            setCurrentPage(page);
            setPagination({
                current: page,
                pageSize: pageSize
            })
        };

        const [form] = Form.useForm();
        const [priceForm] = Form.useForm();

        useEffect(() => {
            if (open && record) {
                form.setFieldsValue({
                    ...record,
                    delistingTime: dayjs(record.delistingTime),
                    listingTime: dayjs(record.listingTime),
                    // recommend: record.recommend !== null && record.recommend !== undefined
                    //     ? record.recommend.toString():null,
                    // defaultSelected: record.defaultSelected !== null && record.defaultSelected !== undefined
                    //     ? record.defaultSelected.toString():null,
                })
                channelHandleQuery();
            }
            setLoad(load+1);
        }, [open]);

        useEffect(() => {
            setGoodsIcon("");
            setGoodsIconUnselected("");
            setFileList([]);
            setGoodsIconList([]);
            setGoodsIconUnselectedList([]);
            // 在组件挂载时发送请求
            if (record.id) {
                // 发送请求的逻辑，使用 record.id 去获取数据
                // 比如：fetchData(record.id);
                queryPicture();
                setGoodsIcon(record.goodsIcon);
                setOldGoodsIcon(record.goodsIcon);
                setGoodsIconUnselected(record.goodsIconUnselected)
                setOldGoodsIconUnselected(record.goodsIconUnselected)
                setOldCornerIcon(record.cornerIcon)
                setCornerIcon(record.cornerIcon)
            }
        }, [record.id]); // 当 record.id 变化时触发

        function channelHandleQuery() {
            let res = goodsChannelApi.pageData(record.id, pagination);
            res.then((result) => {
                if (result) {
                    setChannelList(result.data.records)
                    setTotal(result.data.total)
                }
            })
        }


        const [currentPage, setCurrentPage] = useState(1);
        const [param, setParam] = useState<PageParam>({} as PageParam)
        const [total, setTotal] = useState<number>()
        const [paginationLocale, setPaginationLocale] = useState({
            items_per_page: '/ 页',
            jump_to: '跳转到',
            jump_to_confirm: '确定',
            page: '',
            prev_page: '上一页',
            next_page: '下一页',
            prev_5: '向前 5 页',
            next_5: '向后 5 页',
            prev_3: '向前 3 页',
            next_3: '向后 3 页',
        });

//处理新增方法
        const handleCreate = () => {
            let values = form.getFieldsValue();
            values = {
                ...values,
                listingTime: values.listingTime ? dayjs(values.listingTime).format('YYYY-MM-DD HH:mm:ss') : null,
                delistingTime: values.delistingTime ? dayjs(values.delistingTime).format('YYYY-MM-DD HH:mm:ss') : null,
            };
            values.goodsIcon = goodsIcon;
            values.goodsIconUnselected = goodsIconUnselected;
            values.cornerIcon = cornerIcon;
            goodsApi.create(values).then((result) => {
                if (result) {
                    handleCancel();
                    form.resetFields();
                    closeOpen();
                }
            });
        };

//处理取消方法
        const handleCancel = () => {
            // form.resetFields();

            setGoodsIcon("")
            setGoodsIconList([]);
            setCornerIcon("")
            setCornerIconList([]);

            // setFileList([])
            // setUrlMap(new Map());
            refresh();
        };

//处理更新方法
        function handleUpdate() {
            let values = form.getFieldsValue();
            console.log("goodsUpdate", values)
            values = {
                ...values,
                listingTime: values.listingTime ? dayjs(values.listingTime).format('YYYY-MM-DD HH:mm:ss') : null,
                delistingTime: values.delistingTime ? dayjs(values.delistingTime).format('YYYY-MM-DD HH:mm:ss') : null,
            };
            values.goodsIcon = goodsIcon;
            values.goodsIconUnselected = goodsIconUnselected;
            values.cornerIcon = cornerIcon;
            let valueJSOn = JSON.stringify(values);
            goodsApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
                if (result) {
                    handleCancel();
                    message.success("修改成功");
                    setOldGoodsIcon(goodsIcon)
                    setOldGoodsIconUnselected(goodsIconUnselected)
                    setGoodsIconList([])
                    setGoodsIconUnselectedList([])
                    setOldCornerIcon(cornerIcon)
                    setCornerIconList([])
                }
            });

        }

//处理取消方法
        const goodsHandleCancel = () => {
            form.resetFields();
            closeOpen()
        };

//处理ok按钮，
        function handleOK() {
            form.validateFields().then(() => {
                const values = form.getFieldsValue();
                if (values.id == null) {
                    // 做新增的逻辑
                    handleCreate();
                } else {
                    // 做修改的逻辑
                    handleUpdate();
                }
            })
                .catch((error) => {

                });
        }


        const channelRowSelection = {
            onChange: (selectedRowKeys: React.Key[], selectedRows: ChannelTableDataType[]) => {
                const newSet = new Set(selectedRows.map((key) => key.id));
                //这里有个类型转换
                setChannelIdList(Array.from(newSet) as Number[]);
            }
        };

        const [channelIdList, setChannelIdList] = useState<Number[]>([]);
        const [channelList, setChannelList] = useState<ChannelTableDataType[]>([]);

        const priceHandleCancel = () => {
            priceForm.resetFields();
        };

//处理删除
        const handleDelete = () => {
            goodsChannelApi.batchDelete(channelIdList).then((result) => {
                if (result) {
                    //接口成功后重置idlist
                    setChannelIdList([])
                    //重新调用一次查询接口
                    channelHandleQuery();
                }
            });
        }

//处理更新方法
        function priceHandleUpdate() {
            let values = priceForm.getFieldsValue();
            values = {
                ...values,
            };
            let valueJSOn = JSON.stringify(values);
            goodsPriceApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
                if (result) {
                    priceHandleCancel();
                    message.success("修改成功");
                    // refresh();
                }
            });

        }

//处理新增方法
        const priceHandleCreate = () => {
            let values = priceForm.getFieldsValue();
            values = {
                ...values,
            };
            goodsPriceApi.create(values).then((result) => {
                if (result) {
                    priceHandleCancel();
                    message.success("新增成功");
                    // refresh();
                }
            });
        };

//处理ok按钮，
        function priceHandleOK() {
            priceForm.validateFields().then(() => {
                const values = priceForm.getFieldsValue();
                if (values.id == null) {
                    // 做新增的逻辑
                    priceHandleCreate();
                } else {
                    // 做修改的逻辑
                    priceHandleUpdate();
                }
            })
                .catch((error) => {

                });
        }

        const [channelOpen, setChannelOpen] = useState(false)
        const [rowData, setRowData] = useState<ChannelTableDataType>({} as ChannelTableDataType)


        const [urlMap, setUrlMap] = useState<Map<string, string>>(new Map());
        const [fileList, setFileList] = useState<UploadFile[]>([])
        const [savePictureUrlList, setSavePictureUrlList] = useState<String[]>([]);
        const [deletePictureIdList, setDeletePictureIdList] = useState<String[]>([]);
        const [oldPictureIdList, setOldPictureIdList] = useState<String[]>([]);

        const [previewOpen, setPreviewOpen] = useState(false);
        const [previewImage, setPreviewImage] = useState('');
        const [previewTitle, setPreviewTitle] = useState('');
        // 产品角标图
        const [oldCornerIcon, setOldCornerIcon] = useState(record.cornerIcon);
        const [cornerIcon, setCornerIcon] = useState(record.cornerIcon)
        const [cornerIconList, setCornerIconList] = useState<UploadFile[]>([])

        // 图片展示
        const handleCancelPictureShow = () => setPreviewOpen(false);

        // 查询商品的图片
        function queryPicture() {
            const body = {
                objectId: record.id,
                objectTypeCd: "O02"
            }
            let res = pdtPictureApi.queryData(body);
            res.then((result) => {
                if (result) {
                    // 处理后端返回的数据
                    const mappedData = result.data.map(item => ({
                        uid: item.id, // 使用id作为uid
                        name: item.pictureUrl.substring(item.pictureUrl.lastIndexOf('/') + 1), // 从图片URL中提取文件名
                        status: 'done',
                        url: item.pictureUrl,
                    }));

                    // 更新状态
                    setFileList([...fileList, ...mappedData]);
                    // 把查到的图片的id保存在一个oldPictureIdList列表里。怎么做？
                    // 更新 oldPictureIdList 列表
                    const idList = result.data.map(item => item.id);
                    setOldPictureIdList([...oldPictureIdList, ...idList]);
                }
            })
        }

        const cornerIconprops: UploadProps = {
            multiple: false,
            name: 'file',
            maxCount: 1,
            action: domain + '/pub/picture/upload',

            headers: {
                authorization: getToken(),
                // 'Content-Type': 'multipart/form-data',
            },
            // className:"upload-list-inline",
            // listType:"picture-card",

            fileList: cornerIconList,
            onChange(info) {
                let {fileList} = info;
                const {status} = info.file;
                let file = info.file;
                console.log("status,", status)

                // setProductIconList([...productIconList])
                if (status !== 'uploading') {
                }

                if (status === 'done') {
                    if (file.response.code == 0) {
                        let urlRes = file.response.data;
                        console.log("done,", urlRes)
                        setCornerIcon(urlRes)
                        setCornerIconList(prevList => [...prevList, file]);
                        message.success(`${info.file.name} 文件上传成功 .`);
                    } else {
                        message.error(`${info.file.name} 文件上传失败.`);
                    }

                } else if (status === 'error') {
                    message.error(`${info.file.name} 文件上传失败.`);
                }
                setCornerIconList([...fileList])

            },
            beforeUpload(file) {
                const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
                const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
                if (!allowedExtensions.includes(fileExtension)) {
                    message.error('只能上传图片');
                    return false;
                }
                const isLt20M = file.size / 1024 / 1024 < 20;
                if (!isLt20M) {
                    message.error('图片大小不能超过20m');
                    return false;
                }
                return true;
            },
            onDrop: function (e) {


            },
            onRemove(file) {
                setCornerIcon(type === 0 ? "" : oldCornerIcon)
            },

        };

        // 图片上传逻辑
        const props: UploadProps = {
            multiple: true,
            name: 'file',
            maxCount: 1000,
            action: domain + '/pub/picture/upload',

            headers: {
                authorization: getToken(),
                // 'Content-Type': 'multipart/form-data',
            },
            // className:"upload-list-inline",
            listType: "picture-card",

            fileList: fileList,
            onChange(info) {
                let {fileList} = info;
                const {status} = info.file;
                let file = info.file;
                console.log("status,", status)

                if (status !== 'uploading') {

                }

                if (status === 'done') {
                    if (file.response.code == 0) {
                        let urlRes = file.response.data;
                        console.log("done,", urlRes)
                        // 将urlRes添加到savePictureUrlList中
                        setSavePictureUrlList((prevList) => [...prevList, urlRes]);
                        console.log("SavePictureUrlList:" + savePictureUrlList)
                        urlMap.set(file.uid, urlRes);
                        setUrlMap(new Map(urlMap));
                        // setFileList([...fileList, file])
                        setFileList(prevList => [...prevList, file]);
                        message.success(`${info.file.name} 文件上传成功 .`);
                    } else {
                        message.error(`${info.file.name} 文件上传失败.`);
                    }

                }

                if (status === 'uploading') {
                    setFileList(prevList => [...prevList, file]);
                } else if (status === 'error') {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

                // setFileList(prevList => [...prevList, file]);
                setFileList([...fileList])
            },
            beforeUpload(file) {
                const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
                const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
                if (!allowedExtensions.includes(fileExtension)) {
                    message.error('只能上传图片');
                    return false;
                }
                const isLt20M = file.size / 1024 / 1024 < 20;
                if (!isLt20M) {
                    message.error('图片大小不能超过20m');
                    return false;
                }
                return true;
            },
            onDrop: function (e) {


            },
            onRemove(file) {
                console.log("remove file", file.uid)

                setSavePictureUrlList((prevList) => prevList.filter((url) => url !== file.url));

                // 将要删除的图片id记录下来。先判断
                console.log("remove file", file.uid);

                if (oldPictureIdList.includes(file.uid)) {
                    setDeletePictureIdList(prevList => [...prevList, file.uid]);
                    // console.log("setDeletePictureIdList:" + deletePictureIdList)
                } else {
                    setSavePictureUrlList(prevList => prevList.filter(url => url !== file.url));
                }

                urlMap.delete(file.uid);
                setUrlMap(new Map(urlMap));

            },

        };

        const getBase64 = (file: RcFile): Promise<string> =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = (error) => reject(error);
            });

        const handlePreview = async (file: UploadFile) => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as RcFile);
            }

            setPreviewImage(file.url || (file.preview as string));
            setPreviewOpen(true);
            setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
        };

        function handlePictureOK() {
            // 保存新增的，删除被删除的
            if (savePictureUrlList.length > 0) {
                savePicture();
            }
            console.log(deletePictureIdList)
            if (deletePictureIdList.length > 0) {
                deletePicture();
            }
            // 将图片列表置空
            setFileList([])
        }

        function deletePicture() {
            pdtPictureApi.batchDelete(deletePictureIdList).then((result) => {
                if (result) {
                    //接口成功后重置idlist
                    // setIdList([])
                    // //重新调用一次查询接口
                    // handleQuery();
                    setDeletePictureIdList([]);
                }
            });
        }

        function savePicture() {
            // 遍历 savePictureUrlList 数组中的字符串
            savePictureUrlList.forEach((url) => {
                // 在这里对每个字符串进行操作
                console.log(url);

                // 可以在这里执行你的操作，比如发送请求等
                // 创建一个对象，包含多个属性
                const picture = {
                    pictureUrl: url,
                    pictureTypeCd: "P02",
                    objectTypeCd: "O02",
                    objectId: record.id,
                    pictureTag: "",
                    //第一个是主要图片，0表示否，1表示是
                    masterMark: "0",
                    statusCd: "S01",
                    notes: ""
                };

                console.log("picture:" + picture)
                pdtPictureApi.create(picture).then((result) => {
                    if (result) {
                        // 上传成功
                        // message.success("上传成功");
                        // handleCancel();
                        // refresh();
                    }
                });

            });
            setSavePictureUrlList([]);
        }

        const channelColumns: ColumnsType<ChannelTableDataType> = [
            {
                title: '操作',
                dataIndex: 'operation',
                render: (_: any, record: ChannelTableDataType) => {
                    return <Typography.Link onClick={() => {
                        setChannelOpen(true)
                        setRowData(record);
                    }}>
                        编辑
                    </Typography.Link>
                },
            },
            {
                title: '渠道',
                dataIndex: 'channelIdDesc',
                key: 'channelIdDesc',
            },
            {
                title: '生效时间',
                dataIndex: 'effTime',
                key: 'effTime',
            },
            {
                title: '失效时间',
                dataIndex: 'expTime',
                key: 'expTime',
            },
            {
                title: '状态',
                dataIndex: 'statusCdDesc',
                key: 'statusCdDesc',
            },
            {
                title: '备注',
                dataIndex: 'notes',
                key: 'notes',
            },
        ];

        const updatePriceId = (priceId: number) => {
            form.setFieldValue("priceId", priceId);
        }

        const [load, setLoad] = useState<number>(0)

        return (
            <Modal
                open={open}
                width={1800}
                title={type == 0 ? "新增商品" : "编辑商品"}
                footer={null}
                onCancel={goodsHandleCancel}
            >
                <div>
                    <h3>商品</h3>
                    <Form
                        {...layout}
                        form={form}
                        name="control-hooks"
                        onFinish={handleOK}
                        style={{display: "block"}}
                    >
                        <Form.Item name="id" label="id值" hidden={true}>
                        </Form.Item>
                        <Form.Item name="priceId" label="priceId" hidden={true}>
                        </Form.Item>
                        <Row>
                            <Col span={4}>
                                <Form.Item name="goodsTypeCd" label="商品类型"
                                           rules={[{required: true, message: "商品类型不能为空"}]}>
                                    <Select
                                        style={{width: 180}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={goodsTypeOption}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="goodsName" label="商品名称"
                                           rules={[{required: true, message: "商品名称不能为空"}]}>
                                    <Input style={{width: 180}}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="goodsCode" label="商品编码"
                                           rules={[{required: true, message: "商品编码不能为空"}]}>
                                    <Input style={{width: 180}}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                {/*需要修改*/}
                                <Form.Item name="productId" label="关联产品"
                                           rules={[{required: true, message: "关联产品不能为空"}]}>
                                    <Select
                                        style={{width: 180}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={productIdOption}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="goodsDetail" label="商品详情"
                                           rules={[{required: true, message: "商品详情不能为空"}]}>
                                    <Input style={{width: 180}}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="advertWords" label="商品广告词"
                                           rules={[{required: true, message: "商品广告词不能为空"}]}>
                                    <Input style={{width: 180}}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={4}>
                                <Form.Item name="saleModeCd" label="销售方式"
                                           rules={[{required: true, message: "销售方式不能为空"}]}>
                                    <Select
                                        style={{width: 180}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={saleModeOption}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="saleObjectCd" label="销售对象"
                                           rules={[{required: true, message: "销售对象不能为空"}]}>
                                    <Select
                                        style={{width: 180}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={saleObjectOption}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="displayModeCd" label="展示方式"
                                           rules={[{required: true, message: "展示方式不能为空"}]}>
                                    <Select
                                        style={{width: 180}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={displayModeOption}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="autoRenewal" label="自动续费"
                                           rules={[{required: true, message: "自动续费不能为空"}]}>
                                    <Select
                                        style={{width: 180}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={[
                                            {value: '1', label: '是'},
                                            {value: '0', label: '否'},
                                        ]}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="listingTime" label="上架时间"
                                           rules={[{required: true, message: "上架时间必填"}]}>
                                    <DatePicker showTime placeholder={''} style={{width: 180}}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="delistingTime" label="下架时间"
                                           rules={[{required: true, message: "下架时间必填"}]}>
                                    <DatePicker showTime placeholder={''} style={{width: 180}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                                    <Select
                                        style={{width: 180}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={statusOption}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="reorder" label="排序"
                                           rules={[{required: true, message: "排序不能为空"}]}>
                                    <InputNumber style={{width: 180}} min={0}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="recommend" label="是否推荐">
                                    <Select
                                        style={{width: 180}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={[
                                            {value: 1, label: '是'},
                                            {value: 0, label: '否'},
                                        ]}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="defaultSelected" label="默认选中">
                                    <Select
                                        style={{width: 180}}
                                        showSearch
                                        optionFilterProp="children"
                                        allowClear
                                        filterOption={filterOption}
                                        options={[
                                            {value: 1, label: '是'},
                                            {value: 0, label: '否'},
                                        ]}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4} hidden={type == 0}>
                                <Form.Item name="goodsIcon" label="商品图标">
                                    <Image src={imgIp+goodsIcon} width={100}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="goodsIconUplod" label="">
                                    <Upload {...goodsIconprops}>
                                        <Button icon={<UploadOutlined/>}>点击上传</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={4} hidden={type == 0}>
                                <Form.Item name="goodsIconUnselected" label="未选中图标">
                                    <Image src={imgIp+goodsIconUnselected} width={100}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="goodsIconUplod" label="">
                                    <Upload {...goodsIconUnselectedprops}>
                                        <Button icon={<UploadOutlined/>}>点击上传</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="cornerIcon" label="产品角标">
                                    <Image src={imgIp+cornerIcon} width={100}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="cornerIconUplod" label="">
                                    <Upload {...cornerIconprops}>
                                        <Button icon={<UploadOutlined/>}>点击上传</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="notes" label="备注" labelCol={{sm: {span:1}}}>
                            <TextArea rows={1} style={{width: 1100}}/>
                        </Form.Item>
                        <div style={{textAlign: "center"}}>
                            <Form.Item style={{display: "inline-block"}}>
                                <Button type="primary" htmlType="submit">
                                    提交商品
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>

                {/*附加属性*/}
                <div hidden={type === 0}>
                    <div>
                        <div>
                            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                            <h3>定价管理</h3>
                            <GoodsPriceForm
                                refresh={() => {
                                    //重新调用查询的接口
                                    refresh();
                                }}
                                load={load}
                                priceId={record.priceId}
                                goodsId={record.id}
                                updatePriceId={updatePriceId}
                            ></GoodsPriceForm>
                        </div>
                        <div>
                            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                            <h3>渠道管理</h3>
                            <Table rowSelection={{
                                ...channelRowSelection,
                            }} dataSource={channelList} columns={channelColumns} rowKey={record => record.id}
                                   pagination={false}/>

                            {/*表格的分页*/}
                            <div
                                style={{textAlign:"right"}}>
                                <Pagination size="small" style={{marginLeft: 'auto'}}
                                            total={total}
                                            showSizeChanger showQuickJumper
                                            current={currentPage}
                                            defaultPageSize={20}
                                            locale={paginationLocale}
                                            onChange={handlePaginationChange}
                                            showTotal={(total) => `一共 ${total} 条数据`}
                                />
                            </div>

                            <div style={{textAlign: "center"}}>
                                <Button type="primary" ghost onClick={() => {
                                    setChannelOpen(true);
                                }} style={{marginRight: '25px'}}>
                                    新增
                                </Button>
                                <Popconfirm
                                    title="是否确认删除所选项？"
                                    onConfirm={() => {
                                        if (channelIdList.length === 0) {
                                            message.error('删除失败，请选择需要删除的数据');
                                        } else {
                                            message.success('确认删除');
                                            handleDelete();
                                        }
                                    }}
                                    onCancel={() => {
                                        message.error('取消删除');
                                    }}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <Button type="primary" danger style={{marginRight: '25px'}}>
                                        删除
                                    </Button>
                                </Popconfirm>

                                {/*新增和编辑的form表单，子组件*/}
                                <GoodsChannelForm
                                    refresh={() => {
                                        //设置表单不展示
                                        setChannelOpen(false);
                                        setRowData({} as ChannelTableDataType)
                                        //重新调用查询的接口
                                        channelHandleQuery();
                                    }}
                                    record={rowData}
                                    channelOpen={channelOpen}
                                    closeOpen={() => {
                                        setChannelOpen(false)
                                        setRowData({} as ChannelTableDataType)
                                    }}
                                    goodsId={record.id}
                                ></GoodsChannelForm>
                            </div>
                        </div>
                        <div>
                            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                            <div className={"goods-attribute"}>
                                <div className={"goods-attribute-form-section"}>
                                    <h3>商品规格</h3>
                                </div>
                                <div className={"goods-attribute-content"}>
                                    <GoodsAttributeContent goodsId={record.id}></GoodsAttributeContent>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={"goods-picture"}>
                                <div className={"goods-picture-form-section"} style={{}}>
                                    <h3>商品图片</h3>
                                </div>
                                <div className={"goods-picture-content"}>
                                    <Form.Item>
                                        <Dragger
                                            {...props}
                                            onPreview={handlePreview}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined/>
                                            </p>
                                            <p className="ant-upload-text">点击或者拖拉图片上传</p>
                                            <p className="ant-upload-hint">
                                                支持单个，多个图片上传
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                </div>
                                <div className={"goods-picture-button"} style={{textAlign: 'center'}}>
                                    <Button type="primary" ghost onClick={() => {
                                        handlePictureOK()
                                        closeOpen()
                                    }} style={{marginRight: '25px'}}>
                                        保存图片
                                    </Button>
                                </div>
                                <Modal open={previewOpen} title={previewTitle} footer={null}
                                       onCancel={handleCancelPictureShow}>
                                    <img alt="example" style={{width: '100%'}} src={previewImage}/>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        );
    }
;

/**
 * 查询的表单
 * @constructor
 */


export default GoodsForm;