import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider, DatePicker,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import {TableDataType} from "../../../../apis/types/res/insTransfer";
import insTransferApi from "../../../../apis/res/insTransfer";
import dayjs from "dayjs";
import {filterOption} from "../../../../apis/common";
import catalogInfoApi from "../../../../apis/app/catalogInfo";

function convertSecondsToDHMS(seconds) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);

    return `${days}天 ${hours}小时 ${minutes}分钟`;
}

const {RangePicker} = DatePicker;

const OrganContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [open, setOpen] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [productNameOption, setProductNameOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [convertedDataList, setConvertedDataList] = useState([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {

        let body = form.getFieldsValue();
        var beginInsTransferTime = form.getFieldValue('beginInsTransferTime');
        var endInsTransferTime = form.getFieldValue('endInsTransferTime');

        body = {
            ...body,
            beginInsTransferTime: beginInsTransferTime ? dayjs(beginInsTransferTime).format('YYYY-MM-DD') : null,
            endInsTransferTime: endInsTransferTime ? dayjs(endInsTransferTime).format('YYYY-MM-DD') : null,
        };

        let res = insTransferApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }
        })

    }

    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //处理导出
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        var beginInsTransferTime = form.getFieldValue('beginInsTransferTime');
        var endInsTransferTime = form.getFieldValue('endInsTransferTime');

        body = {
            ...body,
            beginInsTransferTime: beginInsTransferTime ? dayjs(beginInsTransferTime).format('YYYY-MM-DD') : null,
            endInsTransferTime: endInsTransferTime ? dayjs(endInsTransferTime).format('YYYY-MM-DD') : null,
        };
        insTransferApi.handleAppDerive(body);
    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            fixed: 'left',
            render: (_, __, index) => index + 1
        },
        {title: "云手机编号", dataIndex: "insCode", key: "insCode", ellipsis: true, width: 70},
        {title: "产品名称", dataIndex: "productName", key: "productName", ellipsis: true, width: 100},
        {
            title: "云手机剩余时长",
            dataIndex: "leftControlTime",
            key: "leftControlTime",
            ellipsis: true,
            width: 100,
            render: (leftControlTime: string | number) => {
                const timeInSec = parseInt(leftControlTime.toString());
                return <span>{convertSecondsToDHMS(timeInSec)}</span>;
            },
        },
        {title: "转让账号", dataIndex: "transferSubscriberCode", key: "transferSubscriberCode", ellipsis: true, width: 100},
        {title: "接收账号", dataIndex: "receiveSubscriberCode", key: "receiveSubscriberCode", ellipsis: true, width: 100},
        {title: "转让状态", dataIndex: "transferResultDesc", key: "transferResultDesc", ellipsis: true, width: 100},
        {title: "转让时间", dataIndex: "createTime", key: "createTime", ellipsis: true, width: 100},

    ];

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      labelAlign={"left"}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item style={{margin: 5}} name="insCode" label="云手机编号">
                        <Input style={{width: 150}}/>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="productName" label="产品名称">
                        <Input style={{width: 150}}/>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="transferSubscriberCode" label="转让账号">
                        <Input style={{width: 200}} placeholder="请输入会员编码"/>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="receiveSubscriberCode" label="接收账号">
                        <Input style={{width: 200}} placeholder="请输入会员编码"/>
                    </Form.Item>
                    {/* 这里的TimeRange参数实际上服务端接口是不需要的，是为了重置的时候把页面上的日期选择器显示重置掉   */}
                    <Form.Item style={{margin: 5}} label="转让时间" name={['InsTransferTimeRange', 'dates']}>
                        <RangePicker
                            style={{width: 250}}
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginInsTransferTime", null);
                                    form.setFieldValue("endInsTransferTime", null);
                                } else if (Array.isArray(value)) {
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("beginInsTransferTime", date[0]);
                                        form.setFieldValue("endInsTransferTime", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }}
                        />
                    </Form.Item>

                    <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                form.resetFields()

                                handleQuery()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>

                    <Popconfirm
                        title="是否确认导出筛选的数据？"
                        onConfirm={confirmDerive}
                        onCancel={cancelDerive}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="primary" danger style={{marginRight: '25px'}}>
                            导出
                        </Button>
                    </Popconfirm>
                </Form>
            </div>


            <Divider/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}}
                       dataSource={list} columns={columns} rowKey={record => record.sequenceNumber} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

        </div>
    )

}

export default OrganContent;