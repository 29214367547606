const baseUrl = "/sub/user";
/** 获取列表-参数 */
export interface PageParam {
    subscriberId: string;
    acctNo: string;
    phoneNumber: string;
    nickName: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    subscriberId: number;
    subscriberCode: string;
    primaryMark: string;
    primaryMarkDesc: string;
    acctNo: string;
    wxOpenid: string;
    wxUnionid: string;
    qqOpenid: string;
    qqUnionid: string;
    registTime: string;
    statusdesc: string;
    notes: string;
}




export  {baseUrl};


