import React, {useEffect, useState} from "react";
import {
    Button,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import UserFrom from "./changePasswordFrom";
import userApi from "../../../../apis/sys/user";
import {TableDataType} from "../../../../apis/types/sys/user";
import {OptionRes} from "../../../../apis/types/common";
import staffApi from "../../../../apis/sys/staff";
import actionApi from "../../../../apis/sys/action";
import UserRoleForm from "../userRole/userRoleForm";
import Details from "../../public/details/details";

interface FormProps {
    staffId: number;
}

const UserContent: React.FC<FormProps> = ({staffId}) => {
    //进入页面先查询数据
    useEffect(() => {
        staffApi.optinos().then((res) => {
            if (res) {
                setStaffOption(res.data)
            }
        })
        actionApi.optinos().then((res) => {
            if (res) {
                setActionOption(res.data)
            }
        })
    }, []);

    //定义用到的useState，用来绑定数据
    const [model, setModel] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
        const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [staffOption, setStaffOption] = useState<OptionRes[]>([])
    const [actionOption, setActionOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination, staffId]);

    //处理查询
    function handleQuery() {
        let res = userApi.pageData(staffId, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        userApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => (
                <div>
                    <Typography.Link onClick={() => {
                        setOpen(true)
                        setModel('edit')
                        setRowData(record);
                    }}>
                        编辑
                    </Typography.Link>
                </div>
            ),
        },
        {
            title: '人员工号',
            dataIndex: 'staffNo',
            key: 'staffNo',
        },
        {
            title: '访问账户',
            dataIndex: 'acctNo',
            key: 'acctNo',
        },
        {
            title: '生效时间',
            dataIndex: 'effTime',
            key: 'effTime',
        },
        {
            title: '失效时间',
            dataIndex: 'expTime',
            key: 'expTime',
        },
        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
        },
        {
            title: '功能权限',
            dataIndex: 'actionList',
            key: 'actionList',
            render: (actionList: string[]) => {
                if (actionList) {
                    return actionList.join(', ');
                } else {
                    return '';
                }
            },
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setModel('create')
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>

                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        }else {
                            handleDelete();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>
            </div>

                        <Divider style={{height:1,marginTop:10,marginBottom:0}} />


            {/*表格内容*/}
            <Table rowSelection={{
                ...rowSelection,"selectedRowKeys":selectedRowKeys,
            }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            {/*<UserFrom*/}
            {/*    refresh={() => {*/}
            {/*        //设置表单不展示*/}
            {/*        setOpen(false);*/}
            {/*        setRowData({} as TableDataType)*/}
            {/*        //重新调用查询的接口*/}
            {/*        handleQuery();*/}
            {/*    }}*/}
            {/*    record={rowData}*/}
            {/*    open={open}*/}
            {/*    model={model}*/}
            {/*    closeOpen={() => {*/}
            {/*        setOpen(false)*/}
            {/*        setRowData({} as TableDataType)*/}

            {/*    }}*/}
            {/*    staffId={staffId}*/}
            {/*></UserFrom>*/}


            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default UserContent;