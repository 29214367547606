import {baseUrl, PageParam, TableDataType} from "../types/res/gateway";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const gatewayApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.gatewayName) {
                url += 'gatewayName=' + encodeURIComponent(body.gatewayName);
            }
            if (body.idcId) {
                url += '&idcId=' + body.idcId;
            }
            if (body.gatewayTypeCd) {
                url += '&gatewayTypeCd=' + body.gatewayTypeCd;
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    optinos(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },

    getById(gatewayId: number) {
        return  request.get<TableDataType>(baseUrl+"/getById?id="+gatewayId);

    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"网关列表",body)
    },
}

export default gatewayApi



