const baseUrl = "/pub/area";
/** 获取列表-参数 */
export interface PageParam {
    areaName: string;
    areaGradeCd: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    areaName: string;
    englishName: string;
    abbrName: string;
    areaGradeCd: string;
    areaGradeCdDesc: string;
    parentAreaName: string;
    hotMark: string;
    hotMarkDesc: string;
    statusDesc: string;
    notes: string;
}

export  {baseUrl};
