const baseUrl = "/res/addtime";
/** 获取列表-参数 */
export interface PageParam {
    instanceCode: string;
    phoneNumber: string;
    beginCreateTime: string;
    endCreateTime: string;
    subscriberCode: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    subUserId: string;
    subscriberCode: string,
    phoneNumber: string;
    instanceCode: string;
    operateUid: string;
    operateName: string;
    addTime: string;
    timeUnit: string;
    timeUnitDesc: string;
    createTime: string;
}

export  {baseUrl};