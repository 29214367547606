import {baseUrl, PageParam, TableDataType} from "../../types/mkt/advert/advert";
import {PaginationProps} from "antd";
import {request} from "../../../utils";
import {PageRes} from "../../types/common";
import commonApi from "../../commonApi";


const advertApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.adTitle) {
                url += 'adTitle=' + encodeURIComponent(body.adTitle) + "&";

            }
            if (body.showPosition) {
                url += 'showPosition=' + encodeURIComponent(body.showPosition) + "&";
            }
            if (body.enableStatus) {
                url += 'enableStatus=' + encodeURIComponent(body.enableStatus) + "&";

            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "/delete?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(body: any) {
        let url = baseUrl+'/update' ;
        return request.post(url, body)
    },

    //禁用数据
    disable(body: any) {
        let url = baseUrl+"/disable" ;
        return request.post(url, body)
    },
    //启用数据
    enable(body: any) {
        let url = baseUrl+"/enable" ;
        return request.post(url, body)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"广告列表",body)
    },



}

export default advertApi




