import React, {useEffect, useState} from 'react';
import {
    Button, Col,
    Form,
    Image,
    Input, InputNumber,
    message,
    Modal, Row,
    Select,
    Upload,
    UploadProps
} from 'antd';
import dayjs from "dayjs";
import {UploadOutlined} from "@ant-design/icons";
import {getToken} from "../../../../utils";
import TextArea from "antd/es/input/TextArea";
import {TableDataType} from "../../../../apis/types/app/info";
import infoApi from "../../../../apis/app/info";
import {domain, imgIp} from "../../../../components/constantConfig";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface InfoFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    type: boolean;
    infoOpen: boolean;
    appCatalogId:number;
}


const InfoForm: React.FC<InfoFormProps> = ({
                                               refresh,
                                               record,
                                               infoOpen,
                                               closeOpen,
                                               type,
                                               appCatalogId
                                           }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({appCatalogId:appCatalogId})
        if (infoOpen && record) {
            form.setFieldsValue({
                ...record,
                processorArchitecture: record.processorArchitecture !== null && record.processorArchitecture !== undefined
                    ? record.processorArchitecture.toString():null,
                installMode: record.installMode !== null && record.installMode !== undefined
                    ? record.installMode.toString():null,
                isHot: record.isHot !== null && record.isHot !== undefined
                    ? record.isHot.toString():null,
                appStatus: record.appStatus !== null && record.appStatus !== undefined
                    ? record.appStatus.toString():null,
                status: record.status !== null && record.status !== undefined
                    ? record.status.toString():null,
                enableStatus: record.enableStatus !== null && record.enableStatus !== undefined
                    ? record.enableStatus.toString():null,
            })
        }
    }, [infoOpen]);

    useEffect(() => {
        setIconFile("");
        setOldIconFile("");
        setAppGrayImage("");
        setOldAppGrayImage("");
        // 在组件挂载时发送请求
        if (record) {
            // 发送请求的逻辑，使用 record.id 去获取数据
            // 比如：fetchData(record.id);
            console.log('Sending request for record:', record);
            setIconFile(record.iconFile);
            setOldIconFile(record.iconFile);
            setAppGrayImage(record.appGrayImage);
            setOldAppGrayImage(record.appGrayImage);
        }
    }, [record.appCatalogId]);


    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        values = {
            ...values,
            createTime: values.createTime ? dayjs(values.createTime).format('YYYY-MM-DD HH:mm:ss') : null,
            modifyTime: values.modifyTime ? dayjs(values.modifyTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        values.iconFile = iconFile;
        values.appGrayImage = appGrayImage;
        let valueJSOn = JSON.stringify(values);
        infoApi.create(valueJSOn).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                setAppGrayImage("");
                setIconFile("");

            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        values = {
            ...values,
            createTime: values.createTime ? dayjs(values.createTime).format('YYYY-MM-DD HH:mm:ss') : null,
            modifyTime: values.modifyTime ? dayjs(values.modifyTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        values.iconFile = iconFile;
        values.appGrayImage = appGrayImage;
        let valueJSOn = JSON.stringify(values);
        infoApi.update(JSON.parse(valueJSOn).appId, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                setAppGrayImage("");
                setIconFile("");
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.setFieldValue("iconFile",iconFile);
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.appId == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    function onblur() {
        infoOpen = true;
    }

    const [iconFile, setIconFile] = useState(record.iconFile)
    const [appGrayImage, setAppGrayImage] = useState(record.iconFile)
    const [oldIconFile, setOldIconFile] = useState(record.iconFile);
    const [oldAppGrayImage, setOldAppGrayImage] = useState(record.iconFile);


    const appGrayImageprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain+'/pub/picture/upload',
        headers: {
            authorization: getToken(),
        },
        onChange(info) {
            const {status} = info.file;
            let file = info.file;
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    setAppGrayImage(urlRes)
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setAppGrayImage(oldAppGrayImage)
        },

    };

    const iconFileprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain+'/pub/picture/upload',

        headers: {
            authorization: getToken(),
        },
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            // setIconFileList([...iconFileList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    setIconFile(urlRes)
                    // setIconFileList([...iconFileList,file])
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setIconFile(oldIconFile)
        },

    };


    return (
        <Modal
            title={type ? "新增渠道应用" : "修改渠道应用"}
            open={infoOpen}
            width={1800}
            onCancel={handleCancel}
            onOk={handleOK}
            okText={"保存"}
            cancelText={"取消"}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onBlur={onblur}
            >
                <Form.Item name="appId" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="appCatalogId" label="应用信息id" hidden={true}>
                </Form.Item>
                <Form.Item name="creater" label="创建人" hidden={true}>
                </Form.Item>
                <Form.Item name="createTime" label="创建时间" hidden={true}>
                </Form.Item>
                <Form.Item name="modifier" label="修改人" hidden={true}>
                </Form.Item>
                <Form.Item name="modifyTime" label="修改时间" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={6}>
                        <Form.Item name="appName" label="应用名称" rules={[{required: true, message: "应用名称必填"},{max:50,message:"字段长度不可超过50位"}]}>
                            <Input style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="appCompany" label="应用发行商"
                                   rules={[{required: true, message: "应用发行商必填"},{max:255,message:"字段长度不可超过255位"}]}>
                            <Input style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="appFile" label="应用文件地址"
                                   rules={[{required: true, message: "应用文件地址必填"},{max:255,message:"字段长度不可超过255位"}]}>
                            <Input style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="appSize" label="应用大小" rules={[{required: true, message: "应用大小必填"}]}>
                            <InputNumber style={{width: 250}} max={2147483647} min={0}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item name="iconFile" label="应用图标" rules={[{required: true, message: "应用图标必填"}]}>
                            <Image src={iconFile?imgIp+iconFile:iconFile} width={100}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="iconFileUplod" label="应用图标上传">
                            <Upload {...iconFileprops} >
                                <Button style={{width: 250}} icon={<UploadOutlined/>}>点击上传</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="appGrayImage" label="app灰图标">
                            <Image src={appGrayImage?imgIp+appGrayImage:appGrayImage} width={100}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="appGrayImageUplod" label="app灰图标上传">
                            <Upload {...appGrayImageprops} >
                                <Button style={{width: 250}} icon={<UploadOutlined/>}>点击上传</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item name="appVersion" label="应用版本"
                                   rules={[{max: 255, message: "字段长度不可超过255位"}]}>
                            <Input style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="appMd5" label="应用md5" rules={[{required: true, message: "应用md5必填"},{max: 100, message: "字段长度不可超过100位"}]}>
                            <Input style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="versionCode" label="应用版本编码"
                                   rules={[{required: true, message: "应用版本编码必填"}]}>
                            <InputNumber style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="versionName" label="应用版本名称"
                                   rules={[{required: true, message: "应用版本名称必填"},{max: 100, message: "字段长度不可超过100位"}]}>
                            <Input style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item name="appSha" label="应用sha签名"
                                   rules={[{max: 200, message: "字段长度不可超过200位"}]}>
                            <Input style={{width: 200}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="packageName" label="应用包名"
                                   rules={[{required: true, message: "应用包名必填"},{max:150,message:"字段长度不可超过150位"}]}>
                            <Input style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="processorArchitecture" label="处理器架构">
                            <Select
                                style={{width: 250}}
                                allowClear
                                options={[{value: '0', label: '通用'}, {value: '1', label: 'X86'}, {value: '2', label: 'X86-64'}]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="installMode" label="安装方式"
                                   rules={[{required: true, message: "安装方式必填"}]}>
                            <Select
                                style={{width: 250}}
                                allowClear
                                options={[{value: '1', label: 'apk'}, {value: '2', label: 'xapk'}, {value: '3', label: 'aab'}]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item name="enableStatus" label="启用状态"
                                   rules={[{required: true, message: "启用状态必填"}]}>
                            <Select
                                style={{width: 250}}
                                allowClear
                                options={[{value: '0', label: '禁用'}, {value: '1', label: '启用'}]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="status" label="删除状态"
                                   rules={[{required: true, message: "删除状态必填"}]}>
                            <Select
                                style={{width: 250}}
                                allowClear
                                options={[{value: '0', label: '已删除'}, {value: '1', label: '未删除'}]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="appStatus" label="应用状态"
                                   rules={[{required: true, message: "应用状态必填"}]}>
                            <Select
                                style={{width: 250}}
                                allowClear
                                options={[{value: '1', label: '待审核'}, {value: '2', label: '审核通过'}, {value: '3', label: '已下载'}]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="isHot" label="是否热门应用"
                                   rules={[{required: true, message: "是否热门应用必填"}]}>
                            <Select
                                style={{width: 250}}
                                allowClear
                                options={[{value: '1', label: '是'}, {value: '0', label: '否'}]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item name="reorder" label="排序" rules={[{required: true, message: "排序必填"}]}>
                            <InputNumber style={{width: 250}} max={2147483647}  min={0}/>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item name="score" label="评分" rules={[{required: true, message: "评分必填"}]}>
                            <InputNumber style={{width: 250}} min={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="installCount" label="安装次数"
                                   rules={[{required: true, message: "安装次数必填"}]}>
                            <InputNumber style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="downloadCount" label="下载次数"
                                   rules={[{required: true, message: "下载次数必填"}]}>
                            <InputNumber style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item name="appDesc" label="应用描述" rules={[{required: true, message: "应用描述必填"}]}>
                            <TextArea rows={1} style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="disclaimer" label="免责声明" rules={[{required: true, message: "免责必填"},{max:1000,message:"字段长度不可超过1000位"}]}>
                            <TextArea rows={1} style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="remark" label="备注"
                                   rules={[{max: 500, message: "字段长度不可超过500位"}]}>
                            <TextArea rows={1} style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item name="appPatchFile" label="补丁包地址" rules={[{max:255,message:"字段长度不可超过255位"}]}>
                            <TextArea rows={1} style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="patchBaseVersionCode" label="基准版本号">
                            <InputNumber style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default InfoForm;



