const baseUrl = "/res/proxy";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    proxyCode:string,
    proxyName:string,
    proxyHost:string,
    idcId:number,
    enableStatus:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    proxyCode:string,
    proxyName:string,
    proxyHost:string,
    idcId:number,
    idcName:string,
    proxyTypeCd:string,
    proxyType:string,
    proxyPort:string,

    creator:number,
    creatorDesc:string,
    modifier:number,
    modifierDesc:string,
    softDel:number,
    enableStatus:number,
    enableStatusDesc:string,
    createTime:string,
    modifyTime:string,
    protocol:string,

}



export  {baseUrl};

