import React, {useEffect, useState} from "react";
import {
    Button,
    Form,
    message, Pagination,
    PaginationProps,
    Popconfirm,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import GoodsAttributeForm from "./goodsAttributeForm";
import {AttributeTableDataType} from "../../../../../apis/types/pdt/goodsAttribute";
import {OptionRes} from "../../../../../apis/types/common";
import propertiesApi from "../../../../../apis/sys/properties";
import goodsAttributeApi from "../../../../../apis/pdt/goodsAttribute";
import Details from "../../../public/details/details";


interface FormProps {
    goodsId: number;
}

const GoodsAttributeContent: React.FC<FormProps> = ({goodsId}) => {

    //进入页面先查询数据
    useEffect(() => {
        console.log("GoodsAttributeContent goodsId: " + goodsId)
        handleQuery();
        queryParams();
    }, [goodsId]);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [model, setModel] = useState('')
    const [list, setList] = useState<AttributeTableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
        const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<AttributeTableDataType>({} as AttributeTableDataType)

    // 下拉列表选项
    const [attributeTypeCdOption, setAttributeTypeCdOption] = useState<OptionRes[]>([])
    const [statusCdOption, setStatusCdOption] = useState<OptionRes[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    // 表格
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 10000,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);


    // 查询参数
    function queryParams() {
        propertiesApi.optinos("DM_Attribute_Type").then((res) => {
            if (res) {

            setAttributeTypeCdOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Status").then((res) => {
            if (res) {

            setStatusCdOption(res.data)
            }
        })

    }

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body.goodsId = goodsId;
        let res = goodsAttributeApi.pageData(body, pagination);
        console.log("query",res)
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })
    }

    //处理删除
    const handleDelete = () => {
        goodsAttributeApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }


    //表格的内容
    const columns: ColumnsType<AttributeTableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: AttributeTableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setModel('edit')
                    setRowData(record);
                }}>
                    编辑
                </Typography.Link>
            },
        },
        {
            title: '规格编码',
            dataIndex: 'attributeCode',
            key: 'attributeCode',
            ellipsis: true,
        },
        {
            title: '规格名称',
            dataIndex: 'attributeName',
            key: 'attributeName',
            ellipsis: true,
        },
        {
            title: '规格类型',
            dataIndex: 'attributeTypeCdDesc',
            key: 'attributeTypeCdDesc',
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: AttributeTableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>

            {/*表格内容*/}
            <Table rowSelection={{
                ...rowSelection,"selectedRowKeys":selectedRowKeys,
            }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>
            {/*表格的分页*/}
            <div style={{textAlign:"right"}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*<Divider />*/}

            <div style={{textAlign: 'center', marginTop: '30px'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setModel('create')
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        }else {
                            handleDelete();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>

            </div>

            {/*新增和编辑的form表单，子组件*/}
            <GoodsAttributeForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as AttributeTableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as AttributeTableDataType)
                }}
                model={model}
                goodsId={goodsId}
                attributeTypeCdOption={attributeTypeCdOption}
                statusCdOption={statusCdOption}
            ></GoodsAttributeForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as AttributeTableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default GoodsAttributeContent;