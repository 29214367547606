import moment from "moment";

const baseUrl = "/app/catalog/info";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    appName:string,
    softType:string
}

//定义表格的属性
export interface TableDataType {
    appCatalogId: number;
    appName: string;
    iconFile: string;
    softType: number;
    softTypeDesc: string;
    status: number;
    statusDesc: string;
    enableStatus: number;
    enableStatusDesc: string;
    creater: string;
    createTime: string;
    modifier: string;
    modifyTime: string;
    reorder: number;
    remark: string;
}

export  {baseUrl};

