import {baseUrl, PageParam, TableDataType} from "../types/res/serverModel";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const serverModelApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.modelName) {
                url += 'modelName=' + encodeURIComponent(body.modelName);
            }
            if (body.modelCode) {
                url += '&modelCode=' + encodeURIComponent(body.modelCode);
            }
            let serverTypeCd = body.serverTypeCd;
            if (serverTypeCd) {
                url += '&serverTypeCd=' + serverTypeCd;
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    options() {
        return  request.get<OptionRes[]>(baseUrl+"/options");
    },

    //根据id获取数据
    getById(serverModelCode: number) {
        return  request.get<TableDataType>(baseUrl+"/getById?id="+serverModelCode);
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"服务器型号列表",body)
    },
}

export default serverModelApi



