const baseUrl = "/opt/template";
/** 获取产品申请列表-参数 */
export interface PageParam {
    templateName:string,
    smsTypeCd:string,
    ownerTypeCd:string,
    // ownerObjectId:Number
}

//定义表格的属性
export interface TableDataType {
    id: number;
    templateName: string;
    templateContent: string;
    smsTypeCd: string;
    platformTemplate: string;
    repeatCount: string;
    sendInterval: string;
    validDuration: string;
    ownerTypeCd: string;
    ownerObjectId: number;
    statusCd: string;
    notes: string;
}



export  {baseUrl};

