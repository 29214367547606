import React, {useEffect, useState} from "react";
import {Button, ConfigProvider, DatePicker, Divider, Form, Image, message, Popconfirm, Table, Typography} from 'antd';
import {ColumnsType} from "antd/es/table";
import {TableDataType} from "../../../../../apis/types/mkt/act/goods/goodsAct";

import ActGoodsForm from "./actGoodsForm";
import Details from "../../redeemCode/details";
import actApi from "../../../../../apis/mkt/act/act";
import {imgIp} from "../../../../../components/constantConfig";

const {RangePicker} = DatePicker;

interface ActGoodsContentProps {
    actId: Number,
    setActGoodsList: (list: any) => void;
    open: boolean;
    number: Number


}

const MktGoodsAct: React.FC<ActGoodsContentProps> = ({
                                                         actId,
                                                         setActGoodsList,
                                                         open,
                                                         number
                                                     }) => {


    //定义用到的useState，用来绑定数据
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    //form表单的open
    const [formOpen, setFormOpen] = useState(false)
    const [model, setModel] = useState(0)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    //进入页面先查询数据
    useEffect(() => {

        // 检查 actId 是否为有效的正整数
        if (actId != null && actId > 0) {
            handleQuery();
        }
    }, [actId, number]);

    //处理查询
    function handleQuery() {
        let body = {"actId": actId}

        let res = actApi.actOthers(body);
        res.then((result) => {

            if (result) {
                setList(result.data.goodsActRespVoList)
                setActGoodsList(result.data.goodsActRespVoList)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        // 要替换成在表格内添加数据
        const newList = list.filter(item => !selectedRowKeys.includes(item.id));
        //设置用来展示在列表中的数据
        setList([...newList])
        //设置传递到上层组件的值，用于更新数据
        setActGoodsList([...newList])

    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setFormOpen(true)
                    console.log("operation", record)
                    setRowData(record);
                    setModel(1)
                }}>
                    编辑
                </Typography.Link>
            },
        },
        {
            title: '产品名称',
            dataIndex: 'productName',
            key: 'productName',
        },

        {
            title: '商品名称',
            dataIndex: 'goodsName',
            key: 'goodsName',
        },
        {
            title: '活动价(分)',
            dataIndex: 'actPrice',
            key: 'actPrice',
        }, {
            title: '商品简介',
            dataIndex: 'goodsIntroduce',
            key: 'goodsIntroduce',
        }
        , {
            title: 'pc应用角标',
            dataIndex: 'pcIcon',
            key: 'pcIcon',
            render: (pictureUrl: string) => {
                const url = imgIp + pictureUrl;
                return <Image src={url} width={100}/>
            },
        },
        {
            title: 'app应用角标',
            dataIndex: 'appIcon',
            key: 'appIcon',
            render: (pictureUrl: string) => {
                const url = imgIp + pictureUrl;
                return <Image src={url} width={100}/>
            },
        },
        {
            title: '启用标识',
            dataIndex: 'enableStatusDesc',
            key: 'enableStatusDesc',
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            console.log("rowSelection", selectedRowKeys)
            const newSet = new Set(selectedRows.map((key) => key.id));
            console.log("newSet", newSet)
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);

        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setFormOpen(true)
                    setModel(0)
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>

                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        } else {
                            handleDelete();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>

            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection, "selectedRowKeys": selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>


            <ActGoodsForm
                refresh={() => {
                    //设置表单不展示
                    setFormOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={formOpen}
                closeOpen={() => {
                    setFormOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                actId={actId}
                setList={setList}
                setActGoodsList={setActGoodsList}
                list={list}

            ></ActGoodsForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)

                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default MktGoodsAct;