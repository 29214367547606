const baseUrl = "/res/host/virtual";
/** 获取产品申请列表-参数 */
export interface PageParam {
    virtualName:string,
    instanceGradeCd:Number
}

//定义表格的属性
export interface TableDataType {
    id: number;
    virtualName: string;
    serverModelId: number;
    virtualQuantity: number;
    activeQuantity: number;
    cpuQuota: number;
    gpuQuota: number;
    ramQuota: number;
    romQuota: number;
    instanceGradeCd: string;
    defaultMark: number;
    defaultMarkDesc: number;
    statusCd: string;
    notes: string;
}



export  {baseUrl};

