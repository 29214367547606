const baseUrl = "/app/product/config";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    wallpaperName:string,
    wallpaperDownloadUrl:string,
    productId:number,
    enableStatus:string,
}

//定义表格的属性
export interface TableDataType {
    id: number,
    productId:number,
    productIdDesc:string,
    wallpaperName:string,
    wallpaperDownloadUrl:string,
    creator:number,
    creatorDesc:string,
    modifier:number,
    modifierDesc:string,
    softDel:number,
    enableStatus:number,
    enableStatusDesc:string,
    createTime:string,
    modifyTime:string,
}



export  {baseUrl};

