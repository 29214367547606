import React, {useEffect, useState} from "react";
import {
    Button, Col, ConfigProvider, DatePicker,
    Divider,
    Form, Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm, Row,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import orderApi from "../../../../../apis/ord/order";
import {OrderStatusTableDataType, OrderPayStatusTableDataType} from "../../../../../apis/types/res/orderLog";


const {RangePicker} = DatePicker;

interface Props {
    orderId: number;
}

const OrderStatusContent: React.FC<Props> = ({orderId}) => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        return () => {
            setOrderStatusList([]);
            setOrderPayStatusList([]);
        };
    }, [orderId]);

    //定义用到的useState，用来绑定数据
    const [orderStatusList, setOrderStatusList] = useState<OrderStatusTableDataType[]>([]);

    const [orderPayStatusList, setOrderPayStatusList] = useState<OrderPayStatusTableDataType[]>([]);


    //处理查询
    function handleQuery() {
        let res = orderApi.searchOrderComStatusLog(orderId);
        res.then((result) => {
            if (result) {
                setOrderStatusList(result.data.orderStatusLogData)
                setOrderPayStatusList(result.data.orderPayStatusLogData)
            }
        })

    }


    //表格的内容
    const OrderStatus: ColumnsType<OrderStatusTableDataType> = [
        {
            title: '时间',
            dataIndex: 'operateTime',
            key: 'operateTime',
        },

        {
            title: '状态',
            dataIndex: 'statusCdDesc',
            key: 'statusCdDesc',
        },
        {
            title: '操作者',
            dataIndex: 'operatorName',
            key: 'operatorName',
        }
    ];

    //表格的内容
    const OrderPayStatus: ColumnsType<OrderPayStatusTableDataType> = [
        {
            title: '时间',
            dataIndex: 'operateTime',
            key: 'operateTime',
        },

        {
            title: '支付状态',
            dataIndex: 'payStatusCdDesc',
            key: 'payStatusCdDesc',
        },
        {
            title: '操作者',
            dataIndex: 'operatorName',
            key: 'operatorName',
        }
    ];


    return (

        <div>
            <div>
                <h3>订单状态信息</h3>
                <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                    {/*表格内容*/}
                    <Table
                        size="small" scroll={{ y: 110 }} dataSource={orderStatusList}
                        columns={OrderStatus} rowKey={record => record.id} pagination={false}/>

                </ConfigProvider>
            </div>

            <div>
                <h3>订单支付状态信息</h3>
                <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                    {/*表格内容*/}
                    <Table
                        size="small" scroll={{ y: 110 }} dataSource={orderPayStatusList}
                        columns={OrderPayStatus} rowKey={record => record.id} pagination={false}/>

                </ConfigProvider>
            </div>



        </div>
    )
}


export default OrderStatusContent;