const baseUrl = "/mkt/coupon/coupon";
/** 获取列表-参数 */
export interface PageParam {
    couponType: string;
    enableStatus: string;
    softDel: string;
    startCreateTime: string;
    endCreateTime: string;
    creator: string;
    batchNo: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    templateId: string;
    couponName: string;
    couponType: string;
    couponTypeDesc: string;
    discount: string;
    goodsList: string;
    usageDesc: string;
    startTime: string;
    expTime: string;
    couponCode: string;
    batchNo: string;
    allocationStatus: string;
    allocationStatusDesc: string;
    useStatus: string;
    useStatusDesc: string;
    creator: string;
    modifier: string;
    createTime: string;
    modifyTime: string;
    enableStatus: string;
    enableStatusDesc: string;
    softDel: string;
    softDelDesc: string;
}

export  {baseUrl};