import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/ord/payment";

const paymentApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.orderId) {
                url += 'orderId=' + encodeURIComponent(body.orderId) + "&";
            }
            if (body.id) {
                url += 'id=' + encodeURIComponent(body.id) + "&";
            }
            if (body.paymentCode) {
                url += 'paymentCode=' + encodeURIComponent(body.paymentCode) + "&";
            }
            if (body.paymentTime) {
                url += 'paymentTime=' + encodeURIComponent(body.paymentTime) + "&";
            }
            if (body.statusCd) {
                url += 'statusCd=' + encodeURIComponent(body.statusCd) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
}

export default paymentApi;



