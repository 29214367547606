const baseUrl = "/res/manage/node";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    nodeName:string,
    nodeIp:string,
    enableStatus:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    nodeName:string,
    nodeIp:string,
    creator:number,
    creatorDesc:string,
    modifier:number,
    modifierDesc:string,
    softDel:number,
    enableStatus:number,
    enableStatusDesc:string,
    createTime:string,
    modifyTime:string,
}



export  {baseUrl};

