import React, {useEffect, useState} from 'react';
import {DatePicker, Form, Input, Modal, Select} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import dayjs from "dayjs";
import propertiesApi from "../../../../apis/sys/properties";
import {TableDataType} from "../../../../apis/types/opt/msgRecipient";
import msgRecipientApi from "../../../../apis/opt/msgRecipient";
import subscriberApi from "../../../../apis/sub/subscriberApi";
import userApi from "../../../../apis/sys/user";
import subuserApi from "../../../../apis/sub/subuser";
import TextArea from "antd/es/input/TextArea";

const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface MsgRecipientFormProps {
    refresh: () => void;
    closeOpen: () => void;
    open: boolean;
    type: boolean;
    msgId: number;
    msgTypeCd: string;
    onUpdateList: (data) => void;
}

const MsgRecipientForm: React.FC<MsgRecipientFormProps> = ({
                                                               refresh,
                                                               open,
                                                               type,
                                                               closeOpen,
                                                               msgId,
                                                               msgTypeCd,
                                                               onUpdateList
                                                           }) => {

    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue({
            msgId: msgId,
            msgType: msgTypeCd
        });
    }, [open]);

    const [form] = Form.useForm();
    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        msgRecipientApi.create(values).then((result) => {
            if (result) {
                onUpdateList(result.data)
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            handleCreate();
        }).catch((error) => {

        });
    }


    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name={"msgId"} hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"msgType"} hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name="accNos" label="收件账号"
                           rules={[{required: true, message: "收件账号不能为空"}]}>
                    <TextArea rows={1} style={{width: 200}} placeholder={"使用英文,分隔"}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default MsgRecipientForm;