import React, {useEffect} from 'react';
import {Form, Input, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import {TableDataType} from "../../../../apis/types/res/host";
import hostApi from "../../../../apis/res/host";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    type: boolean;
    serverOption: OptionRes[];
    faultTypeOption: OptionRes[];
    statusOption: OptionRes[];

}


const HostForm: React.FC<StaffFromProps> = ({type, refresh, record, open, closeOpen, serverOption, faultTypeOption, statusOption}) => {
    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        form.setFieldsValue({
            ...record,
        });
    }, [form, record]);


    //处理新增方法
    const handleCreate = () => {
        hostApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        hostApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}
                style={{maxWidth: 300}}
            >

                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                {/*<Form.Item name="serverId" label="服务器" rules={[{required: true, message: "服务器必填"}]}>*/}
                {/*    <Select*/}
                {/*        style={{width: 200}}*/}
                {/*        showSearch*/}
                {/*        optionFilterProp="children"*/}
                {/*        allowClear*/}
                {/*        filterOption={filterOption}*/}
                {/*        options={serverOption}*/}
                {/*    />*/}
                {/*</Form.Item>*/}
                {/*<Form.Item name="hostIp" label="IP地址" rules={[{required: true, message: "IP地址必填"}]}>*/}
                {/*    <Input style={{width: 200}}/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item name="hostMac" label="MAC地址">*/}
                {/*    <Input style={{width: 200}}/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item name="hostCode" label="宿主机编码"*/}
                {/*           rules={[{required: true, message: "宿主机编码必填"}]}>*/}
                {/*    <Input style={{width: 200}}/>*/}
                {/*</Form.Item>*/}
                <Form.Item name="faultTypeCd" label="故障类型">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={faultTypeOption}
                    />
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default HostForm;



