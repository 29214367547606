import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/app/productConfig";
import commonApi from "../commonApi";

const productConfigApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.wallpaperName) {
                url += 'wallpaperName=' + encodeURIComponent(body.wallpaperName) + "&";
            }
            if (body.wallpaperDownloadUrl) {
                url += 'wallpaperDownloadUrl=' + encodeURIComponent(body.wallpaperDownloadUrl) + "&";
            }
            if (body.productId) {
                url += 'productId=' + encodeURIComponent(body.productId) + "&";
            }
            if (body.enableStatus) {
                url += 'enableStatus=' + encodeURIComponent(body.enableStatus) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"产品配置列表",body)
    },

}

export default productConfigApi



