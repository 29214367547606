import React, {useEffect, useState} from 'react';
import {Form, Input, InputNumber, Modal, Select} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import platformApi from "../../../../apis/opt/smsPlatform";
import {TableDataType} from "../../../../apis/types/opt/smsPlatform";
import TextArea from "antd/es/input/TextArea";

const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface SmsPlatformFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    type: boolean;
    ownerTypeOption: OptionRes[];
    statusOption: OptionRes[];


}

const SmsPlatformForm: React.FC<SmsPlatformFormProps> = ({
                                                             refresh,
                                                             record,
                                                             open,
                                                             type,
                                                             closeOpen,
                                                             statusOption,
                                                             ownerTypeOption
                                                         }) => {

    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue({
            ...record
        });
    }, [open]);

    const [ownerObjectIdOption, setOwnerObjectIdOption] = useState<OptionRes[]>([])

    function selectOnchange(event: string) {
        setOwnerObjectIdOption([]);
        if (event == "O02") {
        } else {

        }
        form.setFieldsValue({
            ...record,
            // ownerTypeCd: event,
            ownerObjectId: '',
        });
    }

    const [form] = Form.useForm();
    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        platformApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        platformApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }


    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name="id" label="平台编号" hidden={true}>
                </Form.Item>
                <Form.Item name="platformName" label="平台名称"
                           rules={[{required: true, message: "平台名称不能为空"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="platformCode" label="平台编码"
                           rules={[{required: true, message: "平台编码不能为空"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="reorder" label="平台优先级"
                           rules={[{required: true, message: "平台优先级不能为空"}]}>
                    <InputNumber min={0} style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="accessConfig" label="平台访问配置"
                           rules={[{required: true, message: "平台访问配置不能为空"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="successKeyword" label="成功关键字"
                           rules={[{required: true, message: "成功关键字不能为空"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="platformSupplier" label="平台供应商"
                           rules={[{required: true, message: "平台供应商不能为空"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="ownerTypeCd" label="模板类型"
                           rules={[{required: true, message: "模板类型不能为空"}]}>
                    <Select
                        allowClear
                        style={{width: 200}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={ownerTypeOption}
                        onChange={selectOnchange}
                    />
                </Form.Item>
                <Form.Item name="ownerObjectId" label="模板对象">
                    <Select
                        allowClear
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={ownerObjectIdOption}
                    />
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                    <Select
                        allowClear
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>


            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default SmsPlatformForm;



