import React, {useEffect, useState} from 'react';
import {DatePicker, Divider, Form, Input, InputNumber, Modal, Select} from 'antd';
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import {TableDataType as MktActTableDataType} from "../../../../apis/types/mkt/act/act";
import {TableDataType as GoodsActTableDataType} from "../../../../apis/types/mkt/act/goods/goodsAct";
import {TableDataType as GoodsLadderPriceTableDataType} from "../../../../apis/types/mkt/act/goodsLadderPrice/goodsLadderPrice";

import actApi from "../../../../apis/mkt/act/act";
import TextArea from "antd/es/input/TextArea";
import ActGoodsContent from "./goods/actGoodsContent";
import ActStepContent from "./step/actStepContent";
import RetentionPopupForm from "./retentionPopup/retentionPopupForm";
import dayjs from "dayjs";


const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface ActFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: MktActTableDataType;
    open: boolean;
    model: number;
    actTypeOption: OptionRes[];
    number: Number;


}


const ActForm: React.FC<ActFromProps> = ({
                                             model,
                                             refresh,
                                             record,
                                             open,
                                             closeOpen,

                                             actTypeOption,
                                             number
                                         }) => {

    const [actType, setActType] = useState('')
    const [actGoodsList, setActGoodsList] = useState<GoodsActTableDataType[]>([]);
    const [priceList, setPriceList] = useState<GoodsLadderPriceTableDataType[]>([]);

    const [buyIntervalDay, setBuyIntervalDay] = useState(0)


    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        if (open && Object.keys(record).length > 0) {

            form.setFieldsValue({
                ...record,
                startTime: dayjs(record.startTime),
                endTime: dayjs(record.endTime),
            })

        }
        setActType(record.actType); // 初始化时设置活动类型
        console.log("stepFormOpen,useEffect",open)
    }, [open]);

    //处理新增方法
    const handleCreate = async () => {
        //第一部分的数据：from表单的数据
        let values = form.getFieldsValue();


        //第二部分的数据：子表格（商品以及关联的阶梯价等数据）:
        // 获取所有的 priceRuleList 集合
        const allPriceRuleLists = actGoodsList
            .map(item => item.priceRuleList)  // 提取每个对象的 priceRuleList
            .flat();
        //组合在一起
        values = {
            ...values,
            actGoodsList: actGoodsList,
            goodsLadderPriceList:allPriceRuleLists,
            retentionPopup:{buyIntervalDay:buyIntervalDay},
            startTime: values.startTime ? dayjs(values.startTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endTime: values.endTime ? dayjs(values.endTime).format('YYYY-MM-DD HH:mm:ss') : null,

        };
        actApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        setActGoodsList([])
        form.resetFields();
        closeOpen()
    };


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    //处理新增的情况
    async function handleUpdate() {
        //第一部分的数据：from表单的数据
        let values = form.getFieldsValue();


        //第二部分的数据：子表格（商品以及关联的阶梯价等数据）:

        //组合在一起
        const allPriceRuleLists = actGoodsList
            .map(item => item.priceRuleList)  // 提取每个对象的 priceRuleList
            .flat();
        values = {
            ...values,
            actGoodsList: actGoodsList,
            goodsLadderPriceList:allPriceRuleLists,
            retentionPopup:{buyIntervalDay:buyIntervalDay},
            startTime: values.startTime ? dayjs(values.startTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endTime: values.endTime ? dayjs(values.endTime).format('YYYY-MM-DD HH:mm:ss') : null,

        };

        actApi.update(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    return (
        <Modal
            title={model === 0 ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
            width={1800}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="actName" label="活动名称" rules={[{required: true, message: "活动名称必填"} , {max: 255, message: '活动名称长度不能大于255'}]}>
                            <Input style={{width: 200}}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>

                        <Form.Item name="actType" label="活动类型" rules={[{required: true, message: "活动类型必填"}]}>
                            <Select
                                style={{width: 200}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={actTypeOption}
                                disabled={model === 1} // 当 编辑的时候禁用选择框

                                onChange={(value) => {
                                    setActType(value); // 更新状态
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>

                        <Form.Item style={{margin: 5}} name="startTime" label="开始时间"
                                   rules={[{required: true, message: "活动开始时间必填"}]}>
                            <DatePicker
                                showTime
                                placeholder={''}
                                style={{width: 250}}
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item style={{margin: 5}} name="endTime" label="结束时间"
                                   rules={[{required: true, message: "活动结束时间必填"}]}>
                            <DatePicker
                                showTime
                                placeholder={''}
                                style={{width: 250}}
                                format="YYYY-MM-DD HH:mm:ss"
                            />
                        </Form.Item>
                    </div>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="enableStatus" label="启用状态"
                                   rules={[{required: true, message: "启用状态必填"}]}>
                            <Select
                                style={{width: 200}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={[{value: 0, label: '禁用'}, {value: 1, label: '启用'}]}
                            />

                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="reorder" label="排序字段" rules={[{required: true, message: "排序必填"}]}>
                            <InputNumber min={0} max={32767} style={{width: 200}}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '20%', padding: '0 8px'}}>
                        <Form.Item name="notes" label="备注" rules={[{max: 255, message: '备注长度不能大于255'}]}>
                            <TextArea rows={1} style={{width: 300}}/>
                        </Form.Item>
                    </div>
                </div>
            </Form>


            {/*展示活动商品的主体部分*/}
            {actType === 'goods_act' &&
                (
                    <>
                        <Divider></Divider>
                        <h3>活动商品</h3>

                        <div>

                            <ActGoodsContent
                                actId={record.id}
                                setActGoodsList={setActGoodsList}
                                open={open}
                                number={number}
                            ></ActGoodsContent>
                        </div>
                    </>
                )
            }

            {/*如果是编辑，并且是活动商品类型信息,那么展示活动商品的表单*/}
            {actType === 'ladder_price' &&
                (
                    <>
                        <Divider></Divider>
                        <h3>阶梯商品</h3>
                        <div>

                            <ActStepContent
                                actId={record.id}
                                setActGoodsList={setActGoodsList}
                                setPriceList={setPriceList}
                                openActStep={open}
                                number={number}
                            ></ActStepContent>
                        </div>
                    </>


                )
            }

            {/*展示挽留活动的主体部分*/}
            {actType === 'retention_popup' &&
                (
                    <>
                        <div>
                            <RetentionPopupForm
                                open={open}
                                actId={record.id}
                                setActGoodsList={setActGoodsList}
                                setBuyIntervalDay={setBuyIntervalDay}
                                number={number}
                            ></RetentionPopupForm>
                        </div>
                    </>
                )
            }


        </Modal>
    );
};

export default ActForm;




