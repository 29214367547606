import moment from "moment";

const baseUrl = "/sys/roles";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    roleName:string,
    statusCd:string
}

//定义表格的属性
export interface TableDataType {
    id: number;
    roleName: string;
    statusDesc: string;
    actionList: [];
    notes: string;
}



export  {baseUrl};

