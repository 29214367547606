import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider, DatePicker,
    Divider,
    Form,
    Input,
    Pagination,
    PaginationProps,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import dayjs from "dayjs";
import propertiesApi from "../../../../apis/sys/properties";
import {TableDataType} from "../../../../apis/types/ord/payment";
import paymentApi from "../../../../apis/ord/payment";
import instanceApi from "../../../../apis/res/instance";
import Details from "../../public/details/details";

interface StaffFromProps {
    orderId: number;
}

const OrderContent: React.FC<StaffFromProps> = ({orderId}) => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
    }, [orderId]);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            orderId:orderId
        };
        let res = paymentApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
            }
        })
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     render: (_: any, record: TableDataType) => {
        //         return <Typography.Link onClick={() => {
        //             setOpen(true)
        //             setRowData(record);
        //         }}>
        //             编辑
        //         </Typography.Link>
        //     },
        // },
        {
            title: '支付单流水号',
            dataIndex: 'paymentCode',
            key: 'paymentCode',
        },
        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
        },
        {
            title: '支付方式',
            dataIndex: 'payModeIdDesc',
            key: 'payModeIdDesc',
        },
        {
            title: '支付序列号',
            dataIndex: 'paymentSerial',
            key: 'paymentSerial',
        },
        {
            title: '支付账号',
            dataIndex: 'paymentAccount',
            key: 'paymentAccount',
        },
        {
            title: '支付金额',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
        },
        {
            title: '支付时间',
            dataIndex: 'paymentTime',
            key: 'paymentTime',
        },
        {
            title: '支付结果',
            dataIndex: 'paymentResult',
            key: 'paymentResult',
        },
        {
            title: '结果代码',
            dataIndex: 'resultCode',
            key: 'resultCode',
        },
        {
            title: '支付单创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '状态',
            dataIndex: 'statusCdDesc',
            key: 'statusCdDesc',
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            // setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            {/*<div style={{display: 'flex', alignItems: 'center'}}>*/}

            {/*    /!*查询的表单*!/*/}
            {/*    <Form form={form} name="validateOnly" layout="inline" autoComplete="off"*/}
            {/*          style={{marginLeft: 'auto'}}*/}
            {/*          onFinish={() => {*/}
            {/*              pagination.current = 1;*/}
            {/*              setCurrentPage(1);*/}
            {/*              handleQuery();*/}
            {/*          }}*/}
            {/*    >*/}
            {/*        <Form.Item name="id" label="支付编号">*/}
            {/*            <Input/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item name="orderId" label="订单编号">*/}
            {/*            <Input/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item name="paymentCode" label="支付单流水号">*/}
            {/*            <Input/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item name="paymentTime" label="支付时间">*/}
            {/*            <DatePicker showTime placeholder={''}/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item name="statusCd" label="状态">*/}
            {/*            <Select*/}
            {/*                allowClear*/}
            {/*                style={{width: 150}}*/}

            {/*                optionFilterProp="children"*/}
            {/*                filterOption={filterOption}*/}
            {/*                options={statusOption}*/}
            {/*            />*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item>*/}
            {/*            <Space>*/}
            {/*                <Button type="primary" htmlType="submit">查询</Button>*/}
            {/*                <Button htmlType="reset" onClick={() => {*/}
            {/*                    const body = {};*/}
            {/*                    paymentApi.pageData(body, pagination).then((res) => {*/}
            {/*                        if (res) {*/}
            {/*                            setList(res.data.records)*/}
            {/*                            setTotal(res.data.total)*/}
            {/*                        }*/}
            {/*                    })*/}
            {/*                }}>重置</Button> </Space>*/}
            {/*        </Form.Item>*/}
            {/*    </Form>*/}
            {/*</div>*/}

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{textAlign:"right"}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default OrderContent;