import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider, DatePicker,
    Divider,
    Form, Image,
    Input,
    message,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/mkt/discovery/discoveryVo";
import discoveryApi from "../../../../apis/mkt/discovery/discoveryApi";
import dayjs from "dayjs";
import Details from "../../mkt/redeemCode/details";
import DiscoveryForm from "./discoveryForm";
import {imgIp} from "../../../../components/constantConfig";

const {RangePicker} = DatePicker;

const DiscoveryContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos("DM_Enable_Status").then((res) => {
            if (res) {
                setEnableStatusOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Discovery_Interact_Type").then((res) => {
            if (res) {
                setInteractOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Discovery_Info_Type").then((res) => {
            if (res) {
                setInfoTypeOption(res.data)
            }
        })

    }, []);

    //定义用到的useState，用来绑定数据
    const [model, setModel] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [enableStatusOption, setEnableStatusOption] = useState<OptionRes[]>([])
    const [interactOption, setInteractOption] = useState<OptionRes[]>([])
    const [infoTypeOption, setInfoTypeOption] = useState<OptionRes[]>([])
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();

        var beginStartTime = form.getFieldValue('beginStartTime');
        var endStartTime = form.getFieldValue('endStartTime');
        var beginEndTime = form.getFieldValue('beginEndTime');
        var endEndTime = form.getFieldValue('endEndTime');

        body = {
            ...body,
            beginStartTime: beginStartTime ? dayjs(beginStartTime).format('YYYY-MM-DD 00:00:00') : null,
            endStartTime: endStartTime ? dayjs(endStartTime).format('YYYY-MM-DD 00:00:00') : null,
            beginEndTime: beginEndTime ? dayjs(beginEndTime).format('YYYY-MM-DD 00:00:00') : null,
            endEndTime: endEndTime ? dayjs(endEndTime).format('YYYY-MM-DD 00:00:00') : null,
        };

        let res = discoveryApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        var beginStartTime = form.getFieldValue('beginStartTime');
        var endStartTime = form.getFieldValue('endStartTime');
        var beginEndTime = form.getFieldValue('beginEndTime');
        var endEndTime = form.getFieldValue('endEndTime');

        body = {
            ...body,
            beginStartTime: beginStartTime ? dayjs(beginStartTime).format('YYYY-MM-DD 00:00:00') : null,
            endStartTime: endStartTime ? dayjs(endStartTime).format('YYYY-MM-DD 00:00:00') : null,
            beginEndTime: beginEndTime ? dayjs(beginEndTime).format('YYYY-MM-DD 00:00:00') : null,
            endEndTime: endEndTime ? dayjs(endEndTime).format('YYYY-MM-DD 00:00:00') : null,
        };
        discoveryApi.derive(body);
    }
    //处理启用
    const handleEnable = () => {
        const body = {
            discoveryIds: idList
        }
        discoveryApi.batchEnable(body).then((result) => {
            if (result){
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const handleDisable = () => {
        const body = {
            discoveryIds: idList
        }
        discoveryApi.batchDisable(body).then((result) => {
            if (result){
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const handleDelete = () => {
        const body = {
            discoveryIds: idList
        }
        discoveryApi.batchDelete(body).then((result) => {
            if (result){
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }
    function enableconfirm() {
        if (idList.length === 0) {
            message.error('启用失败，请选择需要启用的数据');
        } else {
            message.success('确认启用');
            handleEnable();
        }
    }

    function enablecancel() {
        message.error('取消启用');
    }

    function disableconfirm() {
        if (idList.length === 0) {
            message.error('禁用失败，请选择需要禁用的数据');
        } else {
            message.success('确认禁用');
            handleDisable();
        }
    }

    function deleteconfirm() {
        if (idList.length === 0) {
            message.error('删除失败，请选择需要删除的数据');
        } else {
            message.success('确认删除');
            handleDelete();
        }
    }

    function disablecancel() {
        message.error('取消禁用');
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [

        {
            title: '操作',
            dataIndex: 'operation',
            width: 30,
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    console.log("operation", record)
                    setRowData(record);
                    setModel("edit")
                }}>
                    编辑
                </Typography.Link>
            },
        },
        {title: "活动类型", dataIndex: "infoTypeDesc", key: "infoTypeDesc", ellipsis:true,width:50},
        {title: "活动名称", dataIndex: "title", key: "title", ellipsis:true,width:70},
        {title: "活动简介", dataIndex: "introduce", key: "introduce", ellipsis:true,width:100},
        {title: "开始时间", dataIndex: "startTime", key: "startTime", ellipsis:true,width:80,},
        {title: "结束时间", dataIndex: "endTime", key: "endTime", ellipsis:true,width:80,},
        {title: "启用状态", dataIndex: "enableStatusDesc", key: "enableStatusDesc", ellipsis:true,width:50},
        {title: "置顶", dataIndex: "stickDesc", key: "stickDesc", ellipsis:true,width:50},
        {title: "跳转链接", dataIndex: "jumpLink", key: "jumpLink", ellipsis:true,width:100},
        {
            title: 'PC端banner图',
            dataIndex: 'pcBannerPic',
            key: 'pcBannerPic',
            render: (pcBannerPic: string) => {
                const url = imgIp + pcBannerPic;
                return <Image src={url} width={100}/>
            },
            ellipsis: true,
            width: 100
        },
        {
            title: '移动端banner图',
            dataIndex: 'appBannerPic',
            key: 'appBannerPic',
            render: (appBannerPic: string) => {
                const url = imgIp + appBannerPic;
                return <Image src={url} width={100}/>
            },
            ellipsis: true,
            width: 100
        },
        {title: "备注", dataIndex: "notes", key: "notes", ellipsis:true,width:100},
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    function cancel() {
        message.error('取消删除');
    }

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginRight: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item style={{margin: 5}} name="infoType" label="活动类型">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={infoTypeOption}
                            size = "small"
                        />
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="enableStatus" label="启用标识">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={enableStatusOption}
                            size = "small"
                        />
                    </Form.Item>

                    {/* 这里的discoveryStartTime参数实际上服务端接口是不需要的，是为了重置的时候把页面上的日期选择器显示重置掉   */}
                    <Form.Item style={{margin: 5}} label="开始时间" name={['discoveryStartTime', 'dates']}>
                        <RangePicker
                            style={{width: 250}}
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginStartTime", null);
                                    form.setFieldValue("endStartTime", null);
                                } else if (Array.isArray(value)) {
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("beginStartTime", date[0]);
                                        form.setFieldValue("endStartTime", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }}
                            size = "small"
                        />
                    </Form.Item>

                    {/* 这里的discoveryEndTime参数实际上服务端接口是不需要的，是为了重置的时候把页面上的日期选择器显示重置掉   */}
                    <Form.Item style={{margin: 5}} label="结束时间" name={['discoveryEndTime', 'dates']}>
                        <RangePicker
                            style={{width: 250}}
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginEndTime", null);
                                    form.setFieldValue("endEndTime", null);
                                } else if (Array.isArray(value)) {
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("beginEndTime", date[0]);
                                        form.setFieldValue("endEndTime", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }}
                            size = "small"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit" size = "small" style={{margin:8}}>查询</Button>
                            <Button htmlType="reset" style={{margin:5}} onClick={()=>{
                                form.resetFields()
                                handleQuery()
                            }} size = "small" >重置</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 5}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setModel('create')
                }} style={{marginRight: '25px'}} size="small">
                    新增
                </Button>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}} size="small">
                        导出
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="是否确认启用所选项？"
                    onConfirm={enableconfirm}
                    onCancel={enablecancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button
                        type="primary" // 或者保持"type='primary'"，根据你的设计偏好
                        size="small"
                        style={{
                            backgroundColor: '#5cb85c', // 绿色
                            marginRight: '25px', //右间距
                            color: 'white', // 确保文字颜色与背景色对比良好
                            border: 'none', // 移除默认的边框，使按钮看起来更整洁
                        }}
                    >
                        启用
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="是否确认禁用所选项？"
                    onConfirm={disableconfirm}
                    onCancel={disablecancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}} size="small">
                        禁用
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={deleteconfirm}
                    onCancel={cancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}} size={"small"}>
                        删除
                    </Button>
                </Popconfirm>

                <Divider style={{height: 1, marginTop: 10, marginBottom: 5}}/>




                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "60vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                                   total={total}
                                   showSizeChanger
                                   showQuickJumper
                                   current={currentPage}
                                   defaultPageSize={20}
                                   locale={paginationLocale}
                                   onChange={handlePaginationChange}
                                   showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


            {/*新增和编辑的form表单，子组件*/}
            <DiscoveryForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                record={rowData}
                enableStatusOption={enableStatusOption}
                interactOption={interactOption}
                infoTypeOption={infoTypeOption}
            ></DiscoveryForm>

            {/*鼠标双击出现的详情展示表单*/}
            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default DiscoveryContent;