import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, Input, message, Modal, Select, Upload} from 'antd';
import type {UploadFile, UploadProps} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/pub/channel/channelParentVersion";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";
import dayjs from "dayjs";
import {UploadOutlined} from "@ant-design/icons";
import {getToken} from "../../../../utils";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface ChannelParentVersionFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    statusOption: OptionRes[];
    mustUpdateOption: OptionRes[];
    clientIdOption: OptionRes[];
    type: boolean;
}

const ChannelParentVersionForm: React.FC<ChannelParentVersionFormProps> = ({
                                                                               refresh,
                                                                               record,
                                                                               open,
                                                                               closeOpen,
                                                                               statusOption,
                                                                               mustUpdateOption,
                                                                               clientIdOption,
                                                                               type
                                                                           }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue({
            ...record,
            // 在组件挂载时将 versionTime 字段转换为 dayjs 对象
            versionTime: record.versionTime ? dayjs(record.versionTime, "YYYY-MM-DD HH:mm:ss") : null,
            // 将数字格式转成字符串格式
            versionMust: record.versionMust ? record.versionMust.toString() : "0",
            fileSize: record.fileSize ? record.fileSize : 0,
        });
    }, [open]);

    /**
     * 初始化文件上传功能的文件列表
     */
    useEffect(() => {
        // 看看有没有上传了的文件
        const downloadUrl = record.downloadUrl;

        if (downloadUrl) {
            // 使用 URL 对象进行解析
            const urlObject = new URL(downloadUrl);
            // 从路径中获取文件名
            const pathParts = urlObject.pathname.split('/');
            const fileName = pathParts[pathParts.length - 1];

            // 创建 UploadFile 对象，使用提取的文件名
            const initialFile: UploadFile = {
                uid: '-1',
                name: fileName,
                status: 'done',
                url: downloadUrl,
                // 其他属性...
            };

            // 设置初始化的 fileList
            setFileList([initialFile]);
        } else {
            // 设置初始化的 fileList
            setFileList([]);
        }
    }, [record]);

    /**
     * 公共的参数校验方法
     * @param values
     */
    const validateFields = (values) => {
        // 检查 filePath 字段是否为空
        if (!values.filePath) {
            // 如果为空，在页面上提醒用户
            message.error('保存失败，母包必须上传');
            return false;
        }

        // 还可以添加其他校验逻辑...

        return true;
    };

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();

        // 调用公共的校验方法
        if (!validateFields(values)) {
            return;
        }

        let valueJSOn = JSON.stringify(values);
        console.log("[channelParentVersionFrom-handleCreate]values:" + valueJSOn);
        channelParentVersionApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();

        // 调用公共的校验方法
        if (!validateFields(values)) {
            return;
        }
        let valueJSOn = JSON.stringify(values);
        console.log("[channelParentVersionFrom-handleUpdate]values:" + valueJSOn);
        channelParentVersionApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    // 定义文件列表参数
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    // 定义文件地址参数
    const [filePath, setFilePath] = useState(record.filePath)
    // 定义文件大小参数
    const [fileSize, setFileSize] = useState(record.fileSize)

    // 在组件内部
    useEffect(() => {
        // 当 filePath 变化时，将最新的值赋给 values.filePath
        form.setFieldsValue({filePath});
    }, [filePath]);

    // 在组件内部
    useEffect(() => {
        // 当 fileSize 变化时，将最新的值赋给 values.fileSize
        console.info("给表单的fileSize字段赋值, fileSize:", fileSize)
        form.setFieldsValue({fileSize})  // 设置 fileSize
    }, [fileSize]);


    const props: UploadProps = {
        action: channelParentVersionApi.getUploadUrl(),
        headers: {
            authorization: getToken(),
        },
        multiple: false,
        maxCount: 1,
        fileList: fileList,
        data: { // 添加 data 参数
            versionCode: form.getFieldValue('versionCode') // 获取表单中的 versionCode
        },
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            console.log("status,", status)

            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    // let downloadUrl = file.response.data.downloadUrl;
                    // console.log("done,downloadUrl:", downloadUrl)
                    // setDownloadUrl(downloadUrl)

                    let filePath = file.response.data.filePath;
                    setFilePath(filePath);
                    form.setFieldsValue({filePath})  // 设置 fileSize

                    let fileSize = file.response.data.fileSize;
                    setFileSize(fileSize);
                    form.setFieldsValue({fileSize})  // 设置 fileSize
                    setFileList(prevList => [...prevList, file]);
                    console.log("done,filePath:{}, fileSize:{}, ", filePath, fileSize)
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setFileList([...fileList])
        },
        beforeUpload(file) {
            const allowedExtensions = ['.apk', '.exe'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            const clientId = form.getFieldValue('clientId');
            if (clientId === "20000") {
                // PC客户端，只能上传zip文件
                if ('.zip' !== fileExtension) {
                    message.error('只能上传zip文件');
                    return false;
                }
            } else {
                // 其他类型客户端
                if (!allowedExtensions.includes(fileExtension)) {
                    message.error('只能上传apk和exe文件');
                    return false;
                }
            }

            const isLt500M = file.size / 1024 / 1024 < 500;
            if (!isLt500M) {
                message.error('文件大小不能超过500m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setFilePath(null)
            setFileSize("0")
        },

    };

    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 700}}
            >
                <Form.Item name="id" label="数据库主键" hidden={true}>
                </Form.Item>
                <Form.Item name="versionCode" label="版本编码（如1001001）"
                           rules={[{required: true, message: "版本编码必填"}, { pattern: /^[1-9]\d*$/, message: "请输入正整数" }]}>
                    <Input style={{width:250}}/>
                </Form.Item>
                <Form.Item name="versionTime" label="版本时间" rules={[{required: true, message: "版本时间必填"}]}>
                    <DatePicker placeholder={''} style={{width:250}}/>
                </Form.Item>
                <Form.Item name="versionMust" label="必需更新"
                           rules={[{required: true, message: "必需更新必填"}]} initialValue={"0"}>
                    <Select
                        style={{width:250}}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={mustUpdateOption}
                    ></Select>
                </Form.Item>
                <Form.Item name="clientId" label="客户端类型" rules={[{required: true, message: "客户端类型必填"}]}
                           initialValue={"10000"}>
                    <Select
                        style={{width:250}}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={clientIdOption}
                    ></Select>
                </Form.Item>
                <Form.Item name="filePath" label="母包文件路径" hidden={true}>
                </Form.Item>
                <Form.Item name="uploadObj" label="母包">
                    <Upload {...props}>
                        <Button icon={<UploadOutlined/>} style={{width:250}}>Upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="fileSize" label="文件大小">
                    <Input disabled={true}  style={{width:250}}></Input>
                </Form.Item>
                <Form.Item name="versionDesc" label="版本描述" rules={[{required: true, message: "版本描述必填"}]}>
                    <Input style={{width:250}}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}
                           initialValue={"S01"}>
                    <Select
                        style={{width:250}}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default ChannelParentVersionForm;



