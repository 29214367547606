import {baseUrl, PageParam, TableDataType} from "../types/pub/picture";
import {request} from "../../utils";
import {ApiResponse, OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const pictureApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.pictureTypeCd) {
                url += 'pictureTypeCd=' + encodeURIComponent(body.pictureTypeCd);
            }
            if (body.statusCd) {
                url += '&statusCd=' + body.statusCd;
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //上传文件
    upload(body: any) {
        const headers = {
            'Content-Type': 'multipart/form-data"', // 设置请求头的Content-Type
        };
        return request.post<string>(baseUrl+"/upload", body,{headers})
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"图片列表",body)
    },
}

export default pictureApi



