const baseUrl = "/res/instance/unStatusList";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    onlineModifyTime:string,
    manageNodeId:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    instanceCode:string,
    idcId:number,
    idcName:string,
    cabinetId:number,
    cabinetCode:string,
    hostId:number,
    hostCode:string,
    instanceSn:number,
    gatewayId:number,
    gatewayName:string,
    manageNodeId:number,
    manageNodeName:string,
    onlineModifyTime:string,
    activateMark:string,
    activateMarkDesc:string,
    onlineMark:string,
    onlineMarkDesc:string,
    handleMark:string,
    handleMarkDesc:string,
    faultMark:string,
    faultMarkDesc:string,
    enableMark:string,
    enableMarkDesc:string,
    maintainMark:string,
    maintainMarkDesc:string,
}



export  {baseUrl};

