const baseUrl = "/sub/instance/distrib";
/** 获取列表-参数 */
export interface PageParam {
    subInstanceId: number;
    subUserId: number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    subInstanceId: number;
    subUserId: number;
    distribTime: string;
    removeTime: string;
    statusDesc: string;
    notes: string;
}

export  {baseUrl};