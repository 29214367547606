
const baseUrl = "/res/host";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    hostCode:string,
    serverCode:string
    statusCd:string
    releasedStatus:number,
    idcId:number,
    beginHostIp:string,
    endHostIp:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    idcName: string;
    serverModelName: string;
    serverCode: string;
    hostCode: string;
    hostMac: string;
    hostIp: string;
    chipTypeDesc: string;
    ramSize: number;
    romSize: number;
    osTypeDesc: string;
    osVersion: string;
    faultMark: string;
    faultTypeDesc: number;
    releasedStatus: number,
    notes: string;
    createTime:string;
    modifyTime:string;
}





export  {baseUrl};

