import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {AttributeTableDataType, baseUrl, PageParam} from "../types/pdt/goodsAttribute";

const goodsAttributeApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.attributeId) {
                url += 'attributeId=' + body.attributeId;
            }
            if (body.goodsId) {
                url += '&goodsId=' + body.goodsId;
            }
        }
        return request.get<PageRes<AttributeTableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl+"/save", body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/update/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    // optinos(){
    //     let url=baseUrl;
    //     url+="/options"
    //     return   request.get<OptionRes[]>(url)
    // },

}

export default goodsAttributeApi



