const baseUrl = "/sub/group/instance";
/** 获取列表-参数 */
export interface PageParam {
    subGroupId: number;
    subInstanceId: number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    subGroupId: number;
    subInstanceId: number;
    statusDesc: string;
    notes: string;
}

export  {baseUrl};