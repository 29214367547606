import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {PageParam, baseUrl, TableDataType} from "../types/opt/msgRecipient";

const msgRecipientApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.msgId) {
                url += 'msgId=' + encodeURIComponent(body.msgId) + '&';
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl+"/save", body)
    },
    //创建新表单
    updateReadMark(id: Number) {
        return request.put(baseUrl+"/updateReadMark/"+id)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },

    //查询下拉列表数据
    // optinos() {
    //     let url = baseUrl;
    //     url += "/options"
    //     return request.get<OptionRes[]>(url)
    // }
}

export default msgRecipientApi



