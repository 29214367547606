import React, {useEffect, useState} from "react";
import {Button, ConfigProvider, Divider, Form, Input, Pagination, PaginationProps, Select, Space, Table} from 'antd';
import {ColumnsType} from "antd/es/table";
import instanceApi from "../../../../apis/sub/instance";
import {TableDataType} from "../../../../apis/types/sub/instance";
import {OptionRes} from "../../../../apis/types/common";
import propertiesApi from "../../../../apis/sys/properties";
import {filterOption} from "../../../../apis/common";
import Details from "../../public/details/details";


const InstanceContent = () => {

    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        queryParams();
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [model, setModel] = useState('')
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
        const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)

    // 下拉列表选项
    const [instanceGradeOption, setInstanceGradeOption] = useState<OptionRes[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    // 表格
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);


    // 查询参数
    function queryParams() {
        propertiesApi.optinos("DM_Instance_Grade").then((res) => {
            if (res) {

                setInstanceGradeOption(res.data)
            }
        })
    }

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = instanceApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '订阅编号',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '会员编号',
            dataIndex: 'subscriberId',
            key: 'subscriberId',
        },
        {
            title: '订阅账号',
            dataIndex: 'subUserId',
            key: 'subUserId',
        },
        {
            title: '会员编码',
            dataIndex: 'subscriberCode',
            key: 'subscriberCode',
        },
        {
            title: '电话号码',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: '订阅云手机编号',
            dataIndex: 'instanceId',
            key: 'instanceId',
        },
        {
            title: '订阅云手机等级',
            dataIndex: 'instanceGradeCdDesc',
            key: 'instanceGradeCdDesc',
        },
        {
            title: '云手机标注名称',
            dataIndex: 'tagName',
            key: 'tagName',
        },
        {
            title: '订阅时长（秒）',
            dataIndex: 'subsDuration',
            key: 'subsDuration',
        },
        {
            title: '剩余时长（秒）',
            dataIndex: 'remainDuration',
            key: 'remainDuration',
        },
        {
            title: '是否打开ADB',
            dataIndex: 'openAdbMark',
            key: 'openAdbMark',
        },
        {
            title: '订阅来源',
            dataIndex: 'subsSourceCdDesc',
            key: 'subsSourceCdDesc',
        },
        {
            title: '来源对象',
            dataIndex: 'sourceObjectId',
            key: 'sourceObjectId',
        },
        {
            title: '订阅时间',
            dataIndex: 'subsTime',
            key: 'subsTime',
        },
        {
            title: '到期时间',
            dataIndex: 'expTime',
            key: 'expTime',
        },
        {
            title: '续时时间',
            dataIndex: 'renewalTime',
            key: 'renewalTime',
        },
        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="subscriberId" label="会员编号">
                        <Input />
                    </Form.Item>
                    <Form.Item name="subscriberCode" label="会员编码">
                        <Input />
                    </Form.Item>
                    <Form.Item name="phoneNumber" label="电话号码">
                        <Input />
                    </Form.Item>
                    <Form.Item name="instanceId" label="云手机编号">
                        <Input />
                    </Form.Item>
                    <Form.Item name="instanceGradeCd" label="云手机等级">
                        <Select
                            style={{width: 150}}
                            showSearch
                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            options={instanceGradeOption}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={()=>{
                                const body={};
                                instanceApi.pageData(body,pagination).then((res)=>{
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                        setIdList([]);
                                    }
                                })
                            }}>重置</Button>                        </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                   <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default InstanceContent;