import React, {useEffect, useState} from 'react';
import {Form, Image, Input, message, Modal, Select, Upload, UploadProps} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/pub/picture";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import pictureApi from "../../../../apis/pub/picture";
import {InboxOutlined} from "@ant-design/icons";
import {UploadFile} from "antd/es/upload/interface";
import {getToken} from "../../../../utils";
import TextArea from "antd/es/input/TextArea";
import {domain, imgIp} from "../../../../components/constantConfig";

const {Dragger} = Upload;

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 16},
};


interface FormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    // option
    pictureTypeOption: OptionRes[];
    statusCdOption: OptionRes[];
}


const PictureForm: React.FC<FormProps> = ({
                                              refresh, record, open, closeOpen, model,
                                              pictureTypeOption, statusCdOption
                                          }) => {
    const [form] = Form.useForm();
    const [parentIdOption, setParentIdOption] = useState<OptionRes[]>([])
    const [urlMap, setUrlMap] = useState<Map<string, string>>(new Map());
    const [fileList, setFileList] = useState<UploadFile[]>([])

    // 编辑的时候回显数据
    useEffect(() => {
        if (open && record) {
            form.setFieldsValue({
                ...record,
            })
        }
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        let formValue = form.getFieldsValue();
        let urlList = Array.from(urlMap.values());
        console.log("create", urlList)
        let body = {...formValue, "pictureUrlList": urlList}
        pictureApi.create(body).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        setUrlMap(new Map());
        setFileList([]);
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        const valuesIterator = urlMap.values();
        const firstValue = valuesIterator.next().value;

        console.log("value", firstValue);


        if (firstValue) {
            values = {...values, "pictureUrl": firstValue}
        }
        let valueJSOn = JSON.stringify(values);
        console.log("update", valueJSOn)
        pictureApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }


    const props: UploadProps = {
        multiple: model == 'create' ? true : false,
        name: 'file',
        maxCount: model == 'create' ? 1000 : 1,
        action: domain+'/pub/picture/upload',
        headers: {
            authorization: getToken(),
            // 'Content-Type': 'multipart/form-data',
        },
        // className:"upload-list-inline",
        listType: "picture-card",

        fileList: fileList,
        onChange(info) {
            const {status} = info.file;
            let file = info.file;
            let {fileList} = info;

            console.log("status,", status)

            setFileList([...fileList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    urlMap.set(file.uid, urlRes);
                    setUrlMap(new Map(urlMap));
                    setFileList([...fileList, file])
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setFileList([...fileList])

        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            console.log("remove file", file.uid)
            urlMap.delete(file.uid);
            setUrlMap(new Map(urlMap));

        },

    };

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name="id" label="图片id" hidden={true}>
                </Form.Item>
                <Form.Item name="pictureTypeCd" label="图片类型" rules={[{required: true, message: "图片类型必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={pictureTypeOption}
                    />
                </Form.Item>
                <Form.Item name="pictureTag" label="图片标签">
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="pictureUrl" label="图片">
                    <Image src={imgIp+record.pictureUrl} width={100}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusCdOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>
                <Form.Item >
                    <Dragger {...props} style={{width:472}}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">点击或者拖拉图片上传</p>
                        <p className="ant-upload-hint">
                            支持单个，多个图片上传
                        </p>
                    </Dragger>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default PictureForm;



