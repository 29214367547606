import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/res/server";
import commonApi from "../commonApi";

const serviceApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.serverCode) {
                url += 'serverCode=' + encodeURIComponent(body.serverCode)+"&";
            }
            if (body.serverTypeCd) {
                url += 'serverTypeCd=' + body.serverTypeCd+"&";
            }
            if (body.statusCd) {
                url += 'statusCd=' + body.statusCd+"&";
            }
        }
        return  request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {

        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    batchCreate(body: any) {
        return request.post(baseUrl+"/batch/save", body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    // 上架
    onShelf(body: any) {
        return request.post(baseUrl+"/on/shelf", body)

    },
    //下架
    deList(idList: Number[]) {
        let url = baseUrl+"/deList";
        if (idList) {
            url += "?"
            const params = [...idList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    powerOff(idList: Number[]) {
        let url = baseUrl+"/powerOff";
        if (idList) {
            url += "?"
            const params = [...idList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    powerOn(idList: Number[]) {
        let url = baseUrl+"/powerOn";
        if (idList) {
            url += "?"
            const params = [...idList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    sell(idList: Number[]) {
        let url = baseUrl+"/sell";
        if (idList) {
            url += "?"
            const params = [...idList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    //故障
    breakDown(faultTypeCd:string,idList: Number[]) {
        let url = baseUrl+"/breakDown";
        if (idList) {
            url += "?"
            url += "faultTypeCd="+faultTypeCd+"&"
            url += [...idList].map((uid) => `ids=${uid}`).join('&');
        }
        return request.get(url);
    },
    //故障恢复
    recover(idList: Number[]) {
        let url = baseUrl+"/recover";
        if (idList) {
            url += "?"
            const params = [...idList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    //创建vm
    createVM(body: any) {
        let url = baseUrl + '/creatVm' ;
        return request.post(url, body)
    },
    //查询下拉列表数据
    optinos(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"服务器列表",body)
    },
}

export default serviceApi



