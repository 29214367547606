import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/opt/smsHis";

const smsHisApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.smsTypeCd) {
                url += 'smsTypeCd=' + encodeURIComponent(body.smsTypeCd) + '&';
            }
            if (body.smsSourceCd) {
                url += 'smsSourceCd=' + encodeURIComponent(body.smsSourceCd) + '&';
            }
            if (body.smsPlatformId) {
                url += 'smsPlatformId=' + encodeURIComponent(body.smsPlatformId) + '&';
            }
            if (body.batchSmsId) {
                url += 'batchSmsId=' + encodeURIComponent(body.batchSmsId) + '&';
            }
            if (body.smsTemplateId) {
                url += 'smsTemplateId=' + encodeURIComponent(body.smsTemplateId) + '&';
            }
            if (body.phoneNumber) {
                url += 'phoneNumber=' + encodeURIComponent(body.phoneNumber);
            }
            // if (ownerObjectId) {
            //     url += 'ownerObjectId=' + body.ownerObjectId;
            // }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    // //批量删除
    // batchDelete(uidList: Number[]) {
    //     let url = baseUrl;
    //     if (uidList) {
    //         url += "?"
    //         const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
    //         url += params;
    //     }
    //     return request.delete(url);
    // },
    // //创建新表单
    // create(body: any) {
    //     return request.post(baseUrl, body)
    // },
    // //更新数据
    // update(id: Number, body: any) {
    //     let url = baseUrl + '/' + id;
    //     return request.put(url, body)
    // },
    //
    // //查询下拉列表数据
    // optinos(){
    //     let url=baseUrl;
    //     url+="/options"
    //     return   request.get<OptionRes[]>(url)
    // }
}

export default smsHisApi



