import React, {useEffect, useState} from 'react';
import {Form, Input, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {OptionRes} from "../../../../apis/types/common";
import {TableDataType} from "../../../../apis/types/pdt/upgradeGoods";
import upgradeGoodsApi from "../../../../apis/pdt/upgradeGoods";
import goodsPriceApi from "../../../../apis/pdt/goodsPrice";
import productApi from "../../../../apis/pdt/product";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
// const {Option} = Select;


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    statusCds: OptionRes[];
    goodsIdOption: OptionRes[];
    upgradeModeCdOption: OptionRes[];
    open: boolean;
    type: boolean;

}


const UpgradeGoodsFrom: React.FC<StaffFromProps> = ({
                                                        refresh,
                                                        record,
                                                        open,
                                                        closeOpen,
                                                        goodsIdOption,
                                                        upgradeModeCdOption,
                                                        statusCds,
                                                        type,
                                                    }) => {

    const [sourceProductIdOption, setSourceProductIdOption] = useState<OptionRes[]>([]);
    const [sourcePriceIdOption, setSourcePriceIdOption] = useState<OptionRes[]>([]);

    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        form.setFieldsValue({
            ...record
        });
        productApi.options().then((result) => {
            setSourceProductIdOption(result.data);
            if (form.getFieldValue("sourceProductId")) {
                goodsPriceApi.options(form.getFieldValue("sourceProductId")).then((result) => {
                    if (result) {

                        setSourcePriceIdOption(result.data);
                    }
                })
                let ok = true;
                for (let i = 0; i < sourcePriceIdOption.length; i++) {
                    if (form.getFieldValue("sourcePriceId") === sourcePriceIdOption[i].value) {
                        ok = false;
                    }
                }
            }
            console.log(form.getFieldsValue())
        })


    }, [open]);

    function pOnChange() {
        goodsPriceApi.options(form.getFieldValue("sourceProductId")).then((result) => {
            if (result) {

                setSourcePriceIdOption(result.data);
                form.setFieldsValue({
                    ...form.getFieldsValue,
                    sourcePriceId: null
                });
            }
        })
    }

    //处理新增方法
    const handleCreate = () => {
        upgradeGoodsApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        refresh();
        // closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        upgradeGoodsApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={type ? "新增商品" : "编辑商品"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="goodsId" label="商品"
                           rules={[{required: true, message: "商品不能为空"}]}>
                    <Select
                        allowClear={true}
                        style={{width: 200}}
                        options={goodsIdOption}
                    />
                </Form.Item>
                <Form.Item name="sourceProductId" label="升级前产品"
                           rules={[{required: true, message: "升级前产品不能为空"}]}>
                    <Select
                        allowClear={true}
                        style={{width: 200}}
                        options={sourceProductIdOption}
                        onChange={pOnChange}
                    />
                </Form.Item>
                <Form.Item name="sourcePriceId" label="升级前价格"
                           rules={[{required: true, message: "升级前价格不能为空"}]}>
                    <Select
                        allowClear={true}
                        style={{width: 200}}
                        options={sourcePriceIdOption}
                    />
                </Form.Item>
                <Form.Item name="upgradeModeCd" label="升级方式"
                           rules={[{required: true, message: "升级方式不能为空"}]}>
                    <Select
                        allowClear={true}
                        style={{width: 200}}
                        options={upgradeModeCdOption}
                    />
                </Form.Item>
                <Form.Item name="statusCd" label="状态"
                           rules={[{required: true, message: "状态不能为空"}]}>
                    <Select
                        allowClear={true}
                        style={{width: 200}}
                        options={statusCds}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注" rules={[{max: 255, message: "备注长度不能超过255位"}]}>
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>

            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default UpgradeGoodsFrom;



