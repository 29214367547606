import moment from "moment";

const baseUrl = "/app/info";

/** 获取产品申请列表-参数 */
export interface PageParam {
    appCatalogId: number
}

//定义表格的属性
export interface TableDataType {
    appId: number;
    appCatalogId: number;
    appCatalogIdDesc: string;
    appName: string;
    appCompany: string;
    appDesc: string;
    appFile: string;
    appSize: number;
    iconFile: string;
    enableStatus: number;
    enableStatusDesc: string;
    status: number;
    statusDesc: string;
    creater: string;
    createTime: string;
    modifier: string;
    modifyTime: string;
    reorder: number;
    remark: string;
    appGrayImage: string;
    appVersion: string;
    appMd5: string;
    appStatus: number;
    appStatusDesc: string;
    appSha: string;
    versionCode: number;
    versionName: string;
    disclaimer: string;
    installMode: number;
    installModeDesc: string;
    processorArchitecture: number;
    processorArchitectureDesc: string;
    packageName: string;
    installCount: number;
    score: string;
    isHot: number;
    isHotDesc: string;
    downloadCount: number;
    appPatchFile: string;
    patchBaseVersionCode: number;
}

export {baseUrl};

