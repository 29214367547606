import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, Input, message, Modal, Select, Upload} from 'antd';
import type {UploadFile, UploadProps} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/pub/channel/channelParentVersion";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";
import channelVersionApi from "../../../../apis/pub/channel/channelVersion";
import dayjs from "dayjs";
import {UploadOutlined} from "@ant-design/icons";
import {getToken} from "../../../../utils";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface ChannelParentVersionFormProps {
    refresh: () => void;
    id: Number;
    subPCPackageFormOpen: boolean;
    closeOpen: () => void;
    record: TableDataType;
}

const ChannelSubPCPackageFrom: React.FC<ChannelParentVersionFormProps> = ({
                                                                              refresh,
                                                                              id,
                                                                              record,
                                                                              subPCPackageFormOpen,
                                                                              closeOpen,
                                                                           }) => {
    const [form] = Form.useForm();




    //处理新增方法
    const handleCreate = () => {

        // 检查 filePath 字段是否为空
        if (!filePath) {
            // 如果为空，在页面上提醒用户
            message.error('保存失败，渠道包必须上传');
            return false;
        }

        let valueJSOn = {
            channelCode: form.getFieldValue('channelCode'),
            parentVersionId: id,
            filePath: filePath
        }

        channelVersionApi.subPCPackageByParentVersionId(valueJSOn).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        // 设置初始化的 fileList
        setFileList([]);
        setFilePath("")
        form.resetFields()
        closeOpen()
    };


    //处理ok按钮，
    function handleOK() {
        handleCreate();
    }

    // 定义文件列表参数
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [filePath, setFilePath] = useState()


    const props: UploadProps = {
        action: channelVersionApi.getUploadUrl(),
        headers: {
            authorization: getToken(),
        },
        multiple: false,
        maxCount: 1,
        fileList: fileList,
        // data: { // 添加 data 参数
        //     versionCode: form.getFieldValue('versionCode') // 获取表单中的 versionCode
        // },
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            console.log("status,", status)

            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    // let downloadUrl = file.response.data.downloadUrl;
                    // console.log("done,downloadUrl:", downloadUrl)
                    // setDownloadUrl(downloadUrl)

                    let filePath = file.response.data.filePath;
                    setFilePath(filePath);
                    form.setFieldsValue({filePath})  // 设置 fileSize

                    let fileSize = file.response.data.fileSize;
                    form.setFieldsValue({fileSize})  // 设置 fileSize
                    setFileList(prevList => [...prevList, file]);
                    console.log("done,filePath:{}, fileSize:{}, ", filePath, fileSize)
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setFileList([...fileList])
        },
        beforeUpload(file) {
            const allowedExtensions = ['.zip'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传zip文件');
                return false;
            }

            const isLt500M = file.size / 1024 / 1024 < 500;
            if (!isLt500M) {
                message.error('文件大小不能超过500m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setFilePath(null)
        },

    };

    return (
        <Modal
            title={"pc渠道分包"}
            open={subPCPackageFormOpen}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 700}}
            >
                <Form.Item name="channelCode" label="渠道编码"
                           rules={[{required: true, message: "渠道编码必填"}]}>
                    <Input style={{width:250}}/>
                </Form.Item>
                <Form.Item name="uploadObj" label="渠道包上传">
                    <Upload {...props}>
                        <Button icon={<UploadOutlined/>} style={{width:250}}>Upload</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default ChannelSubPCPackageFrom;



