// 和用户相关的状态管理

import {createSlice} from '@reduxjs/toolkit'
import {getToken, removeUserInfo, request, setToken as _setToken, setUserInfo as _setUserInfo} from "../../utils";
import {removeToken} from "../../utils";
import {useNavigate} from "react-router-dom";
import {ApiResponse} from "../../apis/types/common";


const userStore = createSlice({
    name: "user",
    // 数据状态
    initialState: {
        token: getToken() || '',
        userInfo: ""
    },
    // 同步修改方法
    reducers: {
        setToken(state, action) {
            state.token = action.payload
            _setToken(action.payload)
        },
        setUserInfo(state, action) {
            state.userInfo = action.payload
            _setUserInfo(action.payload)
        },
        clearUserInfo(state) {
            state.token = ''
            state.userInfo = ""
            removeToken()
            removeUserInfo()
        }
    }
})


// 解构出actionCreater

const {setToken, setUserInfo, clearUserInfo} = userStore.actions

// 获取reducer函数

const userReducer = userStore.reducer

// 登录获取token异步方法封装
const fetchLogin = (loginForm) => {
    return async (dispatch) => {

        let res = await request.post("/user/login", loginForm);
        if (res.code === 0) {
            console.log("token ",res.data)
            dispatch(setToken(res.data.token))
          ;
        }

    }
}

// 获取个人用户信息异步方法
const fetchUserInfo = () => {
    return async (dispatch) => {
        let res = await request.get("/user/getAcctNo");
        dispatch(setUserInfo(res.data))
    }
}

export {fetchLogin, fetchUserInfo, clearUserInfo}

export default userReducer