const baseUrl = "/opt/batch";
/** 获取产品申请列表-参数 */
export interface PageParam {
    smsTitle:string,
    ownerTypeCd:string,
    // ownerObjectId:Number
}

//定义表格的属性
export interface TableDataType {
    id: number;
    smsTitle: string;
    smsContent: number;
    createTime: number;
    ownerTypeCd: number;
    ownerObjectId: number;
    statusCd: string;
    notes: string;
}



export  {baseUrl};

