import React, {useEffect, useState} from 'react';
import {
    Button, ConfigProvider, Divider,
    Form,
    Input,
    Modal, Pagination, PaginationProps,
    Select, Space, Table,
} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/res/instance";
import {TableDataType as  CusTableDataType} from "../../../../apis/types/cus/fault";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import {RcFile} from "antd/es/upload";
import {ColumnsType} from "antd/es/table";
import instanceReplaceApi from "../../../../apis/res/instanceReplace";
import idcApi from "../../../../apis/res/idc";
import {ipRegex} from "../../../common/const";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 100},
    wrapperCol: {span: 160},
};

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });


interface FormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: CusTableDataType;
    open: boolean;
    createData: CusTableDataType;
}


const ReplaceInstanceForm: React.FC<FormProps> = ({
                                            refresh, record, open, closeOpen,createData
                                        }) => {

    //获取要查询的表单
    const [form] = Form.useForm();

    //定义用到的useState，用来绑定数据
    const [openCheckIdOne, setOpenCheckIdOne] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [model, setModel] = useState('')
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [openReplaceCheck, setOpenReplaceCheck] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);


    // 下拉列表选项
    const [IdcOption, setIdcOption] = useState<OptionRes[]>([])

    // 表格
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [replacePagination, setReplacePagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setReplacePagination({
            current: page,
            pageSize: pageSize
        })
    };

    // //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [replacePagination]);


    // 编辑的时候回显数据
    useEffect(() => {
        if (open && record) {
            queryParams();
            handleQuery();
        }
    }, [open]);

    // 查询参数
    function queryParams() {
        idcApi.optinos().then((res) => {
            if (res) {
                setIdcOption(res.data)
            }
        })

    }

    //处理查询
    function handleQuery() {
        if (record.faultWorkOrderId == null) {
            return;
        }
        let body = form.getFieldsValue();
        body = {
            ...body,
            faultWorkOrderId: record.faultWorkOrderId
        }
        let res = instanceReplaceApi.pageData(body, replacePagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
            }
        })

    }

    // 处理新增方法
    const handleCreate = async () => {
        const body = {
            faultWorkOrderId: record.faultWorkOrderId,
            afterReplaceInstanceId: idList[0]
        }

        // 阻塞住，直到执行完
        await setOpenReplaceCheck(false)

        instanceReplaceApi.create(body).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });


    };

    //处理取消方法
    const handleCancel = () => {
        setList([])
        form.resetFields();
        closeOpen()
    };


    //处理ok按钮，
    function handleOK() {
        if (idList.length != 1) {
            setOpenCheckIdOne(true)
        } else {
            setOpenReplaceCheck(true)
        }
    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [

        {
            title: '云手机编码',
            dataIndex: 'instanceCode',
            key: 'instanceCode',
            ellipsis:true,
            width:200
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);
        }
    };



    return (
        <Modal
            title={"更换云手机"}
            open={open}
            okText={"确认更换"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={() => {
                handleOK()
            }}
            width={1500}
        >

            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p style={{ marginRight: '20px' }}><strong>云手机编号：</strong>{record?.instanceCode}</p>
                <p style={{ marginRight: '20px' }}><strong>故障类型：</strong>{record?.faultTypeDesc}</p>
                <p style={{ marginRight: '20px' }}><strong>创建时间：</strong>{record?.createTime}</p>
                <p style={{ marginRight: '20px' }}><strong>受理时间：</strong>{record?.beginTime}</p>
                <p><strong>在线标识：</strong>{record?.onlineMark}</p>
            </div>

            <div>
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {/* 查询的表单 */}
                        <Form
                            form={form}
                            name="validateOnly"
                            layout="inline"
                            autoComplete="off"
                            onFinish={() => {
                                replacePagination.current = 1;
                                setCurrentPage(1);
                                handleQuery();
                            }}
                        >
                            <Form.Item name="idcId" label="机房">
                                <Select
                                    style={{ width: 150 }}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={IdcOption}
                                />
                            </Form.Item>
                            <Form.Item name="beginInstanceIp" label="云手机ip起" rules={[
                                {
                                    pattern: ipRegex,
                                    message: '请输入有效的IP地址',
                                },
                            ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="endInstanceIp" label="云手机ip尾" rules={[
                                {
                                    pattern: ipRegex,
                                    message: '请输入有效的IP地址',
                                },
                            ]}>
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Space>
                                    <Button type="primary" htmlType="submit">查询</Button>
                                    <Button htmlType="reset" onClick={()=>{
                                        form.resetFields();
                                        handleQuery();
                                    }}>重置</Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </div>

                    <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                    <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                        {/*表格内容*/}
                        <Table
                            onRow={(record) => {
                                return {
                                    onDoubleClick: (event) => {
                                        console.log("details", record)
                                        setDetailsOpen(true);
                                        setRowData(record);
                                    }
                                }
                            }}
                            size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}} rowSelection={{
                            ...rowSelection, "selectedRowKeys":selectedRowKeys,
                        }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

                    </ConfigProvider>

                    {/* 表格的分页 */}
                    <div style={{ textAlign: 'right', padding: '10px' }}>
                        <Pagination
                            size="small"
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={10}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                        />
                    </div>


                </div>
            </div>


            <Modal cancelText="取消"
                   okText="确认" title="" open={openCheckIdOne} onOk={() => {
                setOpenCheckIdOne(false)
            }} onCancel={() => {
                setOpenCheckIdOne(false)
            }}>
                请选择一行数据
            </Modal>

            {/*二次确认的弹窗*/
            }
            <Modal
                cancelText="取消"
                okText="确认"
                title="确认更换操作" open={openReplaceCheck} onOk={() => {
                handleCreate()
            }} onCancel={() => {
                setOpenReplaceCheck(false)
            }}>
                您即将进行更改操作，一旦确认，将无法撤销，请确认是否继续
            </Modal>

        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default ReplaceInstanceForm;



