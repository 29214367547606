const baseUrl = "/ord/replace/order";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    orderId:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    subscriberIdDesc: string;
    subUserId: number;
    subsInstanceId: number;
    instanceIdDesc: string;
    instanceGradeCdDesc: string;
    replaceTypeCdDesc: string;
    replaceModeCdDesc: string;
    newInstanceIdDesc: string;
    replaceTime: string;
    statusCdDesc: string;
    notes: string;
}



export  {baseUrl};

