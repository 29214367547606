import React, {useEffect} from 'react';
import {Form, Input, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/pub/sensitiveWords";
import sensitiveWordsApi from "../../../../apis/pub/sensitiveWords";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 16},
};


interface FormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    // option
    sensitiveTypeCdOption: OptionRes[];
    statusCdOption: OptionRes[];
}


const SensitiveWordsFrom: React.FC<FormProps> = ({
                                                     refresh, record, open, closeOpen, model,
                                                     sensitiveTypeCdOption, statusCdOption
                                                 }) => {
    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        if (open && record) {
            form.setFieldsValue({
                ...record,
            })
        }
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        sensitiveWordsApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        sensitiveWordsApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}
                style={{maxWidth: 300}}
            >

                <Form.Item name="id" label="敏感词编号" hidden={true}>
                </Form.Item>
                <Form.Item name="sensitiveTypeCd" label="敏感词类型"
                           rules={[{required: true, message: "敏感词类型必填"}]}>
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear

                        filterOption={filterOption}
                        options={sensitiveTypeCdOption}
                    />
                </Form.Item>
                <Form.Item name="sensitiveWords" label="敏感词" rules={[{required: true, message: "敏感词必填"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear

                        filterOption={filterOption}
                        options={statusCdOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea  rows={1}/>
                </Form.Item>

            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default SensitiveWordsFrom;



