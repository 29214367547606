const baseUrl = "/mkt/advert";

export interface PageParam {
    adTitle:string,
    showPosition:string,
    enableStatus:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    adTitle: string;
    pcImgPath: string;
    appImgPath: string;
    showPosition: string;
    showPositionDesc: string;
    maxTimePerDay: number;
    interactType: string;
    interactTypeDesc: string;
    jumpLink: string;
    jumpPage: string;
    jumpPageDesc: string;
    startTime: string;
    endTime: string;
    createTime: string;
    reorder: number;
    modifyTime: string;
    notes: string;
    enableStatus:number,
    enableStatusDesc: string;
    softDel: string;
    softDelDesc: string;
    modifier: string;
    modifierDesc: string;
    creator: string;
    creatorDesc: string;

}



export  {baseUrl};