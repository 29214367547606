const baseUrl = "/sub/instance";
/** 获取列表-参数 */
export interface PageParam {
    subscriberId: string;
    subscriberCode: string;
    phoneNumber: string;
    instanceId: string;
    instanceGradeCd: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    subscriberId: string;
    subUserId: string;
    subscriberCode: string;
    phoneNumber: string;
    instanceId: string;
    instanceGradeCdDesc: string;
    tagName: string;
    subsDuration: number;
    remainDuration: number;
    openAdbMark: string;
    subsSourceCdDesc: string;
    sourceObjectId: string;
    subsTime: string;
    expTime: string;
    renewalTime: string;
    statusDesc: string;
    notes: string;
}

export  {baseUrl};