import React, {useEffect, useState} from 'react';
import {Checkbox, Col, Form, Modal, Row, Select, Divider} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import instancePhoneApi from "../../../../apis/res/instancePhone";
import instanceApi from "../../../../apis/res/instance";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import type {CheckboxValueType} from 'antd/es/checkbox/Group';
import {CheckboxChangeEvent} from "antd/es/checkbox";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


// const layout = {
//     labelCol: {span: 10},
//     wrapperCol: {span: 16},
// };


interface FromProps {
    refresh: () => void;
    closeOpen: () => void;
    open: boolean;
    initInsIdList: Number[];
}


const InsInitForm: React.FC<FromProps> = ({
                                              refresh, open, closeOpen, initInsIdList
                                          }) => {
    const [form] = Form.useForm();
    const [imei, setImei] = useState<string>('');
    const [androidId, setAndroidId] = useState<string>('');
    const [wifiMac, setWifiMac] = useState<string>('');
    const [serialNo, setSerialNo] = useState<string>('');

    const [selectBrands, setSelectBrands] = useState<Number>();

    // 下拉列表选项
    const [brands, setBrands] = useState<OptionRes[]>([]);
    const [models, setModels] = useState<OptionRes[]>([]);

    //
    const [imeiReq, setImeiReq] = useState<string>('1');
    const [androidIdReq, setAndroidIdReq] = useState<string>('1');
    const [wifiMacReq, setWifiMacReq] = useState<string>('1');
    const [serialNoReq, setSerialNoReq] = useState<string>('1');

    // 编辑的时候回显数据
    useEffect(() => {
        if (open) {
            // queryPhoneParam();
            queryBrandsModelInfo();

        }
    }, [open]);

    // useEffect(() => {
    //     if (initInsIdList.length > 0) {
    //         resetValue();
    //         queryBrandsModelInfo();
    //     }
    // }, [initInsIdList]);

    useEffect(() => {
        if (brands.length > 0 && initInsIdList[0]) {
            console.log("brands:" + brands)
            console.log("initInsIdList[0]:" + initInsIdList[0])
            queryPhoneParam();
        }
        // 品牌列表发生改变
    }, [brands]);

    useEffect(() => {
        form.setFieldsValue({model: undefined}); // 重置机型选择器的值为 undefined
    }, [selectBrands]); // 监听 selectBrands 状态的变化


    const onChangeImei = (e: CheckboxChangeEvent) => {
        console.log('checked = ', e.target.checked);
        // 根据当前的 ImeiReq 状态进行切换
        setImeiReq(prevState => (prevState === '0' ? '1' : '0'));
    };
    const onChangeAndroidId = (e: CheckboxChangeEvent) => {
        console.log('checked = ', e.target.checked);
        setAndroidIdReq(prevState => (prevState === '0' ? '1' : '0'));
    };
    const onChangeWifiMac = (e: CheckboxChangeEvent) => {
        console.log('checked = ', e.target.checked);
        setWifiMacReq(prevState => (prevState === '0' ? '1' : '0'));
    };
    const onChangeSerialNo = (e: CheckboxChangeEvent) => {
        console.log('checked = ', e.target.checked);
        setSerialNoReq(prevState => (prevState === '0' ? '1' : '0'));
    };

    const queryPhoneParam = () => {
        let res = instancePhoneApi.queryPhoneParams(initInsIdList[0]);

        res.then((result) => {
            if (result) {
                // 设置状态以渲染到文本框
                setImei(result.data.imei);
                setAndroidId(result.data.androidId);
                setWifiMac(result.data.wifiMac);
                setSerialNo(result.data.serialNo);
                console.log("result", result)
                // 设置当前的品牌和机型
                if (result.data.brandsId) {
                    form.setFieldsValue({brand: result.data.brandsId});
                    console.log("result.data.brandsId:" + result.data.brandsId)
                    console.log("queryPhoneParambrands:" + brands)
                    const selectedBrand = brands.find(brand => brand.value === result.data.brandsId);
                    if (selectedBrand && selectedBrand.phoneModelList) {
                        console.log("selectedBrand:" + selectedBrand)
                        const brandModels = selectedBrand.phoneModelList.map((model: any) => ({
                            value: model.modelId,
                            label: model.modelName
                        }));
                        setModels(brandModels);
                        if (result.data.modelId) {
                            form.setFieldsValue({model: result.data.modelId});
                        }
                    }
                }
            }
        });
    };

    const queryBrandsModelInfo = () => {
        let res = instancePhoneApi.queryAllBrandsAndModels();

        res.then((result) => {
            if (result) {
                console.log('BrandsModelInfo = ', result.data);
                // 在加载 result.data 数据之前添加一个默认选项
                const brandsWithDefault = [
                    {
                        value: '0',
                        label: '随机',
                        phoneModelList: [
                            {modelId: '0', modelName: '随机'}
                        ]
                    },
                    ...result.data.map((brand: any) => ({
                        value: brand.brandId,
                        label: brand.brandsName,
                        phoneModelList: [
                            {modelId: '0', modelName: '随机'},
                            ...brand.phoneModelList
                        ]
                    }))
                ];
                // 将品牌数据存储到状态中
                setBrands(brandsWithDefault);
            }
        });
    };

    // 处理品牌选择变化
    const handleBrandChange = (value: any) => {
        console.log("handleBrandChange", value)
        if (value === undefined || value === "") {
            console.log("handleBrandChange11S", value)
            form.setFieldValue("model", [])
            setModels([])
            setSelectBrands(undefined)
            return;
        }

        // 根据选择的品牌获取对应的机型数据
        const selectedBrand = brands.find(brand => brand.value === value);
        console.log("handleBrandChange selectedBrand:" + selectedBrand?.value)
        if (selectedBrand && selectedBrand.phoneModelList) {
            const brandModels = selectedBrand.phoneModelList.map((model: any) => ({
                value: model.modelId,
                label: model.modelName
            }));
            setModels(brandModels);
            setSelectBrands(value)
        } else {
            // 如果选择的品牌或者机型列表不存在，清空机型列表并重置机型选择器的值
            setModels([]);
            form.setFieldValue("model", [])
            setSelectBrands(undefined)
        }
    };

    // //处理取消方法
    const handleCancel = () => {
        closeOpen()
        resetValue();
    };

    const resetValue = () => {
        setImei('');
        setAndroidId('');
        setWifiMac('');
        setSerialNo('');
        setSelectBrands(undefined);
        setBrands([]);
        setModels([]);

        setImeiReq('1');
        setAndroidIdReq('1');
        setWifiMacReq('1');
        setSerialNoReq('1');

        form.resetFields();

    }


    //处理ok按钮，
    function handleOK() {
        const brand = form.getFieldValue('brand');
        const model = form.getFieldValue('model');
        const body = {
            instanceIds: initInsIdList,
            imei: imeiReq,
            androidId: androidIdReq,
            wifiMac: wifiMacReq,
            serialNo: serialNoReq,
            brandId: brand,
            modelId: model,
        }
        instanceApi.insInit(body);
        closeOpen()
        form.resetFields();
        resetValue();
    }

    return (
        <Modal
            title={"一键新机"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >

            <label>注意：一键新机功能会自动过滤掉发生故障、维护中、离线和禁用状态下的云手机，这些异常云手机不会执行一键新机指令操作。</label>
            <Divider/>
            <Form>
                <Form.Item>
                    <Row>
                        <Col span={5}>
                            <label>IMEI编码：</label>
                        </Col>
                        <Col span={12}>
                            <text>{imei}</text>
                        </Col>
                        <Col span={4}>
                            <Checkbox checked={imeiReq === '0'} onChange={onChangeImei}></Checkbox>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item>
                    <Row>
                        <Col span={5}>
                            <label>Android ID：</label>
                        </Col>
                        <Col span={12}>
                            <text>{androidId}</text>
                        </Col>
                        <Col span={4}>
                            <Checkbox checked={androidIdReq === '0'} onChange={onChangeAndroidId}></Checkbox>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item>
                    <Row>
                        <Col span={5}>
                            <label>WIFI MAC：</label>
                        </Col>
                        <Col span={12}>
                            <text>{wifiMac}</text>
                        </Col>
                        <Col span={4}>
                            <Checkbox checked={wifiMacReq === '0'} onChange={onChangeWifiMac}></Checkbox>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item>
                    <Row>
                        <Col span={5}>
                            <label>序列号：</label>
                        </Col>
                        <Col span={12}>
                            <text>{serialNo}</text>
                        </Col>
                        <Col span={4}>
                            <Checkbox checked={serialNoReq === '0'} onChange={onChangeSerialNo}></Checkbox>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>

            <Form
                form={form}
                name="control-hooks"
                labelCol={{span: 5, offset: 0}} // 将 offset 设置为 0
                wrapperCol={{span: 13}} // 设置控件所占据的列数为 18
                labelAlign="left" // 将标签对齐方式设置为左对齐
            >
                <Form.Item name="brand" label="品牌">
                    <Select
                        style={{width: '100%'}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={brands}
                        onChange={handleBrandChange}
                    />
                </Form.Item>
                <Form.Item name="model" label="机型">
                    <Select
                        style={{width: '100%'}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={models}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default InsInitForm;