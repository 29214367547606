import moment from "moment";

const baseUrl = "/pdt/goods/channel";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    goodsId:number,
}

//定义表格的属性
export interface ChannelTableDataType {
    id: number;
    channelIdDesc: string;
    effTime: string;
    expTime: string;
    statusCdDesc: string;
    notes: string;
}



export  {baseUrl};

