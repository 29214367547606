import {baseUrl, PageParam, TableDataType} from "../types/sub/subLogin";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const subLoginApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "/page?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.subscriberCode) {
                url += 'subscriberCode=' + encodeURIComponent(body.subscriberCode);
            }
            if (body.phoneNumber) {
                url += '&phoneNumber=' + encodeURIComponent(body.phoneNumber);
            }
            if (body.beginLoginTime) {
                url += '&beginLoginTime=' + body.beginLoginTime;
            }
            if (body.endLoginTime) {
                url += '&endLoginTime=' + body.endLoginTime;
            }
            if (body.beginLogoutTime) {
                url += '&beginLogoutTime=' + body.beginLogoutTime;
            }
            if (body.endLogoutTime) {
                url += '&endLogoutTime=' + body.endLogoutTime;
            }

        }
        return request.get<PageRes<TableDataType>>(url);

    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"登录日志列表",body)
    },

}

export default subLoginApi