import {baseUrl, PageParam, TableDataType} from "../types/sys/action";
import {request} from "../../utils";
import {OptionRes, PageRes, TreeOption} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const actionApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.accessMethod) {
                url += 'accessMethod=' + body.accessMethod;
            }
            if (body.accessResource) {
                url += '&accessResource=' + encodeURIComponent(body.accessResource);
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    optinos() {
        let url = baseUrl;
        url += "/options"
        return request.get<OptionRes[]>(url)
    },
    listOptinos() {
        let url = baseUrl;
        url += "/list/options"
        return request.get<TreeOption[]>(url)
    },
    // 查询方法
    listActionMethods() {
        const data = request.get(baseUrl + "/enums/ActionMethod").then((result) => {
            return result;
        })
        return data;
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"功能列表",body)
    },

}

export default actionApi




