import {request} from "../../utils";
import {baseUrl, PageParam, TableDataType} from "../types/sys/staff";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const staffApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.staffNo) {
                url += 'staffNo=' + encodeURIComponent(body.staffNo) + "&";
            }
            let staffName = body.staffName;
            if (staffName) {
                url += 'staffName=' + encodeURIComponent(staffName) + "&";
            }
            if (body.phoneNumber) {
                url += 'phoneNumber=' + encodeURIComponent(body.phoneNumber) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        console.log("batchDelete", uidList)
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl+"/save", body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/update/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    optinos() {
        let url = baseUrl;
        url += "/options"
        return request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"系统用户",body)
    },

}

export default staffApi



