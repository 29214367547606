import React, { useEffect } from 'react';
import {Form, Input, InputNumber, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { TableDataType } from "../../../../apis/types/res/iptables";
import iptablesApi from "../../../../apis/res/iptables";
import { filterOption } from "../../../../apis/common";
import { OptionRes } from "../../../../apis/types/common";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const { Option } = Select;


const layout = {
    labelCol: { span: 14 },
    wrapperCol: { span: 16 },
};


interface FromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    // option
    gatewayIdOption: OptionRes[];
    instanceOption: OptionRes[];
    statusCdOption: OptionRes[];
}


const IptablesFrom: React.FC<FromProps> = ({ refresh, record, open, closeOpen, model,instanceOption,
                                                   gatewayIdOption, statusCdOption }) => {
    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        if (open&&record) {
            form.setFieldsValue({
                ...record,
            })
        }
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        iptablesApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        iptablesApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}
                style={{ maxWidth: 300 }}
            >

                <Form.Item name="id" label="映射编号" hidden={true}>
                </Form.Item>
                <Form.Item name="gatewayId" label="网关" rules={[{ required: true , message: "网关必填"}]}>
                    <Select
                        style={{ width: 150 }}
                        showSearch
                        
                                                    optionFilterProp="children"
                             allowClear

                        filterOption={filterOption}
                        options={gatewayIdOption}
                    />
                </Form.Item>
                <Form.Item name="externalTcpPort" label="云手机TCP映射外部端口" rules={[{ required: true , message: "云手机TCP映射外部端口必填"}, {type: 'number', min: 0, max: 2000000000, message: '云手机TCP映射外部端口在0-2000000000之间'}]}>
                    <InputNumber min={0}/>
                </Form.Item>
                <Form.Item name="externalUdpPort" label="云手机UDP映射外部端口" rules={[{ required: true , message: "云手机UDP映射外部端口必填"}, {type: 'number', min: 0, max: 2000000000, message: '云手机UDP映射外部端口在0-2000000000之间'}]}>
                    <InputNumber min={0}/>
                </Form.Item>
                <Form.Item name="instanceCode" label="云手机编码" rules={[{ required: true , message: "云手机编码必填"}]}>
                    <Select
                        style={{ width: 150 }}
                        showSearch
                        
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={instanceOption}
                    />
                </Form.Item>
                <Form.Item name="hostIp" label="宿主机IP" rules={[{ required: true , message: "宿主机IP必填"}, {max: 128, message: '宿主机IP不能大于128'}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="tcpPort" label="云手机TCP端口" rules={[{ required: true , message: "云手机TCP端口必填"}, {type: 'number', min: 0, max: 2000000000, message: '云手机TCP端口在0-2000000000之间'}]}>
                    <InputNumber min={0}/>
                </Form.Item>
                <Form.Item name="udpPort" label="云手机UDP端口" rules={[{ required: true , message: "云手机UDP端口必填"}, {type: 'number', min: 0, max: 2000000000, message: '云手机UDP端口在0-2000000000之间'}]}>
                    <InputNumber min={0}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{ required: true , message: "状态必填"}]}>
                    <Select
                        style={{ width: 150 }}
                        showSearch
                        
                                                    optionFilterProp="children"
                             allowClear

                        filterOption={filterOption}
                        options={statusCdOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} />
                </Form.Item>

            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default IptablesFrom;



