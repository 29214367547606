const baseUrl = "/ord/subs/order";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    orderId:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    subscriberIdDesc: string;
    subUserId: number;
    goodsIdDesc: string;
    productIdDesc: string;
    goodsPriceIdDesc: string;
    subsTypeCdDesc: string;
    instanceIdDesc: string;
    subsDuration: number;
    subsTime: string;
    goodsPrice: string;
    autoRenewal: string;
    autoRenewalDesc: string;
    statusCdDesc: string;
    notes: string;
}



export  {baseUrl};

