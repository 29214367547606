import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {PageParam, TableDataType, baseUrl} from "../types/sub/subuser";

const subuserApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.subscriberId) {
                url += 'subscriberId=' + body.subscriberId;
            }
            if (body.acctNo) {
                url += '&acctNo=' + encodeURIComponent(body.acctNo);
            }
            if (body.phoneNumber) {
                url += '&phoneNumber=' + encodeURIComponent(body.phoneNumber);
            }
            if (body.nickName) {
                url += '&nickName=' + encodeURIComponent(body.nickName);
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    optinos(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },


}

export default subuserApi




