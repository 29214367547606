const baseUrl = "/res/supplier";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    supplierName:string,
    supplierTypeCd:string
}

//定义表格的属性
export interface TableDataType {
    id: number;
    supplierName: string;
    abbrName: string;
    supplierTypeCd: string;
    supplierTypeCdDesc: string;
    statusCd: string;
    statusCdDesc: string;
    notes: string;
    credentialsTypeCd: string;
    credentialsTypeCdDesc: string;
    credentialsGradeCd: string;
    credentialsGradeCdDesc: string;
    expDate: string;
    creStatusCd: string;
    creStatusCdDesc: string;
    creNotes: string;
}



export  {baseUrl};

