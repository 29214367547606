import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input, message,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import Details from "../../public/details/details";
import {TableDataType} from "../../../../apis/types/res/proxy";
import proxyApi from "../../../../apis/res/proxy";
import ProxyForm from "./proxyForm";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import idcApi from "../../../../apis/res/idc";
import propertiesApi from "../../../../apis/sys/properties";
import fileServiceApi from "../../../../apis/res/fileService";


const ProxyContent = () => {

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
        queryParams();
    }, [pagination]);

    //进入页面先查询数据
    useEffect(() => {
        getDictList();
    }, []);


    async function getDictList() {

    }

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [rowData1, setRowData1] = useState<TableDataType>({} as TableDataType)
    const [idcType, setManageNodeContentType] = useState<boolean>(false)
    const [idcIdOption, setIdcIdOption] = useState<OptionRes[]>([])
    const [proxyTypeCdOption, setProxyTypeCdOption] = useState<OptionRes[]>([])
    const [proxyProtocolOption, setProxyProtocolOption] = useState<OptionRes[]>([])
    const [idList, setIdList] = useState<Number[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [model, setModel] = useState('')

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = proxyApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setIdList([]);
                setSelectedRowKeys([]);
            }
        })

    }

    // 查询参数
    function queryParams() {
        idcApi.optinos().then((res) => {
            if (res) {
                setIdcIdOption(res.data)
            }
        })

        propertiesApi.optinos('DM_Res_Proxy_Type').then((res) => {
            if (res) {

                setProxyTypeCdOption(res.data)
            }
        })

        propertiesApi.optinos('DM_Res_Proxy_Protocol_Type').then((res) => {
            if (res) {

                setProxyProtocolOption(res.data)
            }
        })


    }

    //处理删除
    const handleDelete = () => {
        proxyApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    //处理启用
    const enable = () => {
        proxyApi.enable(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    //处理禁用
    const disable = () => {
        proxyApi.disable(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }


    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        proxyApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setModel('edit')
                    setRowData(record);
                    queryParams();
                }}>
                    编辑
                </Typography.Link>
            },
            ellipsis: true,
            width: 80
        },

        {
            title: 'proxy编码',
            dataIndex: 'proxyCode',
            key: 'proxyCode',
            ellipsis: true,
            width: 100
        },
        {
            title: 'proxy名称',
            dataIndex: 'proxyName',
            key: 'proxyName',
            ellipsis: true,
            width: 100
        },
        {
            title: '所在机房',
            dataIndex: 'idcName',
            key: 'idcName',
            ellipsis: true,
            width: 100
        },
        {
            title: 'proxy类型',
            dataIndex: 'proxyType',
            key: 'proxyType',
            ellipsis: true,
            width: 100
        },
        {
            title: '访问地址',
            dataIndex: 'proxyHost',
            key: 'proxyHost',
            ellipsis: true,
            width: 100
        },
        {
            title: '访问端口',
            dataIndex: 'proxyPort',
            key: 'proxyPort',
            ellipsis: true,
            width: 100
        },
        {
            title: '协议',
            dataIndex: 'protocol',
            key: 'protocol',
            ellipsis: true,
            width: 100
        },
        {
            title: '创建人',
            dataIndex: 'creatorDesc',
            key: 'creatorDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '修改人',
            dataIndex: 'modifierDesc',
            key: 'modifierDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '是否启用',
            dataIndex: 'enableStatusDesc',
            key: 'enableStatusDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
            width: 150
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            ellipsis: true,
            width: 150
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
            width: 150
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setRowData({} as TableDataType)
                    queryParams();
                    setModel('create')
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        }else {
                            handleDelete();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="是否确认启用所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('启用失败，请选择需要启用的数据');
                        }else {
                            enable();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消启用');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" style={{marginRight: '25px'}}>
                        启用
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="是否确认禁用所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('禁用失败，请选择需要禁用的数据');
                        }else {
                            disable();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消禁用');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        禁用
                    </Button>
                </Popconfirm>


                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="proxyCode" label="proxy编码">
                        <Input style={{width: 120}}/>
                    </Form.Item>
                    <Form.Item name="proxyName" label="proxy名称">
                        <Input style={{width: 120}}/>
                    </Form.Item>
                    <Form.Item name="proxyHost" label="proxy地址">
                        <Input style={{width: 120}}/>
                    </Form.Item>
                    <Form.Item name="idcId" label="所在机房">
                        <Select
                            style={{width: 120}}
                            showSearch
                            optionFilterProp="children"
                            allowClear

                            filterOption={filterOption}
                            options={idcIdOption}
                        />
                    </Form.Item>
                    <Form.Item name="enableStatus" label="是否启用">
                        <Select
                            allowClear={true}
                            style={{width: 70}}
                            options={[
                                {label: '启用', value: '1'},
                                {label: '禁用', value: '0'}
                            ]}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">查询</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={() => {
                            const body = {};
                            proxyApi.pageData(body, pagination).then((res) => {
                                if (res) {
                                    setList(res.data.records)
                                    setTotal(res.data.total)
                                    setIdList([]);
                                    setSelectedRowKeys([]);
                                }
                            })
                        }}>重置</Button>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            <ProxyForm
                refresh={() => {
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                model={model}
                open={open}
                idcIdOption={idcIdOption}
                proxyTypeCdOption={proxyTypeCdOption}
                proxyProtocolOption={proxyProtocolOption}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                type={idcType}>
            </ProxyForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default ProxyContent;