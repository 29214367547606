import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, message, Modal, Row, Select} from 'antd';
import {OptionRes} from "../../../../../apis/types/common";
import dayjs from "dayjs";
import propertiesApi from "../../../../../apis/sys/properties";
import goodsPriceApi from "../../../../../apis/pdt/goodsPrice";
import {PriceTableDataType} from "../../../../../apis/types/pdt/goodsPrice";
import TextArea from "antd/es/input/TextArea";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    updatePriceId: (id:number) => void;
    priceId: number;
    goodsId: number;
    load:number;
}


const GoodsPriceForm: React.FC<StaffFromProps> = ({
                                                      refresh,
                                                      updatePriceId,
                                                      priceId,
                                                      goodsId,
                                                      load
                                                  }) => {
    const [productTypeOption, setProductTypeOption] = useState<OptionRes[]>([])
    const [priceStatusOption, setPriceStatusOption] = useState<OptionRes[]>([])
    const [revenueItemOption, setRevenueItemOption] = useState<OptionRes[]>([])
    const [chargeTypeOption, setChargeTypeOption] = useState<OptionRes[]>([])
    const [payTypeOption, setPayTypeOption] = useState<OptionRes[]>([])
    const [ownerTypeOptino, setOwnerTypeOption] = useState<OptionRes[]>([])
    const [owerObjectIdOption, setOwerObjectIdOption] = useState<OptionRes[]>([])
    const [payRuleIdOption, setPayRuleIdOption] = useState<OptionRes[]>([])
    const [chargeUnitOption, setChargeUnitOption] = useState<OptionRes[]>([])

    useEffect(() => {
        console.log("GoodsPriceForm")
        priceHandleQuery();
        propertiesApi.optinos('DM_Product_Type').then((res) => {
            if (res) {

            setProductTypeOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Revenue_Item').then((res) => {
            if (res) {

            setRevenueItemOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Charge_Type').then((res) => {
            if (res) {

            setChargeTypeOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Pay_Type').then((res) => {
            if (res) {

            setPayTypeOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Charge_Unit').then((res) => {
            if (res) {

            setChargeUnitOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Owner_Type').then((res) => {
            if (res) {

            setOwnerTypeOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Price_Status').then((res) => {
            if (res) {

            setPriceStatusOption(res.data)
            }
        })
    }, [load])
    function priceHandleQuery() {
        if (priceId) {
            let res = goodsPriceApi.pageData(priceId);
            res.then((result) => {
                if (result) {
                    // setPriceRecord({
                    //     ...result.data.records[0]
                    // })
                    priceForm.setFieldsValue({
                        ...result.data.records[0],
                        goodsId:goodsId,
                    });
                }
            })
        }else {
            priceForm.resetFields();
            priceForm.setFieldsValue({
                goodsId:goodsId,
            });
        }
        console.log("priceForm",priceForm.getFieldsValue())

    }

    const [rowData, setRowData] = useState<PriceTableDataType>({} as PriceTableDataType)


    //处理新增方法
    const handleCreate = () => {
        let values = priceForm.getFieldsValue();
        values = {
            ...values,
        };
        goodsPriceApi.create(values).then((result) => {
            if (result) {
                refresh();
                //回填goodsForm中的定价id
                updatePriceId(result.data.id);
                message.success("新增成功");
            }
        });

    };

    //处理更新方法
    function handleUpdate() {
        let values = priceForm.getFieldsValue();
        values = {
            ...values,
        };
        let valueJSOn = JSON.stringify(values);
        goodsPriceApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                refresh();
                message.success("修改成功");
            }
        });
    }


    //处理ok按钮，
    function handleOK() {
        priceForm.validateFields().then(() => {
            const values = priceForm.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const onEffTimeOk = (date: any | null) => {
        console.log("onEffTimeOk", date)
        const currentValues = priceForm.getFieldsValue(); // 获取当前表单字段的值
        const updatedValues = {
            ...currentValues,
            expDate: date ? dayjs(date).format('YYYY-MM-DD') : null,
        };

        priceForm.setFieldsValue(updatedValues); // 更新表单字段的值
    };


    const [priceForm] = Form.useForm();

    return (
        <div>
            <Form
                {...layout}
                form={priceForm}
                name="control-hooks"
                onFinish={handleOK}
                style={{display: "block", marginTop: 20}}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="goodsId" label="商品id" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={4}>
                        <Form.Item name="priceName" label="定价名称"
                                   rules={[{required: true, message: "定价名称不能为空"}]}>
                            <Input style={{width: 180}}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="priceCode" label="定价编码"
                                   rules={[{required: true, message: "定价编码不能为空"}]}>
                            <Input style={{width: 180}}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="productTypeCd" label="产品种类"
                                   rules={[{required: true, message: "产品种类不能为空"}]}>
                            <Select
                                style={{width: 180}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={productTypeOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="revenueItemCd" label="收入科目"
                                   rules={[{required: true, message: "收入科目不能为空"}]}>
                            <Select
                                style={{width: 180}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={revenueItemOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="chargeTypeCd" label="定价类型"
                                   rules={[{required: true, message: "定价类型不能为空"}]}>
                            <Select
                                style={{width: 180}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={chargeTypeOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="payTypeCd" label="付费方式"
                                   rules={[{required: true, message: "付费方式不能为空"}]}>
                            <Select
                                style={{width: 180}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={payTypeOption}
                            />
                        </Form.Item>
                    </Col>
                    {/*<Col span={4}>*/}
                    {/*    <Form.Item name="chargeUnitCd" label="计量单位"*/}
                    {/*               rules={[{required: true, message: "计量单位不能为空"}]}>*/}
                    {/*        <Select*/}
                    {/*            style={{width: 180}}*/}
                    {/*            showSearch*/}
                    {/*            optionFilterProp="children"*/}
                    {/*            allowClear*/}
                    {/*            filterOption={filterOption}*/}
                    {/*            options={chargeUnitOption}*/}
                    {/*        />*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col span={4}>
                        <Form.Item name="chargeDuration" label="计量时长"
                                   rules={[{required: true, message: "计量时长不能为空"}]}>
                            <InputNumber style={{width: 180}} min={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="listPrice" label="目录价（分）"
                                   rules={[{required: true, message: "目录价不能为空"},{whitespace:true,validator:(rule,value,callback)=>{
                                       if (!/^\d+$/.test(value)){
                                           callback("请输入正整数");
                                       }else {
                                           callback();
                                       }
                                       }}]}>
                            <InputNumber style={{width: 180}} min={0} step={1}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="salePrice" label="销售价（分）"
                                   rules={[{required: true, message: "销售价不能为空"},{whitespace:true,validator:(rule,value,callback)=>{
                                           if (!/^\d+$/.test(value)){
                                               callback("请输入正整数");
                                           }else {
                                               callback();
                                           }
                                       }}]}>
                            <InputNumber style={{width: 180}} min={0} step={1}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="ownerTypeCd" label="定价类型"
                                   rules={[{required: true, message: "定价类型不能为空"}]}>
                            <Select
                                style={{width: 180}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={ownerTypeOptino}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="ownerObjectId" label="定价对象">
                            <Select
                                style={{width: 180}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={owerObjectIdOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="payRuleId" label="支付规则">
                            <Select
                                style={{width: 180}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={payRuleIdOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="statusCd" label="状态"
                                   rules={[{required: true, message: "状态不能为空"}]}>
                            <Select
                                style={{width: 180}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={priceStatusOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="reorder" label="显示顺序"
                                   rules={[{required: true, message: "显示顺序不能为空"}]}>
                            <InputNumber style={{width: 180}} min={0}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="notes" label="备注" labelCol={{sm: {span:1}}}>
                    <TextArea rows={1} style={{width: 1100}}/>
                </Form.Item>
                <div style={{textAlign: "center"}}>
                    <Form.Item style={{display: "inline-block"}}>
                        <Button type="primary" htmlType="submit">
                            提交定价
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default GoodsPriceForm;



