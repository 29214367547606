import React, {useEffect} from 'react';
import {Form, Input, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import {TableDataType} from "../../../../apis/types/res/instance";
import cabinetApi from "../../../../apis/res/cabinet";
import instanceApi from "../../../../apis/res/instance";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    type: boolean;
    hostOption: OptionRes[];
    gatewayOption: OptionRes[];
    virtualOption: OptionRes[];
    instancePoolOption: OptionRes[];
    faultTypeOption: OptionRes[];
    statusOption: OptionRes[];
    impartStatusOption: OptionRes[];

}


const InstanceForm: React.FC<StaffFromProps> = ({
                                                    type,
                                                    refresh,
                                                    record,
                                                    open,
                                                    closeOpen,
                                                    hostOption,
                                                    gatewayOption,
                                                    virtualOption,
                                                    instancePoolOption,
                                                    faultTypeOption,
                                                    impartStatusOption,
                                                    statusOption

                                                }) => {
    const [form] = Form.useForm();

    //处理新增方法
    const handleCreate = () => {
        instanceApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            handleCreate();

        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="instanceCode" label="云手机编码" rules={[{required: true, message: "云手机编码必填"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="instanceMac" label="MAC地址">
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="instanceIp" label="IP地址" rules={[{required: true, message: "IP地址必填"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="hostId" label="宿主机" rules={[{required: true, message: "宿主机必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={hostOption}
                    />
                </Form.Item>
                <Form.Item name="gatewayId" label="网关" rules={[{required: true, message: "网关必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={gatewayOption}
                    />
                </Form.Item>
                <Form.Item name="virtualId" label="虚拟化配置" rules={[{required: true, message: "虚拟化配置必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={virtualOption}
                    />
                </Form.Item>
                <Form.Item name="instancePoolCd" label="归属的云手机池"
                           rules={[{required: true, message: "归属的云手机池必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={instancePoolOption}
                    />
                </Form.Item>
                <Form.Item name="onlineMark" label="在线标识">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {value: '1', label: '1'},
                            {value: '0', label: '0'},
                        ]}/>
                </Form.Item>
                <Form.Item name="handleMark" label="操控标识">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {value: '1', label: '1'},
                            {value: '0', label: '0'},
                        ]}/>
                </Form.Item>
                <Form.Item name="faultMark" label="故障标识">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {value: '1', label: '1'},
                            {value: '0', label: '0'},
                        ]}/>
                </Form.Item>
                <Form.Item name="maintainMark" label="维护标识">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {value: '1', label: '1'},
                            {value: '0', label: '0'},
                        ]}/>
                </Form.Item>
                <Form.Item name="faultTypeCd" label="故障类型">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={faultTypeOption}
                    />
                </Form.Item>
                <Form.Item name="impartStatus" label="分配状态">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={impartStatusOption}
                    />
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

interface StaffUpdateFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    type: boolean;
    gatewayOption: OptionRes[];
    faultTypeOption: OptionRes[];
    statusOption: OptionRes[];
    impartStatusOption: OptionRes[];

}

const InstanceUpdateForm: React.FC<StaffUpdateFromProps> = ({
                                                                refresh,
                                                                record,
                                                                open,
                                                                closeOpen,
                                                                type,
                                                                gatewayOption,
                                                                faultTypeOption,
                                                                impartStatusOption,
                                                                statusOption
                                                            }) => {

    const [form] = Form.useForm();
    useEffect(() => {
        console.log("InstanceUpdateForm",)
        if (open && record) {
            form.setFieldsValue({
                ...record,
            })
        }
    }, [open]);
    //处理新增方法
    const handleCreate = () => {
        instanceApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        instanceApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            handleCancel();
            refresh();

        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            handleUpdate();

        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                {/*<Form.Item name="instanceCode" label="云手机编码" rules={[{required: true, message: "云手机编码必填"}]}>*/}
                {/*    <Input style={{width: 200}}/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item name="instanceMac" label="MAC地址">*/}
                {/*    <Input style={{width: 200}}/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item name="instanceIp" label="IP地址" rules={[{required: true, message: "IP地址必填"}]}>*/}
                {/*    <Input style={{width: 200}}/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item name="instancePoolCd" label="归属的云手机池"*/}
                {/*           rules={[{required: true, message: "归属的云手机池必填"}]}>*/}
                {/*    <Select*/}
                {/*        style={{width: 200}}*/}
                {/*        showSearch*/}
                {/*        optionFilterProp="children"*/}
                {/*        allowClear*/}
                {/*        filterOption={filterOption}*/}
                {/*        options={instancePoolOption}*/}
                {/*    />*/}
                {/*</Form.Item>*/}
                <Form.Item name="onlineMark" label="在线标识">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {value: '1', label: '在线'},
                            {value: '0', label: '不在线'},
                        ]}/>
                </Form.Item>
                <Form.Item name="handleMark" label="操控标识">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {value: '1', label: '是'},
                            {value: '0', label: '否'},
                        ]}/>
                </Form.Item>
                <Form.Item name="faultMark" label="故障标识">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {value: '1', label: '故障'},
                            {value: '0', label: '正常'},
                        ]}/>
                </Form.Item>
                <Form.Item name="maintainMark" label="维护标识">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {value: '1', label: '是'},
                            {value: '0', label: '否'},
                        ]}/>
                </Form.Item>
                <Form.Item name="enableMark" label="启用标识">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {value: '1', label: '是'},
                            {value: '0', label: '否'},
                        ]}/>
                </Form.Item>
                <Form.Item name="activateMark" label="激活标识">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {value: '1', label: '是'},
                            {value: '0', label: '否'},
                        ]}/>
                </Form.Item>
                <Form.Item name="faultTypeCd" label="故障类型">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={faultTypeOption}
                    />
                </Form.Item>
                <Form.Item name="impartStatus" label="分配状态">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={impartStatusOption}
                    />
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
                <Form.Item name="gatewayId" label="网关"
                           rules={[{required: true, message: "网关必填"}]}>
                    <Select
                        style={{width: 175}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={gatewayOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default InstanceForm;

export {InstanceUpdateForm}



