const baseUrl = "/pdt/product/attribute";

/** 获取列表-参数 */
export interface PageParam {
    productId: number;
    attributeId: number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    productId: number;
    productName: string;
    attributeId: number;
    attributeCode: string;
    iconUrl: string;
    attributeTypeCdDesc: string;
    attributeTypeCd: string;
    attributeName: string;
    statusDesc: string;
    notes: string;
    reorder: number;
}

export {baseUrl};