import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/opt/msg";
import commonApi from "../commonApi";

const msgApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.msgTypeCd) {
                url += 'msgTypeCd=' + encodeURIComponent(body.msgTypeCd) + '&';
            }
            if (body.msgTitle) {
                url += 'msgTitle=' + encodeURIComponent(body.msgTitle) + '&';
            }
            if (body.msgContent) {
                url += 'msgContent=' + encodeURIComponent(body.msgContent);
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"消息列表",body)
    },

    //查询下拉列表数据
    // optinos() {
    //     let url = baseUrl;
    //     url += "/options"
    //     return request.get<OptionRes[]>(url)
    // }
}

export default msgApi



