// import {baseUrl, PageParam, TableDataType} from "../types/pub/label";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {PageParam, TableDataType} from "../types/pdt/product";

const baseUrl = "/pdt/picture";

const pdtPictureApi = {

    queryData(body: any) {
        let url = baseUrl + "/all";
        return request.post(url, body)

    },

    //批量删除
    batchDelete(idList: String[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        let url = baseUrl + "/save";
        return request.post(url, body)
    },
    //更新数据
    // update(id: Number, body: any) {
    //     let url = baseUrl + '/' + id;
    //     return request.put(url, body)
    // },
    // optinos() {
    //     return  request.get<OptionRes[]>(baseUrl+"/options");
    // },

}

export default pdtPictureApi



