import React, {useEffect, useState} from 'react';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Image,
    Input,
    message,
    Modal,
    Row,
    Select,
    Upload,
    UploadProps
} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/opt/msg";
import msgApi from "../../../../apis/opt/msg";
import dayjs from "dayjs";
import propertiesApi from "../../../../apis/sys/properties";
import MsgRecipientContent from "../msgRecipient/msgRecipientContent";
import TextArea from "antd/es/input/TextArea";
import PaymentContent from "../../ord/payment/paymentContent";
import MsgCollectInstanceContent from "./msgCollectInstance/msgCollectInstanceContent";
import {domain, imgIp} from "../../../../components/constantConfig";
import {getToken} from "../../../../utils";
import {UploadFile} from "antd/es/upload/interface";
import {UploadOutlined} from "@ant-design/icons";

const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 18},
};


interface MsgFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    type: boolean;


}

const MsgForm: React.FC<MsgFormProps> = ({
                                             refresh,
                                             record,
                                             open,
                                             type,
                                             closeOpen,
                                         }) => {

    const [msgTypeOption, setMsgTypeOption] = useState<OptionRes[]>([]);
    const [statusOption, setStatusOption] = useState<OptionRes[]>([]);
    const [msgCategoryCd, setMsgCategoryCd] = useState<OptionRes[]>([]);
    const [msgType, setMsgType] = useState<string>("T01");

    useEffect(() => {
        setMsgImageSm("")
        setMsgImageLg("")
        setMsgImageSmList([]);
        setMsgImageLgList([]);
        setOldMsgImageSm("");
        setOldMsgImageLg("");
        console.log("msgImageLg",msgImageLg)
        console.log("msgImageSm",msgImageSm)
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue({
            ...record,
            createTime: dayjs(record.createTime),
            startTime: dayjs(record.startTime),
            endTime: dayjs(record.endTime),
        });
        setMsgType(record.msgTypeCd);
        propertiesApi.optinos('DM_Message_Type').then((res) => {
            if (res) {
                setMsgTypeOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Status').then((res) => {
            if (res) {
                setStatusOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Msg_Category').then((res) => {
            if (res) {
                setMsgCategoryCd(res.data)
            }
        })
    }, [open]);

    const [form] = Form.useForm();
    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        values = {
            ...values,
            createTime: values.createTime ? dayjs(values.createTime).format('YYYY-MM-DD HH:mm:ss') : null,
            startTime: values.startTime ? dayjs(values.startTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endTime: values.endTime ? dayjs(values.endTime).format('YYYY-MM-DD HH:mm:ss') : null,
        }
        values.msgImageLg = msgImageLg;
        values.msgImageSm = msgImageSm;
        msgApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                setMsgImageSm("")
                setMsgImageLg("")
                setMsgImageSmList([]);
                setMsgImageLgList([]);
                setOldMsgImageSm("");
                setOldMsgImageLg("");
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    function handleUpdateForm(recipientReqVoList) {
        form.setFieldsValue({...form, recipientReqVoList: recipientReqVoList})
    }


    const [msgImageSmList, setMsgImageSmList] = useState<UploadFile[]>([])
    const [msgImageSm, setMsgImageSm] = useState(record.msgImageSm)
    const [oldMsgImageSm, setOldMsgImageSm] = useState(record.msgImageSm);
    const [msgImageLgList, setMsgImageLgList] = useState<UploadFile[]>([])
    const [msgImageLg, setMsgImageLg] = useState(record.msgImageLg)
    const [oldMsgImageLg, setOldMsgImageLg] = useState(record.msgImageLg);

    useEffect(() => {
        setMsgImageSm("");
        setMsgImageLg("");
        setMsgImageSmList([]);
        setMsgImageLgList([]);
        setOldMsgImageSm("");
        setOldMsgImageLg("");
        // 在组件挂载时发送请求
        if (record.id) {
            // 发送请求的逻辑，使用 record.id 去获取数据
            // queryPicture();
            setMsgImageSm(record.msgImageSm);
            setOldMsgImageSm(record.msgImageSm);
            setMsgImageLg(record.msgImageLg)
            setOldMsgImageLg(record.msgImageLg)
        }
    }, [record.id]);


    const msgImageSmProps: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain + '/pub/picture/upload',

        headers: {
            authorization: getToken(),
        },
        fileList: msgImageSmList,
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            // setGoodsIconList([...goodsIconList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    setMsgImageSm(urlRes)
                    setMsgImageSmList(prevList => [...prevList, file]);
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setMsgImageSmList([...fileList])

        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setMsgImageSm(oldMsgImageSm)
        },

    };

    const msgImageLgProps: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain + '/pub/picture/upload',

        headers: {
            authorization: getToken(),
        },
        fileList: msgImageLgList,
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            // setGoodsIconList([...goodsIconList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    setMsgImageLg(urlRes)
                    setMsgImageLgList(prevList => [...prevList, file]);
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setMsgImageLgList([...fileList])

        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setMsgImageLg(oldMsgImageSm)
        },

    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        values = {
            ...values,
            createTime: values.createTime ? dayjs(values.createTime).format('YYYY-MM-DD HH:mm:ss') : null,
            startTime: values.startTime ? dayjs(values.startTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endTime: values.endTime ? dayjs(values.endTime).format('YYYY-MM-DD HH:mm:ss') : null,
        }
        values.msgImageLg = msgImageLg;
        values.msgImageSm = msgImageSm;
        let valueJSOn = JSON.stringify(values);
        msgApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }


    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            width={1200}
            onCancel={handleCancel}
            footer={true}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 1100}}
                onFinish={handleOK}
            >
                <Form.Item name="id" label="模板编号" hidden={true}>
                </Form.Item>
                <Form.Item name="recipientReqVoList" label="收取消息对象列表" hidden={true}>
                </Form.Item>
                <Form.Item name="createTime" label="截止时间" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={8}>
                        <Form.Item name="msgTitle" label="信息标题"
                                   rules={[{required: true, message: "信息标题不能为空"}]}>
                            <Input style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="msgTypeCd" label="信息类型"
                                   rules={[{required: true, message: "信息类型不能为空"}]}>
                            <Select
                                disabled={!type}
                                allowClear
                                style={{width: 250}}
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={msgTypeOption}
                                onChange={value => {
                                    setMsgType(value)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="msgCategoryCd" label="信息分类"
                                   rules={[{required: true, message: "信息分类不能为空"}]}>
                            <Select
                                allowClear
                                style={{width: 250}}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={msgCategoryCd}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                            <Select
                                allowClear
                                style={{width: 250}}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={statusOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="startTime" label="起始时间"
                                   rules={[{required: true, message: "起始时间不能为空"}]}>
                            <DatePicker showTime placeholder={''} style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="endTime" label="截止时间"
                                   rules={[{required: true, message: "截止时间不能为空"}]}>
                            <DatePicker showTime placeholder={''} style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item name="msgImageSm" label="信息图片-小">
                            <Image src={msgImageSm} width={100}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="msgImageSm" label="信息图片-小">
                            <Upload {...msgImageSmProps}>
                                <Button icon={<UploadOutlined/>}>点击上传</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="exUrl" label="活动跳转URL">
                            <Input style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item name="msgImageLg" label="信息图片-大">
                            <Image src={msgImageLg} width={100}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="msgImageSm" label="信息图片-大">
                            <Upload {...msgImageLgProps}>
                                <Button icon={<UploadOutlined/>}>点击上传</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="forcedEject" label="强制弹出">
                            <Select
                                style={{width: 250}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={[
                                    {value: 1, label: '是'},
                                    {value: 0, label: '否'},
                                ]}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="msgContent" label="信息内容" labelCol={{span: 2}} wrapperCol={{span: 24}}
                           rules={[{required: true, message: "信息内容不能为空"}]}>
                    <TextArea rows={4} style={{width: 1010}}/>
                </Form.Item>
                <Form.Item name="note" label="备注" labelCol={{span: 2}} wrapperCol={{span: 24}}>
                    <TextArea rows={4} style={{width: 1010}}/>
                </Form.Item>
                <div style={{textAlign: "center"}}>
                    <Form.Item style={{display: "inline-block"}}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </div>
            </Form>
            <div hidden={msgType != "T02"}>
                <h3>消息收取账号列表</h3>
                <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>
                <MsgRecipientContent msgId={record.id} msgTypeCd={msgType} onUpdateList={(recipientReqVoList) => {
                    handleUpdateForm(recipientReqVoList)
                }} parentOpen={open} date={new Date()}
                ></MsgRecipientContent>
            </div>
            <div hidden={msgType != "T03"}>
                <h3>消息收取云手机列表</h3>
                <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>
                <MsgCollectInstanceContent msgId={record.id} msgTypeCd={msgType} onUpdateList={(recipientReqVoList) => {
                    handleUpdateForm(recipientReqVoList)
                }} date={new Date()}></MsgCollectInstanceContent>
            </div>
        </Modal>
    );
};

export default MsgForm;