import {domain} from "../components/constantConfig";
import {getToken} from "../utils";
import {message} from "antd";

const commonApi = {
    // derive(url: string, fileName: string,body: any) {
    //     fetch(domain+url, {
    //         method: 'Post',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization':getToken(),
    //         },
    //         body: JSON.stringify(body)
    //         // 如果需要认证或者其他请求头参数，可以在这里设置
    //     })
    //         .then(response => response.blob())  // 获取二进制数据
    //         .then(blob => {
    //             // 创建一个临时的URL对象
    //             const url = window.URL.createObjectURL(new Blob([blob]));
    //             // 创建一个<a>标签，设置href为生成的URL，并模拟点击以下载文件
    //             const link = document.createElement('a');
    //             link.href = url;
    //             // 设置下载文件名
    //             link.setAttribute('download', fileName+'.xlsx');
    //             document.body.appendChild(link);
    //             link.click();
    //             link.parentNode.removeChild(link);  // 清除临时的<a>标签
    //         })
    //         .catch(error => console.error('Error exporting data:', error));
    // }

    derive(url: string, fileName: string,body: any) {
        fetch(domain+url, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':getToken(),
            },
            body: JSON.stringify(body)
            // 如果需要认证或者其他请求头参数，可以在这里设置
        })
            .then(response => {
                // 先获取 Content-Type 以决定如何处理响应体
                const contentType = response.headers.get('Content-Type');

                if (contentType && contentType.includes('application/json')) {
                    // 尝试解析 JSON 数据
                    return response.json().then(json => {
                        if (json.msg) {
                            message.error(json.msg)
                            throw new Error('Response contains warning message.');
                        }
                        // 返回一个假的 Blob 以便后续处理
                        return new Blob();
                    });
                } else {
                    // 如果响应内容是 Blob，直接读取 Blob 数据
                    return response.blob();
                }
            })
            .then(blob => {
                // 创建一个临时的 URL 对象
                const url = window.URL.createObjectURL(blob);
                // 创建一个 <a> 标签，设置 href 为生成的 URL，并模拟点击以下载文件
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);  // 清除临时的 <a> 标签
                window.URL.revokeObjectURL(url);  // 清除对象 URL
            })
            .catch(error => console.error('Error exporting data:', error));
    }

}

export default commonApi;