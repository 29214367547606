import {baseUrl, PageParam, TableDataType} from "../types/sub/instanceDistrib";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";

const instanceDistribApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.subInstanceId) {
                url += 'subInstanceId=' + encodeURIComponent(body.subInstanceId);
            }
            if (body.subUserId) {
                url += '&subUserId=' + encodeURIComponent(body.subUserId);
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    }

}

export default instanceDistribApi