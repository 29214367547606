
const baseUrl = "/res/service";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    serverCode:string,
    serverTypeCd:string
    statusCd:string
}

//定义表格的属性
export interface TableDataType {
    id: number;
    serverCode: string;
    serverTypeDesc: string;
    serverModelName: string;
    productBatch: string;
    bmcChipTypeDesc: string;
    bmcIp: string;
    bmcPort: string;
    bmcOsTypeDesc: string;
    bmcOsVersion: string;
    adapterTypeDesc: string;
    adapterQuantity: number;
    hostChipTypeDesc: string;
    hostPcbVersion: string;
    hostOsTypeDesc: string;
    hostOsVersion: string;
    hostRamSize: number;
    hostRomSize: number;
    hostQuantity: number;
    idcName: string;
    cabinetCode: string;
    supplierName: string;
    faultMark: string;
    faultMarkDesc: string;
    faultTypeDesc: string;
    statusDesc: string;
    notes: string;
    createTime:string;
    modifyTime:string;
}





export  {baseUrl};

