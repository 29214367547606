import {baseUrl, PageParam, TableDataType} from "../types/sys/property";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";

const propertiesApi = {
    //查询下拉列表数据
    optinos(paramCategory: string) {
        return request.get<OptionRes[]>("/sys/properties/listOptinos?paramCategory=" + paramCategory);
    },

    pageData(paramCategory: string, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (paramCategory) {
            url += 'paramCategory=' + paramCategory;

        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    // 查询有效的父参数类型
    listValidParentCategories() {
        const data = request.get<OptionRes[]>(baseUrl + "/parentCategory/options").then((result) => {
            return result;
        })
        return data;
    },
    getParentValues(parentCategory: string) {
        return request.get<OptionRes[]>(baseUrl + "/parentValue/" + parentCategory + "/options");
    }

}

export default propertiesApi;