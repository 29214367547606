const baseUrl = "/sys/config";
/** 获取列表-参数 */
export interface PageParam {
    configKey: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    configKey: string;
    configValue: string;
    statusCd: string;
    statusCdDesc: string;
    notes: string;
}



export  {baseUrl};
