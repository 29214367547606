const baseUrl = "/res/cabinet";

/** 获取产品申请列表-参数 */
export interface PageParam {
    cabinetCode: string,
    idcId: number
    statusCd: string
}

//定义表格的属性
export interface TableDataType {
    id: number;
    cabinetCode: string;
    idcName: string;
    statusDesc: string;
    notes: string;
    createTime:string;
    modifyTime:string;
}


export {baseUrl};

