import {baseUrl, ChangePassword, PageParam, TableDataType} from "../types/sys/user";
import {StaffDate} from "../types/sys/staff";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";

const userApi = {

    pageData(staffId: number, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (staffId) {
            url += 'staffId=' + encodeURIComponent(staffId);
        }
        return request.get<PageRes<TableDataType>>(url);
    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        console.log("batchDelete", uidList)
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    optinos() {
        let url = baseUrl;
        url += "/options"
        return request.get<OptionRes[]>(url)
    },
    getById(id:number){
        return request.get<TableDataType>(baseUrl+"/id/"+id)
    },
    getCurrentUser(){
        return request.get<StaffDate>(baseUrl+"/getCurrentUser")
    },
    changePassword(body:ChangePassword){
        return request.put(baseUrl+"/changePassword",body);
    }

}

export default userApi



