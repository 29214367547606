import React, {useEffect, useState} from 'react';
import {Cascader, DatePicker, Divider, Form, Input, InputNumber, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {filterOption} from "../../../../apis/common";
import {CascaderOptionRes, OptionRes} from "../../../../apis/types/common";
import {TableDataType} from "../../../../apis/types/mkt/couponTemplate/couponTemplate";
import couponTemplateApi from "../../../../apis/mkt/couponTemplate/couponTemplate";
import TextArea from "antd/es/input/TextArea";
import type { MultipleCascaderProps } from 'antd/es/cascader';

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface CouponTemplateFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: string;
    couponTypeOption: OptionRes[];
    productGoodsOption: CascaderOptionRes[];


}


const CouponTemplateForm: React.FC<CouponTemplateFromProps> = ({
                                             model,
                                             refresh,
                                             record,
                                             open,
                                             closeOpen,
                                                        couponTypeOption,
                                                                   productGoodsOption
                                         }) => {
    //子活动开关控制类
    const [goodsIdOption, setGoodsIdOption] = useState<Number[]>([]);

    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        if (open && Object.keys(record).length > 0) {
            form.setFieldsValue({
                ...record,
            });
        }
    }, [open]);


    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    //处理新增方法
    const handleCreate = () => {
        var body = form.getFieldsValue();
        body = {
            ...body,
            goodsIds: goodsIdOption
        }
        couponTemplateApi.create(body).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理更新的情况
    function handleUpdate() {
        var body = form.getFieldsValue();
        body = {
            ...body,
            goodsIds: goodsIdOption
        }
        couponTemplateApi.update(body).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    const onChange: MultipleCascaderProps<CascaderOptionRes>['onChange'] = (value, selectedOptions) => {
        console.log(value);
        let goodsIds = value.map(option => option[option.length -1]);
        setGoodsIdOption(goodsIds)
    };

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
            width={1400}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '25%', padding: '0 8px'}}>
                        <Form.Item name="templateName" label="模版名称" rules={[{required: true, message: "模版名称必填"}]}>
                            <Input style={{width: 200}}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '25%', padding: '0 8px'}}>
                        <Form.Item name="couponName" label="显示名称" rules={[{required: true, message: "显示名称必填"}]}>
                            <Input style={{width: 200}}/>
                        </Form.Item>
                    </div>

                    <div style={{flexBasis: '25%', padding: '0 8px'}}>

                        <Form.Item name="couponType" label="优惠券类型" rules={[{required: true, message: "优惠券类型必填"}]}>
                            <Select
                                style={{width: 200}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={couponTypeOption}
                            />
                        </Form.Item>
                    </div>

                    <div style={{flexBasis: '25', padding: '0 8px'}}>
                        <Form.Item name="discount" label="优惠" rules={[{ required: true , message: "优惠必填"}, {type: 'number', min: 0}]}>
                            <InputNumber style={{width:150}}/>
                        </Form.Item>
                    </div>


                    <div style={{flexBasis: '25%', padding: '0 8px'}}>
                        <Form.Item name="usageDesc" label="使用说明">
                            <Input style={{width: 200}}/>
                        </Form.Item>
                    </div>

                    <div style={{flexBasis: '25%', padding: '0 8px'}}>
                        <Form.Item name="goodsIds" label="商品">
                            <Cascader
                                style={{ width: '100%' }}
                                options={productGoodsOption}
                                onChange={onChange}
                                multiple
                                maxTagCount="responsive"
                                showCheckedStrategy={Cascader.SHOW_CHILD}
                                value={record.goodsIds}
                            />
                        </Form.Item>

                    </div>



                </div>
            </Form>

        </Modal>
    );
};

export default CouponTemplateForm;




