import React, {useEffect, useState} from "react";
import {
    Button,
    ConfigProvider, DatePicker,
    Divider,
    Form, Input, InputNumber, message, Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Image,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {CascaderOptionRes, OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import AdvertApi from "../../../../apis/mkt/advert/advert";
import {TableDataType} from "../../../../apis/types/mkt/advert/advert";
import Details from "../redeemCode/details";
import AdvertForm from "./advertForm";
import dayjs from "dayjs";
import {imgIp} from "../../../../components/constantConfig";
import {type} from "@testing-library/user-event/dist/type";


const AdvertContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos('DM_Ad_Show_Position').then((res) => {
            if (res) {
                setAdShowPositionOption(res.data)
            }
        })

        propertiesApi.optinos('DM_Advert_Interact_Type').then((res) => {
            if (res) {
                setAdvertInteractTypeOption(res.data)
            }
        })

        propertiesApi.optinos('DM_App_Page').then((res) => {
            if (res) {
                setAppPageOption(res.data)
            }
        })

        propertiesApi.optinos('DM_Advert_Max_Time_Per_Day').then((res) => {
            if (res) {
                setMaxTimePerDayOption(res.data)
            }
        })

    }, []);


    //定义用到的useState，用来绑定数据
    const [openCheckIdOne, setOpenCheckIdOne] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [model, setModel] = useState('')
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [adShowPositionOption, setAdShowPositionOption] = useState<OptionRes[]>([])
    const [advertInteractTypeOption, setAdvertInteractTypeOption] = useState<OptionRes[]>([])
    const [appPageOption, setAppPageOption] = useState<OptionRes[]>([])
    const [maxTimePerDayOption, setMaxTimePerDayOption] = useState<OptionRes[]>([])

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = AdvertApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }


    //处理删除
    const handleDelete = () => {
        AdvertApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    //处理启用
    const enable = () => {
        AdvertApi.enable(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    //处理禁用
    const disable = () => {
        AdvertApi.disable(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            startCreateTime: body.startCreateTime ? dayjs(body.startCreateTime).format('YYYY-MM-DD') : null,
            endCreateTime: body.endCreateTime ? dayjs(body.endCreateTime).format('YYYY-MM-DD') : null,
        }
        AdvertApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    // 添加一个样式类
    const ellipsisStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    console.log("operation", record)
                    setRowData(record);
                    setModel("edit")
                }}>
                    编辑
                </Typography.Link>
            },
            width: 50
        },
        {
            title: '广告名称',
            dataIndex: 'adTitle',
            key: 'adTitle',
            width: 100
        },
        {
            title: '启用状态',
            dataIndex: 'enableStatusDesc',
            key: 'enableStatusDesc',
            width: 100
        },
        {
            title: '展示位置',
            dataIndex: 'showPositionDesc',
            key: 'showPositionDesc',
            width: 100
        },
        {
            title: 'pc图片',
            dataIndex: 'pcImgPath',
            key: 'pcImgPath',
            render: (pcImgPath: string) => {
                if (!pcImgPath) {
                    return null; // 或者返回一个占位元素
                }
                const url = imgIp + pcImgPath;
                return <Image src={url} width={75} height={75} style={{ objectFit: 'cover' }} />; // 固定高度并保持比例
            },
            ellipsis: true,
            width: 100
        },

        {
            title: '移动端图片',
            dataIndex: 'appImgPath',
            key: 'appImgPath',
            render: (appImgPath: string) => {
                if (!appImgPath) {
                    return null; // 或者返回一个占位元素
                }
                const url = imgIp + appImgPath;
                return <Image src={url} width={75} height={75} style={{ objectFit: 'cover' }} />; // 固定高度并保持比例
            },
            ellipsis: true,
            width: 100
        },

        {
            title: '推送频率',
            dataIndex: 'maxTimePerDayDesc',
            key: 'maxTimePerDayDesc',
            width: 100
        },

        {
            title: '交互方式',
            dataIndex: 'interactTypeDesc',
            key: 'interactTypeDesc',
            width: 100
        },

        {
            title: '跳转位置',
            dataIndex: 'jumpPageDesc',
            key: 'jumpPageDesc',
            width: 100
        },

        {
            title: '跳转链接',
            dataIndex: 'jumpLink',
            key: 'jumpLink',
            width: 100,
            render: (text: string) => <div style={ellipsisStyle}>{text}</div>,
        },

        {
            title: '开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
            width: 100
        },

        {
            title: '结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
            width: 100
        },

        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 100
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            width: 100
        },
        {
            title: '操作人',
            dataIndex: 'modifierDesc',
            key: 'modifierDesc',
            width: 100
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            {/*查询的表单*/}
            <Form form={form} name="validateOnly" autoComplete="off"

                  labelAlign={"left"}
                  layout={"inline"}
                  size={"small"}
                  style={{maxWidth: 3000}}

                  onFinish={() => {
                      pagination.current = 1;
                      setCurrentPage(1);
                      handleQuery();
                  }}
            >

                <Form.Item name="adTitle" label="广告名称">
                    <Input/>
                </Form.Item>

                <Form.Item name="showPosition" label="显示位置">
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={adShowPositionOption}
                    />
                </Form.Item>


                <Form.Item name="enableStatus" label="启用状态">
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {label: '启用', value: '1'},
                            {label: '禁用', value: '0'}
                        ]}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">查询</Button>
                </Form.Item>

                <Form.Item>
                    <Button htmlType="reset" onClick={() => {
                        const body = {};
                        AdvertApi.pageData(body, pagination).then((res) => {
                            if (res) {
                                setList(res.data.records)
                                setTotal(res.data.total)
                                setSelectedRowKeys([]);
                                setIdList([]);
                            }
                        })
                    }}>重置</Button>
                </Form.Item>

                <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>


                        <Form.Item>
                            <Button type="primary" ghost onClick={() => {
                                setOpen(true)
                                setModel('create')
                            }} style={{marginRight: '10px'}}>
                                新增
                            </Button>
                        </Form.Item>


                    <Popconfirm
                        title="是否确认删除所选项？"
                        onConfirm={() => {
                            if (idList.length === 0) {
                                message.error('删除失败，请选择需要删除的数据');
                            }else {
                                handleDelete();
                            }
                        }}
                        onCancel={() => {
                            message.error('取消删除');
                        }}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="primary" danger style={{marginRight: '25px'}}>
                            删除
                        </Button>
                    </Popconfirm>

                    <Popconfirm
                        title="是否确认启用所选项？"
                        onConfirm={() => {
                            if (idList.length === 0) {
                                message.error('启用失败，请选择需要启用的数据');
                            }else {
                                enable();
                            }
                        }}
                        onCancel={() => {
                            message.error('取消启用');
                        }}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="primary" style={{marginRight: '25px'}}>
                            启用
                        </Button>
                    </Popconfirm>

                    <Popconfirm
                        title="是否确认禁用所选项？"
                        onConfirm={() => {
                            if (idList.length === 0) {
                                message.error('禁用失败，请选择需要禁用的数据');
                            }else {
                                disable();
                            }
                        }}
                        onCancel={() => {
                            message.error('取消禁用');
                        }}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="primary" danger style={{marginRight: '25px'}}>
                            禁用
                        </Button>
                    </Popconfirm>

                    <Popconfirm
                        title="是否确认导出筛选的数据？"
                        onConfirm={confirmDerive}
                        onCancel={cancelDerive}
                        okText="是"
                        cancelText="否"
                    >
                        <Button type="primary" danger style={{marginRight: '25px'}}>
                            导出
                        </Button>
                    </Popconfirm>



            </Form>

            {/*<Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>*/}

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <AdvertForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                adShowPositionOption={adShowPositionOption}
                advertInteractTypeOption={advertInteractTypeOption}
                appPageOption={appPageOption}
                maxTimePerDayOption={maxTimePerDayOption}
            ></AdvertForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)

                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>


            <Modal cancelText="取消"
                   okText="确认" title="" open={openCheckIdOne} onOk={() => {
                setOpenCheckIdOne(false)
            }} onCancel={() => {
                setOpenCheckIdOne(false)
            }}>
                请选择一行数据
            </Modal>


        </div>
    )
}


export default AdvertContent;