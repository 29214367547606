import React, {useEffect, useState} from 'react';
import {Form, Input, Modal, Select} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/opt/smsTemplate";
import smsTemplateApi from "../../../../apis/opt/smsTemplate";
import TextArea from "antd/es/input/TextArea";

const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface SmsTemplateFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    type: boolean;
    smsTypeOption: OptionRes[];
    ownerTypeOption: OptionRes[];
    statusOption: OptionRes[];


}

const SmsTemplateForm: React.FC<SmsTemplateFormProps> = ({
                                                             refresh,
                                                             record,
                                                             open,
                                                             type,
                                                             closeOpen,
                                                             smsTypeOption,
                                                             statusOption,
                                                             ownerTypeOption
                                                         }) => {

    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue({
            ...record
        });
    }, [open]);

    const [parentIdOption, setParentIdOption] = useState<OptionRes[]>([])
    const [relaObjectOption, setRelaObjectOption] = useState<OptionRes[]>([])

    function selectOnchange(event: string) {
        setRelaObjectOption([])
        setParentIdOption([])
        if (event == "O02") {
        }
        form.setFieldsValue({
            ...record,
            ownerTypeCd: event,
            ownerObjectId: '',
        });
    }

    const [form] = Form.useForm();
    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        smsTemplateApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        smsTemplateApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }


    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name="id" label="模板编号" hidden={true}>
                </Form.Item>
                <Form.Item name="templateName" label="模板名称"
                           rules={[{required: true, message: "模板名称不能为空"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="templateContent" label="模板内容"
                           rules={[{required: true, message: "模板内容不能为空"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="smsTypeCd" label="类型" rules={[{required: true, message: "类型不能为空"}]}>
                    <Select
                        allowClear
                        style={{width: 200}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={smsTypeOption}
                    />
                </Form.Item>
                <Form.Item name="platformTemplate" label="平台模板">
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="repeatCount" label="允许次数"
                           rules={[{required: true, message: "允许次数不能为空"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="sendInterval" label="时间间隔"
                           rules={[{required: true, message: "时间间隔不能为空"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="validDuration" label="有效时长"
                           rules={[{required: true, message: "有效时长不能为空"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="ownerTypeCd" label="模板类型"
                           rules={[{required: true, message: "模板类型不能为空"}]}>
                    <Select
                        allowClear
                        style={{width: 200}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={ownerTypeOption}
                        onChange={selectOnchange}
                    />
                </Form.Item>
                <Form.Item name="ownerObjectId" label="模板对象">
                    <Select
                        allowClear
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={parentIdOption}
                    />
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                    <Select
                        allowClear
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default SmsTemplateForm;