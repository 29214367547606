import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/sys/dictCategory";
import dictCategoryApi from "../../../../apis/sys/dictCategory";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import IdcConfigFrom from "../../res/idcConfig/idcConfigForm";
import PropertyContent from "../property/propertyContent";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 16},
};


interface FormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    type: boolean;
    // option
    statusCdOption: OptionRes[];
}


const DictCategoryFrom: React.FC<FormProps> = ({
                                                   refresh,
                                                   record,
                                                   open,
                                                   model,
                                                   type,
                                                   closeOpen,
                                                   statusCdOption
                                               }) => {
    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        if (open && record) {
            form.setFieldsValue({
                ...record,
            })
        }
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        dictCategoryApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        dictCategoryApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            if (model == 'create') {
                //做新增的逻辑
                handleCreate();
            } else {
                //做修改的逻辑
                handleUpdate()
            }
        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            width={1150}
            // okText={"保存"}
            // cancelText={"取消"}
            onCancel={handleCancel}
            // onOk={handleOK}
            footer={null}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={handleOK}
            >
                <Row>
                    <Col span={6}>
                        <Form.Item name="id" label="参数类型编号"
                                   rules={[{required: true, message: "参数类型编号必填"}]}>
                            {
                                model == 'create'
                                    ? <InputNumber min={1} style={{width: 150}}/>
                                    : <InputNumber readOnly style={{width: 150}}/>
                            }
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="categoryName" label="参数类型名称"
                                   rules={[{required: true, message: "参数类型名称必填"}]}>
                            <Input style={{width: 150}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                            <Select
                                style={{width: 150}}
                                showSearch
                                optionFilterProp="children"
                                allowClear
                                filterOption={filterOption}
                                options={statusCdOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="notes" label="备注">
                            <TextArea rows={1} style={{width: 150}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{textAlign: "center"}}>
                    <Form.Item style={{display: "inline-block"}}>
                        <Button type="primary" htmlType="submit">
                            提交字典类型信息
                        </Button>
                    </Form.Item>
                </div>
            </Form>
            <div hidden={type}>
                <div>
                                <Divider style={{height:1,marginTop:10,marginBottom:0}} />

                    <h3>字典详细信息</h3>
                    <PropertyContent paramCategory={record.categoryName}>

                    </PropertyContent>
                </div>
            </div>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default DictCategoryFrom;



