import moment from "moment";

const baseUrl = "/sys/user/role";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    userId:number,
    roleId:number,
    statusCd:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    acctNo: string;
    roleName: string;
    effTime: string;
    expTime: string;
    statusDesc: string;
    notes: string;
}
export interface UserRoleObj {
    staffId: number;
    roleIds: number[];
    effTime: string;
    expTime: string;
    statusCd: string;
    notes: string;
}



export  {baseUrl};

