// axios的封装处理
import axios from "axios"
import {getToken, removeToken} from "./token"
import {message} from "antd";
import {domain} from "../components/constantConfig";
// import router from "@/router"
// 1. 根域名配置
// 2. 超时时间
// 3. 请求拦截器 / 响应拦截器

const request = axios.create({
    baseURL: domain,
    timeout: 15000
})

// 添加请求拦截器
// 在请求发送之前 做拦截 插入一些自定义的配置 [参数的处理]
request.interceptors.request.use((config) => {
    // 操作这个config 注入token数据
    // 1. 获取到token
    // 2. 按照后端的格式要求做token拼接
    const token = getToken()
    if (token) {
        config.headers.Authorization = `${token}`
    }

    config.headers.setContentType("application/json");
    //请求参数后拼接时间戳防止浏览器或前端框架认为请求的数据相同从而返回旧的缓存数据
    if (config.url.lastIndexOf("&") === config.url.length - 1) {
        config.url = config.url + "_=" + Date.now();
    } else if (config.url.lastIndexOf("&") === -1 && config.url.lastIndexOf("?") === -1) {
        config.url = config.url + "?_=" + Date.now();
    } else {
        config.url = config.url + "&_=" + Date.now();
    }
    console.log("configUrl,",config.url)
    // 将URL转换为小写，然后检查是否包含"options"，如果不包含则显示loading
    if (!config.url.toLowerCase().includes("options")&&!config.url.toLowerCase().includes("optinos")) {
        console.log("configUrlxxx,",config.url)
       // message.loading('请求加载中...', 0);
    }
    return config
}, (error) => {
    message.destroy();
    return Promise.reject(error)
})

// 添加响应拦截器
// 在响应返回到客户端之前 做拦截 重点处理返回的数据
request.interceptors.response.use((response) => {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    console.log("response.data", response.data)
    message.destroy();
    //
    if (response.data.code === 100031) {
        removeToken()
        window.location.assign("/login?msg="+response.data.msg);
        return
    }
    if (response.data.code !== 0) {
        console.log("response.dataCode:", response.data.code)
        message.error("请求失败 " + response.data.msg)
    } else {
        const requestUrl = response.config.url;
        if (!requestUrl.toLowerCase().includes("options")&&!requestUrl.toLowerCase().includes("optinos")) {
            // message.info("请求成功 ")
        }
        return response.data
    }

}, (error) => {
    // // 超出 2xx 范围的状态码都会触发该函数。
    // // 对响应错误做点什么
    // // 监控401 token失效
    // console.dir(error)
    //
    // if (error.response.status === 500) {
    //     let msg = error.response.data.msg;
    //
    //     message.error("请求失败 " + msg)
    //     error.response.data = {open: true}; // 设置响应数据为空对象
    //     return Promise.resolve(error.response); // 返回响应对象
    // }
    // return Promise.reject(error)
})

export {request}



