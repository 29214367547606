import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, ChannelTableDataType, PageParam} from "../types/pdt/goodsChannel";

const goodsChannelApi = {

    pageData(goodsId:number,pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (goodsId) {
            if (goodsId) {
                url += 'goodsId=' + encodeURIComponent(goodsId) + "&";
            }
        }
        return request.get<PageRes<ChannelTableDataType>>(url);
    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询列表
    optinos() {
        let url = baseUrl;
        url += "/options?organType=O01"
        return request.get<OptionRes[]>(url)
    },
    allOptinos() {
        let url = baseUrl;
        url += "/all/options"
        return request.get<OptionRes[]>(url)
    }

}

export default goodsChannelApi



