const baseUrl = "/pdt/goods/price";
/** 获取产品申请列表-参数 */
export interface PricePageParam  {
    id: number;
}

//定义表格的属性
export interface PriceTableDataType {
    id: number;
    priceName: string;
    priceCode: string;
    productTypeCdDesc: string;
    revenueItemCdDesc: string;
    chargeTypeCdDesc: string;
    payTypeCdDesc: string;
    chargeUnitCdDesc: string;
    chargeDuration: number;
    listPrice: number;
    salePrice: number;
    ownerTypeCdDesc: string;
    ownerObjectIdDesc: string;
    payRuleIdDesc: string;
    statusCdDesc: string;
    reorder: number;
    notes: string;
}



export  {baseUrl};

