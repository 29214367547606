const baseUrl = "/res/phone/model";
/** 获取列表-参数 */
export interface PageParam {
    modelName: string;
    modelCode: string;
    brandsId: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    modelName: string;
    modelCode: string;
    brandsName: string;
    cpuModel: string;
    imeiPrefix: string;
    statusDesc: string;
    notes: string;
}



export  {baseUrl};
