import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form, Image,
    message,
    Pagination,
    PaginationProps,
    Popconfirm,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import Details from "../../public/details/details";
import {PageParam, TableDataType} from "../../../../apis/types/app/info";
import infoApi from "../../../../apis/app/info";
import InfoForm from "./infoForm";
import {domain, imgIp} from "../../../../components/constantConfig";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";

interface InfoFormProps {
    appCatalogId: number;
    ts: number;
}

const InfoContent: React.FC<InfoFormProps> = ({
                                                  appCatalogId,
                                                  ts
                                              }) => {

    //进入页面先查询数据
    useEffect(() => {
        console.log("infoContent页面触发useEffect执行....................")
        handleQuery();
        propertiesApi.optinos("DM_YES_OR_NO").then((res) => {
            if (res) {
                setHotOption(res.data)
            }
        })
    }, [ts]);

    const [messageApi, contextHolder] = message.useMessage();

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [type, setType] = useState<boolean>(false);
    const [infoOpen, setInfoOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [query, setQuery] = useState<PageParam>({} as PageParam)
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    // 是否热门应用选项
    const [hotOption, setHotOption] = useState<OptionRes[]>([])
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //处理查询
    function handleQuery() {
        let res = infoApi.pageData(appCatalogId, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        infoApi.batchDelete(idList).then((result) => {
            if (result) {
                messageApi.open({
                    type: 'success',
                    content: '删除成功',
                    className: 'custom-class',
                    style: {
                        margin: "auto",
                        top: "40vh",
                        position: "relative"
                    },
                });
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => (
                <div>
                    <Typography.Link onClick={() => {
                        setInfoOpen(true)
                        setRowData(record);
                        setType(false);
                    }}>
                        编辑
                    </Typography.Link>
                    {/*&nbsp;&nbsp;&nbsp;&nbsp;*/}
                    {/*<Typography.Link onClick={() => {*/}
                    {/*}}>*/}
                    {/*    {record.status==0?"禁用":"启用"}*/}
                    {/*</Typography.Link>*/}
                </div>
            ),
            width: 50
        },
        {
            title: '应用名称',
            dataIndex: 'appName',
            key: 'appName',
            ellipsis: true,
            width: 150
        },
        {
            title: '应用发行商',
            dataIndex: 'appCompany',
            key: 'appCompany',
            ellipsis: true,
            width: 200
        },
        {
            title: '应用描述',
            dataIndex: 'appDesc',
            key: 'appDesc',
            ellipsis: true,
            width: 150
        },
        {
            title: '文件地址',
            dataIndex: 'appFile',
            key: 'appFile',
            ellipsis: true,
            width: 200
        },
        {
            title: '应用大小',
            dataIndex: 'appSize',
            key: 'appSize',
            ellipsis: true,
            width: 100
        },
        {
            title: '应用图标',
            dataIndex: 'iconFile',
            key: 'iconFile',
            render: (iconFile: string) => {
                const url = imgIp + iconFile;
                return <Image src={url} width={100}/>
            },
            ellipsis: true,
            width: 100
        },
        {
            title: '启用状态',
            dataIndex: 'enableStatusDesc',
            key: 'enableStatusDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '删除状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '创建人',
            dataIndex: 'creater',
            key: 'creater',
            ellipsis: true,
            width: 100
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
            width: 150
        },
        {
            title: '修改人',
            dataIndex: 'modifier',
            key: 'modifier',
            ellipsis: true,
            width: 100
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            ellipsis: true,
            width: 150
        },
        {
            title: '排序',
            dataIndex: 'reorder',
            key: 'reorder',
            ellipsis: true,
            width: 50
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
            width: 100
        },
        {
            title: '应用灰图标',
            dataIndex: 'appGrayImage',
            key: 'appGrayImage',
            render: (appGrayImage: string) => {
                const url = imgIp + appGrayImage;
                return <Image src={url} width={100}/>
            },
            ellipsis: true,
            width: 100
        },
        {
            title: '应用版本',
            dataIndex: 'appVersion',
            key: 'appVersion',
            ellipsis: true,
            width: 150
        },
        {
            title: '应用md5',
            dataIndex: 'appMd5',
            key: 'appMd5',
            ellipsis: true,
            width: 250
        },
        {
            title: '应用状态',
            dataIndex: 'appStatusDesc',
            key: 'appStatusDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: 'sha签名',
            dataIndex: 'appSha',
            key: 'appSha',
            ellipsis: true,
            width: 100
        },
        {
            title: '版本编码',
            dataIndex: 'versionCode',
            key: 'versionCode',
            ellipsis: true,
            width: 150
        },
        {
            title: '版本名称',
            dataIndex: 'versionName',
            key: 'versionName',
            ellipsis: true,
            width: 100
        },
        {
            title: '免责声明',
            dataIndex: 'disclaimer',
            key: 'disclaimer',
            ellipsis: true,
            width: 150
        },
        {
            title: '安装方式',
            dataIndex: 'installModeDesc',
            key: 'installModeDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '处理器架构',
            dataIndex: 'processorArchitectureDesc',
            key: 'processorArchitectureDesc',
            ellipsis: true,
            width: 150
        },
        {
            title: '应用包名',
            dataIndex: 'packageName',
            key: 'packageName',
            ellipsis: true,
            width: 150
        },
        {
            title: '安装次数',
            dataIndex: 'installCount',
            key: 'installCount',
            ellipsis: true,
            width: 100
        },
        {
            title: '评分',
            dataIndex: 'score',
            key: 'score',
            ellipsis: true,
            width: 50
        },
        {
            title: '是否热门',
            dataIndex: 'isHot',
            key: 'isHot',
            render: (isHot: number) => {
                const option = hotOption.find((opt) => opt.value === isHot.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
            ellipsis: true,
            width: 80
        },
        {
            title: '下载次数',
            dataIndex: 'downloadCount',
            key: 'downloadCount',
            ellipsis: true,
            width: 100
        },
        {
            title: '补丁包地址',
            dataIndex: 'appPatchFile',
            key: 'appPatchFile',
            ellipsis: true,
            width: 100
        },
        {
            title: '基准版本号',
            dataIndex: 'patchBaseVersionCode',
            key: 'patchBaseVersionCode',
            ellipsis: true,
            width: 100
        },
    ];


    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.appId));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();


    function confirm() {
        if (idList.length === 0) {
            messageApi.open({
                type: 'error',
                content: '删除失败，请选择需要删除的数据',
                className: 'custom-class',
                style: {
                    margin: "auto",
                    top: "40vh",
                    position: "relative"
                },
            });
        } else {
            handleDelete();
        }
    }

    function cancel() {
        messageApi.open({
            type: 'error',
            content: '取消删除',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    return (

        <div>
            {contextHolder}
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setInfoOpen(true)
                    setType(true);
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '80vw', y: "70vh"}} rowSelection={{
                    ...rowSelection, "selectedRowKeys": selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.appId} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{textAlign: "right"}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            <InfoForm
                refresh={() => {
                    //设置表单不展示
                    setInfoOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                infoOpen={infoOpen}
                appCatalogId={appCatalogId}
                closeOpen={() => {
                    setInfoOpen(false)
                    setRowData({} as TableDataType)

                }}
                type={type}
            ></InfoForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>
        </div>
    )
}


export default InfoContent;