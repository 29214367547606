const baseUrl = "/res/server/model";
/** 获取列表-参数 */
export interface PageParam {
    modelName:string,
    modelCode:string,
    serverTypeCd:string
}

//定义表格的属性
export interface TableDataType {
    id: number;
    modelName: string;
    modelCode: string;
    serverTypeDesc: string;
    bmcMark: string;
    bmcMarkDesc: string;
    bmcChipTypeDesc: string;
    bmcOsTypeDesc: string;
    bmcOsVersion: string;
    adapterTypeDesc: string;
    adapterQuantity: number;
    hostChipTypeDesc: string;
    hostChipType: string;
    hostOsTypeDesc: string;
    hostOsType: string;
    hostOsVersion: string;
    hostCpuQuantity: number;
    hostRamSize: number;
    hostRomSize: number;
    hostQuantity: number;
    statusDesc: number;
    notes: string;
}



export  {baseUrl};

