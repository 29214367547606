import React, {useEffect} from 'react';
import {Form, Input, InputNumber, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/res/serverModel";
import serverModelApi from "../../../../apis/res/serverModel";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 12},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    // 参数类型选项
    serverTypeCdOption: OptionRes[];
    bmcChipTypeOption: OptionRes[];
    bmcOsTypeOption: OptionRes[];
    adapterTypeCdOption: OptionRes[];
    hostChipTypeOption: OptionRes[];
    hostOsTypeOption: OptionRes[];
    statusCdOption: OptionRes[];
}


const ServerModelFrom: React.FC<StaffFromProps> = ({
                                                       refresh,
                                                       record,
                                                       open,
                                                       closeOpen,
                                                       model,
                                                       serverTypeCdOption,
                                                       bmcChipTypeOption,
                                                       bmcOsTypeOption,
                                                       adapterTypeCdOption,
                                                       hostChipTypeOption,
                                                       hostOsTypeOption,
                                                       statusCdOption
                                                   }) => {
    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        if (open && record) {
            form.setFieldsValue({
                ...record,
            })
        }
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        serverModelApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        serverModelApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            if (model == 'create') {
                //做新增的逻辑
                handleCreate();
            } else {
                //做修改的逻辑
                handleUpdate()
            }
        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}
                style={{maxWidth: 300}}
            >

                <Form.Item name="id" label="编号" rules={[
                    {required: model === 'create', message: "编号必填"},
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (model === 'create') {
                                if (!value || (value >= 1 && value <= 30000)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('编号在1-30000'));
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}>
                    {
                        model == 'create'
                            ? <InputNumber style={{width:150}}/>
                            : <InputNumber readOnly style={{width:150}}/>
                    }
                </Form.Item>
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="modelName" label="名称" rules={[{required: true, message: "名称必填"}, {max: 128, message: '名称长度不能大于128'}]}>
                    <Input style={{width:150}}/>
                </Form.Item>
                <Form.Item name="modelCode" label="编码" rules={[{required: true, message: "编码必填"}, {max: 32, message: '编码长度不能大于32'}]}>
                    <Input style={{width:150}}/>
                </Form.Item>
                <Form.Item name="serverTypeCd" label="类型" rules={[{required: true, message: "类型必填"}]}>
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={serverTypeCdOption}
                    />
                </Form.Item>
                <Form.Item name="bmcMark" label="带BMC功能"
                           rules={[{required: true, message: "带BMC功能必填"}]}>
                    <Select
                        style={{width:150}}
                        allowClear
                    >
                        <Option value="1">是</Option>
                        <Option value="0">否</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="bmcChipType" label="BMC主控芯片">
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={bmcChipTypeOption}
                    />
                </Form.Item>
                <Form.Item name="bmcOsType" label="BMC主控OS类型">
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={bmcOsTypeOption}
                    />
                </Form.Item>
                <Form.Item name="bmcOsVersion" label="BMC主控OS版本" rules={[{ max: 128, message: '版本长度不能大于128'}]}>
                    <Input style={{width:150}}/>
                </Form.Item>
                <Form.Item name="adapterTypeCd" label="网络出口类型"
                           rules={[{required: true, message: "网络出口类型必填"}]}>
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={adapterTypeCdOption}
                    />
                </Form.Item>
                <Form.Item name="adapterQuantity" label="网络出口数量" rules={[{ required: true , message: "网络出口数量必填"}, {type: 'number', min: 0, max: 100, message: '网络出口数量在0-100之间'}]}>
                    <InputNumber style={{width:150}}/>
                </Form.Item>
                <Form.Item name="hostChipType" label="宿主机主控芯片"
                           rules={[{required: true, message: "宿主机主控芯片必填"}]}>
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={hostChipTypeOption}
                    />
                </Form.Item>
                <Form.Item name="hostOsType" label="宿主机OS类型"
                           rules={[{required: true, message: "宿主机OS类型必填"}]}>
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={hostOsTypeOption}
                    />
                </Form.Item>
                <Form.Item name="hostOsVersion" label="宿主机OS版本" rules={[{ required: true , message: "宿主机OS版本必填"}, {max: 128, message: '版本长度不能大于128'}]}>
                    <Input style={{width:150}}/>
                </Form.Item>
                <Form.Item name="hostCpuQuantity" label="宿主机CPU核数" rules={[{ required: true , message: "宿主机CPU核数必填"}, {type: 'number', min: 0, max: 100, message: '宿主机CPU核数在0-100之间'}]}>
                    <InputNumber style={{width:150}}/>
                </Form.Item>
                <Form.Item name="hostRamSize" label="宿主机运存大小（GB）" rules={[{ required: true , message: "宿主机运存大小必填"}, {type: 'number', min: 0, max: 30000, message: '宿主机运存大小在0-30000之间'}]}>
                    <InputNumber style={{width:150}}/>
                </Form.Item>
                <Form.Item name="hostRomSize" label="宿主机存储大小（GB）" rules={[{ required: true , message: "宿主机存储大小必填"}, {type: 'number', min: 0, max: 30000, message: '宿主机存储大小在0-30000之间'}]}>
                    <InputNumber style={{width:150}}/>
                </Form.Item>
                <Form.Item name="hostQuantity" label="宿主机数量" rules={[{ required: true , message: "宿主机数量必填"}, {type: 'number', min: 0, max: 1000, message: '宿主机数量在0-1000之间'}]}>
                    <InputNumber style={{width:150}}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusCdOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width:150}}/>
                </Form.Item>

            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default ServerModelFrom;



