import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider, DatePicker,
    Divider,
    Form, Input,
    message,
    Pagination,
    PaginationProps, Popconfirm,
    Space,
    Table,
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import FileUploadForm from "./fileUploadForm";
import fileUploadApi from "../../../../apis/sub/fileUpload";
import {TableDataType} from "../../../../apis/types/sub/fileUpload";
import dayjs from "dayjs";
import subscriberApi from "../../../../apis/sub/subscriberApi";
import instanceApi from "../../../../apis/res/instance";
import fileServiceApi from "../../../../apis/res/fileService";
import subuserApi from "../../../../apis/sub/subuser";


const {RangePicker} = DatePicker;
const FileUploadContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos('DM_FileUpload_Status').then((res) => {
            setStatusOption(res.data)
        })
        instanceApi.optinos().then((res) => {
            setInstanceIdOption(res.data)
        })
        subuserApi.optinos().then((res) => {
            setAcctNoOption(res.data)
        })
        subscriberApi.optinos().then((res) => {
            setSubscriberIdOption(res.data)
        })
        fileServiceApi.optinos().then((res) => {
            setFileServiceIdOption(res.data)
        })

    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [instanceIdOption, setInstanceIdOption] = useState<OptionRes[]>([])
    const [acctNoOption, setAcctNoOption] = useState<OptionRes[]>([])
    const [subscriberIdOption, setSubscriberIdOption] = useState<OptionRes[]>([])
    const [fileServiceIdOption, setFileServiceIdOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            ...body,
            startTimeBegin: body.startTimeBegin ? dayjs(body.startTimeBegin).format('YYYY-MM-DD HH:mm:ss') : null,
            startTimeEnd: body.startTimeEnd ? dayjs(body.startTimeEnd).format('YYYY-MM-DD HH:mm:ss') : null,
            endTimeBegin: body.endTimeBegin ? dayjs(body.endTimeBegin).format('YYYY-MM-DD HH:mm:ss') : null,
            endTimeEnd: body.endTimeEnd ? dayjs(body.endTimeEnd).format('YYYY-MM-DD HH:mm:ss') : null,
        }
        let res = fileUploadApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        fileUploadApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            startTimeBegin: body.startTimeBegin ? dayjs(body.startTimeBegin).format('YYYY-MM-DD HH:mm:ss') : null,
            startTimeEnd: body.startTimeEnd ? dayjs(body.startTimeEnd).format('YYYY-MM-DD HH:mm:ss') : null,
            endTimeBegin: body.endTimeBegin ? dayjs(body.endTimeBegin).format('YYYY-MM-DD HH:mm:ss') : null,
            endTimeEnd: body.endTimeEnd ? dayjs(body.endTimeEnd).format('YYYY-MM-DD HH:mm:ss') : null,
        }
        fileUploadApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     render: (_: any, record: TableDataType) => {
        //         return <Typography.Link onClick={() => {
        //             setOpen(true)
        //             console.log("operation", record)
        //             setRowData(record);
        //         }}>
        //             编辑
        //         </Typography.Link>
        //     },
        // },
        {
            title: '会员',
            dataIndex: 'subscriberIdDesc',
            key: 'subscriberIdDesc',
        },

        {
            title: '账号',
            dataIndex: 'subUserIdDesc',
            key: 'subUserIdDesc',
        },
        {
            title: '云手机',
            dataIndex: 'instanceIdDesc',
            key: 'instanceIdDesc',
        },
        {
            title: '文件服务器',
            dataIndex: 'fileServiceIdDesc',
            key: 'fileServiceIdDesc',
        },
        {
            title: '文件名',
            dataIndex: 'fileName',
            key: 'fileName',
        },
        {
            title: '文件路径',
            dataIndex: 'filePath',
            key: 'filePath',
        },
        // {
        //     title: '文件大小',
        //     dataIndex: 'fileSize',
        //     key: 'fileSize',
        // },
        {
            title: '哈希指纹',
            dataIndex: 'fingerPrint',
            key: 'fingerPrint',
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
        },
        {
            title: '状态',
            dataIndex: 'statusCdDesc',
            key: 'statusCdDesc',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*<Button type="primary" ghost onClick={() => {*/}
                {/*    setOpen(true)*/}
                {/*}} style={{marginRight: '25px'}}>*/}
                {/*    新增*/}
                {/*</Button>*/}
                {/*<Popconfirm*/}
                {/*    title="是否确认删除所选项？"*/}
                {/*    onConfirm={() => {*/}
                {/*        if (idList.length === 0) {*/}
                {/*            message.error('删除失败，请选择需要删除的数据');*/}
                {/*        } else {*/}
                {/*            message.success('确认删除');*/}
                {/*            handleDelete();*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onCancel={() => {*/}
                {/*        message.error('取消删除');*/}
                {/*    }}*/}
                {/*    okText="是"*/}
                {/*    cancelText="否"*/}
                {/*>*/}
                {/*    <Button type="primary" danger style={{marginRight: '25px'}}>*/}
                {/*        删除*/}
                {/*    </Button>*/}
                {/*</Popconfirm>*/}

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{width:1800}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="startTimeBegin" label="开始时间开始" hidden={true}></Form.Item>
                    <Form.Item name="startTimeEnd" label="开始时间结束" hidden={true}></Form.Item>
                    <Form.Item name="endTimeBegin" label="结束时间开始" hidden={true}></Form.Item>
                    <Form.Item name="endTimeEnd" label="结束时间结束" hidden={true}></Form.Item>
                            <Form.Item style={{margin: 5}} name="subscriberIdDesc" label="会员名称">
                                <Input style={{width: 150}}/>
                            </Form.Item>
                            <Form.Item style={{margin: 5}} name="subUserIdDesc" label="账号">
                                <Input style={{width: 150}}/>
                            </Form.Item>
                            <Form.Item style={{margin: 5}} name="instanceIdDesc" label="云手机编码">
                                <Input style={{width: 150}}/>
                            </Form.Item>
                            <Form.Item style={{margin: 5}} name="fileName" label="文件名">
                                <Input style={{width: 150}}/>
                            </Form.Item>
                            <Form.Item style={{margin: 5}} name="filePath" label="文件路径">
                                <Input style={{width: 150}}/>
                            </Form.Item>
                            <Form.Item style={{margin: 5}} name="startTime" label="开始时间">
                                <RangePicker
                                    style={{width: 300}}
                                    showTime={true}
                                    placeholder={['', '']}
                                    onChange={(value) => {
                                        if (value == null) {
                                            form.setFieldValue("startTimeBegin", null);
                                            form.setFieldValue("startTimeEnd", null);
                                        } else {
                                            if (Array.isArray(value)) {
                                                const valueArray = value;
                                                let date = Array.from(value);
                                                if (date.length == 2) {
                                                    form.setFieldValue("startTimeBegin", date[0]);
                                                    form.setFieldValue("startTimeEnd", date[1]);
                                                }
                                                console.log('onOk: ', date);
                                                console.log(form.getFieldsValue())
                                            }
                                        }
                                    }}>

                                </RangePicker>
                            </Form.Item>
                            <Form.Item style={{margin: 5}} name="endTime" label="结束时间">
                                <RangePicker
                                    style={{width: 300}}
                                    showTime={true}
                                    placeholder={['', '']}
                                    onChange={(value) => {
                                        if (value == null) {
                                            form.setFieldValue("endTimeBegin", null);
                                            form.setFieldValue("endTimeEnd", null);
                                        } else {
                                            if (Array.isArray(value)) {
                                                const valueArray = value;
                                                let date = Array.from(value);
                                                if (date.length == 2) {
                                                    form.setFieldValue("endTimeBegin", date[0]);
                                                    form.setFieldValue("endTimeEnd", date[1]);
                                                }
                                                console.log('onOk: ', date);
                                                console.log(form.getFieldsValue())
                                            }
                                        }
                                    }}></RangePicker>
                            </Form.Item>
                            <Form.Item style={{margin: 5}}>
                                <Space>
                                    <Button type="primary" htmlType="submit">查询</Button>
                                    <Button htmlType="reset" onClick={()=>{
                                        const body={};
                                        fileUploadApi.pageData(body,pagination).then((res)=>{
                                            if (res) {
                                                setList(res.data.records)
                                                setTotal(res.data.total)
                                                setSelectedRowKeys([]);
                                                setIdList([]);
                                            }
                                        })
                                    }}>重置</Button>
                                    <Popconfirm
                                        title="是否确认导出筛选的数据？"
                                        onConfirm={confirmDerive}
                                        onCancel={cancelDerive}
                                        okText="是"
                                        cancelText="否"
                                    >
                                        <Button type="primary" danger style={{marginRight: '25px'}}>
                                            导出
                                        </Button>
                                    </Popconfirm>
                                </Space>
                            </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "68vh"}} dataSource={list}
                    columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


            {/*新增和编辑的form表单，子组件*/}
            <FileUploadForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                statusOption={statusOption}
                instanceIdOption={instanceIdOption}
                acctNoOption={acctNoOption}
                subscriberIdOption={subscriberIdOption}
                fileServiceIdOption={fileServiceIdOption}
            ></FileUploadForm>

        </div>
    )
}


export default FileUploadContent;