import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam,TableDataType} from "../types/cus/fault";
import commonApi from "../commonApi";

const faultApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.empStaffName) {
                url += 'empStaffName=' + encodeURIComponent(body.empStaffName) + '&';
            }
            if (body.statusCd) {
                url += 'statusCd=' + encodeURIComponent(body.statusCd)+ '&';
            }
            if (body.faultType) {
                url += 'faultType=' + encodeURIComponent(body.faultType)+ '&';
            }
            if (body.origin) {
                url += 'origin=' + encodeURIComponent(body.origin)+ '&';
            }
            if (body.instanceCode) {
                url += 'instanceCode=' + encodeURIComponent(body.instanceCode)+ '&';
            }
            if (body.onlineMark) {
                url += 'onlineMark=' + encodeURIComponent(body.onlineMark)+ '&';
            }
            if (body.handleMark) {
                url += 'handleMark=' + encodeURIComponent(body.handleMark)+ '&';
            }
            if (body.instanceReplace) {
                url += 'instanceReplace=' + encodeURIComponent(body.instanceReplace)+ '&';
            }


        }
        return request.get<PageRes<TableDataType>>(url);

    },

    // 创建故障工单
    create(body: any) {
        let url = baseUrl + "/create";
        return request.post(url, body)
    },

    //批量受理
    batchAccept(body: any) {
        let url = baseUrl;
        url += "/accept"
        return request.post(url, body)
    },

    //批量完成
    batchFinish(body: any) {
        let url = baseUrl + "/finish";
        return request.post(url, body);
    },

    //批量完成
    batchCancel(body: any) {
        let url = baseUrl + "/cancel";
        return request.post(url, body);
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"故障工单列表",body)
    },
}

export default faultApi
