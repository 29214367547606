import React, {useEffect, useState} from "react";
import {
    Button,
    ConfigProvider, DatePicker,
    Divider,
    Form, Input, InputNumber, message, Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {CascaderOptionRes, OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import couponTemplateApi from "../../../../apis/mkt/couponTemplate/couponTemplate";
import {TableDataType} from "../../../../apis/types/mkt/couponTemplate/couponTemplate";
import Details from "../redeemCode/details";
import CouponTemplateForm from "./couponTemplateForm";
import productApi from "../../../../apis/pdt/product";
import dayjs from "dayjs";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";
const {RangePicker} = DatePicker;


const CouponTemplateContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();

        productApi.listProductGoodsOption().then((res) => {
            if (res) {
                setProductGoodsOption(res.data)
            }
        })


        propertiesApi.optinos('DM_Coupon_Type').then((res) => {
            if (res) {
                setCouponTypeOption(res.data)
            }
        })

    }, []);

    const [createCouponForm] = Form.useForm();

    //定义用到的useState，用来绑定数据
    const [openCheckIds, setOpenCheckIds] = useState(false);
    const [openCheckIdOne, setOpenCheckIdOne] = useState(false);
    const [openCreateCouponForm, setOpenCreateCouponForm] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [model, setModel] = useState('')
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [couponTypeOption, setCouponTypeOption] = useState<OptionRes[]>([])
    const [productGoodsOption, setProductGoodsOption] = useState<CascaderOptionRes[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            ...body,
            startCreateTime: body.startCreateTime ? dayjs(body.startCreateTime).format('YYYY-MM-DD') : null,
            endCreateTime: body.endCreateTime ? dayjs(body.endCreateTime).format('YYYY-MM-DD') : null,
        }
        let res = couponTemplateApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            startCreateTime: body.startCreateTime ? dayjs(body.startCreateTime).format('YYYY-MM-DD') : null,
            endCreateTime: body.endCreateTime ? dayjs(body.endCreateTime).format('YYYY-MM-DD') : null,
        }
        couponTemplateApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    console.log("operation", record)
                    setRowData(record);
                    setModel("edit")
                }}>
                    编辑
                </Typography.Link>
            },
        },
        {
            title: '模版名称',
            dataIndex: 'templateName',
            key: 'templateName',
        },
        {
            title: '显示名称',
            dataIndex: 'couponName',
            key: 'couponName',
        },
        {
            title: '优惠券类型',
            dataIndex: 'couponTypeDesc',
            key: 'couponTypeDesc',
        },

        {
            title: '优惠',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: '使用说明',
            dataIndex: 'usageDesc',
            key: 'usageDesc',
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '状态',
            dataIndex: 'enableStatusDesc',
            key: 'enableStatusDesc',
        },
        {
            title: '逻辑删除',
            dataIndex: 'softDelDesc',
            key: 'softDelDesc',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            {/*查询的表单*/}
            <Form form={form} name="validateOnly" autoComplete="off"

                  labelAlign={"left"}
                  layout={"inline"}
                  size={"small"}
                  style={{maxWidth: 3000}}

                  onFinish={() => {
                      pagination.current = 1;
                      setCurrentPage(1);
                      handleQuery();
                  }}
            >

                <Form.Item name="couponType" label="优惠券类型">
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={couponTypeOption}
                    />
                </Form.Item>


                <Form.Item name="enableStatus" label="启用标识">
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {label: '已启用', value: '1'},
                            {label: '未启用', value: '0'}
                        ]}
                    />
                </Form.Item>

                <Form.Item name="softDel" label="是否在回收站">
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {label: '是', value: '0'},
                            {label: '否', value: '1'}
                        ]}
                    />
                </Form.Item>

                <Form.Item name="creator" label="创建人" hidden={true}>
                    <Input/>
                </Form.Item>

                <Form.Item name="startCreateTime" label="登录时间开始" hidden={true}></Form.Item>
                <Form.Item name="endCreateTime" label="登录时间结束" hidden={true}></Form.Item>

                <Form.Item name="createTime" label="创建时间">
                    <RangePicker
                        style={{width: 300}}
                        placeholder={['', '']}
                        onChange={(value) => {
                            if (value == null) {
                                form.setFieldValue("startCreateTime", null);
                                form.setFieldValue("endCreateTime", null);
                            } else {
                                if (Array.isArray(value)) {
                                    const valueArray = value;
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("startCreateTime", date[0]);
                                        form.setFieldValue("endCreateTime", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }
                        }}></RangePicker>
                </Form.Item>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <div style={{display: 'flex', alignItems: 'center', padding: 0}}>

                <Form.Item>
                    <Button type="primary" htmlType="submit">查询</Button>
                </Form.Item>

                <Form.Item>
                    <Button htmlType="reset" onClick={() => {
                        const body = {};
                        couponTemplateApi.pageData(body, pagination).then((res) => {
                            if (res) {
                                setList(res.data.records)
                                setTotal(res.data.total)
                                setSelectedRowKeys([]);
                                setIdList([]);
                            }
                        })
                    }}>重置</Button>
                </Form.Item>


                <Form.Item>
                    <Button type="primary" ghost onClick={() => {
                        setOpen(true)
                        setModel('create')
                    }}>
                        新增
                    </Button>
                </Form.Item>


                {/*<Form.Item>*/}
                {/*    <Popconfirm*/}
                {/*    title="是否确认删除所选项？"*/}
                {/*    onConfirm={() => {*/}
                {/*        if (idList.length === 0) {*/}
                {/*            message.error('删除失败，请选择需要删除的数据');*/}
                {/*        } else {*/}
                {/*            handleDelete();*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onCancel={() => {*/}
                {/*        message.error('取消删除');*/}
                {/*    }}*/}
                {/*    okText="是"*/}
                {/*    cancelText="否"*/}
                {/*>*/}
                {/*    <Button type="primary" danger style={{marginRight: '25px'}}>*/}
                {/*        删除*/}
                {/*    </Button>*/}
                {/*    </Popconfirm>*/}
                {/*</Form.Item>*/}

                <Form.Item>
                    <Button type="primary" onClick={() => {
                        if (idList.length != 1) {
                            setOpenCheckIdOne(true)
                        } else {
                            setOpenCreateCouponForm(true)
                        }
                    }}>
                        生成优惠券
                    </Button>
                </Form.Item>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>



            </div>
            </Form>

            {/*<Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>*/}

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <CouponTemplateForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                couponTypeOption={couponTypeOption}
                productGoodsOption={productGoodsOption}
            ></CouponTemplateForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)

                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>


            <Modal cancelText="取消"
                   okText="确认" title="" open={openCheckIdOne} onOk={() => {
                setOpenCheckIdOne(false)
            }} onCancel={() => {
                setOpenCheckIdOne(false)
            }}>
                请选择一行数据
            </Modal>

            {/*生成优惠券简单表单*/}

            <Modal
                cancelText="取消"
                okText="确认"
                title="生成优惠券" open={openCreateCouponForm} onOk={() => {
                let body = createCouponForm.getFieldsValue();
                body = {
                    couponAmount: body.couponAmount,
                    templateId: idList[0],
                    effectTime: body.effectTime ? dayjs(body.effectTime).format('YYYY-MM-DD HH:mm:ss') : null,
                    expTime: body.expTime ? dayjs(body.expTime).format('YYYY-MM-DD HH:mm:ss') : null,
                }
                couponTemplateApi.generateCoupon(body).then((res) => {
                    if (res) {
                        setOpenCreateCouponForm(false)
                        createCouponForm.resetFields();
                        handleQuery();
                    }
                })
            }} onCancel={() => {
                createCouponForm.resetFields();
                setOpenCreateCouponForm(false)
            }}>
                <Form
                    form={createCouponForm}
                    name="control-hooks"
                    style={{maxWidth: 300}}
                >
                    <Form.Item name="couponAmount" label="数量" rules={[{ required: true , message: "数量必填"}, {type: 'number', min: 0}]}>
                        <InputNumber></InputNumber>
                    </Form.Item>

                    <Form.Item name="effectTime" label="生效时间" rules={[{required: true, message: "优惠券生效时间必填"}]}>
                        <DatePicker showTime style={{width: 200}}/>
                    </Form.Item>
                    <Form.Item name="expTime" label="失效时间" rules={[{required: true, message: "优惠券失效时间必填"}]}>
                        <DatePicker showTime style={{width: 200}}/>
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    )
}


export default CouponTemplateContent;