import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form, Image,
    Input, message,
    Pagination,
    PaginationProps,
    Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {Modal} from 'antd';
import {ColumnsType} from "antd/es/table";
import faultApi from "../../../../apis/cus/fault";
import {TableDataType} from "../../../../apis/types/cus/fault";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import propertiesApi from "../../../../apis/sys/properties";
import Details from "../../public/details/details";
import FaultForm from "./faultForm";
import ReplaceInstanceForm from "./replaceInstanceForm";
import {imgIp} from "../../../../components/constantConfig";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";


const FaultContent = () => {

    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        queryParams();
    }, []);

    const [openCheckIds, setOpenCheckIds] = useState(false);
    const [openCheckIdOne, setOpenCheckIdOne] = useState(false);
    const [openCheckCancelOrder, setOpenCheckCancelOrder] = useState(false);
    const [acceptForm, setAcceptHandle] = useState(false);
    const [finishForm, setFinishHandle] = useState(false);
    const [cancelForm, setCancelHandle] = useState(false);

    const [notesForm] = Form.useForm();

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [model, setModel] = useState('')
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [replaceOpen, setReplaceOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    // 创建的工单数据
    const [createData, setCreateData] = useState<TableDataType>({} as TableDataType)

    // 下拉列表选项
    const [faultTypeOption, setFaultTypeOption] = useState<OptionRes[]>([])
    const [originOption, setOriginOption] = useState<OptionRes[]>([])
    const [statusCdOption, setStatusCdOption] = useState<OptionRes[]>([])

    // 表格
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    // 查询参数
    function queryParams() {
        propertiesApi.optinos('DM_Work_Order_Fault_Type').then((res) => {
            if (res) {

                setFaultTypeOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Work_Order_Fault_Origin').then((res) => {
            if (res) {

                setOriginOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Work_Order_Status').then((res) => {
            if (res) {

                setStatusCdOption(res.data)
            }
        })

    }

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = faultApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
            }
        })

    }


    const handleAccept = () => {
        const body = {
            workOrderIds: idList,
            appendNotes: notesForm.getFieldValue('notes')
        }
        faultApi.batchAccept(body).then((result) => {
            if (result) {
                //接口成功后重置idlist
                // setIdList([])
                setAcceptHandle(false)
                notesForm.resetFields();
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const handleFinish = () => {
        const body = {
            workOrderIds: idList,
            appendNotes: notesForm.getFieldValue('notes')
        }

        faultApi.batchFinish(body).then((result) => {
            if (result) {
                //接口成功后重置idlist
                // setIdList([])
                setFinishHandle(false)
                notesForm.resetFields();
                //重新调用一次查询接口
                handleQuery();

            }
        });

    }

    const handleOrderCancel = () => {
        const body = {
            workOrderIds: idList,
            appendNotes: notesForm.getFieldValue('notes')
        }
        faultApi.batchCancel(body).then((result) => {
            if (result) {
                setCancelHandle(false)
                notesForm.resetFields();
                handleQuery();
            }
        });

    }

    // 为新列定义渲染逻辑
    const renderImageColumn = (record: TableDataType) => {
        // 检查是否存在 pictureUrls 数组并且不为空
        if (record.pictureUrls && record.pictureUrls.length > 0) {
            return (
                <Image.PreviewGroup
                    items={record.pictureUrls.map(url => imgIp + url)}
                >
                    <div
                        style={{maxWidth: 50, maxHeight: 50, overflow: 'hidden'}}> {/* 设置最大宽度和最大高度，并隐藏溢出内容 */}
                        <Image
                            src={imgIp + record.pictureUrls[0]} // 使用 imgIp 拼接第一张图片的地址
                            style={{maxWidth: '100%', maxHeight: '100%'}} // 使用内联样式设置图片的最大宽度和最大高度
                        />
                    </div>
                </Image.PreviewGroup>
            );
        } else {
            return '没有图片'; // 如果 pictureUrls 数组为空或未定义，则显示此内容
        }
    };

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        faultApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     render: (_: any, record: TableDataType) => {
        //         return <Typography.Link onClick={() => {
        //             setOpen(true)
        //             setModel('edit')
        //             setRowData(record);
        //             queryParams()
        //         }}>
        //             编辑
        //         </Typography.Link>
        //     },
        //     ellipsis:true,
        //     width:80
        // },
        // {
        //     title: '工单id',
        //     dataIndex: 'workOrderId',
        //     key: 'workOrderId',
        //     ellipsis:true,
        //     width:150,
        // },
        {
            title: '状态',
            dataIndex: 'statusCdDesc',
            key: 'statusCdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '云手机编码',
            dataIndex: 'instanceCode',
            key: 'instanceCode',
            ellipsis: true,
            width: 150
        },
        {
            title: '更换后的云手机编码',
            dataIndex: 'afterReplaceInstanceCode',
            key: 'afterReplaceInstanceCode',
            ellipsis: true,
            width: 150
        },
        {
            title: '故障类型',
            dataIndex: 'faultTypeDesc',
            key: 'faultTypeDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '故障来源',
            dataIndex: 'originDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '在线标识',
            dataIndex: 'onlineMark',
            key: 'onlineMark',
            ellipsis: true,
            width: 100
        },
        {
            title: '受控标识',
            dataIndex: 'handleMark',
            key: 'handleMark',
            ellipsis: true,
            width: 100
        },
        {
            title: '故障描述',
            dataIndex: 'faultDesc',
            key: 'faultDesc',
            ellipsis: true,
            width: 100
        },
        // 表格增加一列，列名是“故障图片"，展示pictureUrls数组的第一张图片，点击可以查看大图，多张图片可以左右切换，例如用组件<Image.PreviewGroup
        {
            title: '故障图片',
            key: 'faultImage',
            render: (_, record: TableDataType) => renderImageColumn(record) // 渲染图片列
        },
        {
            title: '处理人',
            dataIndex: 'empStaffName',
            key: 'empStaffName',
            ellipsis: true,
            width: 100
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
            width: 100
        },
        {
            title: '受理时间',
            dataIndex: 'beginTime',
            key: 'beginTime',
            ellipsis: true,
            width: 100
        },
        {
            title: '受理完成时间',
            dataIndex: 'finishTime',
            key: 'finishTime',
            ellipsis: true,
            width: 100
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
            width: 300
        },
    ];

    const rowSelection = {
        onChange: async (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.workOrderId));
            //这里有个类型转换
            await setIdList(Array.from(newSet) as Number[])
            await setRowData(selectedRows[0])
            await setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>

            <Form form={form} name="validateOnly" autoComplete="off"

                  labelAlign={"left"}
                  layout={"inline"}
                  size={"small"}
                  style={{maxWidth: 3000}}

                  onFinish={() => {
                      pagination.current = 1;
                      setCurrentPage(1);
                      handleQuery();
                  }}
            >

                <Form.Item name="instanceCode" label="云手机编码">
                    <Input/>
                </Form.Item>

                <Form.Item name="empStaffName" label="处理人">
                    <Input/>
                </Form.Item>

                <Form.Item name="statusCd" label="工单状态">
                    <Select
                        style={{width: 150}}
                        showSearch

                        optionFilterProp="children"
                        allowClear

                        filterOption={filterOption}
                        options={statusCdOption}
                    />
                </Form.Item>

                <Form.Item name="faultType" label="故障类型">
                    <Select
                        style={{width: 150}}
                        showSearch

                        optionFilterProp="children"
                        allowClear

                        filterOption={filterOption}
                        options={faultTypeOption}
                    />
                </Form.Item>

                <Form.Item name="origin" label="故障来源">
                    <Select
                        style={{width: 150}}
                        showSearch

                        optionFilterProp="children"
                        allowClear

                        filterOption={filterOption}
                        options={originOption}
                    />
                </Form.Item>

                <Form.Item name="instanceReplace" label="已更换云手机">
                    <Select
                        style={{width: 90}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {label: '是', value: '1'},
                            {label: '否', value: '0'}
                        ]}
                    />
                </Form.Item>

                <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                <div style={{display: 'flex', alignItems: 'center', padding: 0}}>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">查询</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="reset" onClick={() => {
                            const body = {};
                            faultApi.pageData(body, pagination).then((res) => {
                                if (res) {
                                    setList(res.data.records)
                                    setTotal(res.data.total)
                                    setSelectedRowKeys([]);
                                }
                            })
                        }}>重置</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" ghost onClick={() => {
                            setOpen(true)
                            setModel('create')
                            queryParams()
                        }}>
                            创建工单
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={() => {
                            if (idList.length == 0) {
                                setOpenCheckIds(true)
                            } else {
                                setAcceptHandle(true)
                            }
                        }}>
                            受理
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={() => {
                            if (idList.length == 0) {
                                setOpenCheckIds(true)
                            } else {
                                setFinishHandle(true)
                            }
                        }}>
                            完成
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" danger onClick={() => {
                            if (idList.length == 0) {
                                setOpenCheckIds(true)
                            } else {
                                setCancelHandle(true)
                            }
                        }}>
                            作废
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={() => {
                            if (idList.length != 1) {
                                setOpenCheckIdOne(true)
                            }
                            else if (rowData.statusCd === 'S03') {
                                // 作废工单不允许更换云手机
                                // setOpenCheckCancelOrder(true)
                                message.error('作废工单不能更换云手机');
                                setSelectedRowKeys([])
                                setIdList([])
                            } else if (rowData.afterReplaceInstanceCode && rowData.afterReplaceInstanceCode !== "") {
                                message.error('已更换云手机，无法重新更换！可新建工单发起云手机更换');
                                setSelectedRowKeys([])
                                setIdList([])
                            } else {
                                setReplaceOpen(true)
                            }
                        }}>
                            更换云手机
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Popconfirm
                            title="是否确认导出筛选的数据？"
                            onConfirm={confirmDerive}
                            onCancel={cancelDerive}
                            okText="是"
                            cancelText="否"
                        >
                            <Button type="primary" danger style={{marginRight: '25px'}}>
                                导出
                            </Button>
                        </Popconfirm>
                    </Form.Item>

                </div>
            </Form>


            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}} rowSelection={{
                    ...rowSelection, "selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.workOrderId} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/
            }
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/
            }
            <FaultForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    // setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                replaceOpen={replaceOpen}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                    setSelectedRowKeys([])
                    setIdList([])
                }}
                model={model}
                faultTypeOption={faultTypeOption}
                originOption={originOption}
                statusCdOption={statusCdOption}
                setReplaceOpen={(open: boolean) => {
                    setReplaceOpen(open)
                }}
                createFinish={(createData: TableDataType) => {
                    setCreateData(createData)
                }}
            ></FaultForm>

            <ReplaceInstanceForm
                refresh={() => {
                    setReplaceOpen(false);
                    handleQuery();
                }}
                record={createData && createData.faultWorkOrderId != null ? createData : rowData}
                open={replaceOpen}
                closeOpen={() => {
                    setReplaceOpen(false)
                    setCreateData(null);
                    setSelectedRowKeys([])
                    setIdList([])
                }}
                createData={createData}
            ></ReplaceInstanceForm>


            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>


            {/*判断操作的ids存在的弹窗*/
            }
            <Modal cancelText="取消"
                   okText="确认" title="" open={openCheckIds} onOk={() => {
                setOpenCheckIds(false)
                setSelectedRowKeys([])
                setIdList([])
            }} onCancel={() => {
                setOpenCheckIds(false)
                setSelectedRowKeys([])
                setIdList([])
            }}>
                先选择要操作的数据
            </Modal>

            <Modal cancelText="取消"
                   okText="确认" title="" open={openCheckIdOne} onOk={() => {
                setOpenCheckIdOne(false)
                setSelectedRowKeys([])
                setIdList([])
            }} onCancel={() => {
                setOpenCheckIdOne(false)
                setSelectedRowKeys([])
                setIdList([])
            }}>
                请选择一行数据
            </Modal>

            <Modal cancelText="取消"
                   okText="确认" title="" open={openCheckCancelOrder} onOk={() => {
                setOpenCheckCancelOrder(false)
                setSelectedRowKeys([])
                setIdList([])
            }} onCancel={() => {
                setOpenCheckCancelOrder(false)
                setSelectedRowKeys([])
                setIdList([])
            }}>
                作废工单不能更换云手机
            </Modal>


            {/*受理的简单表单*/
            }

            <Modal
                cancelText="取消"
                okText="确认"
                title="受理工单"
                open={acceptForm}
                onOk={() => {
                    handleAccept()
                    setSelectedRowKeys([])
                    setIdList([])
                }} onCancel={() => {
                notesForm.resetFields();
                setAcceptHandle(false)
                setSelectedRowKeys([])
                setIdList([])
            }}>
                <Form
                    form={notesForm}
                    name="control-hooks"
                    style={{maxWidth: 300}}
                >
                    <Form.Item name="notes" label="备注">
                        <Input></Input>
                    </Form.Item>
                </Form>
            </Modal>


            {/*完成的简单表单*/
            }
            <Modal
                cancelText="取消"
                okText="确认"
                title="完成工单"
                open={finishForm}
                onOk={() => {
                    handleFinish()
                    setSelectedRowKeys([])
                    setIdList([])
                }} onCancel={() => {
                notesForm.resetFields();
                setFinishHandle(false)
                setSelectedRowKeys([])
                setIdList([])
            }}>
                <Form
                    form={notesForm}
                    name="control-hooks"
                    style={{maxWidth: 300}}
                >
                    <Form.Item name="notes" label="备注">
                        <Input></Input>
                    </Form.Item>
                </Form>
            </Modal>

            {/*作废的简单表单*/
            }
            <Modal
                cancelText="取消"
                okText="确认"
                title="作废工单"
                open={cancelForm}
                onOk={() => {
                    handleOrderCancel()
                    setSelectedRowKeys([])
                    setIdList([])
                }} onCancel={() => {
                notesForm.resetFields();
                setCancelHandle(false)
                setSelectedRowKeys([])
                setIdList([])
            }}>
                <Form
                    form={notesForm}
                    name="control-hooks"
                    style={{maxWidth: 300}}
                >
                    <Form.Item name="notes" label="备注">
                        <Input></Input>
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    )
}


export default FaultContent;