import {baseUrl, PageParam, TableDataType} from "../types/res/proxy";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const proxyApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.proxyCode) {
                url += 'proxyCode=' + body.proxyCode + "&";
            }
            if (body.proxyName) {
                url += 'proxyName=' + body.proxyName + "&";
            }
            if (body.proxyHost) {
                url += 'proxyHost=' + body.proxyHost + "&";
            }
            if (body.idcId) {
                url += 'idcId=' + body.idcId + "&";
            }
            if (body.enableStatus) {
                url += 'enableStatus=' + body.enableStatus + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    options(proxyTypeCd: string){
        let url=baseUrl;
        url= url + "/options" ;
        let body = {
            proxyTypeCd: proxyTypeCd
        }
        return   request.post<OptionRes[]>(url, body)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"proxy列表",body)
    },

    //启用
    enable(body: any) {
        let url = baseUrl + "/enable";
        return request.post(url, body)
    },

    //禁用
    disable(body: any) {
        let url = baseUrl + "/disable";
        return request.post(url, body)
    },

}

export default proxyApi



