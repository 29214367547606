const baseUrl = "/sub/file/upload";

/** 获取产品申请列表-参数 */
export interface PageParam {
    subscriberIdDesc: string,
    subUserIdDesc: string,
    instanceIdDesc: string,
    fileName: string,
    filePath: string,
    startTimeBegin: string,
    startTimeEnd: string,
    endTimeBegin: string,
    endTimeEnd: string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    subscriberId: number;
    subscriberIdDesc: string;
    subUserId: number;
    subUserIdDesc: string;
    instanceId: number;
    instanceIdDesc: string;
    fileServiceId: number;
    fileServiceIdDesc: string;
    fileName: string;
    filePath: string;
    fileSize: number;
    fingerPrint: string;
    startTime: string;
    endTime: string;
    statusCd: string;
    statusCdDesc: string;
}


export {baseUrl};

