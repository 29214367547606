import React, {useEffect} from 'react';
import {Form, message, Modal, Select} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface ChannelSubPackageFromProps {
    refresh: () => void;
    id: Number;
    subPackageFormOpen: boolean;
    closeOpen: () => void;
    yesOrNoOption: OptionRes[];
}

const ChannelSubPackageFrom: React.FC<ChannelSubPackageFromProps> = ({
                                                                             refresh,
                                                                             id,
                                                                             subPackageFormOpen,
                                                                             closeOpen,
                                                                             yesOrNoOption,
                                                                         }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        // 在组件挂载时，手动设置id字段的值
        form.setFieldsValue({id: id});
    }, [subPackageFormOpen]);

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            message.success('开始分包');
            handleSubPackage()
        })
        .catch((error) => {
            message.error("分包失败！")
        });
    }

    const handleSubPackage = () => {
        const values = form.getFieldsValue();
        console.log("[ChannelSubPackageFrom-handleSubPackage]values:" + JSON.stringify(values));
        channelParentVersionApi.subPackageByParentVersionId(values)
            .then((result) => {
            if (result) {
                message.info("分包请求成功！")
                handleCancel();
            } else {
                message.error("分包失败！")
            }
        });
    }

    return (
        <Modal
            title={"安卓渠道分包选项"}
            open={subPackageFormOpen}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <p>
                分包说明：<br/>
                1、此按钮仅针对当前有效的"安卓客户端分发渠道"进行分包<br/>
                2、覆盖分包选项是指，当前选择的版本编码已经有分包记录的渠道，本次操作是否对该渠道重新分包<br/>
            </p>

            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 700}}
            >
                <Form.Item name="id" label="pub_channel_parent_version表数据库主键" hidden={true}>
                    <input/>
                </Form.Item>

                <Form.Item name="overlay" label="已分包渠道是否覆盖分包" style={{width: 600}}
                           rules={[{required: true, message: "已分包渠道是否覆盖分包必填"}]} initialValue={"0"}>
                    <Select
                        style={{width: 100}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={yesOrNoOption}
                    ></Select>
                </Form.Item>

            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default ChannelSubPackageFrom;



