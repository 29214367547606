const baseUrl = "/opt/msg";
/** 获取产品申请列表-参数 */
export interface PageParam {
    msgTypeCd:string,
    msgTitle:string,
    msgContent:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    msgTypeCd: string;
    msgTypeCdDesc: string;
    msgTitle: string;
    msgContent: string;
    msgImageSm: string;
    msgImageLg: string;
    exUrl: string;
    msgCategoryCd: string;
    msgCategoryCdDesc: string;
    createTime: string;
    startTime: string;
    endTime: string;
    statusCd: string;
    statusCdDesc: string;
    forcedEject:number;
    forcedEjectDesc:string;
    note: string;
}



export  {baseUrl};

