import {baseUrl, PageParam, TableDataType} from "../types/res/manageNode";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const manageNodeApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.nodeName) {
                url += 'nodeName=' + body.nodeName + "&";
            }
            if (body.nodeIp) {
                url += 'nodeIp=' + body.nodeIp + "&";
            }
            if (body.enableStatus) {
                url += 'enableStatus=' + body.enableStatus + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    options(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"管理节点列表",body)
    },

}

export default manageNodeApi



