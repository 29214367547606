import {baseUrl, PageParam, TableDataType} from "../types/sub/instance";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";

const instanceApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.subscriberId) {
                url += 'subscriberId=' + encodeURIComponent(body.subscriberId);
            }
            if (body.subscriberCode) {
                url += '&subscriberCode=' + encodeURIComponent(body.subscriberCode);
            }
            if (body.phoneNumber) {
                url += '&phoneNumber=' + encodeURIComponent(body.phoneNumber);
            }
            if (body.instanceId) {
                url += '&instanceId=' + encodeURIComponent(body.instanceId);
            }
            if (body.instanceGradeCd) {
                url += '&instanceGradeCd=' + body.instanceGradeCd;
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    }

}

export default instanceApi