import React, {useEffect, useState} from 'react';
import {Form, Input, Modal, Select} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/pub/contact";
import contactApi from "../../../../apis/pub/contact";
import staffApi from "../../../../apis/sys/staff";
import supplierApi from "../../../../apis/res/supplier";
import idcApi from "../../../../apis/res/idc";
import TextArea from "antd/es/input/TextArea";


const layout = {
    labelCol: {span: 12},
    wrapperCol: {span: 16},
};


interface OrganFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    statusOption: OptionRes[];
    objectTypeCdOption: OptionRes[];
    contactWayCdOption: OptionRes[];


}

const OrganForm: React.FC<OrganFormProps> = ({
                                                 refresh,
                                                 record,
                                                 open,
                                                 closeOpen,
                                                 statusOption,
                                                 objectTypeCdOption,
                                                 contactWayCdOption
                                             }) => {


    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue({
            ...record
        });
    }, [open]);

    const [objectIdOption, setObjectIdOption] = useState<OptionRes[]>([])

    function selectOnchange(event: string) {
        setObjectIdOption([])
        if (event === "C01") {
            staffApi.optinos().then((res) => {
                if (res) {

                setObjectIdOption(res.data)
                }
            })
        }
        if (event === "C02") {
            supplierApi.optinos().then((res) => {
                if (res) {

                setObjectIdOption(res.data)
                }
            })
        }
        if (event === "C03") {
            idcApi.optinos().then((res) => {
                if (res) {

                setObjectIdOption(res.data)
                }
            })
        }
        form.setFieldsValue({
            ...record,
            objectId: ''
        });
    }

    const [form] = Form.useForm();
    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        contactApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        contactApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }


    // @ts-ignore
    return (
        <Modal
            title="新增项目"
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >

                <Form.Item name="id" label="组织编号" hidden={true}>
                </Form.Item>
                <Form.Item name="contactWayCd" label="联系方式" rules={[{required: true, message: "联系方式必填"}]}>
                    <Select
                        allowClear
                        style={{width: 150}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={contactWayCdOption}
                    />
                </Form.Item>
                <Form.Item name="contactNo" label="联系号码" rules={[{required: true, message: "联系号码必填"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="objectTypeCd" label="归属对象类型"
                           rules={[{required: true, message: "归属对象类型必填"}]}>
                    <Select
                        allowClear
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={objectTypeCdOption}
                        onChange={selectOnchange}
                    />
                </Form.Item>
                <Form.Item name="objectId" label="归属对象编号" rules={[{required: true, message: "归属对象编号必填"}]}>
                    <Select
                        allowClear
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={objectIdOption}
                    />
                </Form.Item>
                <Form.Item name="primaryMark" label="是否主联系信息"
                           rules={[{required: true, message: "是否主联系信息必填"}]}>
                    <Select
                        allowClear
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={[{value: '1', label: '是'}, {value: '0', label: '否'}]}
                    />
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        allowClear
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1}/>
                </Form.Item>


            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default OrganForm;



