const baseUrl = "/pub/contact";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    contactWayCd:string,
    contactNo:string,
    objectTypeCd:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    contactWayDesc: string;
    contactNo: string;
    objectTypeDesc: string;
    objectId: number;
    primaryMark: number;
    statusDesc: string;
    notes: string;
}



export  {baseUrl};

