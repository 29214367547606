import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PageParam, baseUrl, TableDataType} from "../types/opt/smsTemplate";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const smsTemplateApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.templateName) {
                url += 'templateName=' + encodeURIComponent(body.templateName) + '&';
            }
            if (body.smsTypeCd) {
                url += 'smsTypeCd=' + encodeURIComponent(body.smsTypeCd) + '&';
            }
            if (body.ownerTypeCd) {
                url += 'ownerTypeCd=' + encodeURIComponent(body.ownerTypeCd);
            }
            // if (ownerObjectId) {
            //     url += 'ownerObjectId=' + body.ownerObjectId;
            // }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },

    //查询下拉列表数据
    optinos() {
        let url = baseUrl;
        url += "/options"
        return request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"短信模板列表",body)
    },
}

export default smsTemplateApi



