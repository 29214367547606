import React, {useEffect, useState} from 'react';
import {DatePicker, Form, Input, Modal, Select} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import dayjs from "dayjs";
import organApi from "../../../../apis/sys/organ";
import {TableDataType} from "../../../../apis/types/sys/organ";
import supplierApi from "../../../../apis/res/supplier";
import channelApi from "../../../../apis/pub/channel";
import TextArea from "antd/es/input/TextArea";

const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface OrganFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    statusOption: OptionRes[];
    organTypeCdOption: OptionRes[];
    type: boolean;
}

const OrganForm: React.FC<OrganFormProps> = ({
                                                 refresh,
                                                 record,
                                                 open,
                                                 closeOpen,
                                                 statusOption,
                                                 organTypeCdOption,
                                                 type,
                                             }) => {

    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue({
            ...record
        });
    }, [open]);

    const [parentIdOption, setParentIdOption] = useState<OptionRes[]>([])
    const [relaObjectOption, setRelaObjectOption] = useState<OptionRes[]>([])

    function selectOnchange(event: string) {
        setRelaObjectOption([])
        setParentIdOption([])
        if (event == "O01") {
            organApi.optinos().then((res) => {
                setParentIdOption(res.data)
            })
        } else if (event == "O02") {
            channelApi.optinos().then((res) => {
                setRelaObjectOption(res.data)
            })
        } else if (event == "O03") {
            supplierApi.optinos().then((res) => {
                setRelaObjectOption(res.data)
            })
        }
        form.setFieldsValue({
            ...record,
            organTypeCd: event,
            relaObject: '',
            parentId: ''
        });
    }

    const [form] = Form.useForm();
    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        organApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        organApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }


    return (
        <Modal
            title={type?"新增项目":"编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >

                <Form.Item name="id" label="组织编号" hidden={true}>
                </Form.Item>
                <Form.Item name="organName" label="组织名称" rules={[{required: true, message: "组织名称不能为空"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="organTypeCd" label="组织类型" rules={[{required: true, message: "组织类型不能为空"}]}>
                    <Select
                        allowClear
                        style={{width: 150}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={organTypeCdOption}
                        onChange={selectOnchange}
                    />
                </Form.Item>
                <Form.Item name="relaObject" label="关联外部组织">
                    <Select
                        allowClear
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={relaObjectOption}
                    />
                </Form.Item>
                <Form.Item name="parentId" label="父级组织">
                    <Select
                        allowClear
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={parentIdOption}
                    />
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                    <Select
                        allowClear
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={statusOption}
                    />

                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} />
                </Form.Item>


            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default OrganForm;



