import React, {useEffect, useState} from "react";
import {
    Button,
    ConfigProvider,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {OptionRes} from "../../../../apis/types/common";
import propertiesApi from "../../../../apis/sys/properties";
import {TableDataType} from "../../../../apis/types/cus/refund";
import Details from "../../public/details/details";
import {filterOption} from "../../../../apis/common";
import refundApi from "../../../../apis/cus/refund";
import AudingForm from "./audingForm";
import ConfirmRefundForm from "./confirmRefundForm";
import dayjs from "dayjs";
import faultApi from "../../../../apis/cus/fault";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";

const {RangePicker} = DatePicker;

const SmsTemplate = () => {

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //进入页面先查询数据
    useEffect(() => {
        getDictList()
    }, []);


    async function getDictList() {
        propertiesApi.optinos('DM_Business_Type').then((res) => {
            if (res) {
                setBusinessType(res.data)
            }
        })
        propertiesApi.optinos('DM_Auditing_Status').then((res) => {
            if (res) {
                setAuditingStatus(res.data)
            }
        })
        propertiesApi.optinos('DM_Order_Source').then((res) => {
            if (res) {
                setOrderSource(res.data)
            }
        })
        propertiesApi.optinos('DM_Refund_Work_Type').then((res) => {
            if (res) {
                setRefundType(res.data)
            }
        })
    }

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [auditOpen, setAuditOpen] = useState(false)
    const [refundOpen, setRefundOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [businessType, setBusinessType] = useState<OptionRes[]>([]);
    const [auditingStatus, setAuditingStatus] = useState<OptionRes[]>([]);
    const [orderSource, setOrderSource] = useState<OptionRes[]>([]);
    const [refundType, setRefundType] = useState<OptionRes[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            ...body,
            beginCreatTime: body.beginCreatTime ? dayjs(body.beginCreatTime).format('YYYY-MM-DD') : null,
            endCreatTime: body.endCreatTime ? dayjs(body.endCreatTime).format('YYYY-MM-DD') : null,

        };
        let res = refundApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            beginCreatTime: body.beginCreatTime ? dayjs(body.beginCreatTime).format('YYYY-MM-DD') : null,
            endCreatTime: body.endCreatTime ? dayjs(body.endCreatTime).format('YYYY-MM-DD') : null,

        };
        refundApi.derive(body);
    }

    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => (
                <div>
                    <Typography.Link onClick={() => {
                        setAuditOpen(true)
                        setRowData(record);
                    }}>
                        审核
                    </Typography.Link>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Typography.Link onClick={() => {
                        setRefundOpen(true)
                        setRowData(record);
                    }}>
                        退款
                    </Typography.Link>
                </div>
            ),
            ellipsis: true,
            width: 80
        },

        {
            title: '订单编号',
            dataIndex: 'orderId',
            key: 'orderId',
            ellipsis: true,
            width: 100
        }, {
            title: '会员编码',
            dataIndex: 'subscriberCode',
            key: 'subscriberCode',
            ellipsis: true,
            width: 100
        }, {
            title: '手机号',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            ellipsis: true,
            width: 100
        },
        {
            title: '云手机编号',
            dataIndex: 'instanceId',
            key: 'instanceId',
            ellipsis: true,
            width: 100
        }, {
            title: '商品编号',
            dataIndex: 'goodsId',
            key: 'goodsId',
            ellipsis: true,
            width: 100
        },
        {
            title: '支付流水号',
            dataIndex: 'paymentCode',
            key: 'paymentCode',
            ellipsis: true,
            width: 150
        },
        {
            title: '业务类型',
            dataIndex: 'businessTypeDesc',
            key: 'businessTypeDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '订单来源',
            dataIndex: 'orderSourceDesc',
            key: 'orderSourceDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '审核状态',
            dataIndex: 'auditingDesc',
            key: 'auditingDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '退款金额',
            dataIndex: 'refundAmount',
            key: 'refundAmount',
            ellipsis: true,
            width: 80
        },
        {
            title: '退款方式',
            dataIndex: 'refundTypeDesc',
            key: 'refundTypeDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '审核备注',
            dataIndex: 'auditingNotes',
            key: 'auditingNotes',
            ellipsis: true,
            width: 80
        },
        {
            title: '退款状态',
            dataIndex: 'refundCdDesc',
            key: 'refundCdDesc',
            ellipsis: true,
            width: 80
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
            width: 80
        }, {
            title: '受理时间',
            dataIndex: 'beginTime',
            key: 'beginTime',
            ellipsis: true,
            width: 80
        }, {
            title: '完成时间',
            dataIndex: 'finishTime',
            key: 'finishTime',
            ellipsis: true,
            width: 80
        }, {
            title: '订阅数量',
            dataIndex: 'orderQuantity',
            key: 'orderQuantity',
            ellipsis: true,
            width: 80
        }, {
            title: '分配的数量',
            dataIndex: 'allocateQuantity',
            key: 'allocateQuantity',
            ellipsis: true,
            width: 80
        }, {
            title: '未分配的数量',
            dataIndex: 'unAllocateQuantity',
            key: 'unAllocateQuantity',
            ellipsis: true,
            width: 80
        }, {
            title: '订单金额',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
            ellipsis: true,
            width: 80
        }, {
            title: '优惠金额',
            dataIndex: 'discountAmount',
            key: 'discountAmount',
            ellipsis: true,
            width: 80
        }, {
            title: '支付金额',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            ellipsis: true,
            width: 80
        }, {
            title: '销售价',
            dataIndex: 'salePrice',
            key: 'salePrice',
            ellipsis: true,
            width: 80
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
            width: 300
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();


    function cancel() {
        message.error('取消删除');
    }

    const [auditForm] = Form.useForm();
    const [refundForm] = Form.useForm();


    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>


                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="phoneNumber" label="会员手机号">
                        <Input/>

                    </Form.Item>
                    <Form.Item name="orderId" label="订单号">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="instanceId" label="云手机编号">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="orderSourceCd" label="订单来源">
                        <Select
                            allowClear
                            style={{width: 200}}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={orderSource}
                        />
                    </Form.Item>
                    <Form.Item name="auditingCd" label="审核状态">
                        <Select
                            allowClear
                            style={{width: 200}}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={auditingStatus}
                        />
                    </Form.Item>
                    <Form.Item name="paymentCode" label="支付单流水号">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="businessTypeCd" label="业务类型">
                        <Select
                            allowClear
                            style={{width: 200}}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={businessType}
                        />
                    </Form.Item>
                    <Form.Item name="beginCreatTime" label="创建时间开始" hidden={true}></Form.Item>
                    <Form.Item name="endCreatTime" label="创建时间结束" hidden={true}></Form.Item>
                    <Form.Item name="creatTime" label="创建时间">
                        <RangePicker
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginCreatTime", null);
                                    form.setFieldValue("endCreatTime", null);
                                } else {
                                    if (Array.isArray(value)) {
                                        const valueArray = value;
                                        let date = Array.from(value);
                                        if (date.length == 2) {
                                            form.setFieldValue("beginCreatTime", date[0]);
                                            form.setFieldValue("endCreatTime", date[1]);
                                        }
                                        console.log('onOk: ', date);
                                    }
                                }
                            }}></RangePicker>
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                refundApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                    }
                                })
                            }}>重置</Button>
                            <Popconfirm
                                title="是否确认导出筛选的数据？"
                                onConfirm={confirmDerive}
                                onCancel={cancelDerive}
                                okText="是"
                                cancelText="否"
                            >
                                <Button type="primary" danger style={{marginRight: '25px'}}>
                                    导出
                                </Button>
                            </Popconfirm>
                        </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '80vw', y: "70vh"}} rowSelection={{
                    ...rowSelection, "selectedRowKeys": selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


            <AudingForm
                refresh={() => {
                    //设置表单不展示
                    setAuditOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                closeOpen={() => {
                    setAuditOpen(false)
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={auditOpen}
            >
            </AudingForm>
            <ConfirmRefundForm
                refresh={() => {
                    //设置表单不展示
                    setRefundOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                closeOpen={() => {
                    setRefundOpen(false)
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={refundOpen}
                refundType={refundType}
            >
            </ConfirmRefundForm>
            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default SmsTemplate;