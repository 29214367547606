import React, {useEffect, useState} from 'react';
import {
    Button, Col, Divider,
    Form,
    Image,
    Input, InputNumber,
    message,
    Modal, Row,
    Select,
    Upload,
    UploadProps
} from 'antd';
import dayjs from "dayjs";
import {TableDataType} from "../../../../apis/types/app/catalogInfo";
import catalogInfoApi from "../../../../apis/app/catalogInfo";
import {UploadOutlined} from "@ant-design/icons";
import {getToken} from "../../../../utils";
import TextArea from "antd/es/input/TextArea";
import InfoContent from "../appInfo/infoContent";
import {domain, imgIp} from "../../../../components/constantConfig";

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 16},
};


interface CatalogInfoFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    type: boolean;
    open: boolean;
}


const CatalogInfoForm: React.FC<CatalogInfoFormProps> = ({
                                                 refresh,
                                                 record,
                                                 open,
                                                 closeOpen,
                                                 type
                                             }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        setIconFile("");
        setOldIconFile("");
        console.log("record",record)
        if (open && record) {
            form.setFieldsValue({
                ...record,
                status: record.status !== null && record.status !== undefined
                    ? record.status.toString():null,
                enableStatus: record.enableStatus !== null && record.enableStatus !== undefined
                    ? record.enableStatus.toString():null,
                softType: record.softType !== null && record.softType !== undefined
                    ? record.softType.toString():null,
            })
        }
    }, [open]);

    useEffect(() => {
        setIconFile("");
        setOldIconFile("");
        // 在组件挂载时发送请求
        if (record.appCatalogId) {
            // 发送请求的逻辑，使用 record.id 去获取数据
            // 比如：fetchData(record.id);
            console.log('Sending request for record:', record);
            setIconFile(record.iconFile);
            setOldIconFile(record.iconFile);
        }
    }, [record.appCatalogId]);



    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        values = {
            ...values,
            createTime: values.createTime ? dayjs(values.createTime).format('YYYY-MM-DD HH:mm:ss') : null,
            modifyTime: values.modifyTime ? dayjs(values.modifyTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        values.iconFile=iconFile;
        let valueJSOn = JSON.stringify(values);
        catalogInfoApi.create(valueJSOn).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        values = {
            ...values,
            createTime: values.createTime ? dayjs(values.createTime).format('YYYY-MM-DD HH:mm:ss') : null,
            modifyTime: values.modifyTime ? dayjs(values.modifyTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        values.iconFile=iconFile;
        let valueJSOn = JSON.stringify(values);
        catalogInfoApi.update(JSON.parse(valueJSOn).appCatalogId, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.setFieldValue("iconFile",iconFile);
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.appCatalogId == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    function onblur() {
        open = true;
    }

    const [iconFile, setIconFile] = useState(record.iconFile)
    const [oldIconFile, setOldIconFile] = useState(record.iconFile);


    const iconFileprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain+'/pub/picture/upload',
        headers: {
            authorization: getToken(),
        },
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            // setIconFileList([...iconFileList])
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    setIconFile(urlRes)
                    // setIconFileList([...iconFileList,file])
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setIconFile(oldIconFile)
        },

    };

    return (
        <Modal
            title={type ? "新增应用" : "修改应用"}
            open={open}
            width={1600}
            onCancel={handleCancel}
            onOk={handleOK}
            okText={"保存"}
            cancelText={"取消"}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                onBlur={onblur}
            >
                <Form.Item name="appCatalogId" label="id值" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={6}>
                        <Form.Item name="appName" label="应用名称" rules={[{required: true, message: "应用名称必填"}]}>
                            <Input style={{width: 250}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="iconFile" label="应用图标" rules={[{required: true, message: "应用图标必填"}]}>
                            <Image src={iconFile?imgIp+iconFile:iconFile} width={100}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="iconFileUplod" label="应用图标上传">
                            <Upload {...iconFileprops} >
                                <Button style={{width: 250}} icon={<UploadOutlined/>}>点击上传</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="enableStatus" label="启用状态">
                            <Select
                                style={{width: 250}}
                                allowClear
                                options={[{value: '0', label: '禁用'}, {value: '1', label: '启用'}]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    {/*<Col span={6}>*/}
                    {/*    <Form.Item name="status" label="删除状态">*/}
                    {/*        <Select*/}
                    {/*            style={{width: 250}}*/}
                    {/*            allowClear*/}
                    {/*            options={[{value: '0', label: '已删除'}, {value: '1', label: '未删除'}]}*/}
                    {/*        />*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col span={6}>
                        <Form.Item name="softType" label="软件类型"
                                   rules={[{required: true, message: "软件类型必填"}]}>
                            <Select
                                style={{width: 250}}
                                allowClear
                                options={[{value: '1', label: '应用'}, {value: '2', label: '游戏'}]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="reorder" label="排序">
                            <InputNumber style={{width: 250}} min={0}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="remark" label="备注">
                            <TextArea rows={1} style={{width:250}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div hidden={type}>
                <h3>渠道应用信息</h3>
                <Divider style={{height:1,marginTop:10,marginBottom:0}} />

                <InfoContent appCatalogId={record.appCatalogId} ts={Date.now()}></InfoContent>
            </div>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default CatalogInfoForm;



