import React, {useEffect} from 'react';
import {Col, Divider, Form, Input, InputNumber, Modal, Row, Select} from 'antd';
import dayjs from "dayjs";
import {TableDataType} from "../../../../apis/types/ord/order";
import SubsOrderContent from "./subsOrder/subsOrderContent";
import RenewalOrderContent from "./renewalOrder/renewalOrderContent";
import ReplaceOrderContent from "./replaceOrder/replaceOrderContent";
import UpgradeOrderContent from "./upgradeOrder/upgradeOrderContent";
import TrialOrderContent from "./trialOrder/trialOrderContent";
import PaymentContent from "../payment/paymentContent";
import OrderStatusContent from "../order/orderLog/orderStatusContent";
import refundApi from "../../../../apis/cus/refund";
import TextArea from "antd/es/input/TextArea";

const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

interface StaffFromProps {
    closeOpen: () => void;
    refresh: () => void;
    record: TableDataType;
    open: boolean;
}

const OrderForm: React.FC<StaffFromProps> = ({
                                                 record,
                                                 open,
                                                 closeOpen,
                                                 refresh,
                                             }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        console.log(record)
        if (open && record) {
            form.setFieldsValue({
                ...record,
                effTime: dayjs(record.createTime),
                expTime: dayjs(record.finishTime),
            })
        }

    }, [open]);

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    return (


        <Modal title="项目详情" open={open} okText={"提交"} width={1800}
               cancelText={"取消"} onOk={() => {
            let value = form.getFieldsValue();
            value = {notes:value.refundNotes, orderId: value.id,refundAmount:value.refundAmount}
            refundApi.create(value).then((result) => {
                if (result) {
                    handleCancel();
                    refresh();
                }
            })
        }}
               onCancel={handleCancel}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{display: "block"}}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={4}>
                        <Form.Item name="orderTypeCdDesc" label="订单类型">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="subscriberIdDesc" label="会员编号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="subUserId" label="会员账号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="channelIdDesc" label="订单发起渠道">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="businessTypeCdDesc" label="业务类型">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="orderSourceCdDesc" label="订单来源">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Form.Item name="sourceIp" label="订单发起IP">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="orderQuantity" label="订阅数量">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="orderAmount" label="订单金额">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="activityGoodsIdDesc" label="活动商品编号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="discountIdDesc" label="优惠编号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="discountAmount" label="优惠金额">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Form.Item name="paymentAmount" label="支付金额">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="createTime" label="订单创建时间">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="finishTime" label="订单完成时间">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="orderSnapshot" label="订单快照">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="ownerTypeCdDesc" label="订单归属类型">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="ownerObjectIdDesc" label="订单归属对象">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Form.Item name="payStatusCdDesc" label="支付状态">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="orderStatusCdDesc" label="订单状态">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="orderCode" label="订单流水号">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="notes" label="备注">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>


                <div hidden={record.businessTypeCd !== 'B01'}>
                    <h3>云手机订阅订单信息</h3>
                    <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                    <SubsOrderContent orderId={record.id}></SubsOrderContent>
                </div>
                <div hidden={record.businessTypeCd !== 'B02'}>
                    <h3>续时订单信息</h3>
                    <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                    <RenewalOrderContent orderId={record.id}></RenewalOrderContent>
                </div>
                <div hidden={record.businessTypeCd !== 'B05'}>
                    <h3>云手机升级订单信息</h3>
                    <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                    <UpgradeOrderContent orderId={record.id}></UpgradeOrderContent>
                </div>
                <div hidden={record.businessTypeCd !== 'B06'}>
                    <h3>云手机更换订单信息</h3>
                    <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                    <ReplaceOrderContent orderId={record.id}></ReplaceOrderContent>
                </div>
                {/*<div hidden={record.businessTypeCd !== 'B10'}>*/}
                {/*    <h3>体验云手机订阅订单</h3>*/}
                {/*    <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>*/}

                {/*    <TrialOrderContent orderId={record.id}></TrialOrderContent>*/}
                {/*</div>*/}
                <div>
                    <h3>订单支付信息</h3>
                    <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>
                    <PaymentContent orderId={record.id}></PaymentContent>
                </div>

                <div>
                    <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>
                    <OrderStatusContent orderId={record.id}></OrderStatusContent>
                </div>


                {/*退款的字段*/}
                <h3>退款申请</h3>
                <Row>
                <Form.Item name="refundAmount" label="退款金额(分)"  rules={[{required: true, message: "退款金额必填"}]}
                           style={{marginLeft: 30, marginTop: 20,width: 200}}>
                    <InputNumber min={0} style={{marginLeft:30,width: 150}}/>
                </Form.Item>
                <Form.Item name="refundNotes" label="备注" style={{marginLeft: 30, marginTop: 20}}>
                    <TextArea rows={1} style={{width: 500}}  />
                </Form.Item>
                </Row>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default OrderForm;



