import React, {useEffect, useState} from "react";
import {
    Button,
    ConfigProvider,
    Divider,
    Form,
    Input, message,
    Pagination,
    PaginationProps,
    Popconfirm,
    Select,
    Space,
    Table
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {OptionRes} from "../../../../apis/types/common";
import propertiesApi from "../../../../apis/sys/properties";
import {TableDataType} from "../../../../apis/types/opt/smsList";
import smsListApi from "../../../../apis/opt/smsList";
import batchSmsApi from "../../../../apis/opt/batchSms";
import platformApi from "../../../../apis/opt/smsPlatform";
import smsTemplateApi from "../../../../apis/opt/smsTemplate";
import Details from "../../public/details/details";


const List = () => {

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //进入页面先查询数据
    useEffect(() => {
        getDictList()
    }, []);


    async function getDictList() {
        propertiesApi.optinos('DM_Sms_Type').then((res) => {
            if (res) {

                setSmsTypeCds(res.data)
            }
        })
        propertiesApi.optinos('DM_Sms_Source').then((res) => {
            if (res) {

                setSmsSourceCds(res.data)
            }
        })
        propertiesApi.optinos('DM_Status').then((res) => {
            if (res) {

                setStatusCds(res.data)
            }
        })
        batchSmsApi.optinos().then((res) => {
            if (res) {

                setBatchSmsOption(res.data)
            }
        })
        platformApi.optinos().then((res) => {
            if (res) {
                setPlatformIdOption(res.data)
            }
        })
        smsTemplateApi.optinos().then((res) => {
            if (res) {
                setSmsTemplateIdOption(res.data)
            }
        })
    }

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [uidList, setUidList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusCds, setStatusCds] = useState<OptionRes[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = smsListApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setUidList([]);
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        smsListApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        // {
        //     title: 'operation',
        //     dataIndex: 'operation',
        //     render: (_: any, record: TableDataType) => {
        //         return <Typography.Link onClick={() => {
        //             setOpen(true)
        //             setRowData(record);
        //         }}>
        //             编辑
        //         </Typography.Link>
        //     },
        // },
        {
            title: '短信类型',
            dataIndex: 'smsTypeCdDesc',
            key: 'smsTypeCdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '短信来源',
            dataIndex: 'smsSourceCdDesc',
            key: 'smsSourceCdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '生成IP',
            dataIndex: 'sourceIp',
            key: 'sourceIp',
            ellipsis: true,
            width: 80
        },
        {
            title: '批量编号',
            dataIndex: 'batchSmsIdDesc',
            key: 'batchSmsIdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '下发平台',
            dataIndex: 'smsPlatformIdDesc',
            key: 'smsPlatformIdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '下发模板',
            dataIndex: 'smsTemplateIdDesc',
            key: 'smsTemplateIdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '下发号码',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            ellipsis: true,
            width: 100
        },
        {
            title: '下发会员',
            dataIndex: 'subscriberId',
            key: 'subscriberId',
            ellipsis: true,
            width: 100
        },
        {
            title: '下发内容',
            dataIndex: 'smsContent',
            key: 'smsContent',
            ellipsis: true,
            width: 100
        },
        {
            title: '优先级',
            dataIndex: 'priorityLevel',
            key: 'priorityLevel',
            ellipsis: true,
            width: 70
        },
        {
            title: '允许发送次数',
            dataIndex: 'repeatCount',
            key: 'repeatCount',
            ellipsis: true,
            width: 100
        },
        {
            title: '发送间隔',
            dataIndex: 'sendInterval',
            key: 'sendInterval',
            ellipsis: true,
            width: 100
        },
        {
            title: '已经发送次数',
            dataIndex: 'sentCount',
            key: 'sentCount',
            ellipsis: true,
            width: 100
        },
        {
            title: '生成时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
            width: 150
        },
        {
            title: '发送时间',
            dataIndex: 'sendTime',
            key: 'sendTime',
            ellipsis: true,
            width: 150
        },
        {
            title: '短信平台消息id',
            dataIndex: 'msgId',
            key: 'msgId',
            ellipsis: true,
            width: 100
        },
        {
            title: '平台结果',
            dataIndex: 'returnResult',
            key: 'returnResult',
            ellipsis: true,
            width: 100
        },
        {
            title: '平台代码',
            dataIndex: 'returnCode',
            key: 'returnCode',
            ellipsis: true,
            width: 100
        },
        {
            title: '状态',
            dataIndex: 'statusCdDesc',
            key: 'statusCdDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis: true,
            width: 100
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setUidList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    const [smsTemplateIdOption, setSmsTemplateIdOption] = useState<OptionRes[]>([]);
    const [smsTypeCds, setSmsTypeCds] = useState<OptionRes[]>([]);
    const [smsSourceCds, setSmsSourceCds] = useState<OptionRes[]>([]);
    const [batchSmsOption, setBatchSmsOption] = useState<OptionRes[]>([]);
    const [platformIdOption, setPlatformIdOption] = useState<OptionRes[]>([]);

    //获取要查询的表单
    const [form] = Form.useForm();

    // function confirm() {
    //     if (uidList.length === 0) {
    //         message.error('删除失败，请选择需要删除的数据');
    //     } else {
    //         handleDelete();
    //     }
    // }
    //
    // function cancel() {
    //     message.error('取消删除');
    // }

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*<Button type="primary" ghost onClick={() => {*/}
                {/*    setOpen(true)*/}
                {/*}} style={{marginRight: '25px'}}>*/}
                {/*    新增*/}
                {/*</Button>*/}
                {/*<Popconfirm*/}
                {/*    title="是否确认删除所选项？"*/}
                {/*    onConfirm={confirm}*/}
                {/*    onCancel={cancel}*/}
                {/*    okText="是"*/}
                {/*    cancelText="否"*/}
                {/*>*/}
                {/*    <Button type="primary" danger style={{marginRight: '25px'}}>*/}
                {/*        删除*/}
                {/*    </Button>*/}
                {/*</Popconfirm>*/}
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="phoneNumber" label="下发手机号码">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="smsTypeCd" label="短信类型">
                        <Select
                            allowClear={true}
                            style={{width: 120}}
                            options={smsTypeCds}
                        />
                    </Form.Item>
                    <Form.Item name="smsSourceCd" label="短信来源">
                        <Select
                            allowClear={true}
                            style={{width: 120}}
                            options={smsSourceCds}
                        />
                    </Form.Item>
                    <Form.Item name="batchSmsId" label="批量短信编号">
                        <Select
                            allowClear={true}
                            style={{width: 120}}
                            options={batchSmsOption}
                        />
                    </Form.Item>
                    <Form.Item name="smsPlatformId" label="下发短信平台">
                        <Select
                            allowClear={true}
                            style={{width: 120}}
                            options={platformIdOption}
                        />
                    </Form.Item>
                    <Form.Item name="smsTemplateId" label="下发短信模板">
                        <Select
                            allowClear={true}
                            style={{width: 120}}
                            options={smsTemplateIdOption}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                smsListApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                        setUidList([]);
                                    }
                                })
                            }}>重置</Button> </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} dataSource={list}
                    columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*/!*新增和编辑的form表单，子组件*!/*/}
            {/*<HostVirtualFrom*/}
            {/*    refresh={() => {*/}
            {/*        //设置表单不展示*/}
            {/*        setOpen(false);*/}
            {/*        //重新调用查询的接口*/}
            {/*        handleQuery();*/}
            {/*    }}*/}
            {/*    record={rowData}*/}
            {/*    open={open}*/}
            {/*    closeOpen={() => {*/}
            {/*        setOpen(false)*/}
            {/*    }}*/}
            {/*    statusCds={statusCds}*/}
            {/*    instanceGradeCdOptions={instanceGradeCds}*/}
            {/*    serverModelIdOptions={serverModelIdOptions}*/}
            {/*></HostVirtualFrom>*/}

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default List;