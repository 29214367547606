const baseUrl = "/res/file/service";
/** 获取列表-参数 */
export interface PageParam {
    serviceName:string;
    serviceTypeCd:string;
    idcId:string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    idcName: string;
    serviceName: string;
    serviceTypeCdDesc: string;
    serviceHost: string;
    servicePort: string;
    pathPrefix: string;
    dynamicPahtId: string;
    statusDesc: string;
    notes: string;
    createTime:string;
    modifyTime:string;
}

export  {baseUrl};