import React, {useEffect, useState} from "react";
import {
    Button,
    ConfigProvider,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import actApi from "../../../../apis/mkt/act/act";
import {TableDataType} from "../../../../apis/types/mkt/act/act";
import Details from "../redeemCode/details";
import ActForm from "./actForm";
import dayjs from "dayjs";

const {RangePicker} = DatePicker;

const MktAct = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos('DM_Mkt_Act_Type').then((res) => {
            if (res) {

                setActTypeOption(res.data)
            }
        })


    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [model, setModel] = useState(0)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [actTypeOption, setActTypeOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };
    const [number, setNumber] = useState(0); // 初始化 ID 为 0

    const incrementId = () => {
        setNumber((prevId) => prevId + 1); // 自增 ID
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let values = form.getFieldsValue();
        values = {
            ...values,
            startStartTime: values.startStartTime ? dayjs(values.startStartTime).format('YYYY-MM-DD') : null,
            endStartTime: values.endStartTime ? dayjs(values.endStartTime).format('YYYY-MM-DD') : null,
            startEndTime: values.startEndTime ? dayjs(values.startEndTime).format('YYYY-MM-DD') : null,
            endEndTime: values.endEndTime ? dayjs(values.endEndTime).format('YYYY-MM-DD') : null,
        };
        let res = actApi.pageData(values, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        actApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }


    //启用
    const enable = () => {
        let body = {"idList": idList}
        actApi.enable(body).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    //禁用
    const disable = () => {
        let body = {"idList": idList}
        actApi.disable(body).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            startStartTime: body.startStartTime ? dayjs(body.startStartTime).format('YYYY-MM-DD') : null,
            endStartTime: body.endStartTime ? dayjs(body.endStartTime).format('YYYY-MM-DD') : null,
            startEndTime: body.startEndTime ? dayjs(body.startEndTime).format('YYYY-MM-DD') : null,
            endEndTime: body.endEndTime ? dayjs(body.endEndTime).format('YYYY-MM-DD') : null,
        };
        actApi.derive(body);
    }

    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    console.log("operation", record)
                    setRowData(record);
                    setModel(1)
                    incrementId();
                }}>
                    编辑
                </Typography.Link>
            },
        },
        {
            title: '活动名称',
            dataIndex: 'actName',
            key: 'actName',
        },
        {
            title: '活动类型',
            dataIndex: 'actTypeDesc',
            key: 'actTypeDesc',
        },
        {
            title: '活动编码',
            dataIndex: 'actCode',
            key: 'actCode',
        },

        {
            title: '开始时间',
            dataIndex: 'startTime',
            key: 'startTime',
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            key: 'endTime',
        }, {
            title: '启用标识',
            dataIndex: 'enableStatusDesc',
            key: 'enableStatusDesc',
        },
        {
            title: '排序',
            dataIndex: 'reorder',
            key: 'reorder',
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            console.log("actR",selectedRowKeys)
            const newSet = new Set(selectedRows.map((key) => key.id));
            console.log("actSet",newSet)
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);

        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>

            <div style={{display: 'flex', alignItems: 'center'}}>


                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      size={"small"}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="actType" label="活动类型">
                        <Select
                            style={{width: 150}}
                            showSearch
                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            options={actTypeOption}
                        />
                    </Form.Item>
                    <Form.Item name="actCode" label="活动编码" hidden={true}>
                        <Input/>
                    </Form.Item>

                    <Form.Item name="enableStatus" label="启用标识">
                        <Select
                            style={{width: 90}}
                            showSearch
                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            options={[
                                {label: '启用', value: '1'},
                                {label: '禁用', value: '0'}
                            ]}
                        />
                    </Form.Item>

                    <Form.Item name="startTime" label="开始时间">
                        <RangePicker
                            placeholder={['', '']}

                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("startStartTime", null);
                                    form.setFieldValue("endStartTime", null);
                                } else if (Array.isArray(value)) {
                                    const valueArray = value;
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("startStartTime", date[0]);
                                        form.setFieldValue("endStartTime", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }}

                        />
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="startStartTime" hidden={true}></Form.Item>
                    <Form.Item style={{margin: 5}} name="endStartTime" hidden={true}></Form.Item>

                    <Form.Item name="endTime" label="结束时间">
                        <RangePicker
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("startEndTime", null);
                                    form.setFieldValue("endEndTime", null);
                                } else if (Array.isArray(value)) {
                                    const valueArray = value;
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("startEndTime", date[0]);
                                        form.setFieldValue("endEndTime", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }}

                        />
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="startEndTime" hidden={true}></Form.Item>
                    <Form.Item style={{margin: 5}} name="endEndTime" hidden={true}></Form.Item>
                    <div style={{ margin: '10px 0' }}></div> {/* 添加一个空 div 作为换行 */}


                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                actApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                        setIdList([]);
                                    }
                                })
                            }}>重置</Button> </Space>
                    </Form.Item>

                    <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>



                    <Form.Item>
                        <Button type="primary" ghost onClick={() => {
                            setOpen(true)
                            setModel(0)
                        }} style={{marginRight: '5px'}}>
                            新增
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" ghost onClick={() => {
                            if (idList.length === 0) {
                                message.error('启用失败，请选择需要启用的数据');
                            } else {
                                enable();
                            }
                        }} style={{marginRight: '5px'}} >
                            启用
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" danger onClick={() => {
                            if (idList.length === 0) {
                                message.error('禁用失败，请选择需要禁用的数据');
                            } else {
                                disable();
                            }
                        }} style={{marginRight: '5px'}}>
                            禁用
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Popconfirm
                            title="是否确认删除所选项？"
                            onConfirm={() => {
                                if (idList.length === 0) {
                                    message.error('删除失败，请选择需要删除的数据');
                                } else {
                                    handleDelete();
                                }
                            }}
                            onCancel={() => {
                                message.error('取消删除');
                            }}
                            okText="是"
                            cancelText="否"
                        >
                            <Button type="primary" danger style={{marginRight: '5px'}}>
                                删除
                            </Button>
                        </Popconfirm>
                    </Form.Item>

                    <Form.Item>

                            <Button type="primary"
                                    onClick={() => {
                                        confirmDerive();
                                    }}
                                    style={{marginRight: '5px'}}>
                                导出
                            </Button>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection, "selectedRowKeys": selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <ActForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                actTypeOption={actTypeOption}
                number={number}
            ></ActForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)

                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default MktAct;