const baseUrl = "/res/dynamic/path";
/** 获取列表-参数 */
export interface PageParam {
    generateModeCd:string;
    pathName:string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    generateModeCdDesc: string;
    pathName: string;
    parentId: number;
    notes: string;
}

export  {baseUrl};