import {baseUrl, PageParam, TableDataType} from "../../types/mkt/couponTemplate/couponTemplate";
import {PaginationProps} from "antd";
import {request} from "../../../utils";
import {PageRes} from "../../types/common";
import * as url from "url";
import commonApi from "../../commonApi";


const couponTemplateApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "/page?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.couponType) {
                url += 'couponType=' + encodeURIComponent(body.couponType) + "&";

            }
            if (body.enableStatus) {
                url += 'enableStatus=' + encodeURIComponent(body.enableStatus) + "&";
            }
            if (body.softDel) {
                url += 'softDel=' + encodeURIComponent(body.softDel) + "&";

            }
            if (body.startCreateTime) {
                url += 'startCreateTime=' + encodeURIComponent(body.startCreateTime) + "&";

            }
            if (body.endCreateTime) {
                url += 'endCreateTime=' + encodeURIComponent(body.endCreateTime) + "&";
            }
            if (body.creator) {
                url += 'creator=' + encodeURIComponent(body.creator) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //创建优惠券模版
    create(body: any) {
        let url = baseUrl + '/create' ;
        return request.post(url, body)
    },
    //更新数据
    update(body: any) {
        let url = baseUrl + '/update';
        return request.post(url, body)
    },
    //生成优惠券
    generateCoupon(body: any) {
        let url = baseUrl + '/generateCoupon' ;
        return request.post(url, body)
    },
    //批量删除
    batchDelete(body: any) {
        let url = baseUrl + '/delete';
        return request.post(url, body);
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"优惠券模板列表",body)
    },

}

export default couponTemplateApi




