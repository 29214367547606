import React, {useEffect, useState} from "react";
import {
    Divider,
    Form,
    Pagination,
    PaginationProps,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {TableDataType} from "../../../../../apis/types/ord/renewalOrder";
import renewalOrderApi from "../../../../../apis/ord/renewalOrder";
import Details from "../../../public/details/details";

interface StaffFromProps {
    orderId: number
}

const SubsOrderContent: React.FC<StaffFromProps> = ({
                                                        orderId,
                                                    }) => {
    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
        const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })

    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
    }, [orderId]);

    useEffect(() => {
        handleQuery();
    }, [pagination]);

    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            orderId: orderId
        };
        let res = renewalOrderApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }
        })
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     render: (_: any, record: TableDataType) => {
        //         return <Typography.Link onClick={() => {
        //             setOpen(true)
        //             setRowData(record);
        //         }}>
        //             编辑
        //         </Typography.Link>
        //     },
        // },
        {
            title: '会员编号',
            dataIndex: 'subscriberIdDesc',
            key: 'subscriberIdDesc',
        },
        {
            title: '会员账号',
            dataIndex: 'subUserId',
            key: 'subUserId',
        },
        {
            title: '云手机订阅编号',
            dataIndex: 'subsInstanceId',
            key: 'subsInstanceId',
        },
        {
            title: '云手机编号',
            dataIndex: 'instanceIdDesc',
            key: 'instanceIdDesc',
        },
        {
            title: '续时类型',
            dataIndex: 'renewalTypeCdDesc',
            key: 'renewalTypeCdDesc',
        },
        {
            title: '商品编号',
            dataIndex: 'goodsIdDesc',
            key: 'goodsIdDesc',
        },
        {
            title: '商品定价',
            dataIndex: 'goodsPriceIdDesc',
            key: 'goodsPriceIdDesc',
        },
        {
            title: '续时时长',
            dataIndex: 'renewalDuration',
            key: 'renewalDuration',
        },
        {
            title: '续时时间',
            dataIndex: 'renewalTime',
            key: 'renewalTime',
        },
        {
            title: '商品价格',
            dataIndex: 'goodsPrice',
            key: 'goodsPrice',
        },
        {
            title: '状态',
            dataIndex: 'statusCdDesc',
            key: 'statusCdDesc',
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            // setIdList(Array.from(newSet) as Number[]);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            {/*表格内容*/}
            <Table dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            {/*表格的分页*/}
            <div style={{textAlign:"right"}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default SubsOrderContent;