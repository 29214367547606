import React, {useEffect, useState} from "react";
import {
    Button, Card,
    ConfigProvider,
    DatePicker,
    Divider,
    Form,
    Input,
    PaginationProps,
    Table,
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {OptionRes} from "../../../../apis/types/common";
import {TableDataType} from "../../../../apis/types/res/instance";
import instanceApi from "../../../../apis/res/instance";
import Details from "../../public/details/details";


const {RangePicker} = DatePicker;

const QueryInstanceForm: React.FC<{ onIdListChange: (newIdList: number[]) => void }> = ({ onIdListChange }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [type, setType] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [idcOption, setIdcOption] = useState<OptionRes[]>([])
    const [greadeOption, setGradeOption] = useState<OptionRes[]>([])
    const [hostOption, setHostOption] = useState<OptionRes[]>([])
    const [gatewayOption, setGatewayOption] = useState<OptionRes[]>([])
    const [virtualOption, setVirtualOption] = useState<OptionRes[]>([])
    const [instancePoolOption, setInstancePoolOption] = useState<OptionRes[]>([])
    const [faultTypeOption, setFaultTypeOption] = useState<OptionRes[]>([])
    const [impartStatusOption, setImpartStatusOption] = useState<OptionRes[]>([])
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [initOpen, setInitOpen] = useState(false)


    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })

    //获取要查询的表单
    const [form] = Form.useForm();

    const [selectedRowsData, setSelectedRowsData] = useState<TableDataType[]>([]);


    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //进入页面先查询数据
    useEffect(() => {
        console.log("进入子组件")
        setIdList([]);
        form.resetFields();
        setList([])
        setTotal(0)
    }, [form]);

    //当分页条件变化的时候，就去刷新页面。
    // useEffect(() => {
    //     // handleQuery();
    // }, [pagination]);

    // 当idList发生变化时，调用父组件传递的函数并传递idList参数
    useEffect(() => {
        onIdListChange(idList);
    }, [idList, onIdListChange]);

    //处理查询
    function handleQuery() {
        if (form.getFieldValue("instanceCodeStr") == null) {
            return;
        }
        let body = form.getFieldsValue();
        body = {
            ...body
        };
        let res = instanceApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setIdList([]);
            }
        })

    }




    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '云手机编码',
            dataIndex: 'instanceCode',
            key: 'instanceCode',
            width: 150,
            ellipsis:true,

        },
        {
            title: '会员编码',
            dataIndex: 'subscriberCode',
            key: 'subscriberCode',
            ellipsis: true

        },
        {
            title: '电话号码',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            ellipsis:true,

        },
        {
            title: '用户云手机',
            dataIndex: 'tagName',
            key: 'tagName',
            ellipsis:true,


        },

        {
            title: '所在机房',
            dataIndex: 'idcName',
            key: 'idcName',
            ellipsis:true,


        },
        {
            title: '服务器型号',
            dataIndex: 'serverModelName',
            key: 'serverModelName',
            ellipsis:true,


        },

        {
            title: '服务器编码',
            dataIndex: 'serverCode',
            key: 'serverCode',
            ellipsis:true,


        },
        {
            title: '宿主机',
            dataIndex: 'hostCode',
            key: 'hostCode',
            width: 150,
            ellipsis:true,


        },
        {
            title: '云手机等级',
            dataIndex: 'instanceGradeDesc',
            key: 'instanceGradeDesc',

            ellipsis:true,

        }, {
            title: '云手机SN',
            dataIndex: 'instanceSn',
            key: 'instanceSn',
            ellipsis:true,


        }, {
            title: 'MAC地址',
            dataIndex: 'instanceMac',
            key: 'instanceMac',
            ellipsis: true

        }, {
            title: 'IP地址',
            dataIndex: 'instanceIp',
            key: 'instanceIp',

            ellipsis:true,

        },
        {
            title: 'OS类型',
            dataIndex: 'osTypeDesc',
            key: 'osTypeDesc',

            ellipsis:true,

        },
        {
            title: 'OS版本',
            dataIndex: 'osVersion',
            key: 'osVersion',
            ellipsis:true,


        },


        {
            title: '在线标识',
            dataIndex: 'onlineMarkDesc',
            key: 'onlineMarkDesc',
            ellipsis:true,


        },
        {
            title: '操控标识',
            dataIndex: 'handleMarkDesc',
            key: 'handleMarkDesc',
            ellipsis:true,


        },
        {
            title: '维护标识',
            dataIndex: 'maintainMarkDesc',
            key: 'maintainMarkDesc',

            ellipsis:true,

        },
        {
            title: '分配状态',
            dataIndex: 'impartStatusDesc',
            key: 'impartStatusDesc',
            ellipsis:true,


        }, {
            title: '启用标识',
            dataIndex: 'enableMarkDesc',
            key: 'enableMarkDesc',
            ellipsis:true,


        }, {
            title: '激活标识',
            dataIndex: 'activateMarkDesc',
            key: 'activateMarkDesc',
            ellipsis:true,


        },
        {
            title: 'root状态',
            dataIndex: 'rootStatusDesc',
            key: 'rootStatusDesc',
            ellipsis:true,


        },
        {
            title: '网关编号',
            dataIndex: 'gatewayName',
            key: 'gatewayName',

            ellipsis:true,

        },
        {
            title: '默认域名',
            dataIndex: 'gatewayDomain',
            key: 'gatewayDomain',
            ellipsis: true


        }, {
            title: '电信域名',
            dataIndex: 'ctccDomain',
            key: 'ctccDomain',
            ellipsis: true


        }, {
            title: '移动域名',
            dataIndex: 'cmccDomain',
            key: 'cmccDomain',
            ellipsis: true


        }
        , {
            title: '联通',
            dataIndex: 'cuccDomain',
            key: 'cuccDomain',
            ellipsis: true


        },
        {
            title: 'TCP端口',
            dataIndex: 'tcpPort',
            key: 'tcpPort',
            ellipsis: true


        },
        {
            title: 'UDP端口',
            dataIndex: 'udpPort',
            key: 'udpPort',
            ellipsis:true,


        },
        {
            title: '故障标识',
            dataIndex: 'faultMarkDesc',
            key: 'faultMarkDesc',
            ellipsis:true,


        }, {
            title: '故障类型',
            dataIndex: 'faultTypeDesc',
            key: 'faultTypeDesc',
            ellipsis:true,


        },
        {
            title: '受控时间',
            dataIndex: 'controlledTime',
            key: 'controlledTime',
            ellipsis:true,


        },
        {
            title: '到期时间',
            dataIndex: 'expTime',
            key: 'expTime',
            ellipsis:true,


        },
        {
            title: '订阅时间',
            dataIndex: 'subsTime',
            key: 'subsTime',
            ellipsis:true,


        },
        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            ellipsis:true,


        },


        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis:true,


        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            setSelectedRowsData(selectedRows)
            console.log("selectedRows11:" + selectedRows)
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
        }
    };



    return (

        <div>

            <div>

                <ConfigProvider
                    theme={{
                        token: {
                            margin: 10
                        },
                    }}
                >

                    {/*查询的表单*/}
                    <Form form={form} name="validateOnly" autoComplete="off"

                          onFinish={() => {
                              pagination.current = 1;
                              setCurrentPage(1);
                              handleQuery();
                          }}
                          labelAlign={"left"}
                          layout={"inline"}
                        // labelCol={{ span: 14 ,offset:14}}
                        // wrapperCol={{ span: 4 }}
                          size={"small"}
                          style={{maxWidth: 3000}}
                    >

                        <Form.Item style={{margin: 5}} name="instanceCodeStr"
                                   label="云手机编码">
                            <Input></Input>
                        </Form.Item>


                        {/*<Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>*/}

                        <div style={{display: 'flex', alignItems: 'center', padding: 0}}>
                            <Form.Item>


                                <Button type="primary" htmlType="submit">查询</Button>
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="reset" onClick={()=>{
                                    const body={};
                                    setList([])
                                    setTotal(0)
                                }}>重置</Button>
                            </Form.Item>

                        </div>
                    </Form>
                </ConfigProvider>

            </div>


            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>


            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 3000, y: '53vh'}} rowSelection={{
                    ...rowSelection,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/
            }
            {/*<div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>*/}

            {/*    <Pagination size="small" style={{marginLeft: 'auto', float: 'right'}}*/}
            {/*                total={total}*/}
            {/*                showSizeChanger*/}
            {/*                showQuickJumper*/}
            {/*                current={currentPage}*/}
            {/*                defaultPageSize={20}*/}
            {/*                locale={paginationLocale}*/}
            {/*                onChange={handlePaginationChange}*/}
            {/*                showTotal={(total) => `一共 ${total} 条数据`}*/}
            {/*    />*/}
            {/*</div>*/}


            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default QueryInstanceForm;