import React, {useEffect, useState} from 'react';
import {Checkbox, Col, Form, Modal, Row, Select, Divider, Input, message} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import insAdminGrantApi from "../../../../apis/res/insAdminGrantApi";
import dayjs from "dayjs";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');

const layout = {
    labelCol: {span: 200},
    wrapperCol: {span: 16},
};


// 创建一个授权时间校验规则数组
const grantTimeInMinutesRules = [
    // 规则1：必填项
    { required: true, message: '授权时间不能为空' },
    // 规则2：必须是数字
    ({ getFieldValue }) => ({
        validator(_, value) {
            if (!/^\d+$/.test(value)) {
                return Promise.reject('授权时间必须为数字');
            }
            return Promise.resolve();
        },
    }),
    // 规则3：数字范围在1到1440之间
    ({ getFieldValue }) => ({
        validator(_, value) {
            if (value && (Number(value) < 1 || Number(value) > 720)) {
                return Promise.reject('授权时间必须在1到720分钟之间');
            }
            return Promise.resolve();
        },
    }),
];

interface FromProps {
    refresh: () => void;
    closeOpen: () => void;
    open: boolean;
    insId: number;
}


const InsGrantForm: React.FC<FromProps> = ({refresh, open, closeOpen, insId}) => {
    const [form] = Form.useForm();

    const [instanceId, setInsId] = useState<number>(insId);
    const [insCode, setInsCode] = useState<string>('');
    const [insTagName, setInsTagName] = useState<string>('');
    const [expireTime, setExpireTime] = useState<string>('');
    const [leftControlTimeInMinutes, setLeftControlTimeInMinutes] = useState<number>(0);
    const [granteePhoneNumber, setGranteePhoneNumber] = useState<string>('');
    const [insOwnerUserId, setInsOwnerUserId] = useState<number>(0);

    // 页面打开时查询云手机的关于授权的相关信息
    useEffect(() => {
        if (open) {
            queryGrantForm();
        }
    }, [open]);

    const queryGrantForm = () => {
        let res = insAdminGrantApi.queryGrantForm(insId);

        res.then((result) => {
                if (result) {
                    var data = result.data;
                    console.log('grantForm data = ', data);
                    setInsId(data.instanceId);
                    setInsCode(data.insCode);
                    setInsOwnerUserId(data.insOwnerUserId ? data.insOwnerUserId : null);
                    setInsTagName(data.insTagName);
                    setExpireTime(data.expireTime? dayjs(data.expireTime).format('YYYY-MM-DD HH:mm:ss') : null);
                    setLeftControlTimeInMinutes(data.leftControlTimeInMinutes);
                    setGranteePhoneNumber(data.currentUserPhone ? data.currentUserPhone : null);


                    // 初始化表单
                    form.setFieldsValue({
                        instanceId: data.instanceId,
                        insCode: data.insCode,
                        insOwnerUserId : data.insOwnerUserId,
                        insTagName : data.insTagName,
                        expireTime: data.expireTime ? dayjs(data.expireTime).format('YYYY-MM-DD HH:mm:ss') : null,
                        leftControlTimeInMinutes: data.leftControlTimeInMinutes,
                        granteePhoneNumber: data.currentUserPhone ? data.currentUserPhone : null

                    });
                } else {
                    // 这里处理接口调用时发生的错误
                    console.log('接口调用出错: ', data);
                    handleCancel();
                }
            })
            .catch((error) => {
                // 这里处理接口调用时发生的错误
                console.log('接口调用出错: ', error);
                handleCancel();
            });
    };


    // 处理取消方法
    const handleCancel = () => {
        closeOpen()
        resetValue();
    };
    const resetValue = () => {
        setInsId(0);
        setInsCode("");
        setInsTagName("");
        setExpireTime("");
        setLeftControlTimeInMinutes(0);
        setGranteePhoneNumber("'");
        form.resetFields();
    }


    //处理ok按钮，
    function handleOK() {
        const values = form.getFieldsValue();
        const body = {
            insId: values.instanceId,
            granteePhoneNumber: values.granteePhoneNumber,
            grantTimeInMinutes: values.grantTimeInMinutes,
        }
        let res = insAdminGrantApi.insGrant(body);
        res.then((result) => {
                if (result){
                    // 请求成功时关闭弹框
                    message.info("授权操作成功！")
                    closeOpen()
                    resetValue();
                }
            })
            .catch((error) => {
                // 这里处理接口调用时发生的错误
                console.log('接口调用出错: ', error);
                message.error('接口调用出错: ', error);
            });



    }

    // 展示出来的列表
    return (
        <Modal
            title={"管理员授权"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >

            <label>注意：1、接受授权的掌派帐号的手机号码与某个管理后台管理员的手机号码相同时，才视为管理员帐号 2、同一时间内同一个云手机只允许授权给一个管理员</label>
            <Divider/>
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}
                style={{maxWidth: 300}}
            >
                <Form.Item name="instanceId" label="云手机ID">
                    <span>{instanceId}</span>
                </Form.Item>
                <Form.Item name="insCode" label="云手机编码" >
                    <span>{insCode}</span>
                </Form.Item>
                <Form.Item name="insOwnerUserId" label="云手机订阅用户userId">
                    <span>{insOwnerUserId}</span>
                </Form.Item>
                <Form.Item name="insTagName" label="云手机名称">
                    <span>{insTagName}</span>
                </Form.Item>
                <Form.Item name="expireTime" label="云手机订阅到期时间">
                    <span>{expireTime}</span>
                </Form.Item>
                <Form.Item name="leftControlTimeInMinutes" label="云手机订阅剩余时长">
                    <span> {leftControlTimeInMinutes} </span>分钟
                </Form.Item>

                <Form.Item name="granteePhoneNumber" label="接受授权帐号(手机号码)" rules={[{required: true, message: "接受授权帐号不能为空"}]}
                           initialValue={granteePhoneNumber}>
                    <Input/>
                </Form.Item>
                <Form.Item name="grantTimeInMinutes" label="授权时间(分钟)" rules={grantTimeInMinutesRules}>
                    <Input/>
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default InsGrantForm;