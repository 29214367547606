import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider, DatePicker,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/res/InsTask";
import dayjs from "dayjs";
import insAdminGrantApi from "../../../../apis/res/insAdminGrantApi";

const {RangePicker} = DatePicker;

const OrganContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos("DM_Ins_Grant_Way").then((res) => {
            if (res) {
                setInsGrantWayOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Ins_Grant_Type").then((res) => {
            if (res) {
                setInsGrantTypeOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Ins_Grant_Control").then((res) => {
            if (res) {
                setInsGrantControlOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Status').then((res) => {
            if (res) {
                setStatusCdOption(res.data)
            }
        })
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [insGrantWayOption, setInsGrantWayOption] = useState<OptionRes[]>([])
    const [insGrantTypeOption, setInsGrantTypeOption] = useState<OptionRes[]>([])
    const [insGrantControlOption, setInsGrantControlOption] = useState<OptionRes[]>([])
    const [statusCdOption, setStatusCdOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();

        var beginGrantOptTime = form.getFieldValue('beginGrantOptTime');
        var endGrantOptTime = form.getFieldValue('endGrantOptTime');

        body = {
            ...body,
            beginGrantOptTime: beginGrantOptTime ? dayjs(beginGrantOptTime).format('YYYY-MM-DD 00:00:00') : null,
            endGrantOptTime: endGrantOptTime ? dayjs(endGrantOptTime).format('YYYY-MM-DD 00:00:00') : null,
        };

        let res = insAdminGrantApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        insAdminGrantApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        // {title: "主键ID", dataIndex: "id", key: "id", ellipsis:true,width:100},
        {title: "接受授权的帐号", dataIndex: "grantToUserId", key: "grantToUserId", ellipsis:true,width:100},
        {title: "发起授权的帐号（即云手机拥有者）", dataIndex: "grantFromUserId", key: "grantFromUserId", ellipsis:true,width:100},
        {title: "授权的云手机ID", dataIndex: "insId", key: "insId", ellipsis:true,width:100},
        {title: "授权方式", dataIndex: "grantWay", key: "grantWay", ellipsis:true,width:100,
            render: (grantWay: number) => {
                const option = insGrantWayOption.find((opt) => opt.value === grantWay.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
        },
        {title: "授权类型", dataIndex: "grantType", key: "grantType", ellipsis:true,width:100,
            render: (grantType: number) => {
                const option = insGrantTypeOption.find((opt) => opt.value === grantType.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
        },
        {title: "授权权限", dataIndex: "grantControl", key: "grantControl", ellipsis:true,width:100,
            render: (grantControl: number) => {
                const option = insGrantControlOption.find((opt) => opt.value === grantControl.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
        },
        {title: "授权操作时间", dataIndex: "grantOptTime", key: "grantOptTime", ellipsis:true,width:100},
        {title: "授权开始时间", dataIndex: "grantStartTime", key: "grantStartTime", ellipsis:true,width:100},
        {title: "授权结束时间", dataIndex: "grantEndTime", key: "grantEndTime", ellipsis:true,width:100},
        {title: "启用状态", dataIndex: "statusCd", key: "statusCd", ellipsis:true,width:100,
            render: (statusCd: String) => {
                const option = statusCdOption.find((opt) => opt.value === statusCd.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
        },
        {title: "备注", dataIndex: "note", key: "note", ellipsis:true,width:100},

    ];

    //获取要查询的表单
    const [form] = Form.useForm();

    function cancel() {
        message.error('取消删除');
    }

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      labelAlign={"left"}
                    // labelCol={{ span: 14 ,offset:14}}
                    // wrapperCol={{ span: 4 }}
                      size={"small"}
                      style={{maxWidth: 3000}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >

                    <Form.Item style={{margin:5}} name="grantToUserId" label="接受授权的帐号">
                        <Input style={{width: 110}} />
                    </Form.Item>
                    <Form.Item style={{margin:5}} name="grantFromUserId" label="发起授权的帐号">
                        <Input style={{width: 110}}/>
                    </Form.Item>
                    <Form.Item style={{margin:5}} name="insId" label="云手机ID">
                        <Input style={{width: 110}}/>
                    </Form.Item>
                    <Form.Item style={{margin:5}} name="grantWay" label="授权方式" >
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={insGrantWayOption}
                        />
                    </Form.Item>
                    <Form.Item style={{margin:5}} name="grantType" label="授权类型">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={insGrantTypeOption}
                        />
                    </Form.Item>
                    <Form.Item style={{margin:5}} name="grantControl" label="授权权限">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={insGrantControlOption}
                        />
                    </Form.Item>
                    {/* 这里的grantOptTimeRange参数实际上服务端接口是不需要的，是为了重置的时候把页面上的日期选择器显示重置掉   */}
                    <Form.Item style={{margin: 5}} label="授权操作时间" name={['grantOptTimeRange', 'dates']}>
                        <RangePicker
                            style={{width: 250}}
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginGrantOptTime", null);
                                    form.setFieldValue("endGrantOptTime", null);
                                } else if (Array.isArray(value)) {
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("beginGrantOptTime", date[0]);
                                        form.setFieldValue("endGrantOptTime", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="statusCd" label="启用状态">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            placeholder="启用状态"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={statusCdOption}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                form.resetFields()
                                handleQuery()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>


            <Divider/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}}
                       dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

        </div>
    )
}


export default OrganContent;