
import {PaginationProps} from "antd";
import {request} from "../utils";
import {baseUrl} from "./types/login";

const loginApi = {
    loginOut:()=>{
        let url = baseUrl+"/logout";
        return request.post(url);

    }


}

export default loginApi




