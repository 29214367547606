import React, {useEffect, useState} from 'react';
import {Col, Form, InputNumber, message, Modal, Row, Select, Space} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {OptionRes} from "../../../../../apis/types/common";
import {TableDataType} from "../../../../../apis/types/mkt/act/goodsLadderPrice/goodsLadderPrice";
import goodsActApi from "../../../../../apis/mkt/act/goods/goodsAct";
import dayjs from "dayjs";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StepPriceFormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: Number;
    goodsActId: Number;
    setPriceList: (list:any) => void;
    setUpPriceList: (list:any) => void;
    priceList: TableDataType[];



}


const StepPriceForm: React.FC<StepPriceFormProps> = ({
                                                         model,
                                                         refresh,
                                                         record,
                                                         open,
                                                         closeOpen,
                                                         goodsActId,
                                                         setPriceList,
                                                         setUpPriceList,
                                                         priceList,

                                                     }) => {


    const [form] = Form.useForm();
    const [id, setId] = useState(0); // 初始化 ID 为 0

    const incrementId = () => {
        setId((prevId) => prevId + 1); // 自增 ID
    };
    // 编辑的时候回显数据
    useEffect(() => {
        if (open && Object.keys(record).length > 0) {
            form.setFieldsValue({
                ...record
            })
        }

    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        incrementId();
        values = {
            ...values,
            "id":id,

        };
        const updatedPriceList = [...priceList, values].sort((a, b) => a.min - b.min);

        setPriceList(updatedPriceList);
        setUpPriceList(updatedPriceList);
        console.log()
        handleCancel();

    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()

    };


    //处理ok按钮，
    function handleOK() {

        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            // 校验 max 必须大于 min
            if (values.min != null && values.max != null && values.max < values.min) {
                message.error("最大值必须大于等于最小值");
                return;  // 直接返回，不继续执行后续逻辑
                        }
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    //处理新增的情况
    function handleUpdate() {
        let values = form.getFieldsValue();
        values = {
            ...values,

        };

        const newList = priceList.filter(item => item.id!==values.id);
        const updatedPriceList = [...newList, values].sort((a, b) => a.min - b.min);

        setPriceList(updatedPriceList);
        setUpPriceList(updatedPriceList);
        handleCancel();

    }


    return (
        <Modal
            title={model == 1 ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
            width={1800}

        >

            <Form
                {...layout}
                form={form}
                name="control-hooks"
            >
                <Form.Item name="id" label="id值" hidden={true}/>

                <Row style={{alignItems: 'center'}}>
                    <Col>
                        购买数量必须大于等于
                    </Col>
                    <Col>
                        <Form.Item name="min" label="" rules={[{required: true, message: "最小值必填"}]}
                                   style={{height: 8}}>

                            <InputNumber min={1} style={{width: 70}}/>
                        </Form.Item>
                    </Col>
                    <Col>
                          且小于
                    </Col>
                    <Col>
                        <Form.Item name="max" label="" rules={[{required: true, message: "最大值必填"}]}
                                   style={{height: 8}}>
                            <InputNumber min={1} max={501}style={{width: 70}}/>
                        </Form.Item>
                    </Col>
                    <Col>
                        台, 每台价格
                    </Col>
                    <Col>
                        <Form.Item name="goodsPrice" label="" rules={[{required: true, message: "价格必填"}]}
                                   style={{height: 8}}>
                            <InputNumber min={1} style={{width: 170}}/>
                        </Form.Item>
                    </Col>
                    <Col>
                        分
                    </Col>
                </Row>
            </Form>


        </Modal>
    );
};

export default StepPriceForm;




