import React, {useEffect, useState} from 'react';
import {Form, Input, InputNumber, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/sys/property";
import propertiesApi from "../../../../apis/sys/properties";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import dictCategoryApi from "../../../../apis/sys/dictCategory";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 16},
};


interface FormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    parentCategoryOption: OptionRes[];
    statusCdOption: OptionRes[];
    paramCategory: string;
}


const PropertyFrom: React.FC<FormProps> = ({
                                               refresh,
                                               record,
                                               open,
                                               closeOpen,
                                               model,
                                               parentCategoryOption,
                                               statusCdOption,
                                               paramCategory,
                                           }) => {
    const [form] = Form.useForm();
    // 下拉列表选项
    const [parentValueOption, setParentValueOption] = useState<OptionRes[]>([])

    // 编辑的时候回显数据
    useEffect(() => {
        if (open && record) {
            form.setFieldsValue({
                ...record,
            })
            let parentCategory = record.parentCategory;
            if (parentCategory != undefined && parentCategory !== "") {
                propertiesApi.getParentValues(parentCategory).then((res) => {
                    setParentValueOption(res.data)
                })
            }
            // 新增时置空
            if (model == 'create') {
                setParentValueOption([])
            }
        }
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        console.log(1)
        form.setFieldValue("paramCategory", paramCategory);
        propertiesApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        propertiesApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            if (model == 'create') {
                //做新增的逻辑
                handleCreate();
            } else {
                //做修改的逻辑
                handleUpdate()
            }
        })
            .catch((error) => {

            });
    }

    function handleParentCategoryChange(parentCategory: string) {
        if (parentCategory == undefined || parentCategory == "") {
            // 设置父参数值控件
            form.setFieldsValue({
                parentValue: null,
            });
            setParentValueOption([])
            return;
        }
        propertiesApi.getParentValues(parentCategory).then((res) => {
            setParentValueOption(res.data)
            // // 设置父参数值控件
            // form.setFieldsValue({
            //     parentValue: res.data[0],
            // });
        })
    }

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}

            >
                <Form.Item name="paramCategory" label="参数类型" hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name="id" label="参数编号" rules={[{required: true, message: "参数编号必填"}]}>
                    {
                        model == 'create'
                            ? <InputNumber min={1}/>
                            : <InputNumber readOnly/>
                    }
                </Form.Item>
                <Form.Item name="paramValue" label="参数值" rules={[{required: true, message: "参数值必填"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="paramDesc" label="参数描述">
                    <Input/>
                </Form.Item>
                <Form.Item name="parentCategory" label="父参数类型">
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={parentCategoryOption}
                        onChange={handleParentCategoryChange} // 添加onChange事件处理函数
                    />
                </Form.Item>
                <Form.Item name="parentValue" label="父参数值">
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={parentValueOption}
                    />
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusCdOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1}/>
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default PropertyFrom;



