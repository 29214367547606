import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/sys/logWebOperation";
import commonApi from "../commonApi";

const logWebOperationApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.userId) {
                url += 'userId=' + encodeURIComponent(body.userId) + "&";
            }
            if (body.menuName) {
                url += 'menuName=' + encodeURIComponent(body.menuName) + "&";
            }
            if (body.operationType) {
                url += 'operationType=' + encodeURIComponent(body.operationType) + "&";
            }
            if (body.operationIp) {
                url += 'operationIp=' + encodeURIComponent(body.operationIp) + "&";
            }
            if (body.operationTimeBegin) {
                url += 'operationTimeBegin=' + encodeURIComponent(body.operationTimeBegin) + "&";
            }
            if (body.operationTimeEnd) {
                url += 'operationTimeEnd=' + encodeURIComponent(body.operationTimeEnd) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    getMenusOptions() {
        let url = baseUrl;
        url += "/menus"
        return request.get<OptionRes[]>(url)
    },
    getOperationTypeOption() {
        let url = baseUrl;
        url += "/types"
        return request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"运营后台操作日志列表",body)
    },
}

export default logWebOperationApi



