import moment from "moment";

const baseUrl = "/sys/staff";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    staffNo:number,
    staffName:string,
    phoneNumber:string
}

//定义表格的属性
export interface TableDataType {
    id: number;
    staffNo: number;
    staffName: string;
    sexTypeDesc: string;
    organName: string;
    registDate: string;
    statusDesc: string;
    notes: string;
}

//定义表格的属性
export interface StaffDate {
    id: number;
    staffNo: number;
    staffName: string;
    phoneNumber: string;
    sexTypeCd: string;
    sexTypeCdDesc: string;
    certTypeCd: string;
    certTypeCdDesc: string;
    certNo: number;
    organId: number;
    organIdDesc: string;
    registDate: string;
    statusCd: string;
    statusCdDesc: string;
    notes: string;
    acctNo: string;
}



export  {baseUrl};

