import {baseUrl, PageParam, TableDataType} from "../types/res/addTimeLog";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const addTimeLogApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "/page?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.instanceCode) {
                url += 'instanceCode=' + encodeURIComponent(body.instanceCode);
            }
            if (body.subscriberCode) {
                url += '&subscriberCode=' + encodeURIComponent(body.subscriberCode);
            }
            if (body.phoneNumber) {
                url += '&phoneNumber=' + encodeURIComponent(body.phoneNumber);
            }
            if (body.beginCreateTime) {
                url += '&beginCreateTime=' + body.beginCreateTime;
            }
            if (body.endCreateTime) {
                url += '&endCreateTime=' + body.endCreateTime;
            }

        }
        return request.get<PageRes<TableDataType>>(url);

    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"加时长日志列表",body)
    },

}

export default addTimeLogApi