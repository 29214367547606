import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType, UserRoleObj} from "../types/sys/userRole";

const userRoleApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.roleId) {
                url += 'roleId=' + body.roleId + "&";
            }
            if (body.userId) {
                url += 'userId=' + body.userId + "&";
            }
            if (body.statusCd) {
                url += 'statusCd=' + body.statusCd + "&";
            }

        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl+"/save", body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询指定角色授权信息
    getById(id: Number) {
        let url = baseUrl + '/id/' + id;
        return request.get<UserRoleObj>(url)
    }

}

export default userRoleApi



