import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam,TableDataType} from "../types/cus/refund";
import commonApi from "../commonApi";

const refundApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.phoneNumber) {
                url += 'phoneNumber=' + encodeURIComponent(body.phoneNumber) + '&';
            }
            if (body.orderId) {
                url += 'orderId=' + encodeURIComponent(body.orderId)+ '&';
            }
            if (body.instanceId) {
                url += 'instanceId=' + encodeURIComponent(body.instanceId)+ '&';
            }
            if (body.orderSourceCd) {
                url += 'orderSourceCd=' + encodeURIComponent(body.orderSourceCd)+ '&';
            }
            if (body.beginCreatTime) {
                url += 'beginCreatTime=' + encodeURIComponent(body.beginCreatTime)+ '&';
            }
            if (body.endCreatTime) {
                url += 'endCreatTime=' + encodeURIComponent(body.endCreatTime)+ '&';
            }
            if (body.auditingCd) {
                url += 'auditingCd=' + encodeURIComponent(body.auditingCd)+ '&';
            }
            if (body.paymentCode) {
                url += 'paymentCode=' + encodeURIComponent(body.paymentCode)+ '&';
            }
            if (body.businessTypeCd) {
                url += 'businessTypeCd=' + encodeURIComponent(body.businessTypeCd)+ '&';
            }

        }
        return request.get<PageRes<TableDataType>>(url);

    },

    //创建退款工单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //审核
    audit(body: any) {
        let url = baseUrl;
        url=url+"/auditing"
        return request.post(url, body)
    },
    //确认退款
    confirm(body: any) {
        let url = baseUrl;
        url= url+"/confirm"
        return request.post(url, body)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"退款/退订订单列表",body)
    },

}

export default refundApi



