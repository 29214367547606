const baseUrl = "/res/instance/phone";
/** 获取产品申请列表-参数 */
export interface PageParam {
    phoneModelId:string,
    phoneImei:string,
    androidId:string,
    serialNo:string,
    wifiMac:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    phoneModelId: string;
    phoneImei: string;
    androidId: string;
    serialNo: string;
    wifiMac: string;
    statusCd: string;
    notes: string;
}



export  {baseUrl};

