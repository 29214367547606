import React, {useEffect, useRef, useState} from 'react';
import {DatePicker, Form, Input, Modal, Select, Space, Radio, Row, Col} from 'antd';
import type { RadioChangeEvent } from 'antd';
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import redeemCodeApi from "../../../../apis/mkt/redeemCode/redeemCodeApi";
import {InputNumber} from "antd/lib";
import dayjs from "dayjs";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface CreateRedeemCodeProps {
    refresh: () => void;
    closeOpen: () => void;
    open: boolean;
    model: String;
    statusOption: OptionRes[];
    redeemTypeOption: OptionRes[];
    redeemStatusOption: OptionRes[];
    productCodeOption: OptionRes[];
}

const CreateRedeemCodeForm: React.FC<CreateRedeemCodeProps> = ({
                                                     refresh,
                                                     open,
                                                     closeOpen,
                                                     model,
                                                     statusOption,
                                                   redeemTypeOption,
                                                   redeemStatusOption,
                                                   productCodeOption
                                                 }) => {
    const [form] = Form.useForm();
    const [durationUnit, setDurationUnit] = useState('days');
    const durationInputRef = useRef(null); // 创建 ref

    // 自定义校验规则
    const validateExpTime = (_, value) => {
        const effTime = form.getFieldValue('effTime'); // 获取开始时间的值
        if (value && effTime && !value.isAfter(effTime)) {
            return Promise.reject('兑换码结束时间必须大于开始时间');
        }
        return Promise.resolve();
    };

    useEffect(() => {
        form.setFieldsValue({
            'durationUnit': durationUnit
        });
        console.log(durationUnit)
    }, [durationUnit, form, open]);

    // 处理时间单位变化
    useEffect(() => {
        const maxDuration = durationUnit === 'days' ? 366 : 24 * 366; // 根据单位设置最大值
        form.setFieldsValue({ 'redeemDuration': Math.min(form.getFieldValue('redeemDuration') || 0, maxDuration) });
    }, [durationUnit, form]);
    const convertToMinutes = (value: number, unit: string): number => {
        switch (unit) {
            case 'days':
                return value * 24 * 60;
            case 'hours':
                return value * 60;
            default:
                return value; // 如果单位不是天或小时，直接返回值
        }
    };

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        console.log(values);

        // 获取用户选择的时间单位
        const unit = form.getFieldValue('durationUnit');
        console.log(unit);
        // 转换兑换时长为分钟
        const redeemDurationInMinutes = convertToMinutes(values.redeemDuration, unit);
        console.log(redeemDurationInMinutes);
        values = {
            ...values,
            // 使用转换后的分钟数
            redeemDuration: redeemDurationInMinutes,
            // 格式化时间为接口需要的格式
            effTime: values.effTime ? dayjs(values.effTime).format('YYYY-MM-DD HH:mm:ss') : null,
            expTime: values.expTime ? dayjs(values.expTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        console.log(values);
        // 校验通过，可以提交数据
        redeemCodeApi.createRedeemCode(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setDurationUnit(e.target.value);
        form.setFieldsValue({
            'durationUnit': durationUnit
        })
        setTimeout(() => {
            if (durationInputRef.current) {
                // @ts-ignore
                durationInputRef.current.focus(); // 聚焦到 InputNumber 组件
            }
        }, 0);
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            handleCreate();
        }).catch((error) => {
            // 校验失败，可以处理错误信息
            console.log('校验失败:', error);
        });
    }

    return (
        <Modal
            title={"生成兑换码"}
            open={open}
            okText={"生成"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >

            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 1000}}
            >

            <Form.Item style={{margin: 5}} name="codeCount" label="生成数量" rules={[{required: true, message: "兑换码生成数量必填"}]}>
                {/*数字类型输入框*/}
                <InputNumber min={1} max={1000} style={{maxWidth: 180}}/>
            </Form.Item>

            <Form.Item style={{margin: 5}} name="redeemCodePrefix" label="兑换码前缀(3位英文)"
                       rules={[{required: true, message: "兑换码前缀必填"}, {pattern: /^[A-Za-z]{3}$/, message: "前缀固定3位长度的英文字母"}]}>
                <Input style={{maxWidth: 180}}/>
            </Form.Item>

            <Form.Item style={{margin: 5}} name="redeemType" label="兑换类型" rules={[{required: true, message: "兑换类型必填"}]}>
                <Select
                    style={{width: 180}}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    options={redeemTypeOption}
                />
            </Form.Item>
            <Form.Item style={{margin: 5}} name="productCode" label="产品等级" rules={[{required: true, message: "产品等级必填"}]}>
                <Select
                    style={{width: 180}}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    options={productCodeOption}
                />
            </Form.Item>

                <Row>
                    <Col span={16}>
                    <Form.Item style={{margin: 5, marginLeft: 83}} labelCol={{ span: 8 }} // 定义标签所占的栅格数
                        wrapperCol={{ span: 16 }} name="redeemDuration" label="兑换时长"
                               rules={[{required: true, message: "兑换时长必填"}]}>
                        <Space>
                            <InputNumber
                                ref={durationInputRef} // 将 ref 绑定到 InputNumber
                                min={1}
                                max={durationUnit === 'days' ? 366 : 24 * 366}
                                style={{maxWidth: 300,width: 140}}
                                onChange={(value) => {
                                    form.setFieldsValue({'redeemDuration': value});
                                }}
                            />
                        </Space>
                    </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{margin: 5}}>
                            <Radio.Group
                                name="durationUnit"
                                value={durationUnit}
                                onChange={onChange}
                                buttonStyle="solid"
                            >
                                <Space>
                                    <Radio value="days" style={{ marginLeft: 0 }}>天</Radio>
                                    <Radio value="hours" style={{width: 70}}>小时</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>


            <Form.Item style={{margin: 5}} name="effTime" label="兑换码开始生效时间" rules={[{required: true, message: "兑换码开始生效时间必填"}]}>
                <DatePicker
                    showTime
                    placeholder={''}
                    style={{width:250}}
                    format="YYYY-MM-DD HH:mm:ss"
                />
            </Form.Item>
            <Form.Item style={{margin: 5}} name="expTime" label="兑换码结束生效时间"
                       rules={[{required: true, message: "兑换码结束生效时间必填"},
                           { validator: validateExpTime}] // 使用自定义校验规则
            }>
                <DatePicker
                    showTime
                    placeholder={''}
                    style={{width:250}}
                    format="YYYY-MM-DD HH:mm:ss"
                />
            </Form.Item>
            <Form.Item style={{margin: 5}} name="channelNote" label="渠道备注" rules={[{required: true, message: "渠道备注必填"}]}>
                <Input/>
            </Form.Item>
            <Form.Item style={{margin: 5}} name="notes" label="备注">
                <Input/>
            </Form.Item>

            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default CreateRedeemCodeForm;



