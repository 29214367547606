import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/pub/channel/channelVersion";
import channelVersionApi from "../../../../apis/pub/channel/channelVersion";
import dayjs from "dayjs";
import Details from "../../public/details/details";


const OrganContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos("DM_Status").then((res) => {
            if (res) {
                setStatusOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Must_Update").then((res) => {
            if (res) {
                setMustUpdateOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Channel_Client_Id").then((res) => {
            if (res) {
                setClientIdOption(res.data)
            }
        })
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
        const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [mustUpdateOption, setMustUpdateOption] = useState<OptionRes[]>([])
    const [clientIdOption, setClientIdOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
		setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = channelVersionApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        channelVersionApi.batchDelete(idList).then((result) => {
            if (result){
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }
    //处理启用
    const handleEnable = () => {
        channelVersionApi.batchEnable(idList).then((result) => {
            if (result){
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const handleDisable = () => {
        channelVersionApi.batchDisable(idList).then((result) => {
            if (result){
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        channelVersionApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     render: (_: any, record: TableDataType) => {
        //         return <Typography.Link onClick={() => {
        //             setOpen(true)
        //             console.log("operation", record)
        //             setRowData(record);
        //         }}>
        //             编辑
        //         </Typography.Link>
        //     },
        //     ellipsis:true,
        //     width:80
        // },
        {
            title: "版本编码",
            dataIndex: "versionCode",
            key: "versionCode",
            ellipsis:true,
            width:100
        },
        {
            title: "版本时间",
            dataIndex: "versionTime",
            key: "versionTime",
            render: (text: string) => {
                // 使用 dayjs 将原始字符串转换为 dayjs 对象
                const versionTimeObj = dayjs(text, "YYYY-MM-DD HH:mm:ss");
                // 展示格式化后的日期字符串
                return <span>{versionTimeObj.format("YYYY-MM-DD")}</span>;
            },
            ellipsis:true,
            width:100
        },
        {
            title: "渠道编码",
            dataIndex: "channelCode",
            key: "channelCode",
            ellipsis:true,
            width:150
        },
        {
            title: "版本描述",
            dataIndex: "versionDesc",
            key: "versionDesc",
            ellipsis:true,
            width:100
        },
        {
            title: "必需更新",
            dataIndex: "versionMust",
            key: "versionMust",
            render: (versionMust: number) => {
                const option = mustUpdateOption.find((opt) => opt.value === versionMust.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
            ellipsis:true,
            width:80
        },
        {
            title: "客户端类型",
            dataIndex: "clientId",
            key: "clientId",
            render: (clientId: number) => {
                const option = clientIdOption.find((opt) => opt.value === clientId.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
            ellipsis:true,
            width:100
        },
        {
            title: "文件大小",
            dataIndex: "fileSize",
            key: "fileSize",
            ellipsis:true,
            width:100
        },
        {
            title: "下载地址",
            dataIndex: "downloadUrl",
            key: "downloadUrl",
            ellipsis:true,
            width:300
        },
        {
            title: "状态",
            dataIndex: "statusCd",
            key: "statusCd",
            render: (statusCd: number) => {
                const option = statusOption.find((opt) => opt.value === statusCd.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
            ellipsis:true,
            width:50
        },
        {
            title: "最近修改人",
            dataIndex: "modifierDesc",
            key: "modifierDesc",
            ellipsis: true,
            width: 100, // 或者根据实际情况调整宽度
        },
        {
            title: "最近修改时间",
            dataIndex: "modifyTime",
            key: "modifyTime",
            // 可以添加日期格式化，如果modify_time是时间戳或Date对象
            render: (modifyTime: string | number | Date) => {
                if (typeof modifyTime === 'number') {
                    // 如果是时间戳，转换成Date对象
                    const date = new Date(modifyTime);
                    return <span>{date.toLocaleString()}</span>;
                } else if (modifyTime instanceof Date) {
                    // 如果已经是Date对象，直接格式化
                    return <span>{modifyTime.toLocaleString()}</span>;
                } else if (typeof modifyTime === 'string') {
                    // 如果是字符串，直接显示
                    return <span>{modifyTime}</span>;
                }
                return <span>无数据</span>;
            },
            ellipsis: true,
            width: 200
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    function confirm() {
        if (idList.length === 0) {
            message.error('删除失败，请选择需要删除的数据');
        } else {
            message.success('确认删除');
            handleDelete();
        }
    }

    function cancel() {
        message.error('取消删除');
    }

    function enableconfirm() {
        if (idList.length === 0) {
            message.error('启用失败，请选择需要启用的数据');
        } else {
            message.success('确认启用');
            handleEnable();
        }
    }

    function enablecancel() {
        message.error('取消启用');
    }

    function disableconfirm() {
        if (idList.length === 0) {
            message.error('禁用失败，请选择需要禁用的数据');
        } else {
            message.success('确认禁用');
            handleDisable();
        }
    }

    function disablecancel() {
        message.error('取消禁用');
    }
    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>

                <Popconfirm
                    title="是否确认启用所选项？"
                    onConfirm={enableconfirm}
                    onCancel={enablecancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button
                        type="primary" // 或者保持"type='primary'"，根据你的设计偏好
                        style={{
                            backgroundColor: '#5cb85c', // 绿色
                            marginRight: '25px', //右间距
                            color: 'white', // 确保文字颜色与背景色对比良好
                            border: 'none', // 移除默认的边框，使按钮看起来更整洁
                        }}
                    >
                        启用
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="是否确认禁用所选项？"
                    onConfirm={disableconfirm}
                    onCancel={disablecancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        禁用
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item style={{margin: 5}} name="channelCode" label="渠道编码">
                        <Input/>
                    </Form.Item>
                    
                    <Form.Item style={{margin: 5}} name="versionCode" label="版本编码">
                        <Input/>
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="versionMust" label="必需更新">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={mustUpdateOption}
                        ></Select>
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="clientId" label="客户端类型">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={clientIdOption}
                        ></Select>
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="statusCd" label="状态">
                        <Select
                            style={{width: 150}}
                            showSearch
                            
                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            options={statusOption}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={()=>{
                                form.resetFields()
                                handleQuery()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>


            <Divider/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                   <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default OrganContent;