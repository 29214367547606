const baseUrl = "/opt/msg/recipient";
/** 获取产品申请列表-参数 */
export interface PageParam {
    msgId:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    msgId: number;
    subscriberId: number;
    subscriberIdDesc: string;
    userId: number;
    userIdDesc: string;
    readMark: string;
    readMarkDesc: string;
    resInstanceId: number;
    resInstanceIp:String;
    resInstanceCode:String;
}



export  {baseUrl};

