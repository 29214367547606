import React, {useEffect, useState} from 'react';
import {Modal, Select} from 'antd';

import {ColumnsType} from "antd/es/table";

const {Option} = Select;

// const layout = {
//     labelCol: {span: 8},
//     wrapperCol: {span: 16},
// };


interface StaffFromProps {
    closeOpen: () => void;
    record: {};
    open: boolean;
    columns: ColumnsType<any>
}

const OrderForm: React.FC<StaffFromProps> = ({
                                                 record,
                                                 open,
                                                 closeOpen,
                                                 columns
                                             }) => {

    const columList = () => {
        const single = {}
        for (let column of columns) {
            let title = column.title;
            let key11 = column.key;
            single[key11] = title;
        }
        return single;
    }

    let [keys, setKeys] = useState<string[]>([]);
    let [columnList, setColumnList] = useState<{}>([]);

    useEffect(() => {
        setColumnList(columList());
        setKeys(Object.keys(record));
    }, [open])

    //处理取消方法
    const handleCancel = () => {
        closeOpen()
    };

    return (
        <Modal
            title="项目详情"
            open={open}
            width={500}
            onCancel={handleCancel}
            footer={null}
        >
            <div style={{maxWidth: '100%', overflow: 'auto'}}>
                {
                    keys.map(key => {
                        if (columnList[key]) {
                            return (
                                <div key={key} style={{height: 'auto'}}>
                                    <div style={{display: "flex", alignItems: "flex-start"}}>
                                        <div style={{textAlign: "right", display: "inline-block", width: 100}}>
                                            <strong>{columnList[key]}:</strong>
                                        </div>
                                        <div style={{display: "inline-block", marginLeft: 10, maxWidth: 300}}>
                                            <div className="multiline-text"
                                               style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
                                                {record[key]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })
                }
            </div>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default OrderForm;



