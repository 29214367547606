import {baseUrl, PageParam, TableDataType} from "../types/sys/dictCategory";
import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const dictCategoryApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.categoryName) {
                url += 'categoryName=' + encodeURIComponent(body.categoryName);
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    // 查询有效的参数类型
    listValidCategories() {
        const data = request.get(baseUrl + "/options").then((result) => {
            return result;
        })
        return data;
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"字典列表",body)
    },

}

export default dictCategoryApi



