import {baseUrl, PageParam, TableDataType} from "../types/pdt/product";
import {request} from "../../utils";
import {CascaderOptionRes, OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const productApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.productName) {
                url += 'productName=' + encodeURIComponent(body.productName);
            }
            if (body.productTypeCd) {
                url += '&productTypeCd=' + encodeURIComponent(body.productTypeCd);
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    options(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },

    //查询下拉列表数据
    getProductCodeOptions(){
        let url=baseUrl;
        url+="/getProductCodeOptions"
        return   request.get<OptionRes[]>(url)
    },

    //查询下拉列表数据
    listProductGoodsOption(){
        let url=baseUrl;
        url+="/goods/options"
        return   request.get<CascaderOptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"产品列表",body)
    },

}

export default productApi



