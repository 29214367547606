import React, {useEffect, useState} from 'react';
import {DatePicker, Form, Input, message, Modal, Select} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import dayjs from "dayjs";
import {TableDataType} from "../../../../apis/types/sys/userRole";
import userRoleApi from "../../../../apis/sys/userRole";
import propertiesApi from "../../../../apis/sys/properties";
import roleApi from "../../../../apis/sys/role";
import userApi from "../../../../apis/sys/user";
import TextArea from "antd/es/input/TextArea";

const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    staffId: number;
    userRoleOpen: boolean;
}

const UserRoleForm: React.FC<StaffFromProps> = ({
                                                    refresh,
                                                    userRoleOpen,
                                                    closeOpen,
                                                    staffId,
                                                }) => {
    // 下拉框内容
    // const [record, setRecord] = useState<TableDataType>({} as TableDataType)
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [roleNameOption, setRoleNameOption] = useState<OptionRes[]>([])

    const [form] = Form.useForm();
    useEffect(() => {
        form.resetFields();
        if (staffId) {
            handleQuery();
        }
        propertiesApi.optinos('DM_Status').then((res) => {
            if(res){

            setStatusOption(res.data)
            }
        })
        roleApi.optinos().then((res) => {
            if(res){

            setRoleNameOption(res.data)
            }
        })
    }, [userRoleOpen,staffId]);

    //页面初始数据查询
    function handleQuery() {
        userRoleApi.getById(staffId).then((reset) => {
            if (reset.data) {
                form.setFieldsValue({
                    effTime: reset.data.effTime ? dayjs(reset.data.effTime) : null,
                    expTime: reset.data.expTime ? dayjs(reset.data.expTime) : null,
                    notes: reset.data.notes,
                    statusCd: reset.data.statusCd,
                    staffId: staffId,
                    roleIds: reset.data.roleIds
                })
            }
        })
    }



    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        values = {
            ...values,
            expTime: values.expTime ? dayjs(values.expTime).format('YYYY-MM-DD HH:mm:ss') : null,
            effTime: values.effTime ? dayjs(values.effTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        let valueJSOn = JSON.stringify(values);
        userRoleApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            form.setFieldValue("staffId",staffId);
            const values = form.getFieldsValue();
            // 做新增的逻辑
            handleCreate();
        }).catch((error) => {

        });
    }

    function handleChange() {
        console.log(form.getFieldsValue())
    }

    return (
        <Modal
            title="新增项目"
            open={userRoleOpen}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >

                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>

                <Form.Item name="staffId" label="用户" hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name="roleIds" label="角色名称" rules={[{required: true, message: "角色名称不能为空"}]}>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{width: 150}}
                        
                        onChange={handleChange}
                        options={roleNameOption}
                    />
                </Form.Item>
                <Form.Item name="effTime" label="生效时间" rules={[{required: true, message: "生效时间不能为空"}]}>
                    <DatePicker showTime placeholder={''}/>
                </Form.Item>
                <Form.Item name="expTime" label="失效时间" rules={[{required: true, message: "失效时间不能为空"}]}>
                    <DatePicker showTime placeholder={''}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                    <Select
                        style={{width: 150}}
                        showSearch
                        
                        optionFilterProp="children"
                        allowClear

                        filterOption={filterOption}
                        options={statusOption}
                    />

                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} />
                </Form.Item>


            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default UserRoleForm;



