import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, RoleAuthByIdObj, TableDataType} from "../types/sys/roleAuth";

const roleAuthApi = {
    
    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.roleId) {
                url += 'roleId=' + body.roleId+"&";
            }
            if (body.actionId) {
                url += 'actionId=' + body.actionId+"&";
            }

        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        let  url=baseUrl+"/roleAuths"
        return request.post(url, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询指定角色授权信息
    getById(id: Number) {
        let url = baseUrl + '/id/' + id;
        return request.get<RoleAuthByIdObj>(url)
    }

}

export default roleAuthApi



