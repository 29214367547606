const baseUrl = "/sub/instance/group";
/** 获取列表-参数 */
export interface PageParam {
    groupName: string;
    subUserId: number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    groupName: string;
    subUserId: number;
    statusDesc: string;
    notes: string;
}

export  {baseUrl};