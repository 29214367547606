const baseUrl = "/sys/action";
/** 获取列表-参数 */
export interface PageParam {
    accessResource: string;
    accessMethod: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    accessResource: string;
    accessMethod: string;
    actionDesc: string;
    permitAll: string;
    permitAllDesc: string;
    resourceType: string;
    resourceTypeDesc: string;
    parentId: number;
    parentIdDesc: string;
    statusCd: string;
    notes: string;
}



export  {baseUrl};


