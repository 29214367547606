import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input, message,
    Pagination,
    PaginationProps,
    Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {OptionRes} from "../../../../apis/types/common";
import InstancePhoneFrom from "./instancePhoneFrom";
import {TableDataType} from "../../../../apis/types/res/instancePhone";
import instancePhoneApi from "../../../../apis/res/instancePhone";
import propertiesApi from "../../../../apis/sys/properties";
import phoneModelApi from "../../../../apis/res/phoneModel";
import instanceApi from "../../../../apis/res/instance";
import Details from "../../public/details/details";


const InstancePhone = () => {

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //进入页面先查询数据
    useEffect(() => {
        getDictList()
    }, []);


    async function getDictList() {
        phoneModelApi.optinos().then((res) => {
            if (res) {

                setPhoneModelIdOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Status').then((res) => {
            if (res) {

                setStatusCds(res.data)
            }
        })
    }

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
        const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusCds, setStatusCds] = useState<OptionRes[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = instancePhoneApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        instancePhoneApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setRowData(record);
                }}>
                    编辑
                </Typography.Link>
            },
        },
        {
            title: '手机型号',
            dataIndex: 'phoneModelIdDesc',
            key: 'phoneModelIdDesc',
        },
        {
            title: '手机IMEI',
            dataIndex: 'phoneImei',
            key: 'phoneImei',
        },
        {
            title: 'Android ID',
            dataIndex: 'androidId',
            key: 'androidId',
        },
        {
            title: '序列号',
            dataIndex: 'serialNo',
            key: 'serialNo',
        },
        {
            title: 'WIFI MAC',
            dataIndex: 'wifiMac',
            key: 'wifiMac',
        },
        {
            title: '状态',
            dataIndex: 'statusCdDesc',
            key: 'statusCdDesc',
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[])
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    const [phoneModelIdOption, setPhoneModelIdOption] = useState<OptionRes[]>([]);
    const [phoneModelId, setPhoneModelId] = useState(null);

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*<Button type="primary" ghost onClick={() => {*/}
                {/*    setOpen(true)*/}
                {/*}} style={{marginRight: '25px'}}>*/}
                {/*    新增*/}
                {/*</Button>*/}
                {/*<Popconfirm*/}
                {/*    title="是否确认删除所选项？"*/}
                {/*    onConfirm={() => {*/}
                {/*        if (idList.length === 0) {*/}
                {/*            message.error('删除失败，请选择需要删除的数据');*/}
                {/*        } else {*/}
                {/*            handleDelete();*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onCancel={() => {*/}
                {/*        message.error('取消删除');*/}
                {/*    }}*/}
                {/*    okText="是"*/}
                {/*    cancelText="否"*/}
                {/*>*/}
                {/*    <Button type="primary" danger style={{marginRight: '25px'}}>*/}
                {/*        删除*/}
                {/*    </Button>*/}
                {/*</Popconfirm>*/}


                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="phoneImei" label="手机IMEI">
                        <Input />
                    </Form.Item>
                    <Form.Item name="androidId" label="Android ID">
                        <Input />
                    </Form.Item>
                    <Form.Item name="serialNo" label="序列号">
                        <Input />
                    </Form.Item>
                    <Form.Item name="wifiMac" label="WIFI MAC">
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={()=>{
                                const body={};
                                instancePhoneApi.pageData(body,pagination).then((res)=>{
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                        setIdList([]);
                                    }
                                })
                            }}>重置</Button>                        </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                   <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            {/*<InstancePhoneFrom*/}
            {/*    refresh={() => {*/}
            {/*        //设置表单不展示*/}
            {/*        setOpen(false);*/}
            {/*        //重新调用查询的接口*/}
            {/*        handleQuery();*/}
            {/*    }}*/}
            {/*    record={rowData}*/}
            {/*    open={open}*/}
            {/*    closeOpen={() => {*/}
            {/*        setOpen(false)*/}
            {/*    }}*/}
            {/* statusCds={statusCds}*/}
            {/* phoneModelIdOption={phoneModelIdOption}*/}
            {/*></InstancePhoneFrom>*/}

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default InstancePhone;