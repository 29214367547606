const baseUrl = "/ord/order";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    paymentCode: string;
    id:number,
    orderTypeCd:string,
    businessTypeCd:string,
    createTimeBegin:string,
    createTimeEnd:string,
    finishTimeBegin:string,
    finishTimeEnd:string,
    phoneNumber:string,
    orderStatusCd:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    orderTypeCdDesc: string;
    orderTypeCd: string;
    subscriberIdDesc: string;
    subUserIdDesc: string;
    subUserId: number;
    channelIdDesc: string;
    businessTypeCd: string;
    businessTypeCdDesc: string;
    orderSourceCdDesc: string;
    sourceIp: string;
    orderQuantity: number;
    orderAmount: number;
    activityGoodsIdDesc: string;
    discountIdDesc: string;
    discountAmount: number;
    paymentAmount: number;
    createTime: string;
    finishTime: string;
    orderSnapshot: number;
    ownerTypeCdDesc: string;
    ownerObjectIdDesc: string;
    payStatusCdDesc: string;
    orderStatusCdDesc: string;
    orderStatusCd: string;
    orderCode: string;
    goodsIdDesc: string;
    operatorIdDesc: string;
    payTime: string;
    allocateTime: string;
    cancelTime: string;
    discardTime: string;
    notes: string;
    paymentSerial: string;
    paymentCode: string;
    payModeId:number;
    payMode:string;
}



export  {baseUrl};

