import {request} from "../../../utils";
import {OptionRes, PageRes} from "../../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../../types/pub/channel/channelVersion";
import {domain} from "../../../components/constantConfig";
import commonApi from "../../commonApi";

const channelVersionApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        let queryParams = new URLSearchParams();

        // 添加分页参数
        queryParams.set('current', pagination.current.toString());
        queryParams.set('size', pagination.pageSize.toString());

        // 添加 body 中不为空的字段
        for (const key in body) {
            if (body.hasOwnProperty(key) && body[key] !== null && body[key] !== undefined && body[key] !== '') {
                queryParams.set(key, encodeURIComponent(body[key].toString()));
            }
        }

        // 拼接查询参数到 URL
        url += '?' + queryParams.toString();

        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },

    batchEnable(uidList: Number[]) {
        let url = baseUrl;
        url += "/enable";
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.post(url);
    },

    batchDisable(uidList: Number[]) {
        let url = baseUrl;
        url += "/disable";
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.post(url);
    },

    // 渠道包上传
    getUploadUrl(){
        return domain + baseUrl + "/pc/uploadFileRequest";
    },

    // pc渠道分包
    subPCPackageByParentVersionId(values : any){
        let url = baseUrl +  "/pc/subPackage";
        // 构造要发送的数据
        const formData = {
            ...values, // 如果还有其他字段，这将包含它们
            // 你可以在这里添加或修改任何需要发送到服务器的数据
        };
        return request.post(url, formData)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"版本渠道包列表",body)
    },
}

export default channelVersionApi;



