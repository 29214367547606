import React, {useEffect} from 'react';
import {Form, Input, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/cus/refund";
import TextArea from "antd/es/input/TextArea";
import refundApi from "../../../../apis/cus/refund";
import {OptionRes} from "../../../../apis/types/common";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 16},
};


interface FormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;

}


const AudingForm: React.FC<FormProps> = ({
                                             refresh,
                                             closeOpen,
                                             record,
                                             open
                                             ,
                                         }) => {
    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        console.log(record)
        if (open && record) {
            form.setFieldsValue({
                ...record
            })
        }

    }, [open]);
    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };


    return (
        <Modal
            title={"审核"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={() => {
                let value = form.getFieldsValue();
                value = {auditingNotes: value.auditingNotes, auditingCode: value.auditingCode, id: value.id}
                refundApi.audit(value).then((result) => {
                    if (result) {
                        closeOpen();
                        refresh();
                    }
                })
            }}
            width={2000}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}

            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="orderId" label="订单编号">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="instanceId" label="云手机编号">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="paymentCode" label="支付流水号">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="businessTypeDesc" label="业务类型">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>


                        <Form.Item name="orderSourceDesc" label="订单来源">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="auditingDesc" label="审核状态">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="refundAmount" label="退款金额">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="notes" label="备注">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="refundTypeDesc" label="退款方式">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="auditingNotes" label="审核备注">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="refundCdDesc" label="退款状态">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="subscriberId" label="会员编号">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                </div>

                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>


                        <Form.Item name="subscriberCode" label="会员编码">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>


                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="phoneNumber" label="会员手机号">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="createTime" label="创建时间">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="beginTime" label="受理时间">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="finishTime" label="完成时间">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>

                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="orderQuantity" label="订阅数量">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                </div>


                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>
                        <Form.Item name="allocateQuantity" label="分配的数量">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="unAllocateQuantity" label="未分配数量">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="orderAmount" label="订单金额">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="paymentAmount" label="支付金额">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="goodsId" label="商品id">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="salePrice" label="销售价">
                            <Input style={{width: 200}} disabled={true}/>
                        </Form.Item>
                    </div>
                </div>
                <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>
                        <Form.Item name="auditingCode" label="审核结果"
                                   rules={[{required: true, message: "退款方式必填"}]}
                                   style={{marginLeft: 30, marginTop: 20}}>
                            <Select
                                allowClear
                                style={{width: 200}}
                                optionFilterProp="children"
                                filterOption={filterOption}
                                options={[
                                    {label: '通过', value: '1'},
                                    {label: '驳回', value: '0'}
                                ]}
                            />
                        </Form.Item>
                    </div>
                    <div style={{flexBasis: '15%', padding: '0 8px'}}>

                        <Form.Item name="auditingNotes" label="备注" style={{marginLeft: 30, marginTop: 20}}>
                            <TextArea rows={4} style={{width: 300}}/>
                        </Form.Item>
                    </div>
                </div>

            </Form>
        </Modal>
    );
};


export default AudingForm;



