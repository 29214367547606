import {PaginationProps} from "antd";
import {request} from "../../utils";
import {PageRes} from "../types/common";
import {baseUrl, TableDataType} from "../types/app/info";

const infoApi = {
    pageData(appCatalogId: number, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (appCatalogId) {
            if (appCatalogId) {
                url += 'appCatalogId=' + encodeURIComponent(appCatalogId) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl+"/save", body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/update/' + id;
        return request.put(url, body)
    }

}

export default infoApi;



