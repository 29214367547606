import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/sys/organ";
import commonApi from "../commonApi";

const organApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.organName) {
                url += 'organName=' + encodeURIComponent(body.organName) + "&";
            }
            if (body.organTypeCd) {
                url += 'organTypeCd=' + body.organTypeCd + "&";
            }

        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询列表
    optinos() {
        let url = baseUrl;
        url += "/options?organType=O01"
        return request.get<OptionRes[]>(url)
    },
    allOptinos() {
        let url = baseUrl;
        url += "/all/options"
        return request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"组织列表",body)
    },
}

export default organApi



