import {request} from "../../../utils";
import {OptionRes, PageRes} from "../../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../../types/pub/channel/channelParentVersion";
import dayjs from "dayjs";
import {domain} from "../../../components/constantConfig";
import commonApi from "../../commonApi";

const channelParentVersionApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        let queryParams = new URLSearchParams();

        // 添加分页参数
        queryParams.set('current', pagination.current.toString());
        queryParams.set('size', pagination.pageSize.toString());

        // 添加 body 中不为空的字段
        for (const key in body) {
            if (body.hasOwnProperty(key) && body[key] !== null && body[key] !== undefined && body[key] !== '') {
                queryParams.set(key, encodeURIComponent(body[key].toString()));
            }
        }

        // 拼接查询参数到 URL
        url += '?' + queryParams.toString();

        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        body = {
            ...body,
            versionTime: body.versionTime ? dayjs(body.versionTime).format('YYYY-MM-DD 00:00:00') : null,
        };
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        body = {
            ...body,
            versionTime: body.versionTime ? dayjs(body.versionTime).format('YYYY-MM-DD 00:00:00') : null,
        };
        return request.put(url, body)
    },
    //查询下拉列表数据
    optinos(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },
    getUploadUrl(){
        return domain + baseUrl + "/uploadFileRequest";
    },

    subPackageByParentVersionId(values : any){
        let url = baseUrl + "/" +  "subPackage";
        // 构造要发送的数据
        const formData = {
            ...values, // 如果还有其他字段，这将包含它们
            // 你可以在这里添加或修改任何需要发送到服务器的数据
        };
        return request.post(url, formData)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"版本母包列表",body)
    },
}

export default channelParentVersionApi;



