import {baseUrl, PageParam, TableDataType} from "../types/res/fileService";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import commonApi from "../commonApi";

const fileServiceApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.serviceName) {
                url += 'serviceName=' + encodeURIComponent(body.serviceName);
            }
            if (body.serviceTypeCd) {
                url += '&serviceTypeCd=' + body.serviceTypeCd;
            }
            if (body.idcId) {
                url += '&idcId=' + body.idcId;
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    optinos(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"文件服务列表",body)
    },

}

export default fileServiceApi



