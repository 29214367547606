import {request} from "../../utils";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/res/InsTask";
import commonApi from "../commonApi";
import {PageRes} from "../types/common";

function isValidTimeFormat(value) {
    // Assuming a simple check for the 'YYYY-MM-DD HH:mm:ss' format
    const timeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    return timeRegex.test(value);
}


const insTaskApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        let queryParams = new URLSearchParams();

        // 添加分页参数
        queryParams.set('current', pagination.current.toString());
        queryParams.set('size', pagination.pageSize.toString());

        // 添加 body 中不为空的字段
        console.log("body:" + body)
        for (const key in body) {
            console.log("key:" +  key + ":" + body[key])
            if (body.hasOwnProperty(key) && body[key] !== null && body[key] !== undefined && body[key] !== '') {
                let valueToEncode = body[key];

                // Check if the value is not in 'YYYY-MM-DD HH:mm:ss' time format
                if (!isValidTimeFormat(valueToEncode)) {
                    valueToEncode = encodeURIComponent(valueToEncode.toString());
                }

                queryParams.set(key, valueToEncode);
            }
        }

        // 拼接查询参数到 URL
        url += '?' + queryParams.toString();

        return request.get<PageRes<TableDataType>>(url);

    },

    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"云手机任务列表",body)
    },

}

export default insTaskApi;



