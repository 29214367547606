import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input, message,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import Details from "../../public/details/details";
import AppProductConfigForm from "./appProductConfigForm";
import {TableDataType} from "../../../../apis/types/app/productConfig";
import productConfigApi from "../../../../apis/app/productConfig";
import {OptionRes} from "../../../../apis/types/common";
import productApi from "../../../../apis/pdt/product";


const AppProductConfigContent = () => {

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //进入页面先查询数据
    useEffect(() => {
        getDictList();
    }, []);


    async function getDictList() {
        productApi.options().then((res) => {
            if (res) {
                setProductOptions(res.data)
            }
        })
    }

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [rowData1, setRowData1] = useState<TableDataType>({} as TableDataType)
    const [idcType, setAppProductPaperContentType] = useState<boolean>(false)
    const [productOptions, setProductOptions] = useState<OptionRes[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);


    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = productConfigApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        productConfigApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setRowData(record);
                    setAppProductPaperContentType(false);
                }}>
                    编辑
                </Typography.Link>
            },
            ellipsis: true,
            width: 80
        },
        {
            title: '产品名称',
            dataIndex: 'productIdDesc',
            key: 'productIdDesc',
            ellipsis: true,
            width: 150
        },
        {
            title: '壁纸名称',
            dataIndex: 'wallpaperName',
            key: 'wallpaperName',
            ellipsis: true,
            width: 150
        },
        {
            title: '下载地址',
            dataIndex: 'wallpaperDownloadUrl',
            key: 'wallpaperDownloadUrl',
            ellipsis: true,
            width: 150
        },
        {
            title: '创建人',
            dataIndex: 'creatorDesc',
            key: 'creatorDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '修改人',
            dataIndex: 'modifierDesc',
            key: 'modifierDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '是否启用',
            dataIndex: 'enableStatusDesc',
            key: 'enableStatusDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
            width: 100
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            ellipsis: true,
            width: 100
        },
    ];

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                {/*<Button type="primary" ghost onClick={() => {*/}
                {/*    setOpen(true)*/}
                {/*    setAppProductPaperContentType(true);*/}
                {/*    setRowData({} as TableDataType)*/}
                {/*}} style={{marginRight: '25px'}}>*/}
                {/*    新增*/}
                {/*</Button>*/}
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="wallpaperName" label="壁纸名称">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="wallpaperDownloadUrl" label="下载地址">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="productId" label="产品">
                        <Select
                            allowClear={true}
                            style={{width: 120}}
                            options={productOptions}
                        />
                    </Form.Item>
                    <Form.Item name="enableStatus" label="是否启用">
                        <Select
                            allowClear={true}
                            style={{width: 120}}
                            options={[
                                {label: '启用', value: '1'},
                                {label: '禁用', value: '0'}
                            ]}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">查询</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={() => {
                            const body = {};
                            productConfigApi.pageData(body, pagination).then((res) => {
                                if (res) {
                                    setList(res.data.records)
                                    setTotal(res.data.total)
                                    setSelectedRowKeys([]);
                                    setIdList([]);
                                }
                            })
                        }}>重置</Button>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                setDetailsOpen(true);
                                setRowData1(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}}
                    dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            <AppProductConfigForm
                refresh={() => {
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                type={idcType}
                productOptions={productOptions}
            ></AppProductConfigForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData1}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default AppProductConfigContent;