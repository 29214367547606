const baseUrl = "/res/gateway";
/** 获取列表-参数 */
export interface PageParam {
    gatewayName: string;
    gatewayTypeCd: string;
    idcId:number;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    idcName: string;
    gatewayName: string;
    gatewayCode: string;
    gatewayTypeCdDesc: string;
    gatewayDomain: string;
    ctccDomain: string;
    cmccDomain: string;
    cuccDomain: string;
    controlStartPort: number;
    controlEndPort: number;
    controlNextPort: number;
    streamStartPort: number;
    streamEndPort: number;
    streamNextPort: number;
    statusDesc: string;
    notes: string;
    createTime:string;
    modifyTime:string;
}

export  {baseUrl};