import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/ord/subsOrder";

const subsOrderApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.orderId) {
                url += 'orderId=' + encodeURIComponent(body.orderId) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
}

export default subsOrderApi;



