import React, {useEffect, useState} from "react";
import {Button, ConfigProvider, DatePicker, Divider, Form, message, Popconfirm, Table, Typography} from 'antd';
import {ColumnsType} from "antd/es/table";
import {TableDataType} from "../../../../../apis/types/mkt/act/goodsLadderPrice/goodsLadderPrice";
import Details from "../../redeemCode/details";
import StepPriceForm from "./stepPriceForm";
import actApi from "../../../../../apis/mkt/act/act";

const {RangePicker} = DatePicker;

interface StepPriceContentProps {
    actId: Number,
    goodsActId: number,
    setPriceList: (list: any) => void;
    setUpPriceList: (list: any) => void;
    priceList: TableDataType[]
    stepFormopen: boolean;

}

const StepPriceContent: React.FC<StepPriceContentProps> = ({
                                                               actId,
                                                               goodsActId,
                                                               setPriceList,
                                                               setUpPriceList,
                                                               priceList,
                                                               stepFormopen

                                                           }) => {


    //定义用到的useState，用来绑定数据
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [model, setModel] = useState(0)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);


    //进入页面先查询数据
    useEffect(() => {
        console.log("actIdxxx111", actId)
        console.log("priceList11",priceList)
    }, [stepFormopen]);



    //处理删除
    const handleDelete = () => {

        // 要替换成在表格内添加数据
        const newList = priceList.filter(item => !selectedRowKeys.includes(item.id));
        //设置用来展示在列表中的数据
        setPriceList([...newList])
        setUpPriceList([...newList])


    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    console.log("operation", record)
                    setRowData(record);
                    setModel(1)
                }}>
                    编辑
                </Typography.Link>
            },
        },
        {
            title: '组合支付条件',
            dataIndex: 'priceList',
            key: 'priceList',
            render: (_: any, record: TableDataType) => {
                // 使用 record 中的 min, max, goodsPrice 字段进行展示
                return (
                    <div>
                        {`购买数量必须大于等于 ${record.min} 且小于 ${record.max}，每台价格 ${record.goodsPrice} 分`}
                    </div>
                );
            },
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);

        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setModel(1)
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={() => {
                        if (idList.length === 0) {
                            message.error('删除失败，请选择需要删除的数据');
                        } else {
                            handleDelete();
                        }
                    }}
                    onCancel={() => {
                        message.error('取消删除');
                    }}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>

            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection, "selectedRowKeys": selectedRowKeys,
                }} dataSource={priceList} columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>

            <StepPriceForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    console.log("dddd",open)
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                model={model}
                goodsActId={goodsActId}
                setPriceList={setPriceList}
                setUpPriceList={setUpPriceList}
                priceList={priceList}
            ></StepPriceForm>



        </div>
    )
}


export default StepPriceContent;