import React, {useEffect, useState} from 'react';
import {Button, Form, Image, Input, message, Modal, Select, Upload} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {AttributeTableDataType} from "../../../../../apis/types/pdt/goodsAttribute";
import {OptionRes} from "../../../../../apis/types/common";
import goodsAttributeApi from "../../../../../apis/pdt/goodsAttribute";
import {filterOption} from "../../../../../apis/common";
import {UploadOutlined} from "@ant-design/icons";
import {UploadProps} from "antd/es/upload";
import {getToken} from "../../../../../utils";
import {UploadFile} from "antd/es/upload/interface";
import TextArea from "antd/es/input/TextArea";
import {domain, imgIp} from "../../../../../components/constantConfig";
// moment，并设置本地化语言为中文
moment.locale('zh-cn');


const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 16 },
};


interface FormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: AttributeTableDataType;
    open: boolean;
    model: String;
    goodsId: number;
    // option
    attributeTypeCdOption: OptionRes[];
    statusCdOption: OptionRes[];
}


const GoodsAttributeFrom: React.FC<FormProps> = ({ refresh, record, open, closeOpen,model,
                                                       goodsId, attributeTypeCdOption, statusCdOption }) => {
    const [form] = Form.useForm();

    const [oldIconUrl, setOldIconUrl] = useState(record.iconUrl);
    const [urlMap, setUrlMap] = useState<Map<string, string>>(new Map());
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [iconUrl, setIconUrl] = useState(record.iconUrl)

    // 编辑的时候回显数据
    useEffect(() => {
        if (open&&record) {
            form.setFieldsValue({
                ...record,
            })
            console.log("GoodsAttributeFrom goodsId: " , record)
        }
    }, [open]);

    useEffect(() => {
        console.log("Attribute Id:  " + record.id)
        // setFileList([]);
        setIconUrl(record.iconUrl)
        setOldIconUrl(record.iconUrl)
    }, [record.id]); // 当 record 变化时触发

    //处理新增方法
    const handleCreate = () => {
        const formData = form.getFieldsValue();
        formData.goodsId = goodsId; // 添加 goodsId 键值对
        formData.iconUrl = "iconUrl";
        goodsAttributeApi.create(formData).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                message.success("新增成功");
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
        setIconUrl("")
        setFileList([]);
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        values.goodsId = goodsId; // 添加 productId 键值对
        values.goodsAttributeId = values.id; // 添加 productId 键值对
        values.iconUrl = iconUrl;
        let valueJSOn = JSON.stringify(values);
        goodsAttributeApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                message.success("修改成功");
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    // 产品规格图标上传
    const props: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain+'/pub/picture/upload',
        headers: {
            authorization: getToken(),
            // 'Content-Type': 'multipart/form-data',
        },
        // className:"upload-list-inline",
        // listType:"picture-card",

        fileList: fileList,
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            console.log("status,", status)

            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,",urlRes)
                    setIconUrl(urlRes)
                    urlMap.set(file.uid, urlRes);
                    setUrlMap(new Map(urlMap));
                    setFileList([...fileList,file])
                    message.success(`${info.file.name} 文件上传成功 .`);
                }else{
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
            setFileList([...fileList])
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            console.log("remove file", file.uid)
            setIconUrl(oldIconUrl)
        },

    };

    return (
        <Modal
            title={model == 'create' ? "新增商品规格" : "编辑商品规格"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}
                style={{ maxWidth: 300 }}
            >
                <Form.Item name="id" label="商品规格编号" hidden={true}>
                </Form.Item>
                <Form.Item name="attributeId" label="规格编号" hidden={true}>
                </Form.Item>
                <Form.Item name="attributeName" label="规格名称" rules={[{ required: true , message: "规格名称必填"},{ max: 32, message: '规格名称长度不能大于32'}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="attributeCode" label="规格编码" rules={[{ required: true , message: "规格编码必填"},{ max: 32, message: '规格编码长度不能大于32'}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="attributeTypeCd" label="规格类型" rules={[{ required: true , message: "规格类型必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={attributeTypeCdOption}
                    />
                </Form.Item>
                <Form.Item name="iconUrl" label="规格图标" hidden={model == 'create'}>
                    <Image src={imgIp+iconUrl} width={100}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{ required: true , message: "状态必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusCdOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="iconUrlUplod" label="规格图标上传">
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>点击上传</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default GoodsAttributeFrom;



