const baseUrl = "/sub/login";
/** 获取列表-参数 */
export interface PageParam {
    subscriberCode: string;
    phoneNumber: string;
    beginLoginTime: string;
    endLoginTime: string;
    beginLogoutTime: string;
    endLogoutTime: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    subscriberId: string;
    subUserId: string;
    subscriberCode: string;
    phoneNumber: string;
    loginModeCd: string;
    loginModeDesc: string;
    loginMethodCd: string;
    loginMethodCdDesc: string;
    loginIp: string;
    loginMac: string;
    loginImei: string;
    loginTime: string;
    logoutTime: string;
}

export  {baseUrl};