import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/pub/channel/channelParentVersion";
import ChannelParentVersionFrom from "./channelParentVersionFrom";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";
import dayjs from "dayjs";
import Details from "../../public/details/details";
import ChannelSubPackageFrom from "./channelSubPackageFrom";
import ChannelSubPCPackageFrom from "./channelSubPCPackageFrom";
import channelApi from "../../../../apis/pub/channel";


const OrganContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos("DM_Status").then((res) => {
            if (res) {
                setStatusOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Must_Update").then((res) => {
            if (res) {
                setMustUpdateOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Channel_Client_Id").then((res) => {
            if (res) {
                setClientIdOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Yes_Or_No").then((res) => {
            if (res) {
                setYesOrNoOption(res.data)
            }
        })

    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [subPackageFormOpen, setSubPackageFormOpen] = useState(false)
    const [subPCPackageFormOpen, setSubPCPackageFormOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [type, setType] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [mustUpdateOption, setMustUpdateOption] = useState<OptionRes[]>([])
    const [clientIdOption, setClientIdOption] = useState<OptionRes[]>([])
    const [yesOrNoOption, setYesOrNoOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = channelParentVersionApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        channelParentVersionApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        channelParentVersionApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setRowData(record);
                    setType(false)
                }}>
                    编辑
                </Typography.Link>
            },
            ellipsis:true,
            width: 50
        },
        {
            title: "版本编码",
            dataIndex: "versionCode",
            key: "versionCode",
            ellipsis:true,
            width: 150
        },
        {
            title: "版本时间",
            dataIndex: "versionTime",
            key: "versionTime",
            render: (text: string) => {
                // 使用 dayjs 将原始字符串转换为 dayjs 对象
                const versionTimeObj = dayjs(text, "YYYY-MM-DD HH:mm:ss");
                // 展示格式化后的日期字符串
                return <span>{versionTimeObj.format("YYYY-MM-DD")}</span>;
            },
            ellipsis:true,
            width: 150
        },
        {
            title: "版本描述",
            dataIndex: "versionDesc",
            key: "versionDesc",
            ellipsis:true,
            width: 150
        },
        {
            title: "必需更新",
            dataIndex: "versionMust",
            key: "versionMust",
            render: (versionMust: number) => {
                const option = mustUpdateOption.find((opt) => opt.value === versionMust.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
            ellipsis:true,
            width: 100
        },
        {
            title: "客户端类型",
            dataIndex: "clientId",
            key: "clientId",
            render: (clientId: number) => {
                const option = clientIdOption.find((opt) => opt.value === clientId.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
            ellipsis:true,
            width: 100
        },
        {
            title: "文件大小",
            dataIndex: "fileSize",
            key: "fileSize",
            ellipsis:true,
            width: 100
        },
        {
            title: "下载地址",
            dataIndex: "downloadUrl",
            key: "downloadUrl",
            ellipsis:true,
            width: 400
        },
        {
            title: "状态",
            dataIndex: "statusCd",
            key: "statusCd",
            render: (statusCd: number) => {
                const option = statusOption.find((opt) => opt.value === statusCd.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
            ellipsis:true,
            width: 150
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    function confirm() {
        if (idList.length === 0) {
            message.error('删除失败，请选择需要删除的数据');
        } else {
            handleDelete();
        }
    }

    function cancel() {
        message.error('取消删除');
    }
    function subPackageConfirm() {
        if (idList.length != 1) {
            message.error('分包失败，每次能且仅能选择一条母包数据进行分包');
        } else {
            setSubPackageFormOpen(true);
        }
    }

    function subPCPackageConfirm() {
        if (idList.length != 1) {
            message.error('PC渠道包上传，每次能且仅能选择一条母包数据进行上传');
        } else {
            setSubPCPackageFormOpen(true);
        }
    }

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setType(true)
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>

                <Button type="primary" ghost onClick={() => {
                    subPackageConfirm()
                }} style={{marginRight: '25px'}}>
                    安卓渠道分包
                </Button>

                <Button type="primary" ghost onClick={() => {
                    subPCPackageConfirm()
                }} style={{marginRight: '25px'}}>
                    PC渠道包上传
                </Button>

                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="versionCode" label="版本编码">
                        <Input/>
                    </Form.Item>

                    <Form.Item name="versionMust" label="必需更新">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={mustUpdateOption}
                        ></Select>
                    </Form.Item>

                    <Form.Item name="clientId" label="客户端类型">
                        <Select
                            style={{width: 150}}
                            allowClear
                            showSearch
                            
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={clientIdOption}
                        ></Select>
                    </Form.Item>

                    <Form.Item name="statusCd" label="状态">
                        <Select
                            style={{width: 150}}
                            showSearch
                            
                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            options={statusOption}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>

                            <Button htmlType="reset" onClick={()=>{
                                form.resetFields()
                                handleQuery()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>


            <Divider/>


            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                   <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}}
                       rowSelection={{
                           ...rowSelection,"selectedRowKeys":selectedRowKeys,
                       }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


            {/*新增和编辑的form表单，子组件*/}
            <ChannelParentVersionFrom
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                statusOption={statusOption}
                mustUpdateOption={mustUpdateOption}
                clientIdOption={clientIdOption}
                type={type}></ChannelParentVersionFrom>

            {/* 渠道分包子表单组件 */}
            <ChannelSubPackageFrom
                refresh={() => {
                    //设置表单不展示
                    setSubPackageFormOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                id={idList[0]}
                subPackageFormOpen={subPackageFormOpen}
                closeOpen={() => {
                    setSubPackageFormOpen(false)
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                yesOrNoOption = {yesOrNoOption}
            ></ChannelSubPackageFrom>

            {/* 渠道分包子表单组件 */}
            <ChannelSubPCPackageFrom
                refresh={() => {
                    //设置表单不展示
                    setSubPackageFormOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                id={idList[0]}
                record={rowData}
                subPCPackageFormOpen={subPCPackageFormOpen}
                closeOpen={() => {
                    setSubPCPackageFormOpen(false)
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
            ></ChannelSubPCPackageFrom>


            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default OrganContent;