import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Divider, Form, Input, message, Modal, Row, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/res/supplier";
import supplierApi from "../../../../apis/res/supplier";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import CredentialsFrom from "../credentials/CredentialsFrom";
import IdcConfigFrom from "../idcConfig/idcConfigForm";
import dayjs from "dayjs";
import credentialsApi from "../../../../apis/res/credentials";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    type: boolean;
}


const SupplierFrom: React.FC<StaffFromProps> = ({
                                                    refresh,
                                                    record,
                                                    open,
                                                    closeOpen,
                                                    type,
                                                }) => {

    const [credentialsGradeCds, setCredentialsGradeCds] = useState<OptionRes[]>([]);
    const [credentialsTypeCds, setCredentialsTypeCds] = useState<OptionRes[]>([]);
    const [formCreate] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const [supplierTypeCds, setsupplierTypeCds] = useState<OptionRes[]>([]);
    // const [supplierTypeCd, setsupplierTypeCd] = useState(null);
    const [statusCds, setStatusCds] = useState<OptionRes[]>([]);
    // const [statusCd, setStatusCd] = useState(null);

    // 编辑的时候回显数据
    useEffect(() => {
        if (record) {
            // 在组件挂载时，设置表单数据为record的值
            console.log(record)
            formCreate.setFieldsValue({
                ...record,
                expDate: dayjs(record.expDate),
                credentialsGradeCd: record.credentialsGradeCd,
                credentialsTypeCd: record.credentialsTypeCd,
                creNotes: record.creNotes,
                creStatusCd: record.creStatusCd,
            });
            formUpdate.setFieldsValue({
                ...record,
                expDate: dayjs(record.expDate),
                credentialsGradeCd: record.credentialsGradeCd,
                credentialsTypeCd: record.credentialsTypeCd,
                creNotes: record.creNotes,
                creStatusCd: record.creStatusCd,
            });
        }
        getDictList();
    }, [record]);

    async function getDictList() {
        propertiesApi.optinos('DM_Supplier_Type').then((res) => {
            setsupplierTypeCds(res.data)
        })
        propertiesApi.optinos('DM_Status').then((res) => {
            setStatusCds(res.data)
        })
        propertiesApi.optinos('DM_Credentials_Type').then((res) => {
            if (res) {
                setCredentialsTypeCds(res.data)
            }
        })
        propertiesApi.optinos('DM_Credentials_Grade').then((res) => {
            if (res) {
                setCredentialsGradeCds(res.data)
            }
        })
    }

    //处理新增方法
    const handleCreate = () => {
        supplierApi.create(formCreate.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        formCreate.resetFields();
        formUpdate.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = formUpdate.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        supplierApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    // function handleOK() {
    //     form.validateFields().then(() => {
    //         const values = form.getFieldsValue();
    //         if (values.id == null) {
    //             // 做新增的逻辑
    //             handleCreate();
    //         } else {
    //             // 做修改的逻辑
    //             handleUpdate();
    //         }
    //         message.success("提交成功");
    //     })
    //         .catch((error) => {
    //             message.error("提交失败");
    //
    //         });
    // }

    const onEffTimeOk = (date: any | null) => {
        const currentValues = formUpdate.getFieldsValue(); // 获取当前表单字段的值
        const updatedValues = {
            ...currentValues,
            cExpDate: date ? dayjs(date).format('YYYY-MM-DD') : null,
        };

        formUpdate.setFieldsValue(updatedValues); // 更新表单字段的值
    };

    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            footer={null}
            onCancel={handleCancel}
            width={1200}
        >
            <Form
                hidden={!type}
                {...layout}
                form={formCreate}
                name="control-hooks"
                onFinish={handleCreate}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={8}>
                        <Form.Item name="supplierName" label="供应商名称"
                                   rules={[{required: true, message: "供应商名称不能为空"}, {max: 128, message: "供应商名称长度不能超过128位"}]}>
                            <Input width={256}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="abbrName" label="简称"
                                   rules={[{required: true, message: "简称不能为空"}, {max: 64, message: "简称长度不能超过64位"}]}>
                            <Input width={256}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="supplierTypeCd" label="供应商类型"
                                   rules={[{required: true, message: "供应商类型不能为空"}]}>
                            <Select
                                allowClear={true}
                                style={{width: 256}}
                                options={supplierTypeCds}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                            <Select
                                allowClear={true}
                                style={{width: 256}}
                                options={statusCds}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="notes" label="备注" rules={[{max: 255, message: "备注长度不能超过255位"}]}>
                            <TextArea rows={1}/>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{textAlign: "center"}}>
                    <Form.Item style={{display: "inline-block"}}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </div>
            </Form>

            <Form
                hidden={type}
                {...layout}
                form={formUpdate}
                name="control-hooks"
                onFinish={handleUpdate}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Row>
                    <Col span={8}>
                        <Form.Item name="supplierName" label="供应商名称"
                                   rules={[{required: true, message: "供应商名称不能为空"}, {max: 128, message: "供应商名称长度不能超过128位"}]}>
                            <Input width={256}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="abbrName" label="简称"
                                   rules={[{required: true, message: "简称不能为空"}, {max: 64, message: "简称长度不能超过64位"}]}>
                            <Input width={256}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="supplierTypeCd" label="供应商类型"
                                   rules={[{required: true, message: "供应商类型不能为空"}]}>
                            <Select
                                allowClear={true}
                                style={{width: 256}}
                                options={supplierTypeCds}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                            <Select
                                allowClear={true}
                                style={{width: 256}}
                                options={statusCds}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="notes" label="备注" rules={[{max: 255, message: "备注长度不能超过255位"}]}>
                            <TextArea rows={1}/>
                        </Form.Item>
                    </Col>
                </Row>
                <div hidden={type}>
                    <div>
                        <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

                        <h3>资质信息</h3>
                        <Row>
                            <Col span={8}>
                                <Form.Item name="credentialsTypeCd" label="资质类型"
                                           rules={[{required: true, message: "资质类型不能为空"}]}>
                                    <Select
                                        allowClear={true}
                                        style={{width: 256}}
                                        placeholder='机房类型'
                                        options={credentialsTypeCds}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="credentialsGradeCd" label="资质等级"
                                           rules={[{required: true, message: "资质等级不能为空"}]}>
                                    <Select
                                        allowClear={true}
                                        style={{width: 256}}
                                        placeholder='资质等级'
                                        options={credentialsGradeCds}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="expDate" label="资质到期日期"
                                           rules={[{required: true, message: "资质到期日期不能为空"}]}>
                                    <DatePicker style={{width: 256}} onOk={date => onEffTimeOk(date)} placeholder={''}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item name="creStatusCd" label="状态"
                                           rules={[{required: true, message: "状态不能为空"}]}>
                                    <Select
                                        allowClear={true}
                                        style={{width: 256}}
                                        placeholder='状态'
                                        options={statusCds}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="creNotes" label="备注"
                                           rules={[{max: 255, message: "备注长度不能超过255位"}]}>
                                    <Input width={256}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style={{textAlign: "center"}}>
                    <Form.Item style={{display: "inline-block"}}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </div>
            </Form>


            {/*<div hidden={type}>*/}
            {/*    <div>*/}
            {/*                    <Divider style={{height:1,marginTop:10,marginBottom:0}} />*/}

            {/*        <h3>资质信息</h3>*/}
            {/*        <CredentialsFrom*/}
            {/*            refresh={() => {*/}
            {/*                refresh()*/}
            {/*            }}*/}
            {/*            supplierId={record.id}*/}
            {/*        ></CredentialsFrom>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default SupplierFrom;



