import {baseUrl, PageParam, TableDataType} from "../types/res/instance";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";

const instanceReplaceApi = {
    pageData(body: any, pagination: PaginationProps) {
        let url = "/res/replace";
        url += "/faultWork/searchInstance?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.faultWorkOrderId) {
                url += 'faultWorkOrderId=' + body.faultWorkOrderId;
            }
            if (body.idcId) {
                url += '&idcId=' + body.idcId;
            }
            if (body.beginInstanceIp) {
                url += '&beginInstanceIp=' + encodeURIComponent(body.beginInstanceIp);
            }
            if (body.endInstanceIp) {
                url += '&endInstanceIp=' + encodeURIComponent(body.endInstanceIp);
            }

        }
        return request.get<PageRes<TableDataType>>(url);

    },

    create(body: any) {
        let url = "/res/replace";
        url += "/faultWork/create"
        return request.post(url, body)
    },

}



export default instanceReplaceApi