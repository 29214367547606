import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form, Image,
    Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm, Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import Details from "../../public/details/details";
import catalogInfoApi from "../../../../apis/app/catalogInfo";
import {TableDataType} from "../../../../apis/types/app/catalogInfo";
import CatalogInfoForm from "./catalogInfoForm";
import {imgIp} from "../../../../components/constantConfig";


const CatalogInfoContent = () => {

    const [messageApi, contextHolder] = message.useMessage();

    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [type, setType] = useState<boolean>(false);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = catalogInfoApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        catalogInfoApi.batchDelete(idList).then((result) => {
            if (result) {
                messageApi.open({
                    type: 'success',
                    content: '删除成功',
                    className: 'custom-class',
                    style: {
                        margin: "auto",
                        top: "40vh",
                        position: "relative"
                    },
                });
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    //处理禁用
    const handleForbidden = () => {
        catalogInfoApi.batchForbidden(idList).then((result) => {
            if (result) {
                messageApi.open({
                    type: 'success',
                    content: '禁用成功',
                    className: 'custom-class',
                    style: {
                        margin: "auto",
                        top: "40vh",
                        position: "relative"
                    },
                });
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }


    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => (
                <div>
                    <Typography.Link onClick={() => {
                        setOpen(true)
                        setRowData(record);
                        setType(false);
                    }}>
                        编辑
                    </Typography.Link>
                    {/*&nbsp;&nbsp;&nbsp;&nbsp;*/}
                    {/*<Typography.Link onClick={() => {*/}
                    {/*}}>*/}
                    {/*    {record.status == 0 ? "禁用" : "启用"}*/}
                    {/*</Typography.Link>*/}
                </div>
            ),
            width: 50
        },
        {
            title: '应用名称',
            dataIndex: 'appName',
            key: 'appName',
            ellipsis: true,
            width: 100
        },
        {
            title: '图标地址',
            dataIndex: 'iconFile',
            key: 'iconFile',
            render: (iconFile: string) => {
                const url = imgIp + iconFile;
                return <Image src={url} width={100}/>
            },
            ellipsis: true,
            width: 100
        },
        {
            title: '软件类型',
            dataIndex: 'softTypeDesc',
            key: 'softTypeDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '删除状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '启用状态',
            dataIndex: 'enableStatusDesc',
            key: 'enableStatusDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '创建人',
            dataIndex: 'creater',
            key: 'creater',
            ellipsis: true,
            width: 100
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
            width: 150
        },
        {
            title: '修改人',
            dataIndex: 'modifier',
            key: 'modifier',
            ellipsis: true,
            width: 100
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            ellipsis: true,
            width: 150
        },
        {
            title: '排序',
            dataIndex: 'reorder',
            key: 'reorder',
            ellipsis: true,
            width: 50
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
            width: 100
        },
    ];


    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.appCatalogId));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //获取要查询的表单
    const [form] = Form.useForm();


    function confirm() {
        if (idList.length === 0) {
            messageApi.open({
                type: 'error',
                content: '删除失败，请选择需要删除的数据',
                className: 'custom-class',
                style: {
                    margin: "auto",
                    top: "40vh",
                    position: "relative"
                },
            });
        } else {
            handleDelete();
        }
    }

    function forbidden() {
        if (idList.length === 0) {
            messageApi.open({
                type: 'error',
                content: '禁用失败，请选择需要禁用的数据',
                className: 'custom-class',
                style: {
                    margin: "auto",
                    top: "40vh",
                    position: "relative"
                },
            });
        } else {
            handleForbidden();
        }
    }
    function confirmDerive() {
        handleAppDerive();
    }

    //处理删除
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        catalogInfoApi.handleAppDerive(body);
    }

    function cancel() {
        messageApi.open({
            type: 'error',
            content: '取消删除',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    function cancel1() {
        messageApi.open({
            type: 'error',
            content: '取消禁用',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    return (

        <div>
            {contextHolder}
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Button type="primary" ghost onClick={() => {
                    setOpen(true)
                    setType(true);
                }} style={{marginRight: '25px'}}>
                    新增
                </Button>
                <Popconfirm
                    title="是否确认删除所选项？"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        删除
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="是否确认禁用所选项及其子项吗？"
                    onConfirm={forbidden}
                    onCancel={cancel1}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        禁用
                    </Button>
                </Popconfirm>


                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="appName" label="应用名称">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="softType" label="软件类型">
                        <Select
                            style={{width: 120}}
                            allowClear
                            options={[{value: '1', label: '应用'}, {value: '2', label: '游戏'}]}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                catalogInfoApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                    }
                                })
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.appCatalogId} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            {/*新增和编辑的form表单，子组件*/}
            <CatalogInfoForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)

                }}
                type={type}
            ></CatalogInfoForm>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>
        </div>
    )
}


export default CatalogInfoContent;