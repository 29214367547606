const baseUrl = "/pdt/upgrade/goods";

/** 获取产品申请列表-参数 */
export interface PageParam {
    upgradeModeCd: string,
    goodsName: string,
    sourceProductName:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    goodsIdDesc: string;
    sourceProductIdDesc: string;
    sourcePriceIdDesc: string;
    upgradeModeCdDesc: string;
    statusCdDesc: string;
    notes: string;
}


export {baseUrl};

