const baseUrl = "/mkt/redeemCode";
/** 获取产品申请列表-参数 */
export interface PageParam  {

}

//定义表格的属性
export interface TableDataType {

}

export  {baseUrl};

