const baseUrl = "/cus/refund";
export interface PageParam {
    phoneNumber:string,
    orderSourceCd:string,
    auditingCd:string,
    paymentCode:string,
    businessTypeCd:string,
    orderId:number,
    instanceId:number,
    beginCreatTime:string,
    endCreatTime:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    workOrderId: number;
    subscriberId: number;
    goodsId: number;
    title: string;
    orderId: number;
    instanceId: number;
    paymentCode: number;
    businessTypeDesc: string;
    orderSourceDesc: string;
    auditingDesc: string;
    refundAmount: number;
    refundTypeDesc: string;
    auditingNotes: string;
    refundCdDesc: string;
    subscriberCode: string;
    phoneNumber: string;
    createTime: string;
    finishTime: string;
    beginTime: string;
    orderQuantity: number;
    allocateQuantity: number;
    unAllocateQuantity: number;
    orderAmount: number;
    discountAmount: number;
    paymentAmount: number;
    salePrice: number;
    notes: string;
}



export  {baseUrl};

