import {request} from "../../utils";
import {baseUrl, PricePageParam, PriceTableDataType} from "../types/pdt/goodsPrice";
import {OptionRes, PageRes} from "../types/common";

const goodsPriceApi = {

    pageData(id:number) {
        let url = baseUrl;
        url += "?"
        url += "id=" + id;
        return request.get<PageRes<PriceTableDataType>>(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl+"/save", body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/update/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    options(sourceProductId:number){
        let url=baseUrl;
        url+="/options?productId="+sourceProductId
        return   request.get<OptionRes[]>(url)
    },
}

export default goodsPriceApi



