// 封装基于ls存取删三个方法

const USERINFOKEY = 'user_key'

function setUserInfo (token) {
  return localStorage.setItem(USERINFOKEY, token)
}

function getUserInfo () {
  return localStorage.getItem(USERINFOKEY)
}

function removeUserInfo () {
  return localStorage.removeItem(USERINFOKEY)
}

export {
  setUserInfo,
  getUserInfo,
  removeUserInfo
}