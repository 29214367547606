const baseUrl = "/opt/list";
/** 获取产品申请列表-参数 */
export interface PageParam {
    smsTypeCd:string,
    smsSourceCd:string,
    smsPlatformId:number,
    batchSmsId:number,
    smsTemplateId:number,
    phoneNumber:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    smsTypeCdDesc: string;
    smsSourceCdDesc: string;
    sourceIp: string;
    batchSmsIdDesc: number;
    smsPlatformIdDesc: number;
    smsTemplateIdDesc: number;
    phoneNumber: string;
    subscriberIdDesc: number;
    smsContent: string;
    priorityLevel: string;
    repeatCount: string;
    sendInterval: string;
    sentCount: string;
    createTime: string;
    sendTime: string;
    returnResult: string;
    returnCode: string;
    statusCdDesc: string;
    notes: string;
    msgId: string;
}



export  {baseUrl};

