import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/res/host";
import commonApi from "../commonApi";

const hostApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl+"/page";
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.hostCode) {
                url += 'hostCode=' + encodeURIComponent(body.hostCode)+"&";
            }
            if (body.serverCode) {
                url += 'serverCode=' + body.serverCode+"&";
            }
            if (body.statusCd) {
                url += 'statusCd=' + body.statusCd+"&";
            }
            if (body.releasedStatus) {
                url += 'releasedStatus=' + body.releasedStatus+"&";
            }
            if (body.idcId) {
                url += 'idcId=' + body.idcId+"&";
            }
            if (body.beginHostIp) {
                url += 'beginHostIp=' + body.beginHostIp+"&";
            }
            if (body.endHostIp) {
                url += 'endHostIp=' + body.endHostIp+"&";
            }
        }
        return  request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {

        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    batchCreate(body: any) {
        return request.post(baseUrl+"/batch/save", body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    optinos(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },
    //重启宿主机
    rebootHost(idList: Number[]) {
        let url = baseUrl+"/rebootHost";
        if (idList) {
            url += "?"
            const params = [...idList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.get(url);
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"宿主机列表",body)
    },

    //允许释放
    enableRelease(body: any) {
        let url = baseUrl + "/release";
        return request.post(url, body)
    },

    //允许释放
    disableRelease(body: any) {
        let url = baseUrl + "/lock";
        return request.post(url, body)
    },

}

export default hostApi



