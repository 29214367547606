const envConfigs = {
    local: {
        domain: 'http://localhost:8080/cpWeb',
        imgIp: 'http://localhost/'
    },
    zhangpai: {
        domain: 'http://192.168.168.114/cpWeb',
        imgIp: 'http://192.168.168.114/'
    },
    test: {
        domain: 'https://testcgwdebug.ntimespace.com/cpWeb',
        imgIp: 'https://testcgwdebug.ntimespace.com/'
    },
    stress: {
        domain: 'http://teststressdebug.ntimespace.com/cpWeb',
        imgIp: 'http://teststressdebug.ntimespace.com/'
    },
    pre: {
        domain: 'http://sandboxcgw.ntimespace.com/cpWeb',
        imgIp: 'http://sandboxcgw.ntimespace.com/'
    },
    zhangpai: {
        domain: 'https://bsmpapi.palmderive.com/cpWeb',
        imgIp: 'https://bsmpapi.palmderive.com/'
    },
    kuajing: {
        domain: 'https://bsmpapi.palmpai.com/cpWeb',
        imgIp: 'https://bsmpapi.palmpai.com/'
    },
    jizhi: {
        domain: 'https://bsmpapi.jzyhui.com/cpWeb',
        imgIp: 'https://bsmpapi.jzyhui.com/'
    },
    tianxinyun: {
        domain: 'https://bsmpapi.tianxinserver.com/cpWeb',
        imgIp: 'https://bsmpapi.tianxinserver.com/'
    }
};

// const envName = "local";
const envName = "zhangpai";
// const envName = "test";
// const envName = "stress";
// const envName = "pre";
// const envName = "zhangpai";
// const envName = "kuajing";
// const envName = "jizhi";
// const envName = "tianxinyun";

const { domain, imgIp } = envConfigs[envName] || envConfigs.local;

export { domain, imgIp };