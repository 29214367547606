import {baseUrl, PageParam, TableDataType} from "../../types/mkt/coupon/coupon";
import {PaginationProps} from "antd";
import {request} from "../../../utils";
import {PageRes} from "../../types/common";
import commonApi from "../../commonApi";


const couponApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "/page?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.couponType) {
                url += 'couponType=' + encodeURIComponent(body.couponType) + "&";

            }
            if (body.enableStatus) {
                url += 'enableStatus=' + encodeURIComponent(body.enableStatus) + "&";
            }
            if (body.softDel) {
                url += 'softDel=' + encodeURIComponent(body.softDel) + "&";

            }
            if (body.startCreateTime) {
                url += 'startCreateTime=' + encodeURIComponent(body.startCreateTime) + "&";

            }
            if (body.endCreateTime) {
                url += 'endCreateTime=' + encodeURIComponent(body.endCreateTime) + "&";
            }
            if (body.creator) {
                url += 'creator=' + encodeURIComponent(body.creator) + "&";
            }
            if (body.batchNo) {
                url += 'batchNo=' + encodeURIComponent(body.batchNo) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },

    //绑定优惠券
    bindingCoupon(body: any) {
        let url = baseUrl + '/binding';
        return request.post(url, body)
    },

    // //更新数据
    // update(id: Number, body: any) {
    //     let url = baseUrl + '/' + id;
    //     return request.put(url, body)
    // }

    //批量启用
    batchEnable(body: any) {
        let url = baseUrl + '/enable';
        return request.post(url, body);
    },

    //批量禁用
    batchDisable(body: any) {
        let url = baseUrl + '/disable';
        return request.post(url, body);
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"优惠券列表",body)
    },



}

export default couponApi




