import React, {useEffect} from 'react';
import {Form, Input, InputNumber, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/res/gateway";
import gatewayApi from "../../../../apis/res/gateway";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import {domainRegex} from "../../../common/const";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 16},
};


interface FromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    // option
    idcIdOption: OptionRes[];
    gatewayTypeCdOption: OptionRes[];
    statusCdOption: OptionRes[];
}


const GatewayFrom: React.FC<FromProps> = ({
                                              refresh, record, open, closeOpen, model,
                                              idcIdOption, gatewayTypeCdOption, statusCdOption
                                          }) => {
    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        if (open && record) {
            form.setFieldsValue({
                ...record,
            })
        }
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        gatewayApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        gatewayApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name="id" label="网关编号" hidden={true}>
                </Form.Item>
                <Form.Item name="idcId" label="所在机房" rules={[{required: true, message: "所在机房必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={idcIdOption}
                    />
                </Form.Item>
                <Form.Item name="gatewayName" label="网关名称"
                           rules={[{required: true, message: "网关名称必填"}, {max: 64, message: '网关名称长度不能大于64'}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="gatewayCode" label="网关编码"
                           rules={[{required: true, message: "网关编码必填"}, {max: 32, message: '网关编码长度不能大于32'}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="gatewayTypeCd" label="网关类型" rules={[{required: true, message: "网关类型必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={gatewayTypeCdOption}
                    />
                </Form.Item>
                <Form.Item name="gatewayDomain" label="默认域名"
                           rules={[{required: true, message: "默认域名必填"}, {max: 64, message: '默认域名长度不能大于64'}, {
                               pattern: domainRegex,
                               message: '请输入有效的域名',
                           }]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="ctccDomain" label="电信线路域名"
                           rules={[{required: true, message: "电信线路域名必填"}, {max: 64, message: '电信线路域名长度不能大于64'}, {
                               pattern: domainRegex,
                               message: '请输入有效的域名',
                           }]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="cmccDomain" label="移动线路域名"
                           rules={[{required: true, message: "移动线路域名必填"}, {max: 64, message: '移动线路域名长度不能大于64'}, {
                               pattern: domainRegex,
                               message: '请输入有效的域名',
                           },]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="cuccDomain" label="联通线路域名"
                           rules={[{required: true, message: "联通线路域名必填"}, {max: 64, message: '联通线路域名长度不能大于64'}, {
                               pattern: domainRegex,
                               message: '请输入有效的域名',
                           }]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="controlStartPort" label="控制起始端口号"
                           rules={[{required: true, message: "起始端口号必填"}, {type: 'number', min: 0, max: 2000000000, message: '起始端口号在0-2000000000之间'}]}>
                    <InputNumber style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="controlEndPort" label="控制结束端口号"
                           rules={[{required: true, message: "结束端口号必填"}, {type: 'number', min: 0, max: 2000000000, message: '结束端口号在0-2000000000之间'}]}>
                    <InputNumber style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="streamStartPort" label="推流起始端口号"
                           rules={[{required: true, message: "起始端口号必填"}, {type: 'number', min: 0, max: 2000000000, message: '起始端口号在0-2000000000之间'}]}>
                    <InputNumber style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="streamEndPort" label="推流结束端口号"
                           rules={[{required: true, message: "结束端口号必填"}, {type: 'number', min: 0, max: 2000000000, message: '结束端口号在0-2000000000之间'}]}>
                    <InputNumber style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusCdOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default GatewayFrom;