const baseUrl = "/pub/picture";

/** 获取列表-参数 */
export interface PageParam {
    pictureTypeCd: string;
    statusCd: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    pictureTypeDesc: string;
    pictureUrl: string;
    pictureTag: string;
    statusDesc: string;
    notes: string;
}

export {baseUrl};
