import React, {useEffect} from 'react';
import {DatePicker, Form, Input, InputNumber, Modal, Select} from 'antd';

import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import dayjs from "dayjs";
import {TableDataType} from "../../../../apis/types/sub/fileUpload";
import fileUploadApi from "../../../../apis/sub/fileUpload";

const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    statusOption: OptionRes[];
    instanceIdOption: OptionRes[];
    acctNoOption: OptionRes[];
    subscriberIdOption: OptionRes[];
    fileServiceIdOption: OptionRes[];
}

const FileUploadForm: React.FC<StaffFromProps> = ({
                                                      refresh,
                                                      record,
                                                      open,
                                                      closeOpen,
                                                      statusOption,
                                                      instanceIdOption,
                                                      acctNoOption,
                                                      subscriberIdOption,
                                                      fileServiceIdOption
                                                  }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (open && record) {
            form.setFieldsValue({
                ...record,
                startTime: dayjs(record.startTime),
                endTime: dayjs(record.endTime),
            })
        }

    }, [open]);
    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        values = {
            ...values,
            startTime: values.startTime ? dayjs(values.startTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endTime: values.endTime ? dayjs(values.endTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        let valueJSOn = JSON.stringify(values);
        fileUploadApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        values = {
            ...values,
            startTime: values.startTime ? dayjs(values.startTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endTime: values.endTime ? dayjs(values.endTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        let valueJSOn = JSON.stringify(values);
        fileUploadApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }


    return (
        <Modal
            title="新增项目"
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="subscriberId" label="会员" rules={[{required: true, message: "会员不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={subscriberIdOption}
                    />
                </Form.Item>
                <Form.Item name="subUserId" label="账户名称" rules={[{required: true, message: "账户名称不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={acctNoOption}
                    />
                </Form.Item>
                <Form.Item name="instanceId" label="云手机" rules={[{required: true, message: "云手机不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={instanceIdOption}
                    />
                </Form.Item>
                <Form.Item name="fileServiceId" label="文件服务器"
                           rules={[{required: true, message: "文件服务器不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={fileServiceIdOption}
                    />
                </Form.Item>
                <Form.Item name="fileName" label="文件名" rules={[{required: true, message: "文件名不能为空"},{max:255,message:"长度不可超过255位"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="filePath" label="文件路径" rules={[{required: true, message: "文件路径不能为空"},{max:255,message:"长度不可超过255位"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="fileSize" label="文件大小" rules={[{required: true, message: "文件大小不能为空"}]}>
                    <InputNumber style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="fingerPrint" label="哈希指纹" rules={[{max:255,message:"长度不可超过255位"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="startTime" label="开始时间" rules={[{required: true, message: "开始时间不能为空"}]}>
                    <DatePicker showTime style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="endTime" label="结束时间" rules={[{required: true, message: "结束时间不能为空"}]}>
                    <DatePicker showTime style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default FileUploadForm;