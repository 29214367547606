const baseUrl = "/ord/payment";

/** 获取产品申请列表-参数 */
export interface PageParam  {
    orderId:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    paymentCode: string;
    orderId: number;
    payModeIdDesc: string;
    paymentSerial: string;
    paymentAccount: string;
    paymentAmount: number;
    paymentTime: string;
    createTime: string;
    paymentResult: string;
    resultCode: string;
    statusCdDesc: string;
    notes: string;
}



export  {baseUrl};

