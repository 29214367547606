import React, {useEffect, useState} from "react";
import {
    Button, Col, ConfigProvider, DatePicker,
    Divider,
    Form, Input,
    message,
    Pagination,
    PaginationProps,
    Popconfirm, Row,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {OptionRes} from "../../../../apis/types/common";
import addTimeLogApi from "../../../../apis/res/addTimeLog";
import {TableDataType} from "../../../../apis/types/res/addTimeLog";
import dayjs from "dayjs";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";


const {RangePicker} = DatePicker;
const AddTimeLogContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [instanceIdOption, setInstanceIdOption] = useState<OptionRes[]>([])
    const [acctNoOption, setAcctNoOption] = useState<OptionRes[]>([])
    const [subscriberIdOption, setSubscriberIdOption] = useState<OptionRes[]>([])
    const [fileServiceIdOption, setFileServiceIdOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            ...body,
            beginCreateTime: body.beginCreateTime ? dayjs(body.beginCreateTime).format('YYYY-MM-DD') : null,
            endCreateTime: body.endCreateTime ? dayjs(body.endCreateTime).format('YYYY-MM-DD') : null,
        }
        let res = addTimeLogApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            beginCreateTime: body.beginCreateTime ? dayjs(body.beginCreateTime).format('YYYY-MM-DD') : null,
            endCreateTime: body.endCreateTime ? dayjs(body.endCreateTime).format('YYYY-MM-DD') : null,
        }
        addTimeLogApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '会员账号',
            dataIndex: 'subUserId',
            key: 'subUserId',
        },

        {
            title: '会员编码',
            dataIndex: 'subscriberCode',
            key: 'subscriberCode',
        },

        {
            title: '手机号码',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: '云手机编码',
            dataIndex: 'instanceCode',
            key: 'instanceCode',
        },
        {
            title: '操作人',
            dataIndex: 'operateName',
            key: 'operateName',
        },
        {
            title: '加的时长',
            dataIndex: 'addTime',
            key: 'addTime',
        },
        {
            title: '时长单位',
            dataIndex: 'timeUnitDesc',
            key: 'timeUnitDesc',
        },
        {
            title: '操作时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>

            <div style={{display: 'flex', alignItems: 'center'}}>

                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >

                    <Form.Item name="subscriberCode" label="会员编码">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="phoneNumber" label="手机号码">
                        <Input/>
                    </Form.Item>


                    <Form.Item name="instanceCode" label="云手机编码">
                        <Input />
                    </Form.Item>

                    <Form.Item name="beginCreateTime" label="创建时间开始" hidden={true}></Form.Item>
                    <Form.Item name="endCreateTime" label="创建时间结束" hidden={true}></Form.Item>

                    <Form.Item name="createTime" label="创建时间">
                        <RangePicker
                            style={{width: 300}}
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginCreateTime", null);
                                    form.setFieldValue("endCreateTime", null);
                                } else {
                                    if (Array.isArray(value)) {
                                        const valueArray = value;
                                        let date = Array.from(value);
                                        if (date.length == 2) {
                                            form.setFieldValue("beginCreateTime", date[0]);
                                            form.setFieldValue("endCreateTime", date[1]);
                                        }
                                        console.log('onOk: ', date);
                                    }
                                }
                            }}></RangePicker>
                    </Form.Item>

                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">查询</Button>
                                <Button htmlType="reset" onClick={() => {
                                    const body = {};
                                    addTimeLogApi.pageData(body, pagination).then((res) => {
                                        if (res) {
                                            setList(res.data.records)
                                            setTotal(res.data.total)
                                            setSelectedRowKeys([]);
                                            setIdList([]);
                                        }
                                    })
                                }}>重置</Button>
                            </Space>
                        </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, y: "68vh"}} dataSource={list}
                    columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


        </div>
    )
}


export default AddTimeLogContent;