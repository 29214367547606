import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Select} from 'antd';
import {OptionRes} from "../../../../../apis/types/common";
import dayjs from "dayjs";
import {ChannelTableDataType} from "../../../../../apis/types/pdt/goodsChannel";
import goodsChannelApi from "../../../../../apis/pdt/goodsChannel";
import {filterOption} from "../../../../../apis/common";
import channelApi from "../../../../../apis/pub/channel";
import propertiesApi from "../../../../../apis/sys/properties";
import TextArea from "antd/es/input/TextArea";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: ChannelTableDataType;
    channelOpen: boolean;
    goodsId: number;
}


const GoodsChannelForm: React.FC<StaffFromProps> = ({
                                                        refresh,
                                                        record,
                                                        channelOpen,
                                                        closeOpen,
                                                        goodsId,
                                                    }) => {
    const [form] = Form.useForm();
    const [channelOption, setChannelOption] = useState<OptionRes[]>([])
    const [channelStatusOption, setChannelStatusOption] = useState<OptionRes[]>([])

    useEffect(() => {
        form.setFieldsValue({
            ...record,
            goodsId:goodsId,
            effTime: dayjs(record.effTime),
            expTime: dayjs(record.expTime),
        });
        channelApi.optinos().then((result) => {
            if (result) {

            setChannelOption(result.data)
            }
        })
        propertiesApi.optinos('DM_Status').then((res) => {
            if (res) {

            setChannelStatusOption(res.data)
            }
        })
    }, [channelOpen])

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        values = {
            ...values,
            expTime: values.expTime ? dayjs(values.expTime).format('YYYY-MM-DD HH:mm:ss') : null,
            effTime: values.effTime ? dayjs(values.effTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        goodsChannelApi.create(values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                message.success("新增成功");
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        values = {
            ...values,
            expTime: values.expTime ? dayjs(values.expTime).format('YYYY-MM-DD HH:mm:ss') : null,
            effTime: values.effTime ? dayjs(values.effTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        let valueJSOn = JSON.stringify(values);
        goodsChannelApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                message.success("修改成功");
            }
        });
    }


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    const onEffTimeOk = (date: any | null) => {
        console.log("onEffTimeOk", date)
        const currentValues = form.getFieldsValue(); // 获取当前表单字段的值
        const updatedValues = {
            ...currentValues,
            expDate: date ? dayjs(date).format('YYYY-MM-DD') : null,
        };

        form.setFieldsValue(updatedValues); // 更新表单字段的值
    };

    return (
        <Modal
            title="新增项目"
            open={channelOpen}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name="goodsId" label="商品Id" hidden={true}>
                </Form.Item>
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="channelId" label="渠道"
                           rules={[{required: true, message: "渠道不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={channelOption}
                    />
                </Form.Item>
                <Form.Item name="effTime" label="生效时间"
                           rules={[{required: true, message: "生效时间必填"}]}>
                    <DatePicker showTime placeholder={''} style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="expTime" label="失效时间"
                           rules={[{required: true, message: "失效时间必填"}]}>
                    <DatePicker showTime placeholder={''} style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态"
                           rules={[{required: true, message: "状态不能为空"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={channelStatusOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default GoodsChannelForm;



