import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider, DatePicker,
    Divider,
    Form,
    Input,
    message,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import Details from "../../public/details/details";
import {TableDataType} from "../../../../apis/types/sys/logWebOperation";
import userApi from "../../../../apis/sys/user";
import logWebOperationApi from "../../../../apis/sys/logWebOperation";
import dayjs from "dayjs";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";


const {RangePicker} = DatePicker;

const LogWebOperationContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        userApi.optinos().then((res) => {
            if (res) {
                setUserOption(res.data)
            }
        })
        logWebOperationApi.getMenusOptions().then((res) => {
            if (res) {
                setMenuOption(res.data)
            }
        })
        logWebOperationApi.getOperationTypeOption().then((res) => {
            if (res) {
                setOperationTypeOption(res.data)
            }
        })
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [userOption, setUserOption] = useState<OptionRes[]>([])
    const [menuOption, setMenuOption] = useState<OptionRes[]>([])
    const [operationTypeOption, setOperationTypeOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            ...body,
            operationTimeBegin: body.operationTimeBegin ? dayjs(body.operationTimeBegin).format('YYYY-MM-DD HH:mm:ss') : null,
            operationTimeEnd: body.operationTimeEnd ? dayjs(body.operationTimeEnd).format('YYYY-MM-DD HH:mm:ss') : null,
        }
        let res = logWebOperationApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }

        })
    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            operationTimeBegin: body.operationTimeBegin ? dayjs(body.operationTimeBegin).format('YYYY-MM-DD HH:mm:ss') : null,
            operationTimeEnd: body.operationTimeEnd ? dayjs(body.operationTimeEnd).format('YYYY-MM-DD HH:mm:ss') : null,
        }
        logWebOperationApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作人员账号',
            dataIndex: 'userIdDesc',
            key: 'userIdDesc',
            ellipsis: true,
            width: 50
        },
        {
            title: '菜单名称',
            dataIndex: 'menuName',
            key: 'menuName',
            ellipsis: true,
            width: 100
        },
        {
            title: '操作类型',
            dataIndex: 'operationType',
            key: 'operationType',
            ellipsis: true,
            width: 40
        },
        {
            title: '操作时间',
            dataIndex: 'operationTime',
            key: 'operationTime',
            ellipsis: true,
            width: 50
        },
        {
            title: '操作来源IP',
            dataIndex: 'operationIp',
            key: 'operationIp',
            ellipsis: true,
            width: 50
        },
        {
            title: '是否成功',
            dataIndex: 'successDesc',
            key: 'successDesc',
            ellipsis: true,
            width: 20
        },
        {
            title: '响应结果',
            dataIndex: 'requestResult',
            key: 'requestResult',
            ellipsis: true,
            width: 100
        },
        {
            title: '请求url',
            dataIndex: 'url',
            key: 'url',
            ellipsis: true,
            width: 100
        },
        {
            title: '请求参数',
            dataIndex: 'params',
            key: 'params',
            ellipsis: true,
            width: 100
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            ellipsis: true,
            width: 100
        },
    ];

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="userId" label="操作用户">
                        <Select
                            allowClear
                            style={{width: 150}}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={userOption}
                        />
                    </Form.Item>
                    <Form.Item name="menuName" label="菜单名称">
                        <Select
                            allowClear
                            showSearch
                            style={{width: 150}}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={menuOption}
                        />
                    </Form.Item>
                    <Form.Item name="operationType" label="操作类型">
                        <Select
                            allowClear
                            showSearch
                            style={{width: 150}}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={operationTypeOption}
                        />
                    </Form.Item>
                    <Form.Item name="operationIp" label="操作ip">
                        <Input/>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="operationTime" label="操作时间">
                        <RangePicker
                            placeholder={['', '']}
                            showTime
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("operationTimeBegin", null);
                                    form.setFieldValue("operationTimeEnd", null);
                                } else if (Array.isArray(value)) {
                                    const valueArray = value;
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("operationTimeBegin", date[0]);
                                        form.setFieldValue("operationTimeEnd", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }}

                        />
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="operationTimeBegin" hidden={true}></Form.Item>
                    <Form.Item style={{margin: 5}} name="operationTimeEnd" hidden={true}></Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                const body = {};
                                logWebOperationApi.pageData(body, pagination).then((res) => {
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                    }
                                })
                            }}>重置</Button> </Space>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '120vw', y: "70vh"}}
                    dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}
                />

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default LogWebOperationContent;