import {baseUrl, PageParam, TableDataType} from "../types/sub/instanceGroup";
import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";

const instanceGroupApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.groupName) {
                url += 'groupName=' + encodeURIComponent(body.groupName);
            }
            if (body.subUserId) {
                url += '&subUserId=' + encodeURIComponent(body.subUserId);
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    }

}

export default instanceGroupApi