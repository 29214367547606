import {request} from "../../../utils";
import {OptionRes, PageRes} from "../../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../../types/mkt/discovery/discoveryVo";
import commonApi from "../../commonApi";
import {domain} from "../../../components/constantConfig";

const discoveryApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        let queryParams = new URLSearchParams();

        // 添加分页参数
        queryParams.set('current', pagination.current.toString());
        queryParams.set('size', pagination.pageSize.toString());

        for (const key in body) {
            console.log("key:" +  key + ":" + body[key])
            if (body.hasOwnProperty(key) && body[key] !== null && body[key] !== undefined && body[key] !== '') {
                let valueToEncode = body[key];

                queryParams.set(key, valueToEncode);
            }
        }

        // 拼接查询参数到 URL
        url += '?' + queryParams.toString();

        return request.get<PageRes<TableDataType>>(url);
    },

    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"发现页列表",body)
    },
    batchEnable(body: any) {
        let url = baseUrl + '/enable';
        return request.post(url, body);
    },
    batchDisable(body: any) {
        let url = baseUrl + '/disable';
        return request.post(url, body);
    },
    batchDelete(body: any) {
        let url = baseUrl + '/delete';
        return request.post(url, body);
    },
    create(body: any) {
        let url = baseUrl + '/create' ;
        return request.post(url, body)
    },
    update(id:number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    getUploadBannerUrl(){
        return domain + baseUrl + '/bannerUpload';
    }


}

export default discoveryApi;