import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider,
    Divider,
    Form,
    Input, message, Modal,
    Pagination,
    PaginationProps,
    Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import HostForm from "./hostForm";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import propertiesApi from "../../../../apis/sys/properties";
import serviceApi from "../../../../apis/res/server";
import hostApi from "../../../../apis/res/host";
import {TableDataType} from "../../../../apis/types/res/host";
import Details from "../../public/details/details";
import phoneModelApi from "../../../../apis/res/phoneModel";
import idcApi from "../../../../apis/res/idc";
import {ipRegex} from "../../../common/const";


const HostContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        idcApi.optinos().then((res) => {
            if (res) {

                setIdcIdOption(res.data)
            }
        })
        propertiesApi.optinos('DM_Res_Released_Status').then((res) => {
            if (res) {

                setReleasedStatus(res.data)
            }
        })
        propertiesApi.optinos("DM_Host_Status").then((res) => {
            if (res) {

                setStatusOption(res.data)
            }
        })

        propertiesApi.optinos("DM_Faoult_Type").then((res) => {
            if (res) {

                setFaultTypeOption(res.data)
            }
        })

        serviceApi.optinos().then((res) => {
            if (res) {

                setServerOption(res.data)
            }
        })
    }, []);
    //定义用到的useState，用来绑定数据
    const [idcIdOption, setIdcIdOption] = useState<OptionRes[]>([])
    const [releasedStatus, setReleasedStatus] = useState<OptionRes[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [idList, setIdList] = useState<Number[]>([]);
    const [open, setOpen] = useState(false)
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [type, setType] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [serverOption, setServerOption] = useState<OptionRes[]>([])
    const [faultTypeOption, setFaultTypeOption] = useState<OptionRes[]>([])
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })

    const [openCheckIds, setOpenCheckIds] = useState(false);
    const [openDoubleCheckReboot, setDoubleCheck] = useState(false);
    const [openDoubleCheckEnableRelease, setDoubleCheckEnableRelease] = useState(false);
    const [openDoubleCheckDisableRelease, setDoubleCheckDisableRelease] = useState(false);
    //定义操作类型：1-重启宿主机
    const [operate, setOperate] = useState<Number>();

    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        let res = hostApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
                setIdList([]);
            }
        })

    }

    //处理删除
    const handleDelete = () => {
        hostApi.batchDelete(idList).then((result) => {
            if (result) {
                //接口成功后重置idlist
                setIdList([])
                //重新调用一次查询接口
                handleQuery();
            }
        });

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        hostApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '操作',
            dataIndex: 'operation',
            render: (_: any, record: TableDataType) => {
                return <Typography.Link onClick={() => {
                    setOpen(true)
                    setRowData(record);
                    setType(false)
                }}>
                    编辑
                </Typography.Link>
            },
        },
        {
            title: '所在机房',
            dataIndex: 'idcName',
            key: 'idcName',
            ellipsis:true,

        },

        {
            title: '服务器型号',
            dataIndex: 'serverModelName',
            key: 'serverModelName',
            ellipsis:true,

        },
        {
            title: '对应服务器',
            dataIndex: 'serverCode',
            key: 'serverCode',
            ellipsis:true,

        },

        {
            title: '宿主机编码',
            dataIndex: 'hostCode',
            key: 'hostCode',
            ellipsis:true,

        },
        {
            title: 'MAC地址',
            dataIndex: 'hostMac',
            key: 'hostMac',
            ellipsis:true,

        },
        {
            title: 'IP地址',
            dataIndex: 'hostIp',
            key: 'hostIp',
            ellipsis:true,

        }, {
            title: '主控芯片',
            dataIndex: 'chipTypeDesc',
            key: 'chipTypeDesc',
            ellipsis:true,

        }, {
            title: '运存大小',
            dataIndex: 'ramSize',
            key: 'ramSize',
            ellipsis:true,

        }, {
            title: '存储大小',
            dataIndex: 'romSize',
            key: 'romSize',
            ellipsis:true,

        },
        {
            title: 'OS类型',
            dataIndex: 'osTypeDesc',
            key: 'osTypeDesc',
            ellipsis:true,

        },
        {
            title: 'OS版本',
            dataIndex: 'osVersion',
            key: 'osVersion',
            ellipsis:true,

        }, {
            title: '故障标识',
            dataIndex: 'faultMark',
            key: 'faultMark',
            ellipsis:true,

        }, {
            title: '故障类型',
            dataIndex: 'faultTypeDesc',
            key: 'faultTypeDesc',
            ellipsis:true,

        },
        {
            title: '释放状态',
            dataIndex: 'releasedStatusDesc',
            key: 'releasedStatusDesc',
            ellipsis: true,
            width: 100
        },

        {
            title: '状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            ellipsis:true,

        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
        },
        {
            title: '修改时间',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            ellipsis: true,
        },

        {
            title: '备注',
            dataIndex: 'notes',
            key: 'notes',
            ellipsis:true,

        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: TableDataType[]) => {
            const newSet = new Set(selectedRows.map((key) => key.id));
            //这里有个类型转换
            setIdList(Array.from(newSet) as Number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    //处理操作类型对应的值：//定义操作类型：1-重启宿主机
    const handleOperate = () => {
        console.log("handleOperate", operate)
        if (operate === 1) {
            // 处理为重启宿主机的情况
            hostApi.rebootHost(idList).then((res) => {
                if (res) {

                    setOperate(0)
                    setDoubleCheck(false)
                    handleQuery();
                }
            })
        }

        if (operate === 2) {
            // 处理释放宿主机的情况
            hostApi.enableRelease(idList).then((res) => {
                if (res) {
                    setOperate(0)
                    setDoubleCheckEnableRelease(false)
                    handleQuery();
                }
            })
        }

        if (operate === 3) {
            // 处理禁止释放宿主机的情况
            hostApi.disableRelease(idList).then((res) => {
                if (res) {
                    setOperate(0)
                    setDoubleCheckDisableRelease(false)
                    handleQuery();
                }
            })
        }

    };


    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>

            <Form form={form} name="validateOnly" autoComplete="off"

                  labelAlign={"left"}
                  layout={"inline"}
                  size={"small"}
                  style={{maxWidth: 3000}}

                  onFinish={() => {
                      pagination.current = 1;
                      setCurrentPage(1);
                      handleQuery();
                  }}
            >

                <Form.Item name="idcId" label="机房">
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear

                        filterOption={filterOption}
                        options={idcIdOption}
                    />
                </Form.Item>


                <Form.Item  name="beginHostIp" label="宿主机ip起" rules={[
                    {
                        pattern: ipRegex,
                        message: '请输入有效的IP地址',
                    },
                ]}>
                    <Input style={{width: 150}}
                    />

                </Form.Item>


                <Form.Item  name="endHostIp" label="宿主机ip尾" rules={[
                    {
                        pattern: ipRegex,
                        message: '请输入有效的IP地址',
                    },
                ]}>
                    <Input style={{width: 150}}
                    />

                </Form.Item>

                <Form.Item name="hostCode" label="宿主机编码">
                    <Input style={{width: 150}}/>
                </Form.Item>

                <Form.Item name="serverCode" label="对应服务器">
                    <Input style={{width: 150}}/>
                </Form.Item>
                <Form.Item name="releasedStatus" label="释放状态">
                    <Select
                        allowClear={true}
                        style={{width: 150}}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={releasedStatus}
                    />
                </Form.Item>
                <Form.Item name="statusCd" label="状态">
                    <Select
                        style={{width: 150}}
                        showSearch
                        optionFilterProp="children"
                        allowClear

                        filterOption={filterOption}
                        options={statusOption}
                    />

                </Form.Item>

                <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>


                <div style={{display: 'flex', alignItems: 'center', padding: 0}}>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={()=>{
                                const body={};
                                hostApi.pageData(body,pagination).then((res)=>{
                                    if (res) {
                                        setList(res.data.records)
                                        setTotal(res.data.total)
                                        setSelectedRowKeys([]);
                                        setIdList([]);
                                    }
                                })
                            }}>重置</Button>                        </Space>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" ghost onClick={() => {
                            if (idList.length == 0) {
                                setOpenCheckIds(true)
                            } else {
                                setOperate(1)
                                setDoubleCheck(true)
                            }
                        }}>
                            重启宿主机
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" onClick={() => {
                            if (idList.length == 0) {
                                setOpenCheckIds(true)
                            } else {
                                setOperate(2)
                                setDoubleCheckEnableRelease(true)
                            }
                        }} >
                            释放
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" danger onClick={() => {
                            if (idList.length == 0) {
                                setOpenCheckIds(true)
                            } else {
                                setOperate(3)
                                setDoubleCheckDisableRelease(true)
                            }
                        }} >
                            禁止释放
                        </Button>
                    </Form.Item>


                    <Form.Item>
                        <Popconfirm
                            title="是否确认导出筛选的数据？"
                            onConfirm={confirmDerive}
                            onCancel={cancelDerive}
                            okText="是"
                            cancelText="否"
                        >
                            <Button type="primary" danger style={{marginRight: '15px'}}>
                                导出
                            </Button>
                        </Popconfirm>
                    </Form.Item>

                </div>


            </Form>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                console.log("details", record)
                                setDetailsOpen(true);
                                setRowData(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: '100vw', y: "70vh"}} rowSelection={{
                    ...rowSelection,"selectedRowKeys":selectedRowKeys,
                }} dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination        size="small"style={{marginLeft: 'auto'}}
                                   total={total}
                                   showSizeChanger
                                   showQuickJumper
                                   current={currentPage}
                                   defaultPageSize={20}
                                   locale={paginationLocale}
                                   onChange={handlePaginationChange}
                                   showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>


            {/*新增和编辑的form表单，子组件*/}
            <HostForm
                refresh={() => {
                    //设置表单不展示
                    setOpen(false);
                    setRowData({} as TableDataType)
                    //重新调用查询的接口
                    handleQuery();
                }}
                record={rowData}
                open={open}
                closeOpen={() => {
                    setOpen(false)
                    setRowData({} as TableDataType)
                }}
                serverOption={serverOption}
                faultTypeOption={faultTypeOption}
                statusOption={statusOption}
                type={type}></HostForm>

            {/*判断操作的ids存在的弹窗*/}
            <Modal cancelText="取消"
                   okText="确认" title="" open={openCheckIds} onOk={() => {
                setOpenCheckIds(false)
            }} onCancel={() => {
                setOpenCheckIds(false)
            }}>
                先选择要操作的数据
            </Modal>
            {/*二次确认的弹窗*/}
            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openDoubleCheckReboot} onOk={() => {
                handleOperate();
            }} onCancel={() => {
                setOperate(0)
                setDoubleCheck(false)
            }}>
                该操作会重启所选的宿主机，请确认是否继续。
            </Modal>

            {/*二次确认的弹窗*/}
            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openDoubleCheckEnableRelease} onOk={() => {
                handleOperate();
            }} onCancel={() => {
                setOperate(0)
                setDoubleCheckEnableRelease(false)
            }}>
                该操作会释放所选的宿主机，请确认是否继续。
            </Modal>

            {/*二次确认的弹窗*/}
            <Modal
                cancelText="取消"
                okText="确认"
                title="" open={openDoubleCheckDisableRelease} onOk={() => {
                handleOperate();
            }} onCancel={() => {
                setOperate(0)
                setDoubleCheckDisableRelease(false)
            }}>
                该操作会禁止释放所选的宿主机，请确认是否继续。
            </Modal>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default HostContent;