import React, {useEffect} from 'react';
import {Form, Input, InputNumber, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/res/hostVirtual";
import hostVirtualApi from "../../../../apis/res/hostVirtual";
import {OptionRes} from "../../../../apis/types/common";
import TextArea from "antd/es/input/TextArea";
import {filterOption} from "../../../../apis/common";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
// const {Option} = Select;


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    statusCds: OptionRes[];
    open: boolean;
    type: boolean;
    serverModelIdOptions: OptionRes[];
    instanceGradeCdOptions: OptionRes[];

}


const HostVirtualFrom: React.FC<StaffFromProps> = ({
                                                       refresh,
                                                       record,
                                                       open,
                                                       type,
                                                       closeOpen,
                                                       statusCds,
                                                       serverModelIdOptions,
                                                       instanceGradeCdOptions
                                                   }) => {
    const [form] = Form.useForm();
    // 编辑的时候回显数据
    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        console.log("useEffect", record)
        form.setFieldsValue({
            ...record
        });
    }, [form, record]);

    //处理新增方法
    const handleCreate = () => {
        hostVirtualApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        hostVirtualApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }

    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}
                style={{maxWidth: 300}}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="virtualName" label="虚拟化名称"
                           rules={[{required: true, message: "虚拟化名称不能为空"}, {
                               max: 128,
                               message: "虚拟化名称长度不能超过128位"
                           }]}>
                    <Input style={{width: 250}}/>
                </Form.Item>
                <Form.Item name="serverModelId" label="服务器型号"
                           rules={[{required: true, message: "服务器型号不能为空"}]}>
                    <Select
                        allowClear={true}
                        style={{width: 250}}
                        options={serverModelIdOptions}
                    />
                </Form.Item>
                <Form.Item name="virtualQuantity" label="虚拟数量"
                           rules={[{
                               required: true,
                               message: "虚拟数量不能为空或格式不对，大小处于0-30000之间",
                               type: "number",
                               min: 0,
                               max: 30000
                           }]}>
                    <InputNumber style={{width: 250}}/>
                </Form.Item>
                <Form.Item name="activeQuantity" label="激活数量"
                           rules={[{
                               required: true,
                               message: "激活数量不能为空或格式不对，大小处于0-30000之间",
                               type: "number",
                               min: 0,
                               max: 30000
                           }]}>
                    <InputNumber style={{width: 250}}/>
                </Form.Item>
                <Form.Item name="cpuQuota" label="CPU配额"
                           rules={[{
                               required: true,
                               message: "CPU配额不能为空或格式不对，大小处于0-30000之间",
                               type: "number",
                               min: 0,
                               max: 30000
                           }]}>
                    <InputNumber style={{width: 250}}/>
                </Form.Item>
                <Form.Item name="gpuQuota" label="GPU配额"
                           rules={[{
                               required: true,
                               message: "GPU配额不能为空或格式不对，大小处于0-30000之间",
                               type: "number",
                               min: 0,
                               max: 30000
                           }]}>
                    <InputNumber style={{width: 250}}/>
                </Form.Item>
                <Form.Item name="ramQuota" label="运存配额"
                           rules={[{
                               required: true,
                               message: "运存配额不能为空或格式不对，大小处于0-30000之间",
                               type: "number",
                               min: 0,
                               max: 30000
                           }]}>
                    <InputNumber style={{width: 250}}/>
                </Form.Item>
                <Form.Item name="romQuota" label="存储配额"
                           rules={[{
                               required: true,
                               message: "存储配额不能为空或格式不对，大小处于0-30000之间",
                               type: "number",
                               min: 0,
                               max: 30000
                           }]}>
                    <InputNumber style={{width: 250}}/>
                </Form.Item>
                <Form.Item name="instanceGradeCd" label="云手机等级"
                           rules={[{required: true, message: "云手机等级不能为空"}]}>
                    <Select
                        allowClear={true}
                        style={{width: 250}}
                        options={instanceGradeCdOptions}
                    />
                </Form.Item>
                <Form.Item name="defaultMark" label="默认配置"
                           rules={[{
                               required: true,
                               message: "默认配置不能为空",
                           }]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={[
                            {value: '1', label: '是'},
                            {value: '0', label: '否'},
                        ]}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态"
                           rules={[{required: true, message: "状态不能为空"}]}>
                    <Select
                        allowClear={true}
                        style={{width: 250}}
                        options={statusCds}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注" rules={[{max: 255, message: "备注长度不能超过255位"}]}>
                    <TextArea rows={1} style={{width: 250}}/>
                </Form.Item>

            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default HostVirtualFrom;



