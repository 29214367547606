const baseUrl = "/log/web/operation";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    userId:number,
    menuName:string,
    operationType:string,
    operationIp:string,
    operationTimeBegin:string,
    operationTimeEnd:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    userId: number;
    userIdDesc: string;
    menuName: string;
    operationType: string;
    url: string;
    className: number;
    methodName: string;
    params: string;
    success: number;
    successDesc: string;
    requestResult: string;
    operationTime: string;
    operationIp: string;
    remark: string;
}



export  {baseUrl};

