const baseUrl = "/sys/properties";
/** 获取列表-参数 */
export interface PageParam {
    categoryId:number
}

//定义表格的属性
export interface TableDataType {
    id: number;
    paramCategory: string;
    paramValue: string;
    paramDesc: string;
    parentCategory: string;
    parentValue: string;
    statusCd: string;
    notes: string;
}



export  {baseUrl};
