const baseUrl = "/res/insTask";
/** 云手机任务管理-参数 */
export interface PageParam  {
    id: number;
    instanceId: number;
    taskStatus: string;
    taskType: string;
    taskSource: string;
    taskResultStatus: string;
    creater: number;
    modifier: number;
    beginCreatedTime: string;
    endCreatedTime: string;
    beginModifiedTime: string;
    endModifiedTime: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    instanceId: number;
    curTryTimes: number;
    taskResultInfo: string;
    taskStatus: string;
    taskType: string;
    taskExtraInfo: string;
    taskSource: string;
    taskResultStatus: string;
    note: string;
    creater: number;
    modifier: number;
    createdTime: string;
    modifiedTime: string;
}

export  {baseUrl};

