import React, {useEffect, useState} from 'react';
import {Button, Form, Image, Input, InputNumber, message, Modal, Select, Tooltip, Upload, UploadProps} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {OptionRes} from "../../../../../apis/types/common";
import {filterOption} from "../../../../../apis/common";
import {domain, imgIp} from "../../../../../components/constantConfig";
import {UploadOutlined} from "@ant-design/icons";
import {getToken} from "../../../../../utils";
import productApi from "../../../../../apis/pdt/product";
import goodsApi from "../../../../../apis/pdt/goods";
import actApi from "../../../../../apis/mkt/act/act";
import {TableDataType as GoodsActTableDataType} from "../../../../../apis/types/mkt/act/goods/goodsAct";
import {ExclamationCircleFilled} from '@ant-design/icons';

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface ActGoodsFromProps {
    open: boolean;
    actId: Number;
    setActGoodsList: (list: any) => void;
    setBuyIntervalDay: (value: number) => void;
    number: Number

}


const RetentionPopupForm: React.FC<ActGoodsFromProps> = ({
                                                             open,
                                                             actId,
                                                             setActGoodsList, setBuyIntervalDay,
                                                             number

                                                         }) => {
    const [productOption, setProductOption] = useState<OptionRes[]>([])
    const [goodsOption, setGoodsOption] = useState<OptionRes[]>([])
    const [pcIconUrl, setPcIconUrl] = useState('')
    const [appIconUrl, setAppIconUrl] = useState('')

    const [form] = Form.useForm();
    const [id, setId] = useState(0); // 初始化 ID 为 0

    const incrementId = () => {
        setId((prevId) => prevId + 1); // 自增 ID
    };
    // 编辑的时候回显数据
    useEffect(() => {
        productApi.options().then((res) => {
            if (res) {
                setProductOption(res.data)
            }
        })
        goodsApi.options({}).then((res) => {
            if (res) {
                setGoodsOption(res.data)
            }
        })

    }, [number]);

    //进入页面先查询数据
    useEffect(() => {
        // 检查 actId 是否为有效的正整数
        if (actId != null && actId > 0) {
            handleQuery();
        }
        incrementId()
    }, [actId, number]);


    const [goods, setGoods] = useState<GoodsActTableDataType>()

    // 监听 goods 变化
    useEffect(() => {
        if (goods) {
            setActGoodsList([goods]);
        }
    }, [goods]);

    // 在表单值变化时调用父组件的方法
    const onValuesChange = (changedValues) => {
        const currentValues = form.getFieldsValue();
        // 更新 goods 对象
        const updatedGoods = {
            id:currentValues.id,
            actId: actId,
            actPrice: currentValues.actPrice,
            goodsId: currentValues.goodsId,
            productId: currentValues.productId,
            pcIcon: pcIconUrl,
            appIcon: appIconUrl,
            enableStatus:1,
        };

        setGoods(updatedGoods);
        setBuyIntervalDay(currentValues.buyIntervalDay); // 更新 buyIntervalDay
    };

    //处理查询
    function handleQuery() {
        let body = {"actId": actId}

        let res = actApi.actOthers(body);
        res.then((result) => {

            if (result) {
                var goodsActRespVo = result.data.goodsActRespVo;
                form.setFieldsValue({
                    ...goodsActRespVo,
                    buyIntervalDay: result.data.buyIntervalDay
                });
                setPcIconUrl(goodsActRespVo.pcIcon)
                setAppIconUrl(goodsActRespVo.appIcon)


                // 更新 goods 对象
                const updatedGoods = {
                    id:goodsActRespVo.id,
                    actId: goodsActRespVo.actId,
                    actPrice: goodsActRespVo.actPrice,
                    goodsId: goodsActRespVo.goodsId,
                    productId: goodsActRespVo.productId,
                    pcIcon: goodsActRespVo.pcIcon,
                    appIcon: goodsActRespVo.appIcon,
                    enableStatus:1,
                };
                setGoods(updatedGoods);
            }
        })

    }


    const pcImageprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain + '/pub/picture/upload',
        headers: {
            authorization: getToken(),
        },
        onChange(info) {
            const {status} = info.file;
            let file = info.file;
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    setPcIconUrl(urlRes);

                    const updatedGoods = {
                        ...goods,
                        pcIcon: urlRes, // 注意这里使用 urlRes 而不是 pcIconUrl，因为 pcIconUrl 还没更新
                    };

                    setGoods(updatedGoods);
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setPcIconUrl(pcIconUrl)
        },

    };

    const appImageprops: UploadProps = {
        multiple: false,
        name: 'file',
        maxCount: 1,
        action: domain + '/pub/picture/upload',
        headers: {
            authorization: getToken(),
        },
        onChange(info) {
            const {status} = info.file;
            let file = info.file;
            if (status !== 'uploading') {
            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    setAppIconUrl(urlRes)
                    const updatedGoods = {
                        ...goods,
                        appIcon: urlRes,
                    };
                    setGoods(updatedGoods)

                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }
        },
        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },
        onDrop: function (e) {


        },
        onRemove(file) {
            setAppIconUrl(appIconUrl)
        },

    };

    return (
        <Form
            form={form}
            name="childForm"
            onValuesChange={onValuesChange} // 监听值变化

        >
            <Form.Item name="id" label="id值" hidden={true}>
            </Form.Item>
            <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                <div style={{flexBasis: '20%', padding: '0 8px'}}>
                    <Form.Item name="productId" label="产品名称"
                               rules={[{required: true, message: "产品名称必填"}]}>
                        <Select
                            style={{width: 200}}
                            showSearch
                            optionFilterProp="children"
                            allowClear
                            onClick={() => {
                                let productId = form.getFieldValue("productId");
                                if (productId) {
                                    console.log("productId", productId)
                                    let body = {"productId": productId}
                                    goodsApi.options(body).then((res) => {
                                        if (res) {
                                            setGoodsOption(res.data)
                                        }
                                    })
                                }

                            }}
                            onChange={(value) => {
                                const selectedOption = productOption.find(option => option.value === value);
                                if (selectedOption) {
                                    const label = selectedOption.label; // 获取 label 数据
                                }
                            }}
                            filterOption={filterOption}
                            options={productOption}
                        />
                    </Form.Item>
                </div>
                <div style={{flexBasis: '20%', padding: '0 8px'}}>

                    <Form.Item name="goodsId" label="商品" rules={[{required: true, message: "商品必填"}]}>
                        <Select
                            style={{width: 200}}
                            showSearch
                            optionFilterProp="children"
                            allowClear
                            filterOption={filterOption}
                            onChange={(value) => {
                                const selectedOption = goodsOption.find(option => option.value === value);
                                if (selectedOption) {
                                    const label = selectedOption.label; // 获取 label 数据
                                }
                            }}
                            options={goodsOption}
                        />
                    </Form.Item>
                </div>
                <div style={{flexBasis: '20%', padding: '0 8px'}}>
                    <Form.Item name="actPrice" label="活动价(分)"
                               rules={[{required: true, message: "活动价必填"}]}>
                        <InputNumber min={0}  max={2147483647} style={{width: 200}}/>
                    </Form.Item>
                </div>

                <div style={{flexBasis: '20%', padding: '0 8px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Form.Item name="buyIntervalDay" label="规则时间" style={{marginBottom: 0, marginRight: 10}}>
                            <InputNumber min={0} style={{width: 200}}/>
                        </Form.Item>
                        <Tooltip title="说明：x天内未购买活动商品则出现弹窗" style={{marginLeft: 8}}>
                            <ExclamationCircleFilled/>
                        </Tooltip>
                    </div>

                </div>

            </div>

            <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                <div style={{flexBasis: '20%', padding: '0 8px'}}>

                    <Form.Item name="pcIcon" label="pc应用角标">
                        {pcIconUrl !== '' ? <Image src={imgIp + pcIconUrl} width={100}/> :
                            <Image src={imgIp + pcIconUrl} width={100}/>}
                    </Form.Item>
                </div>
                <div style={{flexBasis: '20%', padding: '0 8px'}}>

                    <Form.Item name="pcIconUpload" label="pcIcon上传">
                        <Upload {...pcImageprops} >
                            <Button style={{width: 250}} icon={<UploadOutlined/>}>点击上传</Button>
                        </Upload>
                    </Form.Item>
                </div>
            </div>

            <div style={{display: 'flex', flexWrap: 'wrap', paddingTop: 20}}>
                <div style={{flexBasis: '20%', padding: '0 8px'}}>

                    <Form.Item name="appIcon" label="app应用角标">
                        {appIconUrl !== '' ? <Image src={imgIp + appIconUrl} width={100}/> :
                            <Image src={imgIp + appIconUrl} width={100}/>}
                    </Form.Item>
                </div>
                <div style={{flexBasis: '20%', padding: '0 8px'}}>

                    <Form.Item name="appIconUpload" label="appIcon上传">
                        <Upload {...appImageprops} >
                            <Button style={{width: 250}} icon={<UploadOutlined/>}>点击上传</Button>
                        </Upload>
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};

export default RetentionPopupForm;




