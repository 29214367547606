const baseUrl = "/opt/platform";
/** 获取产品申请列表-参数 */
export interface PageParam {
    platformName:string,
    ownerTypeCd:string,
    // ownerObjectId:Number
}

//定义表格的属性
export interface TableDataType {
    id: number;
    platformName: string;
    accessConfig: number;
    successKeyword: number;
    platformSupplier: number;
    ownerTypeCd: number;
    ownerObjectId: number;
    statusCd: string;
    notes: string;
    reorder: string;
    platformCode: string;
}



export  {baseUrl};

