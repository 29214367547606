import moment from "moment";

const baseUrl = "/sys/user";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    acctNo:string,
    staffId:string
}

//定义表格的属性
export interface TableDataType {
    id: number;
    staffNo: string;
    acctNo: number;
    acctPwd: string;
    certTypeCd: number;
    effTime: string;
    expTime: string;
    statusCd:string;
    statusDesc: string;
    actionList: [];
    notes: string;
}
//修改密码请求接口
export interface ChangePassword {
    id: number;
    acctPwd: string;
    pwd: string;
}



export  {baseUrl};

