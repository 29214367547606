import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, message, Modal, Row, Select, UploadProps} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/cus/fault";
import faultApi from "../../../../apis/cus/fault"
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import TextArea from "antd/es/input/TextArea";

import QueryInstanceForm from "./QueryInstanceForm";
import Dragger from "antd/es/upload/Dragger";
import {InboxOutlined} from "@ant-design/icons";
import {domain} from "../../../../components/constantConfig";
import {getToken} from "../../../../utils";
import {UploadFile} from "antd/es/upload/interface";
import {RcFile} from "antd/es/upload";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 100},
    wrapperCol: {span: 160},
};

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });


interface FormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    replaceOpen: boolean;
    model: String;
    // option
    statusCdOption: OptionRes[];
    faultTypeOption: OptionRes[];
    originOption: OptionRes[];
    setReplaceOpen: (open:boolean) => void;
    createFinish:( createData: TableDataType) => void;

}


const FaultFrom: React.FC<FormProps> = ({
                                            refresh, record, open, closeOpen, model,
                                            statusCdOption,faultTypeOption, originOption,setReplaceOpen,replaceOpen,createFinish
                                        }) => {


    const [urlMap, setUrlMap] = useState<Map<string, string>>(new Map());
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [savePictureUrlList, setSavePictureUrlList] = useState<String[]>([]);
    const [deletePictureIdList, setDeletePictureIdList] = useState<String[]>([]);
    const [oldPictureIdList, setOldPictureIdList] = useState<String[]>([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const [openCheckIdOne, setOpenCheckIdOne] = useState(false);

    const [form] = Form.useForm();

    // 创建用于接收子组件传递的idList参数的状态变量
    const [instanceIdList, setInstanceIdList] = useState<Number[]>([]);

    // 编辑的时候回显数据
    useEffect(() => {

        if (open && record) {
            form.setFieldsValue({
                ...record,
            })
        }
        setSavePictureUrlList([])
        setFileList([])

    }, [open]);

    // 创建函数来接收子组件传递的idList参数并更新父组件的状态变量
    const handleIdListChange = (newIdList: number[]) => {
        setInstanceIdList(newIdList);
    };

    // 处理新增方法
    const handleCreate = () => {
        const body = {
            ...form.getFieldsValue(),
            instanceId: instanceIdList[0],
            pictureUrls: savePictureUrlList,
            origin: 'S01'
        }

        faultApi.create(body).then((result) => {
            if (result) {
                handleCancel();
                refresh();
                setSavePictureUrlList([])
                setFileList([])
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };


    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (instanceIdList.length == 1) {
                // 做新增的逻辑
                handleCreate();
            } else {
                setOpenCheckIdOne(true)
            }
        })
    }

    //处理更换云手机按钮
    function handleReplace() {
        const body = {
            ...form.getFieldsValue(),
            instanceId: instanceIdList[0],
            pictureUrls: savePictureUrlList,
            origin: 'S01'
        }

        faultApi.create(body).then((result) => {
            if (result) {
                createFinish(result.data)
                setReplaceOpen(true)
                handleCancel();
                refresh();
                setSavePictureUrlList([])
                setFileList([])


            }
        });

    }


    // 故障图片相关方法

    // 图片上传
    const props: UploadProps = {
        multiple: true,
        name: 'file',
        maxCount: 1000,
        action: domain+'/pub/picture/upload',

        headers: {
            authorization: getToken(),
            // 'Content-Type': 'multipart/form-data',
        },
        // className:"upload-list-inline",
        listType: "picture-card",

        fileList: fileList,
        onChange(info) {
            let {fileList} = info;
            const {status} = info.file;
            let file = info.file;
            console.log("status,", status)

            if (status !== 'uploading') {

            }

            if (status === 'done') {
                if (file.response.code == 0) {
                    let urlRes = file.response.data;
                    console.log("done,", urlRes)
                    // 将urlRes添加到savePictureUrlList中
                    setSavePictureUrlList((prevList) => [...prevList, urlRes]);
                    urlMap.set(file.uid, urlRes);
                    setUrlMap(new Map(urlMap));
                    // setFileList([...fileList, file])
                    setFileList(prevList => [...prevList, file]);
                    message.success(`${info.file.name} 文件上传成功 .`);
                } else {
                    message.error(`${info.file.name} 文件上传失败.`);
                }

            }

            if (status === 'uploading') {
                setFileList(prevList => [...prevList, file]);
            } else if (status === 'error') {
                message.error(`${info.file.name} 文件上传失败.`);
            }

            // setFileList(prevList => [...prevList, file]);
            setFileList([...fileList])
        },

        beforeUpload(file) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                message.error('只能上传图片');
                return false;
            }
            const isLt20M = file.size / 1024 / 1024 < 20;
            if (!isLt20M) {
                message.error('图片大小不能超过20m');
                return false;
            }
            return true;
        },

        onRemove(file) {
            console.log("remove file", file.uid)

            // 将要删除的图片id记录下来。先判断
            if (oldPictureIdList.includes(file.uid)) {
                setDeletePictureIdList(prevList => [...prevList, file.uid]);
            } else {
                setSavePictureUrlList(prevList => prevList.filter(url => url !== file.response.data));
            }

            urlMap.delete(file.uid);
            setUrlMap(new Map(urlMap));

        },

    };

    // 图片展示
    const handleCancelPictureShow = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    return (
        <Modal
            title={model == 'create' ? "创建工单" : "编辑工单"}
            open={open}
            // okText={"保存"}
            // cancelText={"取消"}
            onCancel={handleCancel}
            // onOk={handleOK}
            footer={[
                <Button key="changeCloudPhone" onClick={handleReplace}>
                    更换云手机
                </Button>,
                <Button key="cancel" onClick={handleCancel}>
                    取消
                </Button>,
                <Button key="confirm" type="primary" onClick={handleOK}>
                    保存
                </Button>,
            ]}
            width={1500}
        >
            <QueryInstanceForm onIdListChange={handleIdListChange} >
            </QueryInstanceForm>

            <div>
                <h3>填写工单信息</h3>
                <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                >
                    <Form.Item name="workOrderId" label="id值" hidden={true}>
                    </Form.Item>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item name="faultType" label="故障类型" rules={[{required: true, message: "故障类型必填"}]}>
                                <Select
                                    style={{width: 200}}
                                    showSearch
                                    optionFilterProp="children"
                                    allowClear
                                    filterOption={filterOption}
                                    options={faultTypeOption}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="faultDesc" label="故障描述"  rules={[{required: true, message: "故障描述必填"}]}>
                                <TextArea rows={1} style={{width: 200}}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="notes" label="备注">
                                <TextArea rows={1} style={{width: 200}}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col span={6}>
                            <div className={"product-picture-form-section"} style={{}}>
                                <h4>故障图片</h4>
                            </div>
                            <div className={"product-picture-content"}>
                                <Form.Item >
                                    <Dragger
                                        style={{height:20}}
                                        {...props}
                                        onPreview={handlePreview}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined/>
                                        </p>
                                        <p className="ant-upload-text">点击或者拖拉图片上传</p>
                                        <p className="ant-upload-hint">
                                            支持单个，多个图片上传
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </div>
                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancelPictureShow}>
                                <img alt="example" style={{width: '100%'}} src={previewImage}/>
                            </Modal>
                        </Col>

                    </Row>


                </Form>
            </div>

            <Modal cancelText="取消"
                   okText="确认" title="" open={openCheckIdOne} onOk={() => {
                setOpenCheckIdOne(false)
            }} onCancel={() => {
                setOpenCheckIdOne(false)
            }}>
                请选择一行数据
            </Modal>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default FaultFrom;



