const baseUrl = "/ord/upgrade/order";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    orderId:number,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    subscriberIdDesc: string;
    subUserId: number;
    subsInstanceId: number;
    instanceIdDesc: string;
    goodsIdDesc: string;
    sourceProductIdDesc: string;
    goodsPriceIdDesc: string;
    sourcePrice: number;
    upgradeModeCdDesc: string;
    upgradeProductIdDesc: string;
    upgradePriceIdDesc: string;
    upgradePrice: number;
    differValue: number;
    upgradeTime: string;
    statusCdDesc: string;
    notes: string;
}



export  {baseUrl};

