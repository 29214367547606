const baseUrl = "/pdt/goods/attribute";

/** 获取列表-参数 */
export interface PageParam {
    goodsId: number;
    attributeId: number;
}

//定义表格的属性
export interface AttributeTableDataType {
    id: number;
    goodsId: number;
    goodsName: string;
    attributeId: number;
    attributeCode: string;
    iconUrl: string;
    attributeTypeCdDesc: string;
    attributeTypeCd: string;
    attributeName: string;
    statusDesc: string;
    notes: string;
}

export {baseUrl};