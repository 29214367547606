import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/sub/fileUpload";
import commonApi from "../commonApi";

const fileUploadApi = {

    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "/page?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.instanceIdDesc) {
                url += 'instanceIdDesc=' + body.instanceIdDesc + "&";
            }
            if (body.subUserIdDesc) {
                url += 'subUserIdDesc=' + body.subUserIdDesc + "&";
            }
            if (body.subscriberIdDesc) {
                url += 'subscriberIdDesc=' + body.subscriberIdDesc + "&";
            }
            if (body.fileName) {
                url += 'fileName=' + body.fileName + "&";
            }
            if (body.filePath) {
                url += 'filePath=' + body.filePath + "&";
            }
            if (body.startTimeBegin) {
                url += 'startTimeBegin=' + body.startTimeBegin + "&";
            }
            if (body.startTimeEnd) {
                url += 'startTimeEnd=' + body.startTimeEnd + "&";
            }
            if (body.endTimeBegin) {
                url += 'endTimeBegin=' + body.endTimeBegin + "&";
            }
            if (body.endTimeEnd) {
                url += 'endTimeEnd=' + body.endTimeEnd + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"用户文件列表",body)
    },

}

export default fileUploadApi



