import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/app/catalogInfo";
import {request} from "../../utils";
import {PageRes} from "../types/common";
import commonApi from "../commonApi";

const catalogInfoApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.appName) {
                url += 'appName=' + encodeURIComponent(body.appName) + "&";
            }
            if (body.softType) {
                url += 'softType=' + encodeURIComponent(body.softType) + "&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //更新数据
    handleAppLoad() {
        let url = baseUrl + '/appLoad';
        return request.get(url)
    },
    handleAppDerive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"应用表",body)
    },

    batchForbidden(idList: Number[]) {
        let url = baseUrl;
        if (idList) {
            url += "/forbidden?"
            const params = [...idList].map((id) => `ids=${id}`).join('&');
            url += params;
        }
        return request.put(url);
    }
}

export default catalogInfoApi;



