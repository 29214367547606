const baseUrl = "/pub/channel/channelVersion";
/** 获取版本母包管理信息-参数 */
export interface PageParam  {
    versionCode: number;
    versionMust: number;
    clientId: string;
    statusDesc: string;
    channelCode: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    versionMust: number;
    versionCode: number;
    parentVersionId: number;
    versionTime: string;
    fileSize: string;
    statusDesc: string;
    versionDesc: string;
    filePath: string;
    clientId: string;
}

export  {baseUrl};

