import React, {useEffect} from 'react';
import {Form, Input, message, Modal, Select} from 'antd';

import userApi from "../../../../apis/sys/user";
import loginApi from "../../../../apis/login";
import {removeToken} from "../../../../utils";
import {useNavigate} from "react-router-dom";

const {Option} = Select;

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface ChangePasswordFromProps {
    closeOpen: () => void;
    open: boolean;


}

const ChangePasswordFrom: React.FC<ChangePasswordFromProps> = ({
                                                                   open,
                                                                   closeOpen,
                                                               }) => {

    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.resetFields();
    }, [open]);


    const [form] = Form.useForm();
    const navigate = useNavigate();

    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        userApi.changePassword(values).then((result) => {
            if (result) {
                handleCancel();
                loginApi.loginOut().then((res) => {
                    if (res) {
                        message.success("修改成功请重新登录")
                        removeToken();
                        navigate("/login")
                    }

                })
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            handleCreate();
        }).catch((error) => {

        });
    }


    return (
        <Modal
            title="修改密码"
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item
                    name="acctPwd"
                    label="密码"
                    rules={[
                        {
                            validator: async (_, value) => {
                                if (value) {
                                    const pattern = /^[A-Za-z0-9!@#$%^&*()_+\-=]{8,16}$/;
                                    if (pattern.test(value)) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject('请确保密码只包含字母、数字、特殊符号，并且长度为8-16位');
                                    }
                                }
                            },
                        },
                    ]}
                >
                    <Input.Password visibilityToggle={false}/>
                </Form.Item>
                <Form.Item
                    label="重复密码"
                    name="pwd"
                    dependencies={['acctPwd']}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                const acctPwd = getFieldValue('acctPwd');
                                if (acctPwd && !value) {
                                    return Promise.reject(new Error('重复密码必填'));
                                }
                                if (!value || acctPwd === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('您输入的新密码不匹配！'));
                            },
                        }),
                    ]}
                >
                    <Input.Password visibilityToggle={false}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default ChangePasswordFrom;



