const baseUrl = "/cus/fault";
export interface PageParam {
    empStaffName:string,
    statusCd:string,
    faultType:string,
    origin:string,
    instanceCode:string,
    onlineMark:string,
    handleMark:string,
    instanceReplace:string
}

//定义表格的属性
export interface TableDataType {
    workOrderId: number;
    empId: number;
    empStaffName: string;
    statusCd: string;
    statusCdDesc: string;
    subscriberId: number;
    subUserId: number;
    subscriberCode: string;
    phoneNumber: string;
    createTime: string;
    finishTime: string;
    beginTime: string;

    faultWorkOrderId: number;
    instanceId: number;
    instanceCode: string;
    faultType: string;
    faultTypeDesc: string;
    origin: string;
    originDesc: string;
    onlineMark: string;
    handleMark: string;
    desc: string;
    notes: string;
    // 图片数组
    pictureUrls: string[];
    // 更换的云手机编码
    afterReplaceInstanceCode: string;
}



export  {baseUrl};