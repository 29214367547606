import {request} from "../../utils";
import {OptionRes, PageRes} from "/../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/res/insTransfer";
import commonApi from "../commonApi";

function isValidTimeFormat(value) {
    // Assuming a simple check for the 'YYYY-MM-DD HH:mm:ss' format
    const timeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    return timeRegex.test(value);
}


const insTransferApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.insCode) {
                url += '&insCode=' + encodeURIComponent(body.insCode);
            }
            if (body.productName) {
                url += '&productName=' + encodeURIComponent(body.productName);
            }
            if (body.transferSubscriberCode) {
                url += '&transferSubscriberCode=' + encodeURIComponent(body.transferSubscriberCode);
            }
            if (body.receiveSubscriberCode) {
                url += '&receiveSubscriberCode=' + encodeURIComponent(body.receiveSubscriberCode);
            }
            if (body.beginInsTransferTime) {
                url += '&beginInsTransferTime=' + body.beginInsTransferTime;
            }
            if (body.endInsTransferTime) {
                url += '&endInsTransferTime=' + body.endInsTransferTime;
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    handleAppDerive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"转让云手机表",body)
    },

}

export default insTransferApi;
