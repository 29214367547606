import React, {useEffect, useState} from "react";
import {
    Button, ConfigProvider, DatePicker,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Space,
    Table,
    Typography
} from 'antd';
import {ColumnsType} from "antd/es/table";
import propertiesApi from "../../../../apis/sys/properties";
import {OptionRes} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import {TableDataType} from "../../../../apis/types/res/InsTask";
import insTaskApi from "../../../../apis/res/InsTask";
import dayjs from "dayjs";
import channelParentVersionApi from "../../../../apis/pub/channel/channelParentVersion";

const {RangePicker} = DatePicker;

const OrganContent = () => {
    //进入页面先查询数据
    useEffect(() => {
        handleQuery();
        propertiesApi.optinos("DM_Ins_Task_Type").then((res) => {
            if (res) {
                setTaskTypeOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Ins_Task_Result_Status").then((res) => {
            if (res) {
                setTaskResultStatusOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Ins_Task_Status").then((res) => {
            if (res) {
                setTaskStatusOption(res.data)
            }
        })
        propertiesApi.optinos("DM_Ins_Task_Source").then((res) => {
            if (res) {
                setTaskSourceOption(res.data)
            }
        })
    }, []);

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [open, setOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [taskTypeOption, setTaskTypeOption] = useState<OptionRes[]>([])
    const [taskResultStatusOption, setTaskResultStatusOption] = useState<OptionRes[]>([])
    const [taskStatusOption, setTaskStatusOption] = useState<OptionRes[]>([])
    const [taskSourceOption, setTaskSourceOption] = useState<OptionRes[]>([])
    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();

        var beginCreatedTime = form.getFieldValue('beginCreatedTime');
        var endCreatedTime = form.getFieldValue('endCreatedTime');
        var beginModifiedTime = form.getFieldValue('beginModifiedTime');
        var endModifiedTime = form.getFieldValue('endModifiedTime');

        body = {
            ...body,
            beginCreatedTime: beginCreatedTime ? dayjs(beginCreatedTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endCreatedTime: endCreatedTime ? dayjs(endCreatedTime).format('YYYY-MM-DD HH:mm:ss') : null,
            beginModifiedTime: beginModifiedTime ? dayjs(beginModifiedTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endModifiedTime: endModifiedTime ? dayjs(endModifiedTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };

        let res = insTaskApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        var beginCreatedTime = form.getFieldValue('beginCreatedTime');
        var endCreatedTime = form.getFieldValue('endCreatedTime');
        var beginModifiedTime = form.getFieldValue('beginModifiedTime');
        var endModifiedTime = form.getFieldValue('endModifiedTime');
        body = {
            ...body,
            beginCreatedTime: beginCreatedTime ? dayjs(beginCreatedTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endCreatedTime: endCreatedTime ? dayjs(endCreatedTime).format('YYYY-MM-DD HH:mm:ss') : null,
            beginModifiedTime: beginModifiedTime ? dayjs(beginModifiedTime).format('YYYY-MM-DD HH:mm:ss') : null,
            endModifiedTime: endModifiedTime ? dayjs(endModifiedTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        insTaskApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     render: (_: any, record: TableDataType) => {
        //         return <Typography.Link onClick={() => {
        //             setOpen(true)
        //             console.log("operation", record)
        //             setRowData(record);
        //         }}>
        //             编辑
        //         </Typography.Link>
        //     },
        //     ellipsis:true,
        //     width:80
        // },

        {title: "任务ID", dataIndex: "id", key: "id", ellipsis: true, width: 100},
        {title: "宿主机ID", dataIndex: "hostId", key: "hostId", ellipsis: true, width: 100},
        {title: "云手机ID", dataIndex: "instanceId", key: "instanceId", ellipsis: true, width: 100},
        {title: "云手机编码", dataIndex: "instanceCode", key: "instanceCode", ellipsis: true, width: 100},
        {
            title: "任务类型", dataIndex: "taskType", key: "taskType", ellipsis: true, width: 100,
            render: (taskType: string) => {
                const option = taskTypeOption.find((opt) => opt.value === taskType.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
        },
        {
            title: "任务结果", dataIndex: "taskResultStatus", key: "taskResultStatus", ellipsis: true, width: 100,
            render: (taskResultStatus: string | undefined) => {
                const option = taskResultStatusOption.find((opt) => opt.value === (taskResultStatus ?? "").toString());
                return <span>{taskResultStatus ? (option ? option.label : "") : ""}</span>;
            },
        },
        {
            title: "任务状态", dataIndex: "taskStatus", key: "taskStatus", ellipsis: true, width: 100,
            render: (taskStatus: string) => {
                const option = taskStatusOption.find((opt) => opt.value === taskStatus.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
        },
        {title: "任务结果信息", dataIndex: "taskResultInfo", key: "taskResultInfo", ellipsis: true, width: 100},
        {
            title: "任务触发来源", dataIndex: "taskSource", key: "taskSource", ellipsis: true, width: 100,
            render: (taskSource: string) => {
                const option = taskSourceOption.find((opt) => opt.value === taskSource.toString());
                return <span>{option ? option.label : "未知"}</span>;
            },
        },
        {title: "当前任务的重试次数", dataIndex: "curTryTimes", key: "curTryTimes", ellipsis: true, width: 100},
        {title: "创建人", dataIndex: "creater", key: "creater", ellipsis: true, width: 100},
        {title: "修改人", dataIndex: "modifier", key: "modifier", ellipsis: true, width: 100},
        {title: "创建时间", dataIndex: "createdTime", key: "createdTime", ellipsis: true, width: 100},
        {title: "修改时间", dataIndex: "modifiedTime", key: "modifiedTime", ellipsis: true, width: 100},
        {title: "备注信息", dataIndex: "note", key: "note", ellipsis: true, width: 100},
        {title: "任务请求信息", dataIndex: "taskExtraInfo", key: "taskExtraInfo", ellipsis: true, width: 100},

    ];

    //获取要查询的表单
    const [form] = Form.useForm();

    function cancel() {
        message.error('取消删除');
    }

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>

                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      labelAlign={"left"}
                      layout={"inline"}
                    // labelCol={{ span: 14 ,offset:14}}
                    // wrapperCol={{ span: 4 }}
                      size={"small"}
                      style={{maxWidth: 3000}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item style={{margin: 5}} name="hostId" label="宿主机ID">
                        <Input style={{width: 90}}/>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="instanceId" label="云手机ID">
                        <Input style={{width: 90}}/>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="instanceCode" label="云手机编码">
                        <Input style={{width: 90}}/>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="taskResultStatus" label="任务结果">
                        <Select
                            style={{width: 90}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={taskResultStatusOption}
                        />
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="taskStatus" label="任务状态">
                        <Select
                            style={{width: 90}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={taskStatusOption}
                        />
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="taskType" label="任务类型">
                        <Select
                            style={{width: 90}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={taskTypeOption}
                        />
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="taskSource" label="任务触发来源">
                        <Select
                            style={{width: 90}}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={taskSourceOption}
                        />
                    </Form.Item>

                    <Form.Item style={{margin: 5}} name="creater" label="创建人">
                        <Input style={{width: 90}}/>
                    </Form.Item>
                    <Form.Item style={{margin: 5}} name="modifier" label="修改人">
                        <Input style={{width: 90}}/>
                    </Form.Item>
                    {/* 这里的createdTimeRange参数实际上服务端接口是不需要的，是为了重置的时候把页面上的日期选择器显示重置掉   */}
                    <Form.Item style={{margin: 5}} label="创建时间" name={['createdTimeRange', 'dates']}>
                        <RangePicker
                            style={{width: 350}}
                            showTime
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginCreatedTime", null);
                                    form.setFieldValue("endCreatedTime", null);
                                } else if (Array.isArray(value)) {
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        console.log('onOk: ', date);
                                        form.setFieldValue("beginCreatedTime", date[0]);
                                        form.setFieldValue("endCreatedTime", date[1]);
                                    }
                                }
                            }}
                        />
                    </Form.Item>
                    {/* 这里的modifiedTimeRange参数实际上服务端接口是不需要的，是为了重置的时候把页面上的日期选择器显示重置掉   */}
                    <Form.Item style={{margin: 5}} label="修改时间" name={['modifiedTimeRange', 'dates']}>
                        <RangePicker
                            style={{width: 350}}
                            showTime
                            placeholder={['', '']}
                            onChange={(value) => {
                                if (value == null) {
                                    form.setFieldValue("beginModifiedTime", null);
                                    form.setFieldValue("endModifiedTime", null);
                                } else if (Array.isArray(value)) {
                                    let date = Array.from(value);
                                    if (date.length == 2) {
                                        form.setFieldValue("beginModifiedTime", date[0]);
                                        form.setFieldValue("endModifiedTime", date[1]);
                                    }
                                    console.log('onOk: ', date);
                                }
                            }}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">查询</Button>
                            <Button htmlType="reset" onClick={() => {
                                form.resetFields()

                                handleQuery()
                            }}>重置</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>


            <Divider/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}}
                       dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

        </div>
    )
}


export default OrganContent;