import React, {useEffect, useState} from 'react';
import {Form, Input, InputNumber, Modal, Select} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {TableDataType} from "../../../../apis/types/pub/area";
import areaApi from "../../../../apis/pub/area";
import {filterOption} from "../../../../apis/common";
import {OptionRes} from "../../../../apis/types/common";
import TextArea from "antd/es/input/TextArea";

// moment，并设置本地化语言为中文
moment.locale('zh-cn');
const {Option} = Select;


const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 16},
};


interface FormProps {
    refresh: () => void;
    closeOpen: () => void;
    record: TableDataType;
    open: boolean;
    model: String;
    // option
    areaGradeCdOption: OptionRes[];
    statusCdOption: OptionRes[];
}


const AreaFrom: React.FC<FormProps> = ({
                                           refresh, record, open, closeOpen, model,
                                           areaGradeCdOption, statusCdOption
                                       }) => {
    const [form] = Form.useForm();
    const [parentIdOption, setParentIdOption] = useState<OptionRes[]>([])

    // 编辑的时候回显数据
    useEffect(() => {
        if (open && record) {
            form.setFieldsValue({
                ...record,
            })
            let areaGradeCd = record.areaGradeCd;
            if (areaGradeCd != undefined && areaGradeCd !== "") {
                areaApi.getValues(record.areaGradeCd).then((res) => {
                    if (res) {
                        const list = res.data.map((key: { value: any; label: any; }) => {
                            return {
                                value: key.value,
                                label: key.label
                            }
                        });
                        setParentIdOption(list)
                    }

                })
            }
        }
    }, [open]);

    //处理新增方法
    const handleCreate = () => {
        areaApi.create(form.getFieldsValue()).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields();
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        const values = form.getFieldsValue();
        let valueJSOn = JSON.stringify(values);
        areaApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            if (model == 'create') {
                //做新增的逻辑
                handleCreate();
            } else {
                //做修改的逻辑
                handleUpdate()
            }
        })
            .catch((error) => {

            });
    }

    function handleAreaGradeCdChange(areaGradeCd: string) {
        if (areaGradeCd == undefined || areaGradeCd == "") {
            // 设置父参数值控件
            form.setFieldsValue({
                parentValue: [],
            });
            setParentIdOption([])
            return;
        }

        areaApi.getValues(areaGradeCd).then((res) => {
            if (res) {
                const list = res.data.map((key: { value: any; label: any; }) => {
                    return {
                        value: key.value,
                        label: key.label
                    }
                });
                setParentIdOption(list)
                // // 设置父参数值控件
                // form.setFieldsValue({
                //     parentAreaName: list[0],
                // });
            }

        })
    }

    return (
        <Modal
            title={model == 'create' ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}

            >

                <Form.Item name="id" label="区域编号" rules={[{required: true, message: "区域编号必填"}]}>
                    {
                        model == 'create'
                            ? <InputNumber min={1} style={{width: 200}}/>
                            : <InputNumber readOnly style={{width: 200}}/>
                    }
                </Form.Item>
                <Form.Item name="areaName" label="区域名称" rules={[{required: true, message: "区域名称必填"}]}>
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="englishName" label="英文名称">
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="abbrName" label="区域简称">
                    <Input style={{width: 200}}/>
                </Form.Item>
                <Form.Item name="areaGradeCd" label="区域等级" rules={[{required: true, message: "区域等级必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={areaGradeCdOption}
                        onChange={handleAreaGradeCdChange} // 添加onChange事件处理函数
                    />
                </Form.Item>
                <Form.Item name="parentId" label="父级区域">
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={parentIdOption}
                    />
                </Form.Item>
                <Form.Item name="hotMark" label="热门区域" rules={[{required: true, message: "热门区域必填"}]}>
                    <Select allowClear style={{width: 200}}>
                        <Option value="1">是</Option>
                        <Option value="0">否</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态必填"}]}>
                    <Select
                        style={{width: 200}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusCdOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 200}}/>
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default AreaFrom;



