import React, {useEffect} from 'react';
import {Form, Input, Modal} from 'antd';

import msgRecipientApi from "../../../../../apis/opt/msgRecipient";
import TextArea from "antd/es/input/TextArea";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface MsgCollectInstanceFormProps {
    refresh: () => void;
    closeOpen: () => void;
    open: boolean;
    type: boolean;
    msgId: number;
    msgTypeCd: string;
    onUpdateList: (data) => void;
}

const MsgCollectInstanceForm: React.FC<MsgCollectInstanceFormProps> = ({
                                                                           refresh,
                                                                           open,
                                                                           type,
                                                                           closeOpen,
                                                                           msgId,
                                                                           msgTypeCd,
                                                                           onUpdateList
                                                                       }) => {

    useEffect(() => {
        // 在组件挂载时，设置表单数据为record的值
        form.setFieldsValue({
            msgId: msgId,
            msgType: msgTypeCd
        });
    }, [open]);

    const [form] = Form.useForm();
    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        msgRecipientApi.create(values).then((result) => {
            if (result) {
                onUpdateList(result.data)
                handleCancel();
                refresh();
            }
        });
    };

    //处理取消方法
    const handleCancel = () => {
        form.resetFields()
        closeOpen()
    };

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            handleCreate();
        }).catch((error) => {

        });
    }


    return (
        <Modal
            title={type ? "新增项目" : "编辑项目"}
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}

        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 300}}
            >
                <Form.Item name={"msgId"} hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"msgType"} hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name="instanceIps" label="云手机IP">
                    <TextArea rows={4} style={{width: 200}} placeholder={"使用英文,分隔"}/>
                </Form.Item>
                <Form.Item name="instanceCodes" label="云手机编号">
                    <TextArea rows={4} style={{width: 200}} placeholder={"使用英文,分隔"}/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default MsgCollectInstanceForm;