import React, {useEffect, useState} from "react";
import {
    Button,
    ConfigProvider, DatePicker,
    Divider,
    Form, message,
    Pagination,
    PaginationProps, Popconfirm,
    Select,
    Table,
} from 'antd';
import {ColumnsType} from "antd/es/table";
import Details from "../../../public/details/details";
import {TableDataType} from "../../../../../apis/types/res/UnStatusList";
import unStatusApi from "../../../../../apis/res/ubStatusList";
import ManageNodeApi from "../../../../../apis/res/manageNode";
import {OptionRes} from "../../../../../apis/types/common";
import dayjs from "dayjs";


const UnStatusListContent = () => {

    const [total, setTotal] = useState<number>()
    const [paginationLocale, setPaginationLocale] = useState({
        items_per_page: '/ 页',
        jump_to: '跳转到',
        jump_to_confirm: '确定',
        page: '',
        prev_page: '上一页',
        next_page: '下一页',
        prev_5: '向前 5 页',
        next_5: '向后 5 页',
        prev_3: '向前 3 页',
        next_3: '向后 3 页',
    });
    const [pagination, setPagination] = useState<PaginationProps>({
        current: 1,
        pageSize: 20,
        total: 0
    })
    const handlePaginationChange = (page: number, pageSize: number) => {
        setCurrentPage(page);
        setPagination({
            current: page,
            pageSize: pageSize
        })
    };

    //当分页条件变化的时候，就去刷新页面。
    useEffect(() => {
        handleQuery();
    }, [pagination]);

    //进入页面先查询数据
    useEffect(() => {
        getDictList();
        ManageNodeApi.options().then((res) => {
            if (res) {
                setManageOption(res.data)
            }
        })
    }, []);


    async function getDictList() {

    }

    //定义用到的useState，用来绑定数据
    const [currentPage, setCurrentPage] = useState(1);
    const [list, setList] = useState<TableDataType[]>([]);
    const [detailsOpen, setDetailsOpen] = useState(false)
    const [rowData, setRowData] = useState<TableDataType>({} as TableDataType)
    const [rowData1, setRowData1] = useState<TableDataType>({} as TableDataType)
    const [manageOption, setManageOption] = useState<OptionRes[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);


    //处理查询
    function handleQuery() {
        let body = form.getFieldsValue();
        body = {
            ...body,
            onlineModifyTime: body.onlineModifyTime ? dayjs(body.onlineModifyTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        let res = unStatusApi.pageData(body, pagination);
        res.then((result) => {
            if (result) {
                setList(result.data.records)
                setTotal(result.data.total)
                setSelectedRowKeys([]);
            }
        })

    }

    const [messageApi, contextHolder] = message.useMessage();
    const handleAppDerive = () => {
        let body = form.getFieldsValue();
        body = {
            ...body,
            onlineModifyTime: body.onlineModifyTime ? dayjs(body.onlineModifyTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        unStatusApi.derive(body);
    }
    function confirmDerive() {
        handleAppDerive();
    }

    function cancelDerive() {
        messageApi.open({
            type: 'error',
            content: '取消',
            className: 'custom-class',
            style: {
                margin: "auto",
                top: "40vh",
                position: "relative"
            },
        });
    }

    //表格的内容
    const columns: ColumnsType<TableDataType> = [
        {
            title: '云手机编号',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: 150
        },
        {
            title: '云手机编码',
            dataIndex: 'instanceCode',
            key: 'instanceCode',
            ellipsis: true,
            width: 150
        },
        {
            title: '机房名称',
            dataIndex: 'idcName',
            key: 'idcName',
            ellipsis: true,
            width: 150
        },
        {
            title: '机柜编码',
            dataIndex: 'cabinetCode',
            key: 'cabinetCode',
            ellipsis: true,
            width: 100
        },
        {
            title: '宿主机编码',
            dataIndex: 'hostCode',
            key: 'hostCode',
            ellipsis: true,
            width: 100
        },
        {
            title: 'IP地址',
            dataIndex: 'instanceIp',
            key: 'instanceIp',
            ellipsis: true,
            width: 100
        },
        {
            title: '云手机SN',
            dataIndex: 'instanceSn',
            key: 'instanceSn',
            ellipsis: true,
            width: 100
        },
        {
            title: '网关名称',
            dataIndex: 'gatewayName',
            key: 'gatewayName',
            ellipsis: true,
            width: 100
        },
        {
            title: '管理节点名称',
            dataIndex: 'manageNodeName',
            key: 'manageNodeName',
            ellipsis: true,
            width: 100
        },
        {
            title: '状态变更时间',
            dataIndex: 'onlineModifyTime',
            key: 'onlineModifyTime',
            ellipsis: true,
            width: 100
        },
        {
            title: '激活标识',
            dataIndex: 'activateMarkDesc',
            key: 'activateMarkDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '在线标识',
            dataIndex: 'onlineMarkDesc',
            key: 'onlineMarkDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '操控标识',
            dataIndex: 'handleMarkDesc',
            key: 'handleMarkDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '故障标识',
            dataIndex: 'faultMarkDesc',
            key: 'faultMarkDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '启用标识',
            dataIndex: 'enableMarkDesc',
            key: 'enableMarkDesc',
            ellipsis: true,
            width: 100
        },
        {
            title: '维护标识',
            dataIndex: 'maintainMarkDesc',
            key: 'maintainMarkDesc',
            ellipsis: true,
            width: 100
        },
    ];

    //获取要查询的表单
    const [form] = Form.useForm();

    return (

        <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Popconfirm
                    title="是否确认导出筛选的数据？"
                    onConfirm={confirmDerive}
                    onCancel={cancelDerive}
                    okText="是"
                    cancelText="否"
                >
                    <Button type="primary" danger style={{marginRight: '25px'}}>
                        导出
                    </Button>
                </Popconfirm>

                {/*查询的表单*/}
                <Form form={form} name="validateOnly" layout="inline" autoComplete="off"
                      style={{marginLeft: 'auto'}}
                      onFinish={() => {
                          pagination.current = 1;
                          setCurrentPage(1);
                          handleQuery();
                      }}
                >
                    <Form.Item name="onlineModifyTime" label="状态最后变更时间">
                        <DatePicker showTime/>
                    </Form.Item>
                    <Form.Item name="manageNodeId" label="管理节点">
                        <Select
                            allowClear={true}
                            style={{width: 120}}
                            options={manageOption}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">查询</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={() => {
                            const body = {};
                            unStatusApi.pageData(body, pagination).then((res) => {
                                if (res) {
                                    setList(res.data.records)
                                    setTotal(res.data.total)
                                    setSelectedRowKeys([]);
                                }
                            })
                        }}>重置</Button>
                    </Form.Item>
                </Form>
            </div>

            <Divider style={{height: 1, marginTop: 10, marginBottom: 0}}/>

            <ConfigProvider theme={{components: {Table: {cellFontSizeSM: 12, cellPaddingBlockSM: 4}}}}>

                {/*表格内容*/}
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: (event) => {
                                setDetailsOpen(true);
                                setRowData1(record);
                            }
                        }
                    }}
                    size="small" scroll={{scrollToFirstRowOnChange: true, x: 'max-content', y: "70vh"}}
                    dataSource={list} columns={columns} rowKey={record => record.id} pagination={false}/>

            </ConfigProvider>

            {/*表格的分页*/}
            <div style={{position: 'fixed', bottom: 0, right: 0, justifyContent: 'flex-end', padding: '10px'}}>
                <Pagination size="small" style={{marginLeft: 'auto'}}
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={currentPage}
                            defaultPageSize={20}
                            locale={paginationLocale}
                            onChange={handlePaginationChange}
                            showTotal={(total) => `一共 ${total} 条数据`}
                />
            </div>

            <Details
                closeOpen={() => {
                    setDetailsOpen(false);
                    setRowData({} as TableDataType)
                }}
                record={rowData1}
                open={detailsOpen}
                columns={columns}
            ></Details>

        </div>
    )
}


export default UnStatusListContent;