import moment from "moment";

const baseUrl = "/sys/organ";
/** 获取产品申请列表-参数 */
export interface PageParam  {
    organName:string,
    organTypeCd:string,
}

//定义表格的属性
export interface TableDataType {
    id: number;
    organName: string;
    organTypeCd: string;
    relaObject: string;
    parentId: number;
    statusDesc: string;
    notes: string;
}



export  {baseUrl};

