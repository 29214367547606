import {request} from "../../../utils";
import {OptionRes, PageRes} from "../../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../../types/app/apk/notAllowInstall";
import commonApi from "../../commonApi";

const notAllowInstallApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.packageName) {
                url += 'packageName=' + encodeURIComponent(body.packageName)+"&";
            }
            if (body.appName) {
                url += 'appName=' + encodeURIComponent(body.appName)+"&";
            }
            if (body.statusCd) {
                url += 'statusCd=' + body.statusCd+"&";
            }
            if (body.appDomain){
                url += 'appDomain=' + body.appDomain+"&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    optinos(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"apk黑名单列表",body)
    },
    batchEnable(body: any) {
        let url = baseUrl + '/enable';
        return request.post(url, body);
    },

    batchDisable(body: any) {
        let url = baseUrl + '/disable';
        return request.post(url, body);
    },
}

export default notAllowInstallApi;



