import {request} from "../../utils";
import {PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {PageParam, baseUrl, TableDataType} from "../types/res/UnStatusList";
import commonApi from "../commonApi";

const unStatusApi = {
    pageData(body: PageParam, pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url += "current=" + pagination.current + "&";
        url += "size=" + pagination.pageSize + "&";
        if (body) {
            if (body.manageNodeId) {
                url += 'manageNodeId=' + body.manageNodeId + "&";
            }
            if (body.onlineModifyTime) {
                url += 'onlineModifyTime=' + body.onlineModifyTime + "&";
            }
        }

        return request.get<PageRes<TableDataType>>(url);

    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"宕机云手机列表",body)
    },
}

export default unStatusApi



