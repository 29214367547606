import React, {useEffect, useState} from 'react';
import {DatePicker, Form, Input, message, Modal, Select, TreeSelect} from 'antd';

import {OptionRes, TreeOption} from "../../../../apis/types/common";
import {filterOption} from "../../../../apis/common";
import dayjs from "dayjs";
import roleAuthApi from "../../../../apis/sys/roleAuth";
import actionApi from "../../../../apis/sys/action";
import {Simulate} from "react-dom/test-utils";
import propertiesApi from "../../../../apis/sys/properties";
import TextArea from "antd/es/input/TextArea";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};


interface StaffFromProps {
    refresh: () => void;
    closeOpen: () => void;
    recordId: number;
    open: boolean;
}

const RoleAuthForm: React.FC<StaffFromProps> = ({
                                                    refresh,
                                                    recordId,
                                                    open,
                                                    closeOpen,
                                                }) => {


    const [resourceOption, setResourceOption] = useState<TreeOption[]>([])
    const [statusOption, setStatusOption] = useState<OptionRes[]>([])

    const [form] = Form.useForm();
    useEffect(() => {
        if (open && recordId) {
            handleQuery();
            form.setFieldsValue({
                roleId: recordId,
            })
        }
        propertiesApi.optinos('DM_Status').then((res) => {
            setStatusOption(res.data)
        })
    }, [open]);

    function handleQuery() {
        roleAuthApi.getById(recordId).then((reset) => {
            if (reset&&reset.data) {
                form.setFieldsValue({
                    effTime: reset.data.effTime ? dayjs(reset.data.effTime) : null,
                    expTime: reset.data.expTime ? dayjs(reset.data.expTime) : null,
                    notes: reset.data.notes,
                    statusCd: reset.data.statusCd,
                    roleId: reset.data.roleId,
                    actionIds:reset.data.actionIds
                })
            }
        })
        actionApi.listOptinos().then((reset) => {
            setResourceOption(reset.data);
        })
    }



    //处理新增方法
    const handleCreate = () => {
        let values = form.getFieldsValue();
        values = {
            ...values,
            expTime: values.expTime ? dayjs(values.expTime).format('YYYY-MM-DD HH:mm:ss') : null,
            effTime: values.effTime ? dayjs(values.effTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        let valueJSOn = JSON.stringify(values);
        roleAuthApi.create(valueJSOn).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }

        });
    };

    //处理取消方法
    const handleCancel = () => {
        setValue([])
        form.resetFields()
        closeOpen()
    };

    //处理更新方法
    function handleUpdate() {
        let values = form.getFieldsValue();
        values = {
            ...values,
            actionIds: value,
            expTime: values.expTime ? dayjs(values.expTime).format('YYYY-MM-DD HH:mm:ss') : null,
            effTime: values.effTime ? dayjs(values.effTime).format('YYYY-MM-DD HH:mm:ss') : null,
        };
        let valueJSOn = JSON.stringify(values);
        roleAuthApi.update(JSON.parse(valueJSOn).id, values).then((result) => {
            if (result) {
                handleCancel();
                refresh();
            }
        });

    }

    //处理ok按钮，
    function handleOK() {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.id == null) {
                // 做新增的逻辑
                handleCreate();
            } else {
                // 做修改的逻辑
                handleUpdate();
            }
        })
            .catch((error) => {

            });
    }


    const {SHOW_PARENT} = TreeSelect;
    const [value, setValue] = useState<number[]>([]);

    const onChange = (newValue: number[]) => {
        setValue({
            ...newValue
        });
    };

    return (
        <Modal
            title="新增项目"
            open={open}
            okText={"保存"}
            cancelText={"取消"}
            onCancel={handleCancel}
            onOk={handleOK}
        >
            <Form
                {...layout}
                form={form}
                name="control-hooks"
                style={{maxWidth: 600}}
            >
                <Form.Item name="id" label="id值" hidden={true}>
                </Form.Item>
                <Form.Item name="roleId" label="角色名称" hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name="actionIds" label="资源名称" rules={[{required: true, message: "资源名称不能为空"}]}>
                    <TreeSelect
                        treeData={resourceOption}
                        value={value}
                        onChange={onChange}
                        treeCheckable={true}
                        defaultValue={value}
                        showCheckedStrategy={TreeSelect.SHOW_ALL}
                        placeholder={'Please select'}
                        maxTagCount={0}
                        treeDefaultExpandAll={true}
                        listHeight={600}
                        style={{width: 300}}
                    />
                </Form.Item>
                <Form.Item name="effTime" label="生效时间" rules={[{required: true, message: "生效时间不能为空"}]}>
                    <DatePicker showTime placeholder={''} style={{width: 300}}/>
                </Form.Item>
                <Form.Item name="expTime" label="失效时间" rules={[{required: true, message: "失效时间不能为空"}]}>
                    <DatePicker showTime placeholder={''} style={{width: 300}}/>
                </Form.Item>
                <Form.Item name="statusCd" label="状态" rules={[{required: true, message: "状态不能为空"}]}>
                    <Select
                        style={{width: 300}}
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        filterOption={filterOption}
                        options={statusOption}
                    />
                </Form.Item>
                <Form.Item name="notes" label="备注">
                    <TextArea rows={1} style={{width: 300}}/>
                </Form.Item>


            </Form>
        </Modal>
    );
};

/**
 * 查询的表单
 * @constructor
 */


export default RoleAuthForm;



