import React from "react";

const baseUrl = "/res/insTransfer";
/** 云手机任务管理-参数 */
export interface PageParam  {
    insCode: string;
    productName: string;
    transferSubscriberCode: string;
    receiveSubscriberCode: string;
    beginInsTransferTime: string;
    endInsTransferTime: string;
}

//定义表格的属性
export interface TableDataType {
    sequenceNumber: number;
    insCode: string;
    productName: string;
    leftControlTime: number;
    transferSubscriberCode: string;
    receiveSubscriberCode: string;
    transferResultDesc: string;
    createTime: string;
}

export  {baseUrl};