const baseUrl = "/res/insGrant";

//定义表格的属性
export interface TableDataType {
    id: number;
    grantToUserId: number;
    grantFromUserId: number;
    subInsId: number;
    insId: number;
    grantWay: number;
    grantType: number;
    grantControl: number;
    grantStartTime: string;
    grantEndTime: string;
    grantOptTime: string;
    statusDesc: string;
    note: string;
}

export  {baseUrl};

