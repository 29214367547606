import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PaginationProps} from "antd";
import {baseUrl, PageParam, TableDataType} from "../types/res/idc";
import commonApi from "../commonApi";

const idcApi = {
    // 获取产品申请列表
    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.idcName) {
                url += 'idcName=' + encodeURIComponent(body.idcName)+"&";
            }
            if (body.idcCode) {
                url += 'idcCode=' + encodeURIComponent(body.idcCode)+"&";
            }
            if (body.idcAddr) {
                url += 'idcAddr=' + encodeURIComponent(body.idcAddr)+"&";
            }
            if (body.idcTypeCd) {
                url += 'idcTypeCd=' + body.idcTypeCd+"&";
            }
            if (body.releasedStatus) {
                url += 'releasedStatus=' + body.releasedStatus+"&";
            }

            let areaId = body.areaId;
            if (areaId) {
                url += 'areaId=' + areaId+"&";
            }
        }
        return request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },
    //查询下拉列表数据
    optinos(){
        let url=baseUrl;
        url+="/options"
        return   request.get<OptionRes[]>(url)
    },
    //查询所有的机房
    getAllIdc(){
        const data = request.get(baseUrl).then((result) => {
            return result;
        })
        return data;
    },
    derive(body: PageParam){
        let url = baseUrl;
        url += "/derive?"
        commonApi.derive(url,"机房列表",body)
    },

}

export default idcApi



