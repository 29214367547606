import {request} from "../../utils";
import {OptionRes, PageRes} from "../types/common";
import {PageParam, TableDataType, baseUrl} from "../types/res/instancePhone";
import {PaginationProps} from "antd";

const instancePhoneApi = {

    pageData(body: PageParam,pagination: PaginationProps) {
        let url = baseUrl;
        url += "?"
        url+="current="+pagination.current+"&";
        url+="size="+pagination.pageSize+"&";
        if (body) {
            if (body.phoneModelId) {
                url += 'phoneModelId=' + body.phoneModelId;
            }
            if (body.phoneImei) {
                url += 'phoneImei=' + encodeURIComponent(body.phoneImei);
            }
            if (body.androidId) {
                url += 'androidId=' + body.androidId;
            }
            if (body.serialNo) {
                url += 'serialNo=' + encodeURIComponent(body.serialNo);
            }
            if (body.wifiMac) {
                url += 'wifiMac=' + encodeURIComponent(body.wifiMac);
            }
        }
        return  request.get<PageRes<TableDataType>>(url);

    },
    //批量删除
    batchDelete(uidList: Number[]) {
        let url = baseUrl;
        if (uidList) {
            url += "?"
            const params = [...uidList].map((uid) => `ids=${uid}`).join('&');
            url += params;
        }
        return request.delete(url);
    },
    //创建新表单
    create(body: any) {
        return request.post(baseUrl, body)
    },
    //更新数据
    update(id: Number, body: any) {
        let url = baseUrl + '/' + id;
        return request.put(url, body)
    },

    // 获取手机参数
    queryPhoneParams(id: Number) {
        const body = {
            id : id
        }
        let url = baseUrl + "/params?id=" + id;
        return request.get(url)
    },

    // 获取品牌
    queryAllBrandsAndModels() {
        let url = baseUrl + "/brandModelInfo";
        return request.get(url)
    },

}

export default instancePhoneApi



