const baseUrl = "/pub/label";
/** 获取列表-参数 */
export interface PageParam {
    labelTypeCd: string;
    labelName: string;
}

//定义表格的属性
export interface TableDataType {
    id: number;
    labelTypeCd: string;
    labelName: string;
    labelCode: string;
    hotMark: string;
    hotMarkDesc: string;
    statusCd: string;
    notes: string;
}



export  {baseUrl};
